import React from "react";
import { Bar, Pie, Doughnut, PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  RadialLinearScale
);

export const BarChart = ({ chartData, options }: any) => {
  return (
    <>
      <Bar
        data={chartData}
        options={options}
        // redraw={true}
      />
    </>
  );
};

export const PieChart = ({ chartData, options }: any) => {
  return (
    <>
      <Pie data={chartData} />
    </>
  );
};

export const DoughnutChart = ({ chartData, options }: any) => {
  return (
    <>
      <Doughnut data={chartData} options={options} />
    </>
  );
};

export const PolarAreaChart = ({ chartData, options }: any) => {
  return (
    <>
      <PolarArea data={chartData} options={options} />
    </>
  );
};
