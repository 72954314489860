import { Button, Grid, Modal, Switch, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import classes from "../pages/styleModules/AppShellMainStyles.module.css";

interface Props {
  editBusinessOpened: boolean;
  handleBusinessProfileEdit: any;
  data: any;
  handleBusinessClose: any;
}

interface EditUserProfileProps {
  CIN: string;
  GSTN: string;
  address: string;
  businessId: string;
  businessName: string;
  city: string;
  fax: string;
  multiOutlet: boolean;
}

const EditBusinessProfile = ({
  editBusinessOpened,
  handleBusinessProfileEdit,
  handleBusinessClose,
  data,
}: Props) => {
  const form = useForm<EditUserProfileProps>({
    validateInputOnChange: true,
    initialValues: {
      CIN: data?.CIN ?? "",
      GSTN: data?.GSTN ?? "",
      address: data?.address ?? "",
      businessId: data?._id ?? "",
      businessName: data?.businessName ?? "",
      city: data?.city ?? "",
      fax: data?.fax ?? "",
      multiOutlet: data?.multiOutlet ?? false,
    },
    validate: {
      CIN: (value) =>
        value.length !== 21 && value.length > 0
          ? "Must be 21 characters"
          : null,
      GSTN: (value) =>
        value.length !== 15 && value.length > 0
          ? "Must be 15 characters"
          : null,
      address: (value) =>
        value.length <= 2 ? "Should atleast have 2 characters" : null,
      businessId: (value) => (value.length <= 30 ? null : "Must be 30 or less"),
      businessName: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 50
          ? null
          : "Must be 50 or less",
      city: (value) =>
        value.length <= 2
          ? "Should atleast have 2 characters"
          : value.length > 30
          ? "Must be less than 30"
          : null,
      fax: (value) => (value.length > 20 ? "Must be less than 20" : null),
    },
  });

  useEffect(() => {
    form.setValues({
      CIN:
        data?.CIN !== undefined && data?.CIN !== null ? data?.CIN.trim() : "",
      GSTN:
        data?.GSTN !== undefined && data?.GSTN !== null
          ? data?.GSTN.trim()
          : "",
      address: data?.address ?? "",
      businessId: data?._id ?? "",
      businessName: data?.businessName ?? "",
      city: data?.city ?? "",
      fax:
        data?.fax !== undefined && data?.fax !== null ? data?.fax.trim() : "",
      multiOutlet: data?.multiOutlet ?? false,
    });
  }, [data]);

  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      size="lg"
      closeOnClickOutside={false}
      opened={editBusinessOpened}
      onClose={() => {
        handleBusinessClose();
        form.setValues({
          CIN:
            data?.CIN !== undefined && data?.CIN !== null
              ? data?.CIN.trim()
              : "",
          GSTN:
            data?.GSTN !== undefined && data?.GSTN !== null
              ? data?.GSTN.trim()
              : "",
          address: data?.address ?? "",
          businessId: data?._id ?? "",
          businessName: data?.businessName ?? "",
          city: data?.city ?? "",
          fax:
            data?.fax !== undefined && data?.fax !== null
              ? data?.fax.trim()
              : "",
          multiOutlet: data?.multiOutlet ?? false,
        });
      }}
      title="Edit Business Profile"
    >
      <form
        onSubmit={form.onSubmit((values: any) =>
          handleBusinessProfileEdit(values)
        )}
      >
        <Grid>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextInput
              value={form.values.businessName}
              error={form.errors.businessName}
              withAsterisk
              label="Business Name"
              placeholder="50 MAX"
              maxLength={50}
              onChange={(e) =>
                form.setFieldValue("businessName", e.currentTarget.value)
              }
            />
            <TextInput
              type="number"
              value={form.values.CIN}
              error={form.errors.CIN}
              label="CIN"
              placeholder="30 MAX"
              onChange={(e) => form.setFieldValue("CIN", e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextInput
              value={form.values.GSTN}
              error={form.errors.GSTN}
              label="GSTN"
              placeholder="20 MAX"
              onChange={(e) =>
                form.setFieldValue("GSTN", e.currentTarget.value)
              }
            />

            <TextInput
              withAsterisk
              multiline
              value={form.values.address}
              error={form.errors.address}
              label="Address"
              placeholder="150 MAX"
              onChange={(e) =>
                form.setFieldValue("address", e.currentTarget.value)
              }
            />
          </Grid.Col>

          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextInput
              withAsterisk
              value={form.values.city}
              error={form.errors.city}
              label="City"
              placeholder="150 MAX"
              onChange={(e) =>
                form.setFieldValue("city", e.currentTarget.value)
              }
            />

            <TextInput
              type="number"
              value={form.values.fax}
              error={form.errors.fax}
              label="Fax"
              placeholder="150 MAX"
              onChange={(e) => form.setFieldValue("fax", e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col>
            <Switch
              checked={form.values.multiOutlet}
              error={form.errors.multiOutlet}
              type="switch"
              label="MultiOutlet"
              placeholder="150 MAX"
              onChange={(e) =>
                form.setFieldValue("multiOutlet", e.currentTarget.checked)
              }
            />
          </Grid.Col>
        </Grid>
        <Button type="submit" mt={12}>
          Update
        </Button>
      </form>
    </Modal>
  );
};

export default EditBusinessProfile;
