import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../awatarStore/store";
import {
  getSubscriptionOffers,
  razorPay,
  subscriptionTransaction,
  subscriptionTransactionPatch,
  getDeviceSubscriptionInfo,
  FetchSubscriptionTransactions,
} from "./subscriptionActions";
import {
  GetSubscriptionProps,
  GetSubscriptionTransactionPatchProps,
  GetSubscriptionTransactionProps,
  RazorPayProps,
  GetFetchSubscriptionProps,
} from "./service/subscriptionService";

export const useGetSubscriptionOffers = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["subscription/subscriptionOffers"].loading
  );
  const error = useSelector(
    (state: RootState) => state["subscription/subscriptionOffers"].error
  );
  const data = useSelector(
    (state: RootState) => state["subscription/subscriptionOffers"].data
  );

  const getSubscriptionOffersFunction = async (
    subscriptionData: GetSubscriptionProps
  ) => {
    await dispatch(getSubscriptionOffers(subscriptionData));
  };

  return { getSubscriptionOffersFunction, loading, error, data };
};

export const useGetDeviceSubscriptionInfo = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["subscription/deviceSubscriptionInfo"].loading
  );
  const error = useSelector(
    (state: RootState) => state["subscription/deviceSubscriptionInfo"].error
  );
  const data = useSelector(
    (state: RootState) => state["subscription/deviceSubscriptionInfo"].data
  );

  const getDeviceSubscriptionInfoFunction = async () => {
    await dispatch(getDeviceSubscriptionInfo());
  };

  return { getDeviceSubscriptionInfoFunction, loading, error, data };
};

export const useRazorPay = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["subscription/razorPay"].loading
  );
  const error = useSelector(
    (state: RootState) => state["subscription/razorPay"].error
  );
  const data: any = useSelector(
    (state: RootState) => state["subscription/razorPay"].data
  );

  const razorPayFunction = async (razorPayData: RazorPayProps) => {
    await dispatch(razorPay(razorPayData));
  };

  return { razorPayFunction, loading, error, data };
};

export const useSubscriptionTransaction = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["subscription/subscriptionTransaction"].loading
  );
  const error = useSelector(
    (state: RootState) => state["subscription/subscriptionTransaction"].error
  );
  const data = useSelector(
    (state: RootState) => state["subscription/subscriptionTransaction"].data
  );

  const setSubscriptionFunction = async (
    subscriptionData: GetSubscriptionTransactionProps
  ) => {
    const res:any = await dispatch(subscriptionTransaction(subscriptionData));
    return res;
  };

  return { setSubscriptionFunction, loading, error, data };
};

export const useSubscriptionTransactionPatch = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) =>
      state["subscription/subscriptionTransactionPatch"].loading
  );
  const error = useSelector(
    (state: RootState) =>
      state["subscription/subscriptionTransactionPatch"].error
  );
  const data = useSelector(
    (state: RootState) =>
      state["subscription/subscriptionTransactionPatch"].data
  );

  const setSubscriptionPatchFunction = async (
    subscriptionPatchData: GetSubscriptionTransactionPatchProps
  ) => {
    await dispatch(subscriptionTransactionPatch(subscriptionPatchData));
  };

  return { setSubscriptionPatchFunction, loading, error, data };
};

export const useFetchSubscriptionTransaction = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) =>
      state["subscription/getFetchSubscriptionTransactions"].loading
  );
  const error = useSelector(
    (state: RootState) =>
      state["subscription/getFetchSubscriptionTransactions"].error
  );
  const data = useSelector(
    (state: RootState) =>
      state["subscription/getFetchSubscriptionTransactions"].data
  );

  const FetchSubscriptionTrsactionsFunction = async (
    fetchedsubscriptionData: GetFetchSubscriptionProps
  ) => {
    await dispatch(FetchSubscriptionTransactions(fetchedsubscriptionData));
  };

  return { FetchSubscriptionTrsactionsFunction, loading, error, data };
};
