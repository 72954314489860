import React, { useEffect, useState } from "react";
import { useGetDevice } from "../../modules/device/deviceHooks";
import { GetDeviceProps } from "../../modules/device/service/deviceService";
import { IconX } from "@tabler/icons-react";
import {
  Card,
  Divider,
  Grid,
  Text,
  Combobox,
  InputBase,
  Input,
  useCombobox,
  Title,
  ScrollArea,
  useMantineColorScheme,
  Skeleton,
  Tooltip,
  ActionIcon,
  useMantineTheme,
  TextInput,
  Button,
} from "@mantine/core";

import moment from "moment";
import { useGetSubscriptionOffers } from "../../modules/subscription/subscriptionHooks";
import { GetSubscriptionProps } from "../../modules/subscription/service/subscriptionService";
import SubscriptionTransaction from "./SubscriptionTransaction";
import { useDispatch } from "react-redux";

import {
  addData,
  razorPaySlice,
  removeData,
  reset,
} from "../../modules/subscription/subscriptionSlice";
import { convertToIndianCurrency } from "../../utils/utils";
import { IconTrash } from "@tabler/icons-react";
import { Helmet } from "react-helmet-async";
import { notifications } from "@mantine/notifications";

interface TableTTProps {
  visibility?: boolean;
  data?: any[];
  subdata?: any;
  onModifiedData?: any;
  removeItem: (data: any) => void;
}

const Subscription = () => {
  const dispatch = useDispatch();
  const { colorScheme } = useMantineColorScheme();
  // const isDark = colorScheme === "dark";
  const isLight = colorScheme === "light";

  const {
    fetchDevice,
    data: DeviceData,
    loading: DeviceLoading,
  } = useGetDevice();
  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: false,
  };
  const [tableVisibility, setTableVisibility] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState<any[]>([]);
  const [DisplayData, setDisplayData] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    fetchDevice(getDeviceData);
    // getSubscriptionOffersFunction(offerData);
  }, []);
  useEffect(() => {
    dispatch(reset());
    dispatch(razorPaySlice.actions.resetState());
  }, []);

  useEffect(() => {
    setDisplayData(DeviceData);
  }, [DeviceData]);

  // function clearArray() {
  //   setSelectedDevice([]);
  // }

  const clearArray = () => {
    fetchDevice(getDeviceData);

    setSelectedDevice([]);
  };
  // useEffect(() => {
  //   fetchDevice(getDeviceData);
  // }, [clearArray]);

  const dateDifferenceFromToday = (date1: moment.MomentInput) => {
    let startDate = moment(date1, "YYYY-MM-DD");
    let currentDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(currentDate, "YYYY-MM-DD");
    let result = startDate.diff(endDate, "days");
    return result;
  };

  const handleCardClick = (item: any) => {
    const itemExists = selectedDevice.find(
      (selected) => selected._id === item._id
    );

    if (!itemExists) {
      setSelectedDevice((prevSelectedDevice) => [...prevSelectedDevice, item]);
      // setSelectedDevice((prevSelectedDevice) => [item, ...prevSelectedDevice]);
    }

    setTableVisibility(true);
  };

  const removeItem = (itemId: any) => {
    const filteredItems = selectedDevice.filter((item) => item._id !== itemId);

    if (filteredItems.length === 0) {
      setTableVisibility(false);
    }

    setSelectedDevice(filteredItems);
  };

  const deleteDataInredux = (id: any, devicename: any) => {
    dispatch(removeData({ id }));
    removeItem(id);
    notifications.show({
      color: "blue",
      title: "Device Removed",
      message: `${devicename} removed from Subscription List`,
    });
  };
  const handleSearch = (searchData: any) => {
    setSearchTerm(searchData);

    try {
      const devices = DeviceData?.docs?.filter(
        (doc: any) =>
          doc.serialNo.includes(searchData) ||
          doc.userDeviceName.includes(searchData)
      );

      if (devices) {
        setDisplayData({ docs: devices });
      } else {
        setDisplayData([]);
      }
    } catch (error) {
      console.log("Error in search", error);
      return null;
    }
  };
  // const passData = useSelector((state: any) => state["set/data"].gtArray);
  const theme = useMantineTheme();

  return (
    <>
      <Helmet title="Subscription" />
      <Title order={1}>Subscription</Title>
      <Divider my="md" />

      <Grid gutter="sm">
        <Grid.Col span={{ xl: 3, lg: 4, md: 5, sm: 6, xs: 6 }} mb={80}>
          <Title order={5}>Select Device</Title>
          <TextInput
            placeholder="Search Device Name"
            value={searchTerm}
            onChange={(event) => handleSearch(event.currentTarget.value)}
            rightSection={
              searchTerm.length > 0 && (
                <Tooltip label="Clear Search">
                  <Button
                    p={0}
                    size="xs"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setSearchTerm("");
                      handleSearch("");
                    }}
                    variant="transparent"
                  >
                    <IconX stroke={1.5} color="red" />
                  </Button>
                </Tooltip>
              )
            }
            rightSectionWidth={70}
          />
          <Divider my="md" />
          <ScrollArea
            type="auto"
            h={{ xl: 950, lg: 950, md: 950 }}
            scrollbarSize={2}
          >
            {DeviceLoading ? (
              <>
                <div style={{ width: "100%" }}>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} style={{ marginTop: "10px" }}>
                      <Skeleton key={index} radius={"md"} height={120} />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {DisplayData?.docs?.map((item: any) => {
                  const daysRemaining = dateDifferenceFromToday(
                    item.subscriptionExpiry
                  );

                  // const isSelected = selectedDevice.includes(item._id);
                  const isSelected = selectedDevice.find(
                    (selected) => selected._id === item._id
                  );

                  return (
                    <Card
                      key={item._id}
                      withBorder
                      radius={"md"}
                      style={{
                        boxShadow: isSelected
                          ? "0px 0px 5px rgba(0,0,0,0.2)"
                          : "0px 0px 5px rgba(0,0,0,0.1)",
                        border: isSelected
                          ? `1px solid ${theme.colors.blue[3]}`
                          : "",
                        margin: "10px",
                        cursor: "pointer",
                        backgroundColor: selectedDevice.includes(item)
                          ? isLight
                            ? theme.colors.blue[0]
                            : "#27374D"
                          : "",
                      }}
                      onClick={() => {
                        handleCardClick(item);
                      }}
                    >
                      <Grid>
                        <Grid.Col>
                          <Title order={4}>{item?.userDeviceName} </Title>
                        </Grid.Col>
                        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12 }}>
                          <Text style={{ fontSize: "14px" }}>
                            Serial No: {item.serialNo}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                          <Text style={{ fontSize: "14px" }}>
                            Warranty :{" "}
                            {moment(
                              item.warrantyExpireDate,
                              "YYYY-MM-DD"
                            ).format("DD-MM-YYYY")}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 12, lg: 12 }}>
                          <Text style={{ fontSize: "14px" }}>
                            Subscription Expiry on :{" "}
                            {moment(item.subscriptionExpiry).format(
                              "DD-MM-YYYY"
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 12, lg: 9 }}>
                          {daysRemaining < 0 ? (
                            <>
                              <Text style={{ fontSize: "15px" }} c="red">
                                Subscription Expired on :{" "}
                                {moment(item.subscriptionExpiry).format(
                                  "DD/MM/YYYY"
                                )}
                              </Text>
                            </>
                          ) : (
                            <>
                              {" "}
                              <Text style={{ fontSize: "14px" }}>
                                Remaining Subscription : {daysRemaining} days
                              </Text>
                            </>
                          )}
                        </Grid.Col>
                        <Grid.Col
                          h={60}
                          span={{ sm: 12, md: 12, lg: 3, xl: 3 }}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {selectedDevice.includes(item) ? (
                            <Tooltip label="Remove Device from List">
                              <ActionIcon
                                radius="xl"
                                onClick={() => {
                                  deleteDataInredux(
                                    item._id,
                                    item?.userDeviceName
                                  );
                                }}
                                size="xl"
                                variant="default"
                              >
                                <IconTrash
                                  style={{
                                    color: "var(--mantine-color-red-7)",
                                  }}
                                />
                              </ActionIcon>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Grid.Col>
                      </Grid>
                    </Card>
                  );
                })}
              </>
            )}
          </ScrollArea>
        </Grid.Col>

        <Grid.Col span={{ xl: 9, lg: 8, md: 7, sm: 6, xs: 6 }}>
          {tableVisibility ? (
            <>
              <DeviceDetails
                visibility={tableVisibility}
                data={selectedDevice}
                removeItem={(data) => {
                  removeItem(data);
                }}
              />
              <SubscriptionTransaction onButtonClick={clearArray} />
            </>
          ) : (
            <div
              style={{
                display: "grid",
                height: "80vh",
                placeItems: "center",
              }}
            >
              <Title order={1}>Select Device </Title>
            </div>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export const DeviceDetails: React.FC<TableTTProps> = ({ data }) => {
  const {
    getSubscriptionOffersFunction,
    data: subdatas,
    loading: SubscriptionLoading,
  } = useGetSubscriptionOffers();

  // const dispatch = useDispatch();

  const offerData: GetSubscriptionProps = {
    Tag: [
      {
        Description: localStorage.getItem("city") ?? "",
      },
      {
        Description: localStorage.getItem("state") ?? "",
      },
    ],
  };
  useEffect(() => {
    getSubscriptionOffersFunction(offerData);
  }, []);

  // const deleteDataInredux = (id: any) => {
  //   dispatch(removeData({ id }));
  //   removeItem(id);
  // };

  return (
    <>
      <Title order={5}>Selected Device Details</Title>
      <Divider my="md" />
      <ScrollArea
        h={{ xl: 430, lg: 430, md: 430 }}
        mb={20}
        type="auto"
        scrollbarSize={2}
      >
        {data?.length ? <></> : <>No Devices Selected</>}
        {data?.map((item: any, index: number) => {
          return (
            <Card
              key={item.serialNo}
              withBorder
              radius={"sm"}
              style={{
                boxShadow: false
                  ? "0px 0px 10px rgba(0,0,0,0.4)"
                  : "0px 0px 5px rgba(0,0,0,0.2)",
                margin: "10px",
              }}
            >
              <div key={item.serialNo}>
                <Grid gutter={"xs"} align="flex-start">
                  <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12 }}>
                    <Text>Device Name : {item.userDeviceName}</Text>
                  </Grid.Col>

                  <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12 }}>
                    <Text>Model : {item.productModelName}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12 }}>
                    <Text>Serial No : {item.serialNo}</Text>
                  </Grid.Col>
                  <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12 }}>
                    <Text>
                      Subscription Expiry :{" "}
                      {moment(item.subscriptionExpiry).format("YYYY-MM-DD")}
                    </Text>
                  </Grid.Col>
                </Grid>
                {!SubscriptionLoading ? (
                  <div key={item.serialNo}>
                    <Grid
                      gutter={"lg"}
                      align="flex-start"
                      style={{ marginTop: "10px" }}
                    >
                      <Grid.Col span={{ xl: 12, lg: 10, md: 12, sm: 12 }}>
                        <ComboboxFirstDrop data={item} subdata={subdatas} />
                      </Grid.Col>
                      {/* <Grid.Col span={{ xl: 2, lg: 2, md: 6, sm: 12 }}>
                          <Button onClick={() => deleteDataInredux(item._id)}>
                            Delete
                          </Button>
                        </Grid.Col> */}
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Card>
          );
        })}
      </ScrollArea>
    </>
  );
};

interface comboprops {
  subdata?: any;
  data?: any;
}

export const ComboboxFirstDrop: React.FC<comboprops> = ({ data, subdata }) => {
  const [value1, setValue] = useState<any>();
  const [passData1, SetPassData] = useState<any>();

  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const combobox2 = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = subdata?.map((item: any) => (
    <Combobox.Option value={item}>{item?.OfferName}</Combobox.Option>
  ));

  const options2 = value1?.OfferDetails?.map((item: any) => (
    <Combobox.Option value={item} key={item._id}>
      {item?.Description}
    </Combobox.Option>
  ));

  const run = (value: any) => {
    const gtData: any = {
      CESSPercentage: value?.CESSPercent,
      CESSValue:
        (value?.CESSPercent * (value?.Price - value?.Discount)) / 100,
      OfferName: value1?.OfferName,
      PlanName: value?.Description,
      Discount: value?.Discount,
      duration: value?.Duration,
      GSTPercentage: value?.GSTPercent,
      GSTValue:
        (value?.GSTPercent * (value?.Price - value?.Discount)) / 100,
      OtherPercentage: value?.OtherPercent,
      OtherValue:
        (value?.OtherPercent * (value?.Price - value?.Discount)) / 100,
      amount: value?.Price,
      serialNumber: data.serialNo,
      deviceId: data._id,
    };
    SetPassData(gtData);

    dispatch(addData(gtData));
  };

  return (
    <>
      <Grid>
        <Grid.Col span={{ xl: 6, lg: 12, md: 12, sm: 12 }}>
          <Combobox
            store={combobox}
            onOptionSubmit={(val) => {
              setValue(val);
              setVisible(true);
              combobox.closeDropdown();
            }}
            key={subdata._id}
          >
            <Combobox.Target>
              <InputBase
                required
                component="button"
                pointer
                rightSection={
                  <Combobox.Chevron
                    onClick={() => {
                      combobox.toggleDropdown();
                    }}
                  />
                }
                onClick={() => {
                  combobox.toggleDropdown();
                }}
                style={{
                  overflow: "hidden",
                }}
              >
                {value1?.OfferName || (
                  <Input.Placeholder>Pick Plan</Input.Placeholder>
                )}
              </InputBase>
            </Combobox.Target>
            <Combobox.Dropdown>
              {subdata ? (
                <Combobox.Options>{options}</Combobox.Options>
              ) : (
                <>
                  <Skeleton height={10} radius="xl" />
                  <Skeleton height={10} mt={6} radius="xl" />
                  <Skeleton height={10} mt={6} width="100%" radius="xl" />
                </>
              )}
            </Combobox.Dropdown>
          </Combobox>
        </Grid.Col>
        <Divider />
        <Grid.Col span={{ xl: 6, lg: 12, md: 12, sm: 12 }}>
          {visible ? (
            <>
              <Combobox
                store={combobox2}
                onOptionSubmit={(val) => {
                  if (val !== undefined) {
                    run(val);
                    setVisible(true);
                    combobox2.closeDropdown();
                  }
                }}
              >
                <Combobox.Target>
                  <InputBase
                    required
                    component="button"
                    pointer
                    rightSection={
                      <Combobox.Chevron
                        onClick={() => {
                          combobox2.toggleDropdown();
                        }}
                      />
                    }
                    onClick={() => {
                      combobox2.toggleDropdown();
                    }}
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    {passData1?.PlanName || (
                      <Input.Placeholder>Pick Sub Plan</Input.Placeholder>
                    )}
                  </InputBase>
                </Combobox.Target>
                <Combobox.Dropdown>
                  {subdata ? (
                    <Combobox.Options>{options2}</Combobox.Options>
                  ) : (
                    <>
                      <Skeleton height={8} radius="xl" />
                      <Skeleton height={8} mt={6} radius="xl" />
                      <Skeleton height={8} mt={6} width="70%" radius="xl" />
                    </>
                  )}
                </Combobox.Dropdown>
              </Combobox>
            </>
          ) : (
            <></>
          )}
        </Grid.Col>
      </Grid>
      <Grid gutter="sm" m={10}>
        {visible ? (
          <>
            <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
              <Text>
                Price : {convertToIndianCurrency(passData1?.amount || 0)}
              </Text>
            </Grid.Col>

            <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
              <Text>
                GST : {convertToIndianCurrency(passData1?.GSTValue || 0)}
              </Text>
            </Grid.Col>

            <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
              <Text>
                Total amount :{" "}
                {convertToIndianCurrency(
                  passData1?.amount -
                  passData1?.Discount +
                  passData1?.GSTValue +
                  passData1?.CESSValue || 0
                )}
              </Text>
            </Grid.Col>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default Subscription;
// function dispatch(arg0: { payload: undefined; type: "set/data/reset" }) {
//   throw new Error("Function not implemented.");
// }
