import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";

import { FirmwareService, GetFirmwareProps } from "./service/firmWareService";

export const getFirmWare = createAsyncThunk(
  "firmware/getFirmware",
  async (billwiseData: GetFirmwareProps, thunkAPI) => {
    try {
      const firmwareService = new FirmwareService(APIPATH);
      const response = await firmwareService.getFirmwareDetails(billwiseData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
