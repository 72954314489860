import { createSlice } from "@reduxjs/toolkit";
import { fetch, create, update } from "./outletAction";

interface FetchOutletState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

interface CreateOutletState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isCreated:boolean
}

interface UpdateOutletState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: [];
  isUpdated:boolean
}

const initialFetchState: FetchOutletState = {
  loading: false,
  errorData: null,
  data: {},
};

const initialCreateState: CreateOutletState = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
  isCreated:false
};

const initialUpdateState: UpdateOutletState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isUpdated:false
};

export const getOutlet = createSlice({
  name: "outlet/fetch",
  initialState: initialFetchState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const createOutlet = createSlice({
  name: "outlet/create",
  initialState: initialCreateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isCreated=false

      })
      .addCase(create.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isCreated=true
      })
      .addCase(create.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isCreated=false
      });
  },
});

export const updateOutlet = createSlice({
  name: "outlet/update",
  initialState: initialUpdateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(update.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isUpdated=false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isUpdated=true
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isUpdated=false
      });
  },
});

export const getOutletReducer = getOutlet.reducer;
export const createOutletReducer = createOutlet.reducer;
export const updateOutletReducer = updateOutlet.reducer;
