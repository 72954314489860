import { useSelector, useDispatch } from 'react-redux'
import { authenticator, businessSignIn, deviceLogin, editPassword, forgotPassword, signIn, signUp,editUserProfile, logout, getProfile } from './authAction'
import { AppDispatch, RootState } from '../awatarStore/store'
import { BusinessPayload, DeviceLoginPayload, ForgotPasswordPayload, ResetPayload, SignInPayload, SignUpPayload } from './service/authService';
import jwtDecode from 'jwt-decode';

export const useLogin = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/login'].signInLoading);
    const error = useSelector((state: RootState) => state['user/login'].signInError);
    const tokenData: any = useSelector((state: RootState) => state['user/login'].token);
    const isLoggedIn=useSelector((state: RootState) => state['user/login'].isLoggedIn);


    const loginUser = async (loginData: SignInPayload, onComplete: (success: boolean, error: boolean, res: any) => void) => {
        try {
            const res = await dispatch(signIn(loginData));
            if (res.meta.requestStatus === 'fulfilled') {
                await authenticationHook((success, err, res) => {
                    if (success) {
                        onComplete(true, false, res.payload);
                    } else {
                        throw new Error("Login Failed");
                    }
                })
            } else if (res.meta.requestStatus === 'rejected') {
                throw new Error(error??"Error in Login !!!");
            }
        } catch (error: any) {
            console.log(error.message, "Error")
            onComplete(false, true, error.message);

        }
    };

    //made for authentication after login
    const authenticationHook = async (onComplete: (success: boolean, error: boolean, res: any) => void) => {
        try {
            const res = await dispatch(authenticator());
            if (res.meta.requestStatus === 'fulfilled') {
                onComplete(true, false, res.payload);
            } else if (res.meta.requestStatus === 'rejected') {
                throw new Error("Error in Login !!!");
            }
        } catch (error: any) {
            console.log(error.message, "Error")
            onComplete(false, true, error.message);

        }
    }

    return { loading, error, loginUser, tokenData,isLoggedIn };
}

export function useGetUserProfile() {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/getProfile'].loading);
    const error = useSelector((state: RootState) => state['user/getProfile'].error);
    const profileData = useSelector((state: RootState) => state['user/getProfile'].data);
    const errorData=useSelector((state: RootState) => state['user/getProfile'].errorData);

    const getUserProfile = async (userId:string) => {
        await dispatch(getProfile(userId));
    };

    return { loading, error,errorData, getUserProfile,profileData };
}

export function useIsLoggedIn() {
    let LoggedIn=false

    function checkIsLoggedIn() {
        const accessToken = localStorage.getItem('access_token'); // Retrieve the access token from storage
        
        if (!(accessToken)) {
        // window.location.href = '/login';
          // No access token found, user is not logged in
          return true;
        }
        if(accessToken!==null && accessToken!==undefined && accessToken!==''){
            const accessTokenValues:any=jwtDecode(accessToken);
        const expiryTimestamp = accessTokenValues?.exp; 
        
        if(expiryTimestamp!==null&& expiryTimestamp!==undefined){
            let date = new Date();

        const currentTimestamp = date.getTime(); // Get the current timestamp in seconds
        return currentTimestamp >= parseInt(expiryTimestamp, 10); // Compare with the expiry timestamp
        }
        else 
        {
            // No expiry timestamp found, consider it expired
            window.location.href = '/login';
            return true;
        }
        }
      }

      if (checkIsLoggedIn()) {
        // Access token is expired, you should refresh it or log the user out
        LoggedIn=false
      } else {
        // Access token is valid, proceed with the authenticated request
        LoggedIn=true
      }
      
  
    return {LoggedIn, checkIsLoggedIn};
  }


  export const useEditUserProfile = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/editUserProfile'].loading);
    const error = useSelector((state: RootState) => state['user/editUserProfile'].error);
    const errorData=useSelector((state: RootState) => state['user/editUserProfile'].errorData);
    const isEdited=useSelector((state: RootState) => state['user/editUserProfile'].isEdited);


    const editProfile = async (editProfileData: any) => {
        await dispatch(editUserProfile(editProfileData));
    };

    return { editProfile , loading, error,errorData,isEdited};
}

export const useBusinessLogin = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/businessLogin'].signInLoading);
    const error = useSelector((state: RootState) => state['user/businessLogin'].signInError);
    const businessTokenData: any = useSelector((state: RootState) => state['user/businessLogin'].token);
    let isBusinessAvailable=useSelector((state: RootState) => state['user/businessLogin'].isBusinessAvailable);

    const businessLoginUser = async (data: BusinessPayload, onComplete: (success: boolean, error: boolean, res: any) => void) => {
        try {
            const resp = await dispatch(businessSignIn(data));
            if (resp.meta.requestStatus === 'fulfilled') {
                onComplete(true, false, resp.payload)
            }
            else if (resp.meta.requestStatus === 'rejected') {
                onComplete(false, true, resp.payload)
            }
        }
        catch (error: any) {
            console.log(error.message, "Error")
            onComplete(false, true, error.message);
        }

    };

    return { loading, error, businessLoginUser, businessTokenData,isBusinessAvailable };
}

export const useDeviceLogin = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/deviceLogin'].deviceLoginLoading);
    const error = useSelector((state: RootState) => state['user/deviceLogin'].deviceLoginError);
    const errorData = useSelector((state: RootState) => state['user/deviceLogin'].deviceLoginErrorMessage);
    const isLoggedIn=useSelector((state: RootState) => state['user/deviceLogin'].isLogin);

    const deviceLoginUser = async (credentials: DeviceLoginPayload) => {
       await dispatch(deviceLogin(credentials));
    };

    return { loading, error,errorData,isLoggedIn, deviceLoginUser };
}

export const useRegister = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/register'].registerLoading);
    const errorMessage = useSelector((state: RootState) => state['user/register'].registerErrorMessage);
    const error=useSelector((state: RootState) => state['user/register'].registerError);
    const isRegistered=useSelector((state: RootState) => state['user/register'].isRegister);

    const registerUser = async (registerData: SignUpPayload) => {
        await dispatch(signUp(registerData));
    };

    return { loading, error, registerUser,errorMessage,isRegistered };
}



export const useEditPassword = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/editPassword'].loading);
    const errorData = useSelector((state: RootState) => state['user/editPassword'].errorData);
    const error=useSelector((state: RootState) => state['user/editPassword'].error);
    const isEdited=useSelector((state: RootState) => state['user/editPassword'].isEdited);

    const editUserPassword = async (editPasswordData: ResetPayload) => {
        await dispatch(editPassword(editPasswordData));
    };

    return { loading, errorData, editUserPassword ,error,isEdited};
}

export const useResetPassword = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['user/forgotPassword'].resetPasswordLoading);
    const error = useSelector((state: RootState) => state['user/forgotPassword'].resetPasswordErrorMessage);

    const resetPassword = async (resetPasswordData: ForgotPasswordPayload, onComplete: (success: boolean, error: boolean, res: any) => void) => {
        try {
            const resp = await dispatch(forgotPassword(resetPasswordData));
            if (resp.meta.requestStatus === 'fulfilled') {
                onComplete(true, false, resp.payload)
            }
            else if (resp.meta.requestStatus === 'rejected') {
                onComplete(false, true, resp.payload)
            }
        }
        catch (error: any) {
            console.log(error.message, "Error")
            onComplete(false, true, error.message);
        }
    };

    return { loading, error, resetPassword };
}


export function useLogout() {
    const dispatch: AppDispatch = useDispatch();
    const isLoggedIn = useSelector((state: RootState) => state['user/login'].isLoggedIn);
  
    const logoutUser = () => {
      dispatch(logout());
    };
  
    return { isLoggedIn,logoutUser};
}
