import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../awatarStore/store'
import { fetch } from './footerActions' 

export const useGetFooter=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['footer/fetch'].loading);
    const error = useSelector((state: RootState) => state['footer/fetch'].error);
    const data: any = useSelector((state: RootState) => state['footer/fetch'].data)
  
    const fetchFooterVersion = async () => {
            await dispatch(fetch());
    };

    return {
        fetchFooterVersion,
        loading,
        error,
        data,
    }
}