import {
  Button,
  FileButton,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Slider,
  Text,
} from "@mantine/core";
import { IconDownload, IconFileUpload } from "@tabler/icons-react";
import React, { useState, useEffect, useRef } from "react";
import { Buffer } from "buffer";
import { useCreateFile, useUpdateFile } from "../../../modules/file/fileHooks";
import { useDispatch } from "react-redux";
import { resetFile } from "../../../modules/file/fileSlice";
import { notifications } from "@mantine/notifications";
import classes from "../../styleModules/AppShellMainStyles.module.css";
interface LogoProps {
  data: any;
  canvasOpened: boolean;
  setCanvasOpened: any;
  logoClick: any;
  fileData: any;
  hasFile: any;
  fetchFileLoading: any;
  fetchFileError: any;
}
const Logo = ({
  data,
  canvasOpened,
  setCanvasOpened,
  fileData,
  hasFile,
  fetchFileLoading,
  fetchFileError,
}: LogoProps) => {
  const canvasLeftRef = useRef<any>(null);
  const canvasRightRef = useRef<any>(null);
  const resetRef = useRef<() => void>(null);

  const {
    updateFile,
    // data: updateData,
    // loading: updateLoading,
    error: updateError,
    isUpdated,
  } = useUpdateFile();
  const {
    createFile,
    // data: createData,
    // loading: createLoading,
    error: createError,
    isCreated,
  } = useCreateFile();
  const dispatch = useDispatch();

  const [scale, setScale] = useState(1);
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [file, setFile] = useState<File | null | any>(null);
  const [fileSize, setFileSize] = useState<any>(0);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [isFileSizeCalculating, setIsFileSizeCalculating] = useState(false);
  const [value, setValue] = useState(100); // The initial value (0.10 KB).
  const [thresholdval, setThresholdval] = useState(100);

  useEffect(() => {
    if (fileData.byteLength) {
      convertFrom1BPPToGrayScaleAndLoadConvas(fileData);
    }
  }, [fileData]);

  useEffect(() => {
    setIsFileSizeCalculating(false);
  }, [fileSize]);

  useEffect(() => {
    if (canvasLeftRef.current) {
      setFileSize(calcFileSize());
      setIsFileSizeCalculating(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (!fetchFileLoading && hasFile) {
      notifications.show({
        title: "Success",
        message: "Logo File Downloaded Successfully",
        color: "green",
        icon: <IconDownload />,
      });
    } else if (hasFile !== null && fetchFileError) {
      if (hasFile === false) {
        notifications.show({
          title: "Error",
          message: "No Logo File Found",
          color: "red",
          icon: <IconDownload />,
        });
      }
    }
  }, [hasFile, fetchFileLoading, fetchFileError]);

  useEffect(() => {
    if ((isCreated && uploadFlag) || (isUpdated && uploadFlag)) {
      notifications.show({
        title: "Success",
        message: "Logo File Uploaded Successfully",
        color: "green",
      });

      setScale(1);
      setWidth(1);
      setHeight(1);
      setFileSize(0);
      setValue(100);
      setFile(null);
      setCanvasOpened(false);
      dispatch(resetFile());
    } else {
      if (createError || updateError) {
        notifications.show({
          title: "Error",
          message: "Logo File Upload Failed",
          color: "red",
        });
      }
    }
  }, [isUpdated, isCreated]);

  function fileUploadToServer(fileData: any, checksum: any) {
    let body = {
      description: "fileuploading from webpage",
      deviceId: data?._id ?? " ",
      checksum: checksum,
      file: {
        name: "logo.bin",
        mimetype: "application/octet-stream",
        encoding: "7bit",
        truncated: false,
        size: fileData.length,
        data: [...fileData],
      },
    };

    if (hasFile) {
      updateFile(body);
      setUploadFlag(true);
    } else {
      createFile(body);
      setUploadFlag(true);
    }
  }

  function handleFileLoadOnCanvas(file: any) {
    setFile(file);
    setIsFileSizeCalculating(true);
    setFileSize(file.size / 1024);
    if (file) {
      const canvasLeft = canvasLeftRef.current;
      const canvasRight = canvasRightRef.current;
      const leftCanvasContext = canvasLeft.getContext("2d");
      const rightCanvasContext = canvasRight.getContext("2d");
      const img = new Image(); // Create new img element
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        img.src = reader.result as string;
        img.onload = (e) => {
          let imageHeight = img.height;
          let imageWidth = img.width;

          // Calculate the new dimensions based on a scale factor
          let scale = 1; // Adjust this scale as needed
          let newCanvasLeftWidth = imageWidth * scale;
          let newCanvasLeftHeight = imageHeight * scale;

          leftCanvasContext.drawImage(
            img,
            0,
            0,
            newCanvasLeftWidth,
            newCanvasLeftHeight
          );

          rightCanvasContext.drawImage(
            img,
            0,
            0,
            newCanvasLeftWidth,
            newCanvasLeftHeight
          );

          // Create ImageData with the scaled dimensions
          let imgData = rightCanvasContext.getImageData(
            0,
            0,
            newCanvasLeftWidth,
            newCanvasLeftHeight
          );

          let imgData1 = Grayscale(imgData, 125); // Threshold

          // Clear the canvas and put the processed ImageData
          rightCanvasContext.clearRect(
            0,
            0,
            canvasLeftRef.current.width,
            canvasLeftRef.current.height
          );
          rightCanvasContext.putImageData(imgData1, 0, 0);

          setWidth(newCanvasLeftWidth); // Update the width
          setHeight(newCanvasLeftHeight); // Update the height
        };
      };
    }
  }

  const clearCanvas = (canvasRef: any) => {
    const canvas = canvasRef.current.getContext("2d");
    if (canvas !== undefined) {
      canvas.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      canvas.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };
  const clearFile = (leftCanvasRef: any, rightCanvasRef: any) => {
    setValue(100);
    setFile(null);
    setThresholdval(100);
    clearCanvas(leftCanvasRef);
    clearCanvas(rightCanvasRef);
    resetRef.current?.();
  };

  function valueLabelFormat(value: any) {
    // return `${(value / 1000).toFixed(2)} KB`;
    return value;
  }

  function Grayscale(pixels: any, threshold: any) {
    var d = pixels.data;
    for (var i = 0; i < d.length; i += 4) {
      var r = d[i];
      var g = d[i + 1];
      var b = d[i + 2];
      var v = 0.2126 * r + 0.7152 * g + 0.0722 * b >= threshold ? 255 : 0;
      d[i] = d[i + 1] = d[i + 2] = v;
    }
    return pixels;
  }

  const convertFrom1BPPToGrayScaleAndLoadConvas = (image1BPPData: any) => {
    let tool = Buffer.from(new Uint8Array(image1BPPData));

    let greyScaleImg = [];
    let tempBufferData = [];
    let incomingwidth = tool[3];
    let width = tool[3] / 8; //converting to bits/pixel
    let height = tool[4]; //already in bits/pixel
    if (incomingwidth === 0 || height === 0) {
      notifications.show({
        color: "red",
        title: "Error",
        message: "Image not Loaded! Image is Corrupted try again",
      });
      return;
    }
    var j = 0,
      i = 0,
      bitNo = 0,
      bitNoInc = 0,
      bitValue = 0;

    for (i = 7, j = 0; i < tool.length - 1; i++, j++) {
      tempBufferData[j] = tool[i];
    }

    for (i = 0; i < height; i++) {
      for (j = 0; j < width; j++) {
        for (bitNo = 7, bitNoInc = 0; bitNo >= 0; bitNo--, bitNoInc++) {
          bitValue = 0;
          bitValue = tempBufferData[i * width + j] & (0x01 << bitNo);
          if (0 !== bitValue) {
            greyScaleImg[i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4] = 255;
            greyScaleImg[
              i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4 + 1
            ] = 255;
            greyScaleImg[
              i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4 + 2
            ] = 255;
          } else {
            greyScaleImg[i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4] = 0;
            greyScaleImg[
              i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4 + 1
            ] = 0;
            greyScaleImg[
              i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4 + 2
            ] = 0;
          }
          greyScaleImg[
            i * (width * 8) * 4 + j * 8 * 4 + bitNoInc * 4 + 3
          ] = 255;
        }
      }
    }
    let img = new ImageData(
      new Uint8ClampedArray(greyScaleImg),
      width * 8,
      height
    );

    canvasRightRef.current.getContext("2d").putImageData(img, 0, 0);
  };

  function calcFileSize() {
    if (scale <= 0) {
      return 0;
    }
    let rightCanvasImageValue = canvasRightRef.current
      .getContext("2d")
      .getImageData(0, 0, width, height);

    let image1BPPData = convertTo1BPP(rightCanvasImageValue);
    return (image1BPPData.length + 100) / 1024;
  }

  function convertTo1BPP(array: any) {
    let convertedArray = [];
    let widthInBytes = (array.width / 8) | 0x00;
    let j = 0,
      i = 0,
      bitNo = 0,
      bitNoInc = 0;

    for (i = 0; i < array.height; i++) {
      for (j = 0; j < widthInBytes; j++) {
        convertedArray[i * widthInBytes + j + i] = 0x00;
        for (bitNo = 7, bitNoInc = 0; bitNo >= 0; bitNo--, bitNoInc++) {
          convertedArray[i * widthInBytes + j + i] |=
            (array.data[i * array.width * 4 + j * 8 * 4 + bitNoInc * 4] === 255
              ? 0x01
              : 0x00) << bitNo;
        }
      }
      if (array.width % 8 !== 0) {
        convertedArray[i * widthInBytes + j + i] = 0xff;
        for (
          bitNo = 7, bitNoInc = 0;
          bitNo >= 7 - ((array.width % 8) - 1);
          bitNo--, bitNoInc++
        ) {
          convertedArray[i * widthInBytes + j + i] &= ~(
            (array.data[i * array.width * 4 + j * 8 * 4 + bitNoInc * 4] === 255
              ? 0x00
              : 0x01) << bitNo
          );
        }
      }
    }

    return convertedArray;
  }

  function LoadFile(eventValue: any, type: any, propFile?: any) {
    let newScale: any;
    let thres: any;
    if (type === "SCALE") {
      thres = thresholdval;
      newScale = eventValue;
    } else {
      thres = eventValue;
      newScale = scale;
    }

    let img = new Image();
    const reader = new FileReader();

    let ctxOut = canvasRightRef.current.getContext("2d");
    let ctx = canvasLeftRef.current.getContext("2d");
    let gs = Grayscale;

    if (propFile) {
      reader.readAsDataURL(propFile);
    } else {
      reader.readAsDataURL(file);
    }
    reader.onloadend = function () {
      img.src = reader.result as string;

      img.onload = function () {
        ctx.clearRect(
          0,
          0,
          canvasLeftRef.current.width,
          canvasLeftRef.current.height
        );
        ctx.save();
        ctx.scale(newScale, newScale);
        ctx.drawImage(img, 0, 0);
        ctx.restore();
        let imgData = ctx.getImageData(
          0,
          0,
          img.width * newScale,
          img.height * newScale
        );
        if (type === "SCALE") {
          setIsFileSizeCalculating(true);
          setWidth(img.width * newScale);
          setHeight(img.height * newScale);
        }
        let imgdsata1 = gs(imgData, thres);
        ctxOut.clearRect(
          0,
          0,
          canvasLeftRef.current.width,
          canvasLeftRef.current.height
        );
        ctxOut.putImageData(imgdsata1, 0, 0);
        // setWidth(img.width * newScale);
        // setHeight(img.height * newScale);
      };
    };
  }

  function getLogoChecksum(imageData: any) {
    let checksum = 0;
    for (var i = 0; i < imageData.length; i++) {
      checksum += imageData[i];
    }
    return checksum;
  }

  const convertFrom1BPPT = () => {
    let canvasData = canvasRightRef.current
      .getContext("2d")
      .getImageData(0, 0, width, height);
    let image1BPPData = convertTo1BPP(canvasData);
    let finalLogoData = [];
    finalLogoData[0] = 0x1b;
    finalLogoData[1] = 0x2a;
    finalLogoData[2] =
      (canvasData.width % 8 === 0
        ? ((canvasData.width / 8) | 0x00) * 8
        : (((canvasData.width / 8) | 0x00) + 0x01) * 8) & 0xff;
    finalLogoData[3] = canvasData.height & 0xff;
    finalLogoData[4] = 0x00;
    finalLogoData[5] = 0x00;
    var initialLength = finalLogoData.length;

    // let verticalData = this.makeImageVertically(image1BPPData);
    for (var i = 0; i < image1BPPData.length; i++) {
      finalLogoData[initialLength + i] = image1BPPData[i];
    }
    var byteArray = new Uint8Array(finalLogoData);

    var buffer = Buffer.from(byteArray);
    let checksum = getLogoChecksum(buffer);
    if (buffer[2] === 0 || buffer[3] === 0) {
      notifications.show({
        color: "red",
        title: "Error",
        message: "Image not Uploaded! Image is Corrupted try again",
      });
      return;
    }
    fileUploadToServer(buffer, checksum);
  };

  function setThreshold(event: any) {
    if (file) {
      setThresholdval(event);
      LoadFile(event, "THRESHOLD");
      // setFileSize(calcFileSize());
    }
  }

  function setFileScale(event: any) {
    if (event !== value) {
      setValue(event);
      let dd = event / 100;
      setScale(dd);
      LoadFile(dd, "SCALE");
      // setFileSize(calcFileSize());
    }
  }

  return (
    <Modal
      title="DEVICE LOGO"
      classNames={{
        close: classes.modalCloseButton,
      }}
      size="xl"
      opened={canvasOpened}
      onClose={() => {
        setScale(1);
        setWidth(1);
        setHeight(1);
        setValue(100);
        setFileSize(0);
        setFile(null);
        setCanvasOpened(false);
        dispatch(resetFile());
      }}
      closeOnClickOutside={false}
    >
      <Grid>
        <Grid.Col display="flex" span={12}>
          <Grid.Col span={6}>
            <Text size="sm" c="dimmed" m={5}>
              Original Image
            </Text>
            <canvas
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                width: "300px",
                height: "200px",
                padding: "0.2rem",
                alignContent: "space-around",
                backgroundColor: "#f1f1f1",
                backgroundImage:
                  " linear-gradient(45deg,#c8c6c6 25%,transparent 0),linear-gradient(-45deg,#c8c6c6 25%,transparent 25%),linear-gradient(45deg,transparent 75%,#c8c6c6 75%),linear-gradient(-45deg,transparent 75%,#c8c6c6 75%)",
                backgroundSize: "20px 20px",
                backgroundPosition: "0 0,0 10px,10px -10px,-10px 0",
              }}
              ref={canvasLeftRef}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="sm" c="dimmed" m={5}>
              Preview Image
            </Text>
            <LoadingOverlay
              visible={fetchFileLoading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
            <canvas
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                width: "300px",
                height: "200px",
                padding: "0.2rem",
                alignContent: "space-around",
                backgroundColor: "#f1f1f1",
                backgroundImage:
                  " linear-gradient(45deg,#c8c6c6 25%,transparent 0),linear-gradient(-45deg,#c8c6c6 25%,transparent 25%),linear-gradient(45deg,transparent 75%,#c8c6c6 75%),linear-gradient(-45deg,transparent 75%,#c8c6c6 75%)",
                backgroundSize: "20px 20px",
                backgroundPosition: "0 0,0 10px,10px -10px,-10px 0",
              }}
              ref={canvasRightRef}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={12}>
          <Group justify="center">
            <FileButton
              resetRef={resetRef}
              onChange={(e) => {
                clearFile(canvasLeftRef, canvasRightRef);
                handleFileLoadOnCanvas(e);
                // LoadFile(50, "SCALE", e);
                // setFileSize(calcFileSize());

                // setFile(e)
              }}
              accept="image/png,image/jpeg"
            >
              {(props) => (
                <>
                  {file ? (
                    isFileSizeCalculating ? (
                      <Text
                        fw={500}
                        size="sm"
                        c={fileSize < 4 ? "teal.4" : "red"}
                      >
                        Calculating File Size...
                      </Text>
                    ) : (
                      <Text
                        fw={500}
                        size="sm"
                        c={fileSize < 4 ? "teal.4" : "red"}
                      >
                        File Size <b>{fileSize.toFixed(2)} KB </b>, must be less
                        than <b>4KB</b>
                      </Text>
                    )
                  ) : (
                    <></>
                  )}
                  <Button variant="default" {...props}>
                    Select Image
                  </Button>
                </>
              )}
            </FileButton>
            <Button
              disabled={!file}
              color="red"
              onClick={(e) => {
                clearFile(canvasLeftRef, canvasRightRef);
              }}
            >
              Reset
            </Button>
            <Button
              variant="default"
              disabled={fileSize < 4 && file ? false : true}
              rightSection={<IconFileUpload size={14} />}
              onClick={() => convertFrom1BPPT()}
            >
              Upload
            </Button>
          </Group>
          {file && file !== null && (
            <Grid.Col span={12} display="flex">
              <Grid.Col span={6}>
                <Text size="sm" mt="xl">
                  Set Threshold
                </Text>

                <Slider
                  size="lg"
                  m={10}
                  onChangeEnd={(e) => {
                    setThreshold(e);
                  }}
                  labelAlwaysOn
                  defaultValue={125}
                  min={1} // Represents 0.10 KB
                  max={255}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Text size="sm" mt="xl">
                  Set File Size
                </Text>
                <Slider
                  size="lg"
                  m={10}
                  value={value}
                  onChangeEnd={(e) => {
                    setFileScale(e);
                  }}
                  labelAlwaysOn
                  label={valueLabelFormat}
                  min={1} // Represents 0.10 KB
                  max={100}
                />
              </Grid.Col>
            </Grid.Col>
          )}
          <Text className={classes.logoRestartText}>
            Restart {data.userDeviceName} Device after uploading logo file
          </Text>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default Logo;
