import React, { useEffect, useState } from "react";
import {
  useCreateOutlet,
  useGetOutlet,
  useUpdateOutlet,
} from "../../modules/outlet/outletHooks";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Pagination,
  Skeleton,
  Text,
  Tooltip,
} from "@mantine/core";
import { OutletAccordion } from "./outlet/OutletAccordion";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import classes from "../styleModules/AppShellMainStyles.module.css";
import CreateOutlet from "./outlet/CreateOutlet";
import { notifications } from "@mantine/notifications";
import NoRecordsFound from "../../utils/uiUtils/NoRecordsFound";
import { useGetBusiness } from "../../modules/business/businessHooks";

const Outlet = () => {
  const { fetchOutlet, data, loading: outletLoading } = useGetOutlet();
  const { multiOutlet } = useGetBusiness();
  const {
    createOutlet,
    error: createError,
    loading: createOutletLoading,
    isCreated,
    errorData: createErrorData,
  } = useCreateOutlet();
  const {
    updateOutlet,
    error: updateError,
    isUpdated,
    loading: updateOutletLoading,
    errorData: updateErrorData,
  } = useUpdateOutlet();

  const [createOutletDialog, setCreateOutletDialog] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10); // Update items per page to 2
  const [currentData, setCurrentData] = useState([]);
  const [createFlag, setCreateFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [searchpage, setSearchpage] = useState(activePage);

  useEffect(() => {
    const fetchOutletData = {
      page: "1",
      limit: limit.toString(),
      businessId: localStorage.getItem("businessId") ?? "",
    };

    fetchOutlet(fetchOutletData);
  }, []);

  useEffect(() => {
    // Calculate the total number of pages based on the data and items per page
    if (data !== undefined) {
      setTotalPages(data?.pages);
      setCurrentData(data?.docs);
    } else {
      setCurrentData([]);
      setTotalPages(0);
    }
  }, [activePage, data, limit]);

  useEffect(() => {
    if (isCreated && createFlag) {
      notifications.show({
        title: "Success",
        message: "Outlet Created Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      const fetchOutletData = {
        page: activePage.toString(),
        limit: limit.toString(),
        businessId: localStorage.getItem("businessId") ?? "",
      };

      form.reset();
      setCreateOutletDialog(false);
      fetchOutlet(fetchOutletData);
    } else if (createError && createFlag) {
      notifications.show({
        title: "Error",
        message: `Outlet Creation Error :${createErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isCreated, createError]);

  useEffect(() => {
    if (isUpdated && updateFlag) {
      notifications.show({
        title: "Success",
        message: "Outlet Updated Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      const fetchOutletData = {
        page: activePage.toString(),
        limit: limit.toString(),
        businessId: localStorage.getItem("businessId") ?? "",
      };

      fetchOutlet(fetchOutletData);
    } else if (updateError && updateFlag) {
      notifications.show({
        title: "Error",
        message: `Outlet Update Error :${updateErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, updateError]);

  useEffect(() => {
    setSearchpage(activePage);
  }, [activePage]);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      outletName: "",
      state: "",
      telephone: "",
      country: "",
      city: "",
      GSTN: "",
      fax: "",
      address: "",
    },
    validate: {
      outletName: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 50
          ? null
          : "Must be 50 or less",
      state: (value) =>
        // value.length < 2
        //   ? "Must be 2 or more"
        //   : value.length <= 20
        //   ? null
        //   : "Must be 20 or less",
        value ? null : "Should be selected",
      telephone: (value) =>
        value.length >= 15
          ? "Must be 15 or less"
          : value.length < 6
          ? "Must be 6 or more"
          : null,
      country: (value) =>
        // value.length < 2
        //   ? "Must be 2 or more"
        //   : value.length <= 20
        //   ? null
        //   : "Must be 20 or less",
        value ? null : "Should be selected",
      city: (value) =>
        value.length < 2
          ? "Must be 2 or more"
          : value.length <= 30
          ? null
          : "Must be 30 or less",
      GSTN: (value) =>
        value.length !== 15 && value.length > 0
          ? "Must be 15 characters"
          : null,
      fax: (value) =>
        value.length < 5 && value.length > 0
          ? "Must be more than 5 characters"
          : value.length > 20
          ? "Must be 20 or less"
          : null,
      address: (value) => (value.length <= 150 ? null : "Must be 150 or less"),
    },
  });

  const onPageChange = (page: any) => {
    const fetchOutletData = {
      page: page,
      limit: limit.toString(),
      businessId: localStorage.getItem("businessId") ?? "",
    };

    fetchOutlet(fetchOutletData);
    setActivePage(page);
  };

  const handleCreateItem = (values: any) => {
    const createOutletData = {
      outletName: values.outletName,
      telephone: values.telephone,
      city: values.city,
      state: values.state,
      country: values.country,
      businessId: localStorage.getItem("businessId") ?? "",
      GSTN: values.GSTN,
      fax: values.fax,
      address: values.address,
    };

    if (
      createOutletData.GSTN.length < 1 ||
      createOutletData.GSTN === undefined ||
      createOutletData.GSTN === null
    ) {
      delete createOutletData.GSTN;
    }
    if (
      createOutletData.fax.length < 1 ||
      createOutletData.fax === undefined ||
      createOutletData.fax === null
    ) {
      delete createOutletData.fax;
    }
    if (
      createOutletData.address.length < 1 ||
      createOutletData.address === undefined ||
      createOutletData.address === null
    ) {
      delete createOutletData.address;
    }

    createOutlet(createOutletData);
    setCreateFlag(true);
  };

  const handleUpdateOutlet = (values: any) => {
    const updateOutletData = {
      outletId: values.outletId,
      outletName: values.outletName,
      telephone: values.telephone,
      city: values.city,
      state: values.state,
      country: values.country,
      businessId: localStorage.getItem("businessId") ?? "",
      GSTN: values.GSTN,
      fax: values.fax,
      address: values.address,
    };

    if (
      updateOutletData.GSTN.length < 1 ||
      updateOutletData.GSTN === undefined ||
      updateOutletData.GSTN === null
    ) {
      updateOutletData.GSTN = " ".repeat(15);
    }
    if (
      updateOutletData.fax.length < 1 ||
      updateOutletData.fax === undefined ||
      updateOutletData.fax === null
    ) {
      updateOutletData.fax = " ".repeat(15);
    }
    if (
      updateOutletData.address.length < 1 ||
      updateOutletData.address === undefined ||
      updateOutletData.address === null
    ) {
      updateOutletData.address = " ".repeat(15);
    }

    updateOutlet(updateOutletData);
    setUpdateFlag(true);
  };

  return (
    <>
      <Helmet title="Outlet" />

      <CreateOutlet
        createOutletDialog={createOutletDialog}
        setCreateOutletDialog={setCreateOutletDialog}
        form={form}
        handleCreateItem={handleCreateItem}
        createLoading={createOutletLoading}
      />
      <Grid className={classes.mainGrid}>
        <Grid.Col className={classes.mainTitleCol}>
          <Text className={classes.mainTitle}>Outlet</Text>
          <Tooltip
            position="bottom"
            display={
              multiOutlet && data?.total >= 1
                ? "none"
                : data?.total < 1
                ? "none"
                : ""
            }
            label={
              multiOutlet && data?.total >= 1
                ? null
                : data?.total < 1
                ? null
                : "multi outlet is disabled"
            }
          >
            <Button
              disabled={
                multiOutlet && data?.total >= 1
                  ? false
                  : data?.total < 1
                  ? false
                  : true
              }
              radius="sm"
              rightSection={<IconPlus size={20} />}
              onClick={() => setCreateOutletDialog(!createOutletDialog)}
            >
              Create
            </Button>
          </Tooltip>
        </Grid.Col>
        <Divider style={{ width: "100%" }} />

        <Grid.Col
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Card withBorder className={classes.outletCard}>
            <Accordion
              variant="separated"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {outletLoading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "17px",
                  }}
                >
                  {[...Array(10)].map((_, index) => (
                    <Skeleton key={index} height={50} />
                  ))}
                </div>
              )}
              {currentData !== undefined &&
              currentData !== null &&
              currentData?.length > 0
                ? currentData?.map((outlet: any) => (
                    <OutletAccordion
                      key={outlet?._id}
                      data={outlet}
                      handleUpdateOutlet={handleUpdateOutlet}
                      updateOutletLoading={updateOutletLoading}
                    />
                  ))
                : !outletLoading && <NoRecordsFound />}
            </Accordion>
            {currentData?.length > 0 && (
              <Flex
                mb={40}
                p={15}
                pt={5}
                mih={50}
                align="center"
                gap="md"
                justify="flex-end"
                direction="row"
                wrap="wrap"
              >
                <Group>
                  <NumberInput
                    value={searchpage}
                    w={60}
                    size="xs"
                    mt="sm"
                    placeholder="Page"
                    radius="sm"
                    min={1}
                    // max={totalPages}
                    hideControls
                    onChange={(event) => {
                      setSearchpage(Number(event));
                    }}
                  />

                  <Button
                    mt="sm"
                    size="xs"
                    variant="outline"
                    onClick={() => {
                      const pageNumber2 = searchpage;

                      if (pageNumber2 < 1 || pageNumber2 > totalPages) {
                        notifications.show({
                          title: "Error",
                          message: "Invalid page number",
                          color: "red",
                          icon: <IconX size="1.1rem" />,
                        });
                      } else {
                        onPageChange(searchpage);
                      }
                    }}
                  >
                    Go
                  </Button>
                </Group>
                <Pagination
                  // radius="xl"
                  style={{
                    // width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  value={activePage}
                  onChange={onPageChange}
                  total={totalPages}
                />
              </Flex>
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Outlet;
