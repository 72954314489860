import { AxiosRequestHeaders } from "axios";
import { APIPATHENV } from "./env";

const APIPATH = APIPATHENV;
export interface GetheaderType {
  Accept: string;
  "Content-Type": string;
  Authorization?: string;
}

type CustomHeaders = AxiosRequestHeaders & {
  "x-access-token"?: string;
};

type DeviceCustomHeaders = AxiosRequestHeaders & {
  terminalToken?: string;
};

const getHeader = (tokn?: string | null): CustomHeaders => {
  let token =
    localStorage.getItem("access_token") != null
      ? localStorage.getItem("access_token")
      : tokn;

  if (token !== null && token !== undefined) {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "x-access-token": token,
    };
    return header as CustomHeaders;
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    } as CustomHeaders;
  }
};

const getDeviceHeader = (tokn?: string | null): DeviceCustomHeaders => {
  let token =
    localStorage.getItem("terminalToken") != null
      ? localStorage.getItem("terminalToken")
      : tokn;
  // console.log("")
  if (token !== null && token !== undefined) {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      terminalToken: token,
    };
    return header as DeviceCustomHeaders;
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    } as DeviceCustomHeaders;
  }
};

const deviceSubscriptionLogin = (tokn?: string | null): DeviceCustomHeaders => {
  let token =
    localStorage.getItem("access_token") != null
      ? localStorage.getItem("access_token")
      : tokn;
  // console.log("")
  if (token !== null && token !== undefined) {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      terminalToken: token,
    };
    return header as DeviceCustomHeaders;
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    } as DeviceCustomHeaders;
  }
};

const ACTION = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const commorReducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTION.LOADING: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ACTION.ERROR: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        statusCode: action.statusCode,
        error: true,
      };
    }
    case ACTION.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        totalRecords: action.total,
        statusCode: action.statusCode,
      };
    }
    default: {
      return {
        ...state,
        error: true,
      };
    }
  }
};

const businessCheck = (_history: any) => {
  const usertype = localStorage.getItem("usertype");
  if (usertype === "Owner") {
    const isBusinessAvailable: boolean =
      localStorage.getItem("isBusinessAvailable") === "true" ? true : false;
    if (isBusinessAvailable === false) {
      _history("/business/create");
    }
  }
};

const isEmpty = (val: any) => {
  return val === undefined || val == null || val === "" ? true : false;
};
const convertToIndianCurrency = (
  input: any,
  showCurrencySymbol: boolean = true
) => {
  const num = typeof input === "string" ? parseFloat(input) : input;

  if (isNaN(num)) {
    throw new Error("Invalid input");
  }

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  return formatter.format(num);
};

export {
  APIPATH,
  ACTION,
  getHeader,
  getDeviceHeader,
  commorReducer,
  businessCheck,
  isEmpty,
  convertToIndianCurrency,
  deviceSubscriptionLogin,
};
