import { getHeader } from "../../../utils/utils";
import jwt_decode from "jwt-decode";
import { api_routes } from "../../../utils/apiEndPoints";
import axios from "axios";

export interface SignInPayload {
  user: string;
  password: string;
}

export interface DeviceLoginPayload {
  deviceId: string;
  token: string;
}

export interface BusinessPayload {
  businessId: string | null;
  token: string | null;
}

export interface SignUpPayload {
  firstname: string;
  lastname?: string;
  password: string;
  mobile: string;
  email: string;
  address: string;
  usertype: string;
  username?: string;

  facebookID?: string;
  imageUrl?: string;
  telephone?: string;
  alternatemobile?: string;
  profilepic?: string;
}

export interface EditUserPayload {
  firstname: string;
  lastname?: string;
  address: string;
}

export interface TokenData {
  access_token: string | null;
  usertype?: string | null;
  userId?: string;
}

export interface TerminalTokenData {
  terminalToken: string | null;
  usertype?: string | null;
  deviceId: string;
}

export interface DecodedToken {
  usertype: string;
}

export interface BusinessTokenData extends TokenData {
  businessId?: string;
  isBusinessAvailable: boolean;
}

export interface ResetPayload {
  currentpassword: string;
  newpassword: string;
}

export interface ForgotPasswordPayload {
  mobile: string;
  otp: string;
  newpassword: string;
}

export class AuthService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async signIn(credentials: SignInPayload): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_login}`;

      const response = await axios.post(url, credentials, {
        headers: header,
      });

      const tokenData: TokenData = {
        access_token: response.data.token,
        usertype: response.data.usertype,
        userId: response.data.userId,
      };

      return tokenData;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async authenticate(): Promise<any> {
    try {
      let header = getHeader();
      let url = `${this.baseUrl}${api_routes.api_path_auth}`;
      let response = await axios.get(url, {
        headers: header,
      });
      return response;
    } catch (err: any) {
      throw new Error(err.response);
    }
  }

  async getProfile(userId: string): Promise<any> {
    try {
      let header = getHeader();
      let url = `${this.baseUrl}${api_routes.api_path_profile}${userId}`;
      let response = await axios.get(url, {
        headers: header,
      });
      return response;
    } catch (err: any) {
      throw new Error(err.response);
    }
  }

  async editUserProfile(editProfileData: EditUserPayload): Promise<any> {
    try {
      let header = getHeader();
      let url = `${this.baseUrl}${api_routes.api_path_signup}`;
      let response = await axios.put(url, editProfileData, {
        headers: header,
      });
      return response;
    } catch (err: any) {
      throw new Error(err.response);
    }
  }

  async businessSignIn(data?: BusinessPayload): Promise<any> {
    try {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      };
      const response = await axios.post(
        `${this.baseUrl}${api_routes.api_path_login}`,
        data,
        {
          headers: header,
          validateStatus: function (status) {
            if (status === 404) {
              return true;
            }
            return status >= 200 && status < 300;
          },
        }
      );
      if (response.status === 200) {
        const decode: any = jwt_decode(response.data.token);
        const tokenData: BusinessTokenData = {
          businessId: decode.businessId,
          isBusinessAvailable: true,
          access_token: response.data.token,
        };
        return tokenData;
      } else {
        const tokenData: BusinessTokenData = {
          access_token: null,
          isBusinessAvailable: false,
        };
        return tokenData;
      }
    } catch (error) {
      throw new Error("Failed to login service");
    }
  }

  async deviceLogin(credentials: DeviceLoginPayload): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_login}`;

      const response = await axios.post(url, credentials, {
        headers: header,
      });

      const data: any = jwt_decode(response.data.token);
      const deviceId = data.deviceId;

      const tokenData: TerminalTokenData = {
        deviceId: deviceId,
        terminalToken: response.data.token,
        usertype: response.data.usertype,
      };

      return tokenData;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const token = localStorage.getItem("access_token");
      // Check if the token exists in the local storage
      if (token) {
        // Additional logic can be added here to verify the token's validity or expiration

        // Return true if the token exists and is considered valid
        return true;
      } else {
        // Return false if the token doesn't exist
        return false;
      }
    } catch (error) {
      throw new Error("Failed to check login status");
    }
  }

  async signUp(signUpData: SignUpPayload): Promise<any> {
    try {
      let header = getHeader();
      const url = this.baseUrl + api_routes.api_path_signup;
      const response = await axios.post(url, signUpData, {
        headers: header,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async editPassword(editPasswordData: ResetPayload): Promise<any> {
    try {
      let header = getHeader();
      const url = this.baseUrl + api_routes.api_path_editpassword;
      const response = await axios.patch(url, editPasswordData, {
        headers: header,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async forgotPassword(resetData: ForgotPasswordPayload): Promise<any> {
    try {
      let header = getHeader();
      const url = this.baseUrl + api_routes.api_path_forgotpassword;
      const response = await axios.patch(url, resetData, {
        headers: header,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async logout(): Promise<boolean> {
    try {
      let colorScheme = localStorage.getItem('my-app-color-scheme');
      localStorage.clear();
      console.log(colorScheme);
      if (colorScheme) {
          localStorage.setItem('my-app-color-scheme', colorScheme);
          console.log(localStorage.getItem('my-app-color-scheme'));
      }
      window.location.href = "/login";
      return true;
    } catch (error) {
      throw new Error("Failed to logout");
    }
  }
}
