import { createSlice } from "@reduxjs/toolkit";
import { fetch } from "./footerActions";


interface footerState {
    loading: boolean;
    errorData?: string | null;
    error?:boolean;
    data?: [];
    multiOutlet?:boolean
}

const initialFetchState: footerState = {
   loading: false,
   errorData: null,
    error:false,
   data: [],
    multiOutlet:false
};


export const footerSlice = createSlice({
    name: 'footer/fetch',
    initialState: initialFetchState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetch.pending, (state) => {
                state.loading=true;
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.loading=false;
                state.data=action.payload;
                state.error=false;
            })
            .addCase(fetch.rejected, (state, action) => {
                state.loading=false;
                state.error=true;
                state.errorData=action.error.message;
            })
    }
});

export const getFooterReducer=footerSlice.reducer;

