import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";
import { FileService } from "./service/fileService";

export const fetch = createAsyncThunk(
    "file/fetch",
    async (fileData: any, thunkAPI) => {
      try {
        const fileService = new FileService(APIPATH);
        const response = await fileService.getFile(fileData);
        return response;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );


  export const create = createAsyncThunk(
    "file/create",
    async (fileData: any, thunkAPI) => {
      try {
        const fileService = new FileService(APIPATH);
        const response = await fileService.create(fileData);
        return response;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );  


export const update = createAsyncThunk(
  "file/update",
  async (fileData: any, thunkAPI) => {
    try {
      const fileService = new FileService(APIPATH);
      const response = await fileService.update(fileData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);