import React, { useEffect, useState } from "react";
import { DeviceCard, DeviceProps } from "./device/DeviceCard";
import {
  GetDeviceProps,
  MapToOutletProps,
  MapToItemListProps,
} from "../../modules/device/service/deviceService";
import {
  useActivateDevice,
  useGetDevice,
  useGetWarrantyTC,
  useMapToItemList,
  useMapToOutlet,
  useUpdateDevice,
} from "../../modules/device/deviceHooks";
import {
  Button,
  Card,
  Grid,
  Divider,
  Pagination,
  Text,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
import { useDeviceLogin } from "../../modules/auth/authHooks";
import { useGetOutlet } from "../../modules/outlet/outletHooks";
import { useGetItemList } from "../../modules/itemList/itemListHooks";
import { IconCheck, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { GetOutletProps } from "../../modules/outlet/service/outletService";
import { Helmet } from "react-helmet-async";
import classes from "../styleModules/AppShellMainStyles.module.css";
import SomethingWentWrong from "../../utils/uiUtils/SomethingWentWrong";
import EditDeviceName from "./device/EditDeviceName";
import ItemListMapping from "./device/ItemListMapping";
import OutletMapping from "./device/OutletMapping";
import ActivateDevice from "./device/ActivateDevice";
import WarrantyTC from "./device/WarrantyTC";
import DeviceSimmer from "./device/DeviceSimmer";
import Logo from "./device/Logo";
import { useGetFile } from "../../modules/file/fileHooks";

const Devices = () => {
  const {
    data,
    fetchDevice,
    loading: DeviceLoading,
    error: DeviceError,
  } = useGetDevice();
  const {
    activateDevice,
    success: ActivationSuccess,
    error: ActivationError,
    errorData: ActivationErrorData,
    data: ActivationData,
  } = useActivateDevice();

  const {
    deviceLoginUser,
    error: loginError,
    errorData: loginErrorData,
    isLoggedIn,
  } = useDeviceLogin();

  const {
    updateDevice,
    loading: updateLoading,
    error: updateError,
    isUpdated,
    errorData: updateErrorData,
  } = useUpdateDevice();

  const {
    getWarrantyTCFunction,
    data: TCData,
    loading: warrantyLoading,
  } = useGetWarrantyTC();

  const {
    mapToItemListFunction,
    error: MapItemListError,
    errorData: MapItemListErrorData,
    isMapped: isItemListMapped,
  } = useMapToItemList();
  const {
    mapToOutletFunction,
    error: MapOutletError,
    errorData: MapOutletErrorData,
    isMapped: isOutletMapped,
  } = useMapToOutlet();

  const { data: outletData, fetchOutlet } = useGetOutlet();

  const { data: fetchItemsListData, fetchItemList } = useGetItemList();

  const {
    fetchFile,
    loading: fetchFileLoading,
    error: fetchFileError,
    data: fileData,
    hasFile,
  } = useGetFile();

  const [activateDeviceDialog, setactivateDeviceDialog] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(6); // Update items per page to 2
  const [currentData, setCurrentData] = useState([]);
  const [nameEditable, setNameEditable] = useState(false);
  const [itemListMapping, setItemListMapping] = useState(false);
  const [termsAndCondition, setTermsAndConditions] = useState(false);
  const [outletMapping, setOutletMapping] = useState(false);
  const [selectedItemListId, setItemListId] = useState("");
  const [selectedOutletId, setOutletId] = useState("");
  const [deviceOption, setDeviceOption] = useState("");
  const [selectedDevice, setSelectedDevice] = useState<any>([]);
  const [activationNotify, setActivationNotify] = useState(false);
  const [EditNameNotify, setEditNameNotify] = useState(false);
  const [loginFlag, setLoginFlag] = useState(false);
  const [outletMapFlag, setOutletMapFlag] = useState(false);
  const [itemListMapFlag, setItemListMapFlag] = useState(false);

  const [canvasOpened, setCanvasOpened] = useState(false);
  const [searchpage, setSearchpage] = useState(1);
  useEffect(() => {
    const getDeviceData: GetDeviceProps = {
      page: "1",
      limit: limit.toString(),
      businessId: localStorage.getItem("businessId") ?? "",
      includeReplacedDevice: true,
    };
    fetchDevice(getDeviceData);
    const getItemListData = {
      businessId: localStorage.getItem("businessId") ?? "",
      page: 1,
      limit: 100,
    };
    fetchItemList(getItemListData);
    const getOutletData: GetOutletProps = {
      page: "1",
      limit: "100",
      businessId: localStorage.getItem("businessId") ?? "",
    };
    fetchOutlet(getOutletData); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ActivationSuccess && activationNotify) {
      setSelectedDevice([]);
      setactivateDeviceDialog(false);
      notifications.show({
        title: "Success",
        message: `${ActivationData?.message}`,
        color: "teal",
        icon: <IconCheck size="1.1rem" />,
      });

      const getDeviceData: GetDeviceProps = {
        page: activePage.toString(),
        limit: limit.toString(),
        businessId: localStorage.getItem("businessId") ?? "",
        includeReplacedDevice: true,
      };
      fetchDevice(getDeviceData);
    } else if (ActivationError && activationNotify) {
      notifications.show({
        title: "Error",
        message: `Device Activation Error :  ${ActivationErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActivationSuccess, ActivationError]);

  useEffect(() => {
    if (data !== undefined) {
      setTotalPages(data?.pages);
      setCurrentData(data?.docs);
    } else {
      setCurrentData([]);
      setTotalPages(0);
    }
  }, [data, fetchItemsListData, outletData]);
  useEffect(() => {
    setSearchpage(activePage);
  }, [activePage]);

  useEffect(() => {
    if (isItemListMapped && itemListMapFlag) {
      setItemListMapping(false);
      setItemListId("");
      notifications.show({
        title: "Success",
        message: "Successfully mapped to Item List",
        color: "teal",
        icon: <IconCheck size="1.1rem" />,
      });
      const getDeviceData: GetDeviceProps = {
        page: activePage.toString(),
        limit: limit.toString(),
        businessId: localStorage.getItem("businessId") ?? "",
        includeReplacedDevice: true,
      };
      fetchDevice(getDeviceData);
    } else if (MapItemListError && itemListMapFlag) {
      setItemListMapping(false);
      setItemListId("");
      notifications.show({
        title: "Error",
        message: `ItemList Error : ${MapItemListErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemListMapped, MapItemListError]);

  useEffect(() => {
    if (isOutletMapped && outletMapFlag) {
      setOutletMapping(false);
      setOutletId("");
      notifications.show({
        title: "Success",
        message: "Successfully mapped to outlet",
        color: "teal",
        icon: <IconCheck size="1.1rem" />,
      });
      const getDeviceData: GetDeviceProps = {
        page: activePage.toString(),
        limit: limit.toString(),
        businessId: localStorage.getItem("businessId") ?? "",
        includeReplacedDevice: true,
      };

      setActivePage(1);
      fetchDevice(getDeviceData);
    } else if (MapOutletError && outletMapFlag) {
      setOutletMapping(false);
      setOutletId("");

      notifications.show({
        title: "Error",
        message: `Outlet Error : ${MapOutletErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutletMapped, MapOutletError]);

  useEffect(() => {
    if (isUpdated && EditNameNotify) {
      notifications.show({
        title: "Success",
        message: "Successfully updated device name",
        color: "teal",
        icon: <IconCheck size="1.1rem" />,
      });
      const getDeviceData: GetDeviceProps = {
        page: activePage.toString(),
        limit: limit.toString(),
        businessId: localStorage.getItem("businessId") ?? "",
        includeReplacedDevice: true,
      };
      fetchDevice(getDeviceData);
      setNameEditable(false);
    } else {
      if (updateError && EditNameNotify) {
        notifications.show({
          title: "Error",
          message: `Device Error : ${updateErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
        setNameEditable(true);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, updateError]);

  useEffect(() => {
    const options = {
      name: "name",
      itemList: "itemList",
      logo: "logo",
      tc: "tc",
    };

    if (isLoggedIn && loginFlag) {
      if (deviceOption === options.name) {
        setNameEditable(true);
      }
      if (deviceOption === options.itemList) {
        setItemListMapping(true);
      }
      if (deviceOption === options.logo) {
        // getFileDataAndLoadOnCanvas("logo.bin");

        const getFileDataProps = {
          fileName: "logo.bin",
        };
        fetchFile(getFileDataProps);
        setCanvasOpened(true);
      }
      if (deviceOption === options.tc) {
        if (selectedDevice?.warrantyTCId !== undefined) {
          const getWarrantyTCData = {
            businessId: localStorage.getItem("businessId") ?? "",
            warrantyTCId: selectedDevice?.warrantyTCId,
          };
          getWarrantyTCFunction(getWarrantyTCData);
          setTermsAndConditions(true);
        } else {
          notifications.show({
            title: "Error",
            message: `No Terms and Conditions Found`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
        }
      }
    } else if (loginError && loginFlag) {
      notifications.show({
        title: "Error",
        message: `Login Error : ${loginErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, loginError]);

  function logoClick() {
    if (data._id !== undefined && data._id !== null) {
      handleDeviceLogin(data, "logo");
    } else {
      alert("Please select a device first");
    }
  }

  const onPageChange = (page: any) => {
    const getDeviceData: GetDeviceProps = {
      page: page,
      limit: limit.toString(),
      businessId: localStorage.getItem("businessId") ?? "",
      includeReplacedDevice: true,
    };
    fetchDevice(getDeviceData);
    setActivePage(page);
  };

  const handleDeviceActivation = (values: any) => {
    const activationData = {
      businessId: localStorage.getItem("businessId") ?? "",
      dealercode: values.dealerCode,
      installedBy: values.installedBy,
      serialNo: values.serialNo,
      userDeviceName: values.userDeviceName,
    };

    activateDevice(activationData);
    setActivationNotify(true);
  };

  const handleDeviceLogin = (data: any, type: string) => {
    setDeviceOption(type);
    setSelectedDevice(data);

    const loginData = {
      deviceId: data._id,
      token: localStorage.getItem("access_token") ?? "",
    };

    deviceLoginUser(loginData);
    setLoginFlag(true);
  };

  interface ItemListProp {
    _id: string;
    itemListName: string;
  }
  const itemListData = (): { value: string; label: string }[] => {
    let data: any = fetchItemsListData?.docs?.map((item: ItemListProp) => {
      return {
        value: item._id,
        label: item.itemListName,
      };
    });
    return data === undefined ? [] : data;
  };

  interface OutletProps {
    _id: string;
    outletName: string;
  }

  const outletMapData = (): { value: string; label: string }[] => {
    let data: any = outletData?.docs?.map((item: OutletProps) => {
      return {
        value: item._id,
        label: item.outletName,
      };
    });
    return data === undefined ? [] : data;
  };

  const handleDeviceNameChange = (text: any) => {
    const updateDeviceData = {
      deviceId: selectedDevice._id,
      userDeviceName: text,
    };

    updateDevice(updateDeviceData);
    setEditNameNotify(true);
  };

  const handleItemListMapping = () => {
    if (
      selectedItemListId !== "" &&
      selectedItemListId !== undefined &&
      selectedItemListId !== null
    ) {
      const mapToItemListData: MapToItemListProps = {
        itemListId: selectedItemListId,
        serialNo: selectedDevice?.serialNo,
      };

      mapToItemListFunction(mapToItemListData);
      setItemListMapFlag(true);
    } else if (selectedItemListId === "") {
      notifications.show({
        title: "Error",
        message: "Please select an Item List",
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
      setItemListMapFlag(false);
    }
  };

  const mapToOutlet = () => {
    if (
      selectedOutletId !== "" &&
      selectedOutletId !== undefined &&
      selectedOutletId !== null
    ) {
      const mapToOutletData: MapToOutletProps = {
        outletId: selectedOutletId,
        serialNo: selectedDevice?.serialNo,
      };

      mapToOutletFunction(mapToOutletData);
      setOutletMapFlag(true);
    } else {
      notifications.show({
        title: "Error",
        message: "Please select an outlet",
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
      setOutletMapFlag(false);
    }
  };

  const handleOutletMapping = (device: any) => {
    setSelectedDevice(device);
    setOutletMapping(true);
  };

  return (
    <>
      <Helmet title="Device" />
      <Logo
        data={selectedDevice}
        canvasOpened={canvasOpened}
        setCanvasOpened={setCanvasOpened}
        logoClick={logoClick}
        fileData={fileData}
        hasFile={hasFile}
        fetchFileLoading={fetchFileLoading}
        fetchFileError={fetchFileError}
      />

      <ActivateDevice
        activateDeviceDialog={activateDeviceDialog}
        setactivateDeviceDialog={setactivateDeviceDialog}
        handleDeviceActivation={handleDeviceActivation}
      />

      {selectedDevice?.userDeviceName !== undefined && (
        <EditDeviceName
          setSelectedDevice={setSelectedDevice}
          userDeviceName={selectedDevice?.userDeviceName}
          nameEditable={nameEditable}
          setNameEditable={setNameEditable}
          updateLoading={updateLoading}
          handleDeviceNameChange={handleDeviceNameChange}
        />
      )}

      <ItemListMapping
        itemListMapping={itemListMapping}
        setItemListMapping={setItemListMapping}
        setItemListId={setItemListId}
        itemListData={itemListData}
        selectedDevice={selectedDevice}
        handleItemListMapping={handleItemListMapping}
      />

      <OutletMapping
        setOutletId={setOutletId}
        setOutletMapping={setOutletMapping}
        mapToOutlet={mapToOutlet}
        outletMapping={outletMapping}
        outletMapData={outletMapData}
        selectedDevice={selectedDevice}
      />
      {TCData !== undefined && (
        <WarrantyTC
          warrantyLoading={warrantyLoading}
          termsAndCondition={termsAndCondition}
          setTermsAndConditions={setTermsAndConditions}
          TCData={TCData}
          data={selectedDevice}
        />
      )}
      <Grid className={classes.mainGrid} mb={10}>
        <Grid.Col className={classes.mainTitleCol}>
          <Text className={classes.mainTitle}>Devices</Text>
          <Button
            onClick={() => setactivateDeviceDialog(true)}
            className={classes.mainNavButton}
          >
            Activate Device
          </Button>
        </Grid.Col>
      </Grid>

      <Divider style={{ width: "100%" }} />
      <Grid>
        <Grid.Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "calc(100vh - 200px)",
            height: "100%",
          }}
        >
          <Card withBorder className={classes.deviceMainCard}>
            {DeviceLoading ? (
              <Grid className={classes.deviceMainGridCol}>
                {[...Array(6)].map((_, index) => (
                  <Grid.Col key={index} span={{ xl: 4, lg: 6, md: 12, sm: 12 }}>
                    <DeviceSimmer />
                  </Grid.Col>
                ))}
              </Grid>
            ) : (
              <>
                <Grid className={classes.deviceMainGridCol}>
                  {currentData?.map((device: DeviceProps) => {
                    return (
                      <Grid.Col
                        key={device._id}
                        span={{ xl: 4, lg: 6, md: 12, sm: 12 }}
                      >
                        <DeviceCard
                          data={device}
                          handleDeviceLogin={handleDeviceLogin}
                          handleOutletMapping={handleOutletMapping}
                        />
                      </Grid.Col>
                    );
                  })}
                </Grid>
              </>
            )}
            {DeviceError && <SomethingWentWrong />}
          </Card>
        </Grid.Col>

        {/* </Grid> */}
      </Grid>
      <div style={{ paddingBottom: "25px" }}>
        {currentData?.length > 0 && (
          <Flex
            mb={40}
            p={15}
            pt={5}
            pb={15}
            mih={50}
            align="center"
            gap="md"
            justify="flex-end"
            direction="row"
            wrap="wrap"
          >
            <Group>
              <NumberInput
                value={searchpage}
                w={60}
                size="xs"
                mt="sm"
                placeholder="Page"
                radius="sm"
                min={1}
                // max={totalPages}
                hideControls
                onChange={(event) => {
                  setSearchpage(Number(event));
                }}
              />

              <Button
                mt="sm"
                size="xs"
                variant="outline"
                onClick={() => {
                  const pageNumber2 = searchpage;

                  if (pageNumber2 < 1 || pageNumber2 > totalPages) {
                    notifications.show({
                      title: "Error",
                      message: "Invalid page number",
                      color: "red",
                      icon: <IconX size="1.1rem" />,
                    });
                  } else {
                    onPageChange(searchpage);
                  }
                }}
              >
                Go
              </Button>
            </Group>
            <Pagination
              // radius="xl"
              style={{
                // width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              value={activePage}
              onChange={onPageChange}
              total={totalPages}
            />
          </Flex>
        )}
      </div>
    </>
  );
};

export default Devices;
