import { Button, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import classes from "../pages/styleModules/AppShellMainStyles.module.css";

interface Props {
  editUserOpened: boolean;
  handleUserProfileEdit: any;
  handleUserEditClose: any;
  data: any;
}

interface EditUserProfileProps {
  address: string;
  firstname: string;
  lastname: string;
}

const EditUserProfile = ({
  editUserOpened,
  handleUserProfileEdit,
  handleUserEditClose,
  data,
}: Props) => {
  const form = useForm<EditUserProfileProps>({
    validateInputOnChange: true,
    initialValues: {
      address:
        data?.address !== null && data?.address !== undefined
          ? data?.address
          : "",
      firstname:
        data?.firstname !== null && data?.firstname !== undefined
          ? data?.firstname
          : "",
      lastname:
        data?.lastname !== undefined && data?.lastname !== null
          ? data?.lastname.trim()
          : "",
    },
    validate: {
      address: (value) => (value.length <= 150 ? null : "Must be 150 or less"),
      firstname: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 50
          ? null
          : "Must be 50 or less",
      lastname: (value) => (value.length > 50 ? "Must be 50 or less" : null),
    },
  });

  useEffect(() => {
    form.setValues({
      address: data?.address ?? "",
      firstname: data?.firstname ?? "",
      lastname:
        data?.lastname !== undefined && data?.lastname !== null
          ? data?.lastname.trim()
          : "",
    });
  }, [data]);

  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      size="xs"
      closeOnClickOutside={false}
      opened={editUserOpened}
      onClose={() => {
        handleUserEditClose();
        form.setValues({
          address: data?.address ?? "",
          firstname: data?.firstname ?? "",
          lastname:
            data?.lastname !== undefined && data?.lastname !== null
              ? data?.lastname.trim()
              : " ",
        });
      }}
      title="Edit User Profile"
    >
      <form
        onSubmit={form.onSubmit((values: any) => handleUserProfileEdit(values))}
      >
        <TextInput
          value={form.values.firstname}
          error={form.errors.firstname}
          withAsterisk
          label="Enter First name"
          placeholder="50 MAX"
          maxLength={50}
          onChange={(e) =>
            form.setFieldValue("firstname", e.currentTarget.value)
          }
        />
        <TextInput
          value={form.values.lastname}
          error={form.errors.lastname}
          label="Enter Last name"
          maxLength={50}
          placeholder="50 MAX"
          onChange={(e) =>
            form.setFieldValue("lastname", e.currentTarget.value)
          }
        />
        <TextInput
          withAsterisk
          value={form.values.address}
          error={form.errors.address}
          label="Enter Address"
          placeholder="150 MAX"
          maxLength={150}
          onChange={(e) => form.setFieldValue("address", e.currentTarget.value)}
        />

        <Button type="submit" mt={12}>
          Update
        </Button>
      </form>
    </Modal>
  );
};

export default EditUserProfile;
