import React, { useEffect, useRef, useState } from "react";
import {
  useDownloadBillRefundReport,
  useGetBillRefund,
  useGetBillRefundSummary,
} from "../../../modules/reports/reportHooks";
import {
  BillRefundProps,
  GetBillRefundSummaryReport,
} from "../../../modules/reports/service/reportService";
import {
  Table,
  Pagination,
  Select,
  Text,
  Divider,
  Tabs,
  Title,
  Tooltip,
  ActionIcon,
  Grid,
  Checkbox,
  Button,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
import moment from "moment";
import { DatePickerInput } from "@mantine/dates";
import { GetOutletProps } from "../../../modules/outlet/service/outletService";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { notifications } from "@mantine/notifications";
import { useGetDevice } from "../../../modules/device/deviceHooks";
import { useGetOutlet } from "../../../modules/outlet/outletHooks";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  IconBuildingStore,
  IconChartBar,
  IconDeviceLandlinePhone,
  IconDownload,
  IconTable,
  IconX,
} from "@tabler/icons-react";
import clases from "./selected.module.css";
import { convertToIndianCurrency } from "../../../utils/utils";
import ChartUtils from "../../../utils/uiUtils/ChartUtils";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
import SkeletonTableLoader from "../../../utils/uiUtils/SkeletonTableLoder";
import { IconCheck } from "@tabler/icons-react";
const RefundBill = () => {
  const [TotalRecords, setTotalRecords] = useState(0);
  const [outletId, setOutletId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [pageNumber, setPageNumber] = useState("1");
  const [reportByDevice, setReportByDevice] = useState(false);
  const [limit] = useState(10);
  const [date, setDate] = useState();
  const [activeTab, setActiveTab] = useState<string | null>("Outlet");
  const [switchTableOrGraph, setSwitchTableOrGraph] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>("");
  const [exportLoading, setExportLoading] = useState(false);
  const [reportDataForDownload, setReportDataForDownload] = useState<any>([]);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(500);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  const pageNumberRef = useRef(pageNumber);

  let apiCallInterval: any;
  const [searchpage, setSearchpage] = useState(1);
  const { getBillRefundSummaryFunction, data } = useGetBillRefundSummary();
  const { downloadBillRefundReportFunction, data: downloadData } =
    useDownloadBillRefundReport();
  const {
    getBillRefundFunction,
    data: BillRefundData,
    loading: billLoading,
  } = useGetBillRefund();
  const { fetchOutlet, data: outlet, loading: Ouletloading } = useGetOutlet();
  const {
    fetchDevice,
    loading: DeviceLoading,
    data: DeviceData,
  } = useGetDevice();

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  const getBillRefundData: BillRefundProps = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    page: pageNumber,
    limit: limit.toString(),
    sort: {
      _Date: true,
    },
  };

  const billSummaryData: GetBillRefundSummaryReport = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    page: "1",
    limit: "100",
  };

  const outletData: GetOutletProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
  };

  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: true,
  };

  function makeAPICall(page: any) {
    let getBillRefundData: BillRefundProps = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      page: page,
      limit: limit.toString(),
      sort: {
        _Date: true,
      },
    };

    let billSummaryData: GetBillRefundSummaryReport = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      page: "1",
      limit: "100",
    };

    if (!reportByDevice) {
      getBillRefundData.outletId = outletId;
      getBillRefundData.deviceId = undefined;
      billSummaryData.outletId = outletId;
      billSummaryData.deviceId = undefined;
    } else {
      getBillRefundData.outletId = undefined;
      getBillRefundData.deviceId = deviceId;
      billSummaryData.outletId = undefined;
      billSummaryData.deviceId = deviceId;
    }

    getBillRefundFunction(getBillRefundData);
    getBillRefundSummaryFunction(billSummaryData);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = (pageNumber: any) => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall(pageNumber);
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall(pageNumberRef.current);

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(
        () => scheduleAPICall(pageNumberRef.current),
        interval
      );

      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, reportByDevice, bothDates, outletId, deviceId]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    fetchOutlet(outletData);
    fetchDevice(getDeviceData);
  }, []);

  useEffect(() => {
    if (outlet?.docs?.length > 0) {
      setOutletId(outlet?.docs[0]?._id);
      if (reportByDevice && !refreshSelected) {
        setSelectedName(outlet?.docs[0]?.outletName);
        getBillRefundData.outletId = outletId ? outletId : outlet?.docs[0]?._id;
        getBillRefundData.deviceId = undefined;
        billSummaryData.outletId = outletId ? outletId : outlet?.docs[0]?._id;
        billSummaryData.deviceId = undefined;

        getBillRefundFunction(getBillRefundData);
        getBillRefundSummaryFunction(billSummaryData);
      }
    }
  }, [outlet, reportByDevice]);

  useEffect(() => {
    if (DeviceData?.docs?.length > 0) {
      setDeviceId(DeviceData?.docs[0]?._id);
      if (reportByDevice && !refreshSelected) {
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);
        getBillRefundData.outletId = undefined;
        getBillRefundData.deviceId = deviceId
          ? deviceId
          : DeviceData?.docs[0]?._id;
        billSummaryData.outletId = undefined;
        billSummaryData.deviceId = deviceId
          ? deviceId
          : DeviceData?.docs[0]?._id;

        getBillRefundFunction(getBillRefundData);
        getBillRefundSummaryFunction(billSummaryData);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceData, reportByDevice]);

  useEffect(() => {
    if (downloadData?.docs?.length > 0 && exportLoading) {
      setReportDataForDownload((prev: any) => [...prev, ...downloadData?.docs]);
    }
  }, [downloadData]);

  useEffect(() => {
    if (reportDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(
        downloadData?.total[0]?.count / downloadLimit
      );

      if (total_Pages < nextPage) {
        try {
          onExportClick(
            reportDataForDownload,
            reportTypes.refundBillWise,
            ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`,
            data
          );
          setExportLoading(false);
          setReportDataForDownload([]);
        } catch (err) {
          notifications.show({
            title: "Export Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);
        const downloadItemWiseData: BillRefundProps = {
          fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
          toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
          page: nextPage.toString(),
          limit: downloadLimit.toString(),
          outletId: !reportByDevice ? outletId : undefined,
          deviceId: reportByDevice ? deviceId : undefined,
          sort: {
            _Date: true,
          },
        };

        if (downloadItemWiseData.deviceId === undefined) {
          delete downloadItemWiseData.deviceId;
        } else if (downloadItemWiseData.outletId === undefined) {
          delete downloadItemWiseData.outletId;
        }

        downloadBillRefundReportFunction(downloadItemWiseData);
      }
    }
  }, [reportDataForDownload]);

  useEffect(() => {
    if (BillRefundData?.total?.[0]?.count > 0) {
      setTotalpage(BillRefundData?.total?.[0]?.count);
    } else {
      setTotalRecords(0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BillRefundData]);

  const handledate = (e: any) => {
    setDate(e);
    getBillRefundData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    getBillRefundData.toDate = moment(e[1]).format("YYYY/MM/DD");
    billSummaryData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    billSummaryData.toDate = moment(e[1]).format("YYYY/MM/DD");
    if (!reportByDevice) {
      getBillRefundData.outletId = outletId;
      getBillRefundData.deviceId = undefined;
      billSummaryData.deviceId = undefined;
      billSummaryData.outletId = outletId;
    } else {
      getBillRefundData.outletId = undefined;
      getBillRefundData.deviceId = deviceId;
      billSummaryData.outletId = undefined;
      billSummaryData.deviceId = deviceId;
    }
    if (!refreshSelected) {
      getBillRefundFunction(getBillRefundData);
      getBillRefundSummaryFunction(billSummaryData);
    }
  };
  const handleOuletAndDeviceChange = (event: any, selectedName?: any) => {
    setSelectedName(selectedName);
    setPageNumber("1");
    stopAPICalls();
    if (reportByDevice) {
      setDeviceId(event);
      getBillRefundData.deviceId = event;
      getBillRefundData.page = "1";
      getBillRefundData.outletId = undefined;
      if (!getBillRefundData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        billSummaryData.deviceId = event;
        billSummaryData.outletId = undefined;
        if (!refreshSelected) {
          getBillRefundFunction(getBillRefundData);
          getBillRefundSummaryFunction(billSummaryData);
        }
      }
    } else {
      setOutletId(event);
      getBillRefundData.outletId = event;
      getBillRefundData.deviceId = undefined;
      getBillRefundData.page = "1";

      if (!getBillRefundData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        billSummaryData.outletId = event;
        billSummaryData.deviceId = undefined;
        if (!refreshSelected) {
          getBillRefundSummaryFunction(billSummaryData);
          getBillRefundFunction(getBillRefundData);
        }
      }
    }
  };

  const selectOutletData = () => {
    let data: any = outlet?.docs?.map((item: any) => {
      return { value: item._id, label: item.outletName };
    });

    return data === undefined ? [] : data;
  };

  const selectDeviceData = () => {
    let data: any = DeviceData?.docs?.map((item: any) => ({
      value: item._id,
      label: `${
        item?.userDeviceName
          ? item.userDeviceName + " [" + item.serialNo + "]"
          : item?.serialNo ?? ""
      }`,
    }));
    return data === undefined ? [] : data;
  };

  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);
    setTotalRecords(totalPages);
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    stopAPICalls();
    getBillRefundData.page = page.toString();
    if (!reportByDevice) {
      getBillRefundData.outletId = outletId;
      getBillRefundData.deviceId = undefined;
    } else {
      getBillRefundData.outletId = undefined;
      getBillRefundData.deviceId = deviceId;
    }
    getBillRefundFunction(getBillRefundData);
  };

  const TableData: any = () => {
    return (
      <>
        {BillRefundData?.docs?.map((item: any, index: number) => (
          <Table.Tr key={index}>
            {/* <Table.Td>{new Date(item._Date).toLocaleDateString()}</Table.Td>
            <Table.Td>
              {new Date(item.RefundDate).toLocaleDateString()}
            </Table.Td> */}
            <Table.Td>{moment.utc(item._Date).format("DD/MM/YYYY")}</Table.Td>
            <Table.Td>
              {moment.utc(item.RefundDate).format("DD/MM/YYYY")}
            </Table.Td>
            <Table.Td>{item.DeviceName}</Table.Td>
            <Table.Td>{item.BillNo}</Table.Td>
            <Table.Td>{item.NoOfItems}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.DiscVal)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalGST)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.RefundAmt)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalAmt)}</Table.Td>
          </Table.Tr>
        ))}
      </>
    );
  };
  const SummaryReport = () => {
    return (
      <Table.Tr
        style={{ backgroundColor: "#deedfd", color: "black", fontWeight: 600 }}
      >
        <Table.Td>
          <span>Total</span>
        </Table.Td>
        <Table.Td>--</Table.Td>
        <Table.Td>--</Table.Td>

        <Table.Td>
          {" "}
          <span>{data?.NoOfBills}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{data?.NoOfRefundItems}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(data?.TotalDiscount || 0)}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(data?.TotalGST || 0)}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(data?.TotalSale || 0)} </span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(data?.RefundAmt || 0)} </span>{" "}
        </Table.Td>
      </Table.Tr>
    );
  };

  const handleExportClick = () => {
    if (
      BillRefundData?.total?.[0]?.count > 0 &&
      BillRefundData?.total?.[0]?.count !== undefined
    ) {
      const downloadBillRefundSummaryData: BillRefundProps = {
        fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
        toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
        page: "1",
        limit: downloadLimit.toString(),
        outletId: !reportByDevice ? outletId : undefined,
        deviceId: reportByDevice ? deviceId : undefined,
        sort: {
          _Date: true,
        },
      };

      if (downloadBillRefundSummaryData.outletId === undefined) {
        delete downloadBillRefundSummaryData.outletId;
      } else if (downloadBillRefundSummaryData.deviceId === undefined) {
        delete downloadBillRefundSummaryData.deviceId;
      }

      downloadBillRefundReportFunction(downloadBillRefundSummaryData);
      setExportLoading(true);
    } else {
      notifications.show({
        title: "No Data Found",
        message: "No Data Found",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Refund Bill Report" />
      <div>
        <Title order={1}>Refund Bill Report</Title>
      </div>

      <div>
        <Divider my="md" />
        <Text size="sm" style={{ paddingBottom: "10px" }}>
          Filter By
        </Text>
        <Tabs
          color="grape"
          value={activeTab}
          onChange={(value) => {
            setActiveTab(value);
            setReportByDevice(value === "Outlet" ? false : true);
            setPageNumber("1");
            stopAPICalls();
          }}
          classNames={clases}
        >
          <Tabs.List>
            <Tooltip
              label="Select Outlet wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab value="Outlet" leftSection={<IconBuildingStore />}>
                Outlet
              </Tabs.Tab>
            </Tooltip>
            <Tooltip
              label="Select Device wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab
                value="Device"
                leftSection={<IconDeviceLandlinePhone />}
              >
                Device
              </Tabs.Tab>
            </Tooltip>
          </Tabs.List>
        </Tabs>
      </div>
      {Ouletloading || DeviceLoading ? (
        <></>
      ) : (
        <Grid>
          <Grid.Col>
            <Grid mt={15}>
              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <Select
                  label={!reportByDevice ? "Select Outlet" : "Select Device"}
                  value={!reportByDevice ? outletId : deviceId}
                  onChange={(event: any) => {
                    let selectedName: any;
                    if (!reportByDevice) {
                      outlet?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.outletName;
                          return item?.outletName;
                        }
                      });
                    } else if (reportByDevice) {
                      DeviceData?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.userDeviceName;
                          return item?.userDeviceName;
                        }
                      });
                    }
                    handleOuletAndDeviceChange(event, selectedName);
                  }}
                  allowDeselect={false}
                  placeholder={
                    !reportByDevice ? "Select Outlet" : "Select Device"
                  }
                  data={
                    !reportByDevice ? selectOutletData() : selectDeviceData()
                  }
                  nothingFoundMessage={
                    !reportByDevice ? "No Outlet Found" : "No Device Found"
                  }
                />
              </Grid.Col>

              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <DatePickerInput
                  label="Select Date"
                  required
                  allowSingleDateInRange
                  valueFormat="DD/MM/YYYY"
                  type="range"
                  placeholder="Pick Dates Range"
                  clearable
                  defaultValue={[new Date(), new Date()]}
                  value={date}
                  onChange={(e: any) => {
                    setDate(e);
                    if (e[1]) {
                      stopAPICalls();
                      setBothDates({
                        fromDate: moment(e[0]).format("YYYY/MM/DD"),
                        toDate: moment(e[1]).format("YYYY/MM/DD"),
                      });
                    }
                    setPageNumber("1");
                    if (e && e[0] !== null && e[1] !== null) {
                      handledate(e);
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 1.5,
                  xl: 1.2,
                }}
              >
                <Checkbox
                  style={{
                    position: "relative",
                    top: "15px",
                  }}
                  checked={refreshSelected}
                  onChange={(event) =>
                    setRefreshSelected(event.currentTarget.checked)
                  }
                  label="Auto Refresh"
                  mt="md"
                />
              </Grid.Col>
              {BillRefundData?.docs?.length > 0 && (
                <Grid.Col
                  pt={20}
                  span={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 0.2,
                    xl: 0.2,
                  }}
                  display="flex"
                  style={{
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    label={`Switch to ${
                      switchTableOrGraph ? "Graph" : "Table"
                    }`}
                    position="bottom-start"
                    offset={2}
                    arrowOffset={50}
                    arrowSize={8}
                    withArrow
                  >
                    <ActionIcon
                      variant="default"
                      size="lg"
                      onClick={() => {
                        if (switchTableOrGraph) {
                          setSwitchTableOrGraph(false);
                        } else {
                          setSwitchTableOrGraph(true);
                        }
                      }}
                    >
                      {switchTableOrGraph ? <IconTable /> : <IconChartBar />}
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Export Reports file">
                    <ActionIcon
                      loading={exportLoading}
                      variant="default"
                      size="lg"
                      onClick={() => {
                        handleExportClick();
                      }}
                    >
                      <IconDownload />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>
          <Grid.Col style={{ paddingTop: "20px" }}>
            {billLoading ? (
              <SkeletonTableLoader />
            ) : (
              <>
                {BillRefundData?.docs?.length ? (
                  switchTableOrGraph ? (
                    <ChartUtils
                      label="Total Sales"
                      xAxisTitle="Bill No"
                      yAxisTitle="Total Amount"
                      pluginsTitle="Refund Bill"
                      data={BillRefundData?.docs}
                      dataSetProp="RefundAmt"
                      labelsProp="BillNo"
                      // isMonthWise
                    />
                  ) : (
                    <div>
                      <Table
                        verticalSpacing="xs"
                        horizontalSpacing="xs"
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                      >
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Bill Date</Table.Th>
                            <Table.Th>Refund Date</Table.Th>
                            <Table.Th>Device Name</Table.Th>
                            <Table.Th>Bill No</Table.Th>
                            <Table.Th>No of Items</Table.Th>
                            <Table.Th>Discount Amt</Table.Th>
                            <Table.Th>Total Gst</Table.Th>
                            <Table.Th>Total Bill Amount</Table.Th>
                            <Table.Th>Refund Amount</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{SummaryReport()}</Table.Tbody>
                        <Table.Tbody>{TableData()}</Table.Tbody>
                      </Table>

                      {TotalRecords > 1 && (
                        <Flex
                          p={15}
                          mih={50}
                          align="center"
                          gap="md"
                          justify="flex-end"
                          direction="row"
                          wrap="wrap"
                        >
                          {/* <div style={{ width: "5%" }}> */}
                          <Group>
                            <NumberInput
                              value={pageNumber}
                              w={60}
                              mt="sm"
                              placeholder="Page "
                              radius="sm"
                              min={1}
                              max={TotalRecords}
                              hideControls
                              onChange={(event) => {
                                setSearchpage(Number(event));
                              }}
                            />
                            <Button
                              mt="sm"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const pageNumber2 = searchpage;

                                if (
                                  pageNumber2 < 1 ||
                                  pageNumber2 > TotalRecords
                                ) {
                                  notifications.show({
                                    title: "Error",
                                    message: "Invalid page number",
                                    color: "red",
                                    icon: <IconX size="1.1rem" />,
                                  });
                                } else {
                                  handlePageChange(searchpage);
                                }
                              }}
                            >
                              Go
                            </Button>
                          </Group>

                          <Pagination
                            mt="sm"
                            total={TotalRecords}
                            onChange={handlePageChange}
                            value={Number(pageNumber)}
                            // radius="xl"
                          />
                        </Flex>
                      )}
                    </div>
                  )
                ) : (
                  <>
                    <div style={{ paddingTop: "60px" }}>
                      <Player
                        autoplay
                        loop
                        src="./static/infographics/noRecordsFound.json"
                        style={{ height: "300px", width: "300px" }}
                        background="transparent"
                        controls={false}
                      ></Player>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default RefundBill;
