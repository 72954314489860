import React, { useEffect } from "react";
import { Button, Switch, TextInput, Grid, Paper } from "@mantine/core";
import { useUpdateItem } from "../../../modules/item/itemHooks";

interface Props {
  item: any;
  editItemDialog: any;
  setEditItemDialog: any;
  handleUpdateItem: any;
  form: any;
}

const UpdateItem = ({ item, handleUpdateItem, form }: Props) => {
  const { loading } = useUpdateItem();
  useEffect(() => {
    if (item) {
      form.reset();
      const Price = item?.Price.map((price: any) => {
        return {
          Price: price?.Price,
          CGST: price?.CGST,
          SGST: price?.SGST,
          IGST: price?.IGST,
        };
      });
      form.setFieldValue(`ICode`, item?.ICode);
      form.setFieldValue(`IName`, item?.IName);
      form.setFieldValue(`Price`, Price);
      form.setFieldValue(`DCode`, item?.DCode);
      form.setFieldValue(`GCode`, item?.GCode);
      form.setFieldValue(`HSN`, item?.HSN?.trim());
      form.setFieldValue(`DName`, item?.DName);
      form.setFieldValue(`GName`, item?.GName);
      form.setFieldValue(`Unit`, item?.Unit?.trim());
      form.setFieldValue(`CP`, item?.CP);
      form.setFieldValue(`DiscPercent`, item?.DiscPercent);
      form.setFieldValue(`CESS1`, item?.CESS1);
      form.setFieldValue(`CESS2`, item?.CESS2);
      form.setFieldValue(`OpenPriceFlag`, item?.OpenPriceFlag);
      form.setFieldValue(`DecimalDisableFlag`, item?.DecimalDisableFlag);
    }
  }, [item]);

  return (
    <form
      onSubmit={form.onSubmit((values: any) =>
        handleUpdateItem(item?.ItemListId, values)
      )}
    >
      <Grid>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            type="number"
            placeholder="2000 max"
            label="Item Code"
            value={form?.values?.ICode}
            error={form?.errors?.ICode}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="24 Max"
            maxLength={24}
            contentEditable={true}
            withAsterisk
            value={form?.values?.IName}
            error={form?.errors?.IName}
            label="Item Name"
            onChange={(e) => {
              form.setFieldValue(`IName`, e.currentTarget.value);
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ xl: 12, lg: 12 }}>
          <Paper withBorder style={{ padding: "10px" }}>
            {form?.values?.Price.map((price: any, index: any) => (
              <Grid key={index}>
                <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                  <TextInput
                    placeholder="9999999 max"
                    type="number"
                    label={`Price ${index + 1}`}
                    value={form?.values?.Price[index].Price}
                    error={form.getInputProps(`Price.${index}.Price`).error}
                    onChange={(e) => {
                      form.setFieldValue(
                        `Price.${index}.Price`,
                        parseInt(e.currentTarget.value)
                      );
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                  <TextInput
                    placeholder="100 max"
                    type="number"
                    label={`CGST % ${index + 1}`}
                    value={form?.values?.Price[index].CGST}
                    error={form.getInputProps(`Price.${index}.CGST`).error}
                    step={0.01}
                    onChange={(e) => {
                      const inputValue = e.currentTarget.value;
                      const parsedValue =
                        inputValue !== "" ? parseFloat(inputValue) : "";

                      form.setFieldValue(`Price.${index}.CGST`, parsedValue);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                  <TextInput
                    step={0.01}
                    placeholder="100 max"
                    type="number"
                    label={`SGST % ${index + 1}`}
                    value={form?.values?.Price[index].SGST}
                    error={form.getInputProps(`Price.${index}.SGST`).error}
                    onChange={(e) => {
                      const inputValue = e.currentTarget.value;
                      const parsedValue =
                        inputValue !== "" ? parseFloat(inputValue) : "";
                      form.setFieldValue(`Price.${index}.SGST`, parsedValue);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                  <TextInput
                    step={0.01}
                    placeholder="100 max"
                    type="number"
                    label={`IGST % ${index + 1}`}
                    value={form?.values?.Price[index].IGST}
                    error={form.getInputProps(`Price.${index}.IGST`).error}
                    onChange={(e) => {
                      const inputValue = e.currentTarget.value;
                      const parsedValue =
                        inputValue !== "" ? parseFloat(inputValue) : "";
                      form.setFieldValue(`Price.${index}.IGST`, parsedValue);
                    }}
                  />
                </Grid.Col>
              </Grid>
            ))}
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="999 max"
            type="number"
            label="DCode"
            value={form?.values?.DCode}
            error={form?.errors?.DCode}
            onChange={(e) => {
              form.setFieldValue(`DCode`, parseInt(e.currentTarget.value));
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="999 max"
            type="number"
            label="GCode"
            value={form?.values?.GCode}
            error={form?.errors?.GCode}
            onChange={(e) => {
              form.setFieldValue(`GCode`, parseInt(e.currentTarget.value));
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="9 max"
            label="HSN"
            value={form?.values?.HSN}
            error={form?.errors?.HSN}
            maxLength={9}
            onChange={(e) => {
              form.setFieldValue(`HSN`, e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            maxLength={19}
            placeholder="19 max"
            label="DName"
            value={form?.values?.DName}
            error={form?.errors?.DName}
            onChange={(e) => {
              form.setFieldValue(`DName`, e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            maxLength={19}
            placeholder="19 max"
            label="GName"
            value={form?.values?.GName}
            error={form?.errors?.GName}
            onChange={(e) => {
              form.setFieldValue(`GName`, e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="3 Max"
            label="Unit"
            value={form?.values?.Unit}
            error={form?.errors?.Unit}
            maxLength={3}
            onChange={(e) => {
              form.setFieldValue(`Unit`, e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            step={0.01}
            placeholder="9999999 max"
            type="number"
            label="CP"
            value={form?.values?.CP}
            error={form?.errors?.CP}
            onChange={(e) => {
              const inputValue = e.currentTarget.value;
              const parsedValue =
                inputValue !== "" ? parseFloat(inputValue) : "";
              form.setFieldValue(`CP`, parsedValue);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            type="number"
            value={form?.values?.DiscPercent}
            error={form?.errors?.DiscPercent}
            placeholder="100 max"
            label="Discount Percentage"
            step={0.01}
            onChange={(e) => {
              const inputValue = e.currentTarget.value;
              const parsedValue =
                inputValue !== "" ? parseFloat(inputValue) : "";
              form.setFieldValue(`DiscPercent`, parsedValue);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="100 max"
            type="number"
            label="CESS 1"
            value={form?.values?.CESS1}
            error={form.errors.CESS1}
            step={0.01}
            onChange={(e) => {
              const inputValue = e.currentTarget.value;
              const parsedValue =
                inputValue !== "" ? parseFloat(inputValue) : "";
              form.setFieldValue(`CESS1`, parsedValue);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <TextInput
            placeholder="100 max"
            type="number"
            label="CESS 2"
            value={form?.values?.CESS2}
            error={form.errors.CESS2}
            step={0.01}
            onChange={(e) => {
              const inputValue = e.currentTarget.value;
              const parsedValue =
                inputValue !== "" ? parseFloat(inputValue) : "";
              form.setFieldValue(`CESS2`, parsedValue);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <Switch
            style={{ margin: "10px" }}
            label="Open Price Flag"
            onLabel="ON"
            offLabel="OFF"
            // value={OpenPriceFlag}
            checked={form?.values?.OpenPriceFlag}
            onChange={(e) => {
              form.setFieldValue(
                `OpenPriceFlag`,
                e.currentTarget.checked === true
                  ? e.currentTarget.checked
                  : false
              );
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
          <Switch
            style={{ margin: "10px" }}
            label="Decimal Disable Flag"
            onLabel="ON"
            offLabel="OFF"
            checked={form?.values?.DecimalDisableFlag}
            onChange={(e) => {
              form.setFieldValue(`DecimalDisableFlag`, e.currentTarget.checked);
            }}
          />
        </Grid.Col>
      </Grid>

      <Button loading={loading} type="submit" style={{ marginTop: "10px" }}>
        Update
      </Button>
    </form>
  );
};

export default UpdateItem;
