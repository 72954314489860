import React, { useState } from "react";
import { useRef } from "react";
import {
  Text,
  Group,
  Button,
  rem,
  useMantineTheme,
  Modal,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import {
  IconCloudUpload,
  IconX,
  IconDownload,
  IconCheck,
} from "@tabler/icons-react";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import { CreateItemProps } from "../Items";
import { notifications } from "@mantine/notifications";
import * as XLSX from "xlsx";
import { forkJoin } from "rxjs";
import {
  GetItemProps,
  ItemService,
} from "../../../modules/item/service/itemService";
import { APIPATHENV } from "../../../utils/env";

interface Props {
  showDropZone: any;
  handleDropZoneClose: () => void;
  selectedItemListId: string;
  fetchItem: any;
}

const BulkUploadModal = ({
  showDropZone,
  handleDropZoneClose,
  selectedItemListId,
  fetchItem,
}: Props) => {
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);

  const [importedList, setImportedList] = useState<any>([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  let arrayBuffer: any;

  function onFileLoad1(data: any) {
    let isEnforce = true;
    const getItemsData: GetItemProps = {
      ItemListId: selectedItemListId,
      page: 1,
      limit: 100,
    };

    const updatePromises = [];
    const errors: any = [];
    let stopLoop = false;

    function handleUpdateError(error: any) {
      errors.push(error);
      stopLoop = true;
    }

    if (data.length < 100 && data.length > 0) {
      const itemService = new ItemService(APIPATHENV);

      let itemsSend: any = {
        item: data,
        ItemListId: selectedItemListId,
        isEnforce,
      };

      const updatePromise = itemService
        .updateItem(itemsSend)
        .then((result) => {
          return result; // Return the result to be collected in forkJoin
        })
        .catch(handleUpdateError);

      updatePromises.push(updatePromise);
    } else if (data.length >= 100) {
      for (let i = 0; i < Math.ceil(data.length / 100) && !stopLoop; i++) {
        const itemService = new ItemService(APIPATHENV);

        let itemsSend: any = {
          item: data.slice(i * 100, (i + 1) * 100 - 1),
          ItemListId: selectedItemListId,
          isEnforce,
        };

        const updatePromise = itemService
          .updateItem(itemsSend)
          .then((result) => {
            return result; // Return the result to be collected in forkJoin
          })
          .catch(handleUpdateError);

        updatePromises.push(updatePromise);
      }
    }

    forkJoin(updatePromises).subscribe({
      next: (results) => {
        if (errors.length > 0) {
          notifications.show({
            title: "Error",
            message: `Item Upload Failed${errors}`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          // fetchItem(getItemsData);
          setUploadLoading(false);

          handleDropZoneClose();
        } else {
          notifications.show({
            title: "Success",
            message: "Item Uploaded Successfully",
            color: "teal",
            icon: <IconCheck size="1.1rem" />,
          });
          setUploadLoading(false);
          fetchItem(getItemsData);

          handleDropZoneClose();
        }
      },
      error: (error) => {
        notifications.show({
          title: "Error",
          message: "Error in sending file data to server",
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
        // fetchItem(getItemsData);
        setUploadLoading(false);
        handleDropZoneClose();
      },
    });
  }

  function prepareImportedList(data: any) {
    let flag = 0;
    let Error_msg;
    for (let i = 0; i < data.length; i++) {
      let importeditem = {} as CreateItemProps;
      if (
        data[i].ICODE !== undefined &&
        data[i].ICODE !== null &&
        Number(data[i].ICODE) > 0 &&
        Number(data[i].ICODE) <= 2000
      ) {
        importeditem.ICode = Number(data[i].ICODE);
      } else {
        flag = 2;
        notifications.show({
          title: "Error",
          message: `Item Upload Error : Invalid Item CODE for Item ${data[i].INAME}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });

        break;
      }
      if (
        data[i].INAME !== undefined &&
        data[i].INAME !== null &&
        String(data[i].INAME).length <= 50
      ) {
        importeditem.IName = String(data[i].INAME);
      } else {
        flag = 1;
        Error_msg = "Invalid Item Name for Item code = " + data[i].ICODE;
        break;
      }
      importeditem.Price = [];
      importeditem.Price[0] = Object.create({});
      if (data[i].IPRICE1) {
        if (data[i].IPRICE1 !== null && Number(data[i].IPRICE1) <= 9999999) {
          if (isNaN(Number(data[i].IPRICE1)) !== true) {
            importeditem.Price[0].Price = Number(data[i].IPRICE1);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item Price1 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[0].Price = 0;
      }

      if (data[i].IGST1) {
        if (data[i].IGST1 !== null && Number(data[i].IGST1) <= 100) {
          if (isNaN(Number(data[i].IGST1)) !== true) {
            importeditem.Price[0].IGST = Number(data[i].IGST1);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item IGST1 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[0].IGST = 0;
      }

      if (data[i].CGST1) {
        if (data[i].CGST1 !== null && Number(data[i].CGST1) <= 100) {
          if (isNaN(Number(data[i].CGST1)) !== true) {
            importeditem.Price[0].CGST = Number(data[i].CGST1);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item CGST1 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[0].CGST = 0;
      }

      if (data[i].SGST1) {
        if (data[i].SGST1 !== null && Number(data[i].SGST1) <= 100) {
          if (isNaN(Number(data[i].SGST1)) !== true) {
            importeditem.Price[0].SGST = Number(data[i].SGST1);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item SGST1 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[0].SGST = 0;
      }

      importeditem.Price[1] = Object.create({});
      if (data[i].IPRICE2) {
        if (data[i].IPRICE2 !== null && Number(data[i].IPRICE2) <= 9999999) {
          if (isNaN(Number(data[i].IPRICE2)) !== true) {
            importeditem.Price[1].Price = Number(data[i].IPRICE2);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item Price2 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[1].Price = 0;
      }

      if (data[i].IGST2) {
        if (data[i].IGST2 !== null && Number(data[i].IGST2) <= 100) {
          if (isNaN(Number(data[i].IGST2)) !== true) {
            importeditem.Price[1].IGST = Number(data[i].IGST2);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item IGST2 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[1].IGST = 0;
      }

      if (data[i].CGST2) {
        if (data[i].CGST2 !== null && Number(data[i].CGST2) <= 100) {
          if (isNaN(Number(data[i].CGST2)) !== true) {
            importeditem.Price[1].CGST = Number(data[i].CGST2);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item CGST2 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[1].CGST = 0;
      }

      if (data[i].SGST2) {
        if (data[i].SGST2 !== null && Number(data[i].SGST2) <= 100) {
          if (isNaN(Number(data[i].SGST2)) !== true) {
            importeditem.Price[1].SGST = Number(data[i].SGST2);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item SGST2 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[1].SGST = 0;
      }

      importeditem.Price[2] = Object.create({});

      if (data[i].IPRICE3) {
        if (data[i].IPRICE3 !== null && Number(data[i].IPRICE3) <= 9999999) {
          if (isNaN(Number(data[i].IPRICE3)) !== true) {
            importeditem.Price[2].Price = Number(data[i].IPRICE3);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item Price3 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[2].Price = 0;
      }

      if (data[i].IGST3) {
        if (data[i].IGST3 !== null && Number(data[i].IGST3) <= 100) {
          if (isNaN(Number(data[i].IGST3)) !== true) {
            importeditem.Price[2].IGST = Number(data[i].IGST3);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item IGST3 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[2].IGST = 0;
      }

      if (data[i].CGST3) {
        if (data[i].CGST3 !== null && Number(data[i].CGST3) <= 100) {
          if (isNaN(Number(data[i].CGST3)) !== true) {
            importeditem.Price[2].CGST = Number(data[i].CGST3);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item CGST3 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[2].CGST = 0;
      }

      if (data[i].SGST3) {
        if (data[i].SGST3 !== null && Number(data[i].SGST3) <= 100) {
          if (isNaN(Number(data[i].SGST3)) !== true) {
            importeditem.Price[2].SGST = Number(data[i].SGST3);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item SGST3 for Item Code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.Price[2].SGST = 0;
      }
      if (
        (data[i].HSN &&
          String(data[i].HSN).length >= 2 &&
          String(data[i].HSN).length <= 9) ||
        (data[i].HSN && String(data[i].HSN).trim().length === 0)
      ) {
        if (!(String(data[i].HSN).trim().length === 0)) {
          importeditem.HSN = String(data[i].HSN).trim();
        }
      } else {
        if (data[i].HSN !== undefined && data[i].HSN !== null) {
          flag = 1;
          Error_msg = `Invalid Item HSN for Item code = ${data[i].ICODE}`;
          if (importeditem.HSN) {
            delete importeditem.HSN;
          }
          break;
        }
      }

      if (
        (data[i].UNIT &&
          String(data[i].UNIT).length >= 1 &&
          String(data[i].UNIT).length <= 3) ||
        (data[i].UNIT && String(data[i].UNIT).trim().length === 0)
      ) {
        if (!(String(data[i].UNIT).trim().length === 0)) {
          importeditem.Unit = String(data[i].UNIT).trim();
        }
      } else {
        if (data[i].UNIT !== undefined && data[i].UNIT !== null) {
          flag = 1;
          Error_msg = `Invalid Item UNIT for Item code = ${data[i].ICODE}`;
          if (importeditem.Unit) {
            delete importeditem.Unit;
          }
          break;
        }
      }

      if (data[i].DCODE) {
        if (data[i].DCODE !== null || Number(data[i].DCODE) <= 999) {
          if (isNaN(Number(data[i].DCODE)) !== true) {
            importeditem.DCode = Number(data[i].DCODE);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item DCODE for Item code = " + data[i].ICODE;
          break;
        }
      }

      if (data[i].DNAME) {
        if (data[i].DNAME !== null && String(data[i].DNAME)?.length < 50) {
          importeditem.DName = String(data[i].DNAME);
        } else {
          flag = 1;
          Error_msg = "Invalid Item DNAME for Item code = " + data[i].ICODE;
          break;
        }
      }       

      if (data[i].GNAME) {
        if (data[i].GNAME !== null && String(data[i].GNAME)?.length < 50) {
          importeditem.GName = String(data[i].GNAME);
        } else {
          flag = 1;
          Error_msg = "Invalid Item GNAME for Item code = " + data[i].ICODE;
          break;
        }
      }  

      if (data[i].GCODE) {
        if (data[i].GCODE !== null && Number(data[i].GCODE) <= 999) {
          if (isNaN(Number(data[i].GCODE)) !== true) {
            importeditem.GCode = Number(data[i].GCODE);
          }
        } else {
          flag = 1;
          Error_msg = " Invalid Item GCODE for Item code =" + data[i].ICODE;
          break;
        }
      }

      if (data[i].CP) {
        if (data[i].CP !== null && Number(data[i].CP) <= 9999999) {
          if (isNaN(Number(data[i].CP)) !== true) {
            importeditem.CP = Number(data[i].CP);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item Cost Price for Item code =" + data[i].ICODE;
          break;
        }
      } else {
        importeditem.CP = 0;
      }

      if (data[i].CESS1) {
        if (data[i].CESS1 !== null && Number(data[i].CESS1) <= 100) {
          if (isNaN(Number(data[i].CESS1)) !== true) {
            importeditem.CESS1 = Number(data[i].CESS1);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item CESS1 for Item code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.CESS1 = 0;
      }

      if (data[i].CESS2) {
        if (data[i].CESS2 !== null && Number(data[i].CESS2) <= 100) {
          if (isNaN(Number(data[i].CESS2)) !== true) {
            importeditem.CESS2 = Number(data[i].CESS2);
          }
        } else {
          flag = 1;
          Error_msg = "Invalid Item CESS2 for Item code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.CESS2 = 0;
      }

      if (data[i].DIS) {
        if (data[i].DIS !== null && Number(data[i].DIS) <= 100) {
          if (isNaN(Number(data[i].DIS)) !== true) {
            importeditem.DiscPercent = Number(data[i].DIS);
          }
        } else {
          flag = 1;
          Error_msg =
            " Invalid Item Discount Percent for Item code = " + data[i].ICODE;
          break;
        }
      } else {
        importeditem.DiscPercent = 0;
      }

      if (data[i].DEC_NO) {
        if (data[i].DEC_NO !== null && data[i].DEC_NO < 2) {
          importeditem.DecimalDisableFlag = Boolean(data[i].DEC_NO);
        } else {
          flag = 1;
          Error_msg =
            "Invalid Item Decimal Disable Flag no for Item code =" +
            data[i].ICODE;
          break;
        }
      } else {
        importeditem.DecimalDisableFlag = false;
      }

      if (data[i].OPF) {
        if (data[i].OPF !== null && data[i].OPF < 2) {
          importeditem.OpenPriceFlag = Boolean(data[i].OPF);
        } else {
          flag = 1;
          Error_msg =
            "Invalid Item OpenPrice Flag no for Item code =" + data[i].ICODE;
          break;
        }
      } else {
        importeditem.OpenPriceFlag = false;
      }
      importedList[i] = Object.assign({}, importeditem);
    }
    if (flag === 1) {
      setUploadLoading(false);
      importedList.length = 0;
      notifications.show({
        title: "Error",
        message: `Item Upload Error:${Error_msg} `,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } else if (flag === 2) {
      setUploadLoading(false);
      importedList.length = 0;
    }
  }

  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      size="lg"
      closeOnClickOutside={false}
      opened={showDropZone}
      onClose={() => {
        handleDropZoneClose();
      }}
    >
      <div className={classes.wrapper}>
          <Dropzone
            loading={uploadLoading}
            openRef={openRef}
            className={classes.dropzone}
            radius="md"
            accept={[MIME_TYPES.csv]}
            maxSize={30 * 1024 ** 2}
            onDrop={(files) => {
              setUploadLoading(true);
              let fileReader = new FileReader();
              fileReader.onload = (e) => {
                arrayBuffer = fileReader.result;
                var data = new Uint8Array(arrayBuffer);
                var arr = [];
                for (var i = 0; i !== data.length; ++i)
                  arr[i] = String.fromCharCode(data[i]);
                var bstr = arr.join("");
                var workbook = XLSX.read(bstr, { type: "binary" });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                let data1 = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                prepareImportedList(data1);
                if (importedList.length === 0) {
                  return 0;
                }
                onFileLoad1(importedList);
                setImportedList([]);
              };
              fileReader.readAsArrayBuffer(files[0]);

              // fileInput.value = null;
            }}
          >
            <div style={{ pointerEvents: "none" }}>
              <Group justify="center">
                <Dropzone.Accept>
                  <IconDownload
                    style={{ width: rem(50), height: rem(50) }}
                    color={theme.colors.blue[6]}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    style={{ width: rem(50), height: rem(50) }}
                    color={theme.colors.red[6]}
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload
                    style={{ width: rem(50), height: rem(50) }}
                    stroke={1.5}
                  />
                </Dropzone.Idle>
              </Group>

              <Text ta="center" fw={700} fz="lg" mt="xl">
                <Dropzone.Accept>Drop files here</Dropzone.Accept>
                <Dropzone.Reject>Csv file less than 30mb</Dropzone.Reject>
                <Dropzone.Idle>Upload Item</Dropzone.Idle>
              </Text>
              <Text ta="center" fz="sm" mt="xs" c="dimmed">
                Drag&apos;n&apos;drop files here or click to upload. We can
                accept only <i>&nbsp;.csv&nbsp; </i> files.
              </Text>
            </div>
          </Dropzone>
        <Group justify="center" m={10}>
          <Button
            className={classes.control}
            size="md"
            radius="sm"
            onClick={() => openRef.current?.()}
          >
            Select files
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default BulkUploadModal;
