import axios from "axios";
import { getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetItemListProps {
  businessId: string;
  page: number;
  limit: number;
}

export interface CreateItemListProps {
  businessId: string;
  itemListName: string;
}

export interface UpdateItemListProps {
  businessId: string;
  itemListId: string;
  itemListName: string;
}

export interface FetchItemsResponse {
  docs: [
    {
      _id: string;
      itemListName: string;
      businessId: string;
    }
  ];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export class ItemListService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getItemList(itemListData: GetItemListProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item_list}?page=${itemListData.page}&limit=${itemListData.limit}&businessId=${itemListData.businessId}`;

      const response = await axios.get(url, {
        headers: header,
      });

      const respData: FetchItemsResponse = response.data;
      return respData;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async createItemList(itemListData: CreateItemListProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item_list}`;

      const response = await axios.post(url, itemListData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateItemList(itemListData: UpdateItemListProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item_list}`;

      const response = await axios.patch(url, itemListData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
