import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../awatarStore/store'
import { fetch,create,update } from './itemListActions';
import { GetItemListProps ,CreateItemListProps,UpdateItemListProps} from './service/itemListService';

 export const useGetItemList=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['itemList/fetch'].loading);
    const error = useSelector((state: RootState) => state['itemList/fetch'].error);
    const data: any = useSelector((state: RootState) => state['itemList/fetch'].data)
    

    const fetchItemList = async (itemListData: GetItemListProps) => {
            await dispatch(fetch(itemListData));
    };

    return {fetchItemList,loading,error, data}
}

export const useCreateItemList=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['itemList/create'].loading);
    const error = useSelector((state: RootState) => state['itemList/create'].error);
    const data = useSelector((state: RootState) => state['itemList/create'].data)
    const errorData = useSelector((state: RootState) => state['itemList/create'].errorData)
    const isCreated = useSelector((state: RootState) => state['itemList/create'].isCreated)

    const createItemList = async (itemListData: CreateItemListProps) => {
     await dispatch(create(itemListData));
            

    };

    return {createItemList,loading,error,errorData,isCreated,data}
}


export const useUpdateItemList=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['itemList/update'].loading);
    const error = useSelector((state: RootState) => state['itemList/update'].error);
    const data = useSelector((state: RootState) => state['itemList/update'].data)
    const errorData = useSelector((state: RootState) => state['itemList/update'].errorData)
    const isUpdated = useSelector((state: RootState) => state['itemList/update'].isUpdated)
    

    const updateItemList = async (itemListData: UpdateItemListProps) => {
    await dispatch(update(itemListData));
            

    };

    return {updateItemList,loading,error,errorData,isUpdated,data}
}
