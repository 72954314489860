import "./App.css";

import MainAppShell from "./appShell/MainAppShell";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Page404 from "./pages/page404/Page404";
import Login from "./pages/auth/Login";
import PhinProvider from "./modules/awatarStore/PhinProvider";
import Register from "./pages/auth/Register";

function App() {
  return (
    <div className="App">
      <PhinProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/*" element={<MainAppShell children={undefined} />} />
            <Route path="/404" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </PhinProvider>
    </div>
  );
}

export default App;
