import { useEffect } from "react";
import SideBar from "../components/SideBar";
import {
  AppShell,
  rem,
  useMantineColorScheme,
  Grid,
  Text,
  Group,
  em,
} from "@mantine/core";
import { Brand } from "./_brand";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import {
  useBusinessLogin,
  useIsLoggedIn,
  useLogout,
} from "../modules/auth/authHooks";
import { sideBarData } from "../utils/uiUtils/JsonRouteData";
import DashBoard from "../pages/dashboard/Dashboard";
import { ItemList } from "../pages/itemlist/ItemList";
import { Items } from "../pages/item/Items";
import Devices from "../pages/device/Devices";
import HeaderFooter from "../pages/device/device/HeaderFooter";
import Settings from "../pages/device/device/Settings";
import Outlet from "../pages/outlet/Outlet";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import Aggregate from "../pages/reports/reports/Aggregate";
import Billwise from "../pages/reports/reports/Billwise";
import Daywise from "../pages/reports/reports/Daywise";
import GstWise from "../pages/reports/reports/GstWise";
import ItemWise from "../pages/reports/reports/ItemWise";
import Monthwise from "../pages/reports/reports/Monthwise";
import RefundBill from "../pages/reports/reports/RefundBill";
import SummaryReport from "../pages/reports/reports/SummaryReport";
import Userwise from "../pages/reports/reports/Userwise";
import classes from "./SideBarNested.module.css";
import Subscription from "../pages/Subscription/Subscription";

import SubscriptionTransactionDetails from "../pages/Subscription/SubscriptionTransactionDetails/SubscriptionTransactionDetails";
import { WEBAPPVERSION } from "../utils/version";

import { useGetFooter } from "../modules/footer/footerHooks";
import { useGetBusiness } from "../modules/business/businessHooks";

interface AppShellProps {
  children: React.ReactNode;
}

interface AuthRouteProps {
  isAuthenticated: boolean | undefined;
  redirectTo?: string;
  element: JSX.Element;
  path: string;
  isBusinessAvailable?: boolean;
}

export function AuthRoute({
  isAuthenticated,
  redirectTo = "/login",
  path,
  element,
  isBusinessAvailable,
}: AuthRouteProps) {
  return isAuthenticated &&
    (isBusinessAvailable === true ||
      localStorage.getItem("isBusinessAvailable") === "true") ? (
    element
  ) : isAuthenticated &&
    !isBusinessAvailable &&
    localStorage.getItem("isBusinessAvailable") === "false" ? (
    <Navigate to={redirectTo} replace={true} />
  ) : (
    <Navigate to={redirectTo} replace={true} />
  );
}
export default function MainAppShell({ children }: AppShellProps) {
  const { LoggedIn, checkIsLoggedIn } = useIsLoggedIn();
  const { logoutUser } = useLogout();
  const navigate = useNavigate();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened] = useDisclosure(true);
  const { colorScheme } = useMantineColorScheme();
  const isMobileOpened = useMediaQuery(`(max-width: ${em(999)})`);
  const { fetchFooterVersion, data: footerData } = useGetFooter();
  const { isBusinessAvailable } = useBusinessLogin();
  const { fetchBusiness, businessName } = useGetBusiness();
  const businessNameFromStorage = localStorage.getItem("businessName");
  const alternateBusinessName =
    businessNameFromStorage && businessNameFromStorage !== "undefined"
      ? businessNameFromStorage
      : "";

  useEffect(() => {
    checkIsLoggedIn();
    fetchFooterVersion();

    const getBusinessData = {
      page: 1,
      limit: 100,
    };
    fetchBusiness(getBusinessData);
  }, []);

  const handleLogout = () => {
    logoutUser();
  };

  const userClickHandle = () => {
    navigate("/");
  };

  if (LoggedIn) {
    return (
      <AppShell
        padding="md"
        navbar={{
          width: desktopOpened ? 300 : mobileOpened ? 60 : 0,
          breakpoint: "sm",
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        footer={{
          height: isMobileOpened ? 20 : 40,
        }}
      >
        <AppShell.Header
          p="xs"
          className={
            colorScheme === "dark"
              ? classes.darkHeader
              : colorScheme === "light"
              ? classes.lightHeader
              : ""
          }
        >
          {
            <Brand
              userClick={userClickHandle}
              handleLogout={handleLogout}
              mobileOpened={mobileOpened}
              toggleMobile={toggleMobile}
              businessName={businessName ?? alternateBusinessName}
            />
          }
        </AppShell.Header>

        <SideBar
          userClick={userClickHandle}
          mobileOpened={mobileOpened}
          desktopOpened={desktopOpened}
          sideMenuItems={sideBarData}
          toggleMobile={toggleMobile}
        />

        <AppShell.Main
          pt={`calc(${rem(70)} + var(--mantine-spacing-md))`}
          className={
            colorScheme === "dark"
              ? classes.darkAppShellMain
              : classes.lightAppShellMain
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <AuthRoute
                  path="/"
                  isAuthenticated={LoggedIn}
                  element={<DashBoard />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/itemManagement/itemList"
              element={
                <AuthRoute
                  path="/itemManagement/itemList"
                  isAuthenticated={LoggedIn}
                  element={<ItemList />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/itemManagement/items"
              element={
                <AuthRoute
                  path="/itemManagement/items"
                  isAuthenticated={LoggedIn}
                  element={<Items />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/deviceManagement/device"
              element={
                <AuthRoute
                  path="/deviceManagement/device"
                  isAuthenticated={LoggedIn}
                  element={<Devices />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/deviceManagement/hf"
              element={
                <AuthRoute
                  path="/deviceManagement/hf"
                  isAuthenticated={LoggedIn}
                  element={<HeaderFooter />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/deviceManagement/settings"
              element={
                <AuthRoute
                  path="/deviceManagement/settings"
                  isAuthenticated={LoggedIn}
                  element={<Settings />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/outlet"
              element={
                <AuthRoute
                  path="/outlet"
                  isAuthenticated={LoggedIn}
                  element={<Outlet />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/billwise"
              element={
                <AuthRoute
                  path="/billwise"
                  isAuthenticated={LoggedIn}
                  element={<Billwise status="CONFIRM" />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/itemwise"
              element={
                <AuthRoute
                  path="/itemwise"
                  isAuthenticated={LoggedIn}
                  element={<ItemWise />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/daywise"
              element={
                <AuthRoute
                  path="/daywise"
                  isAuthenticated={LoggedIn}
                  element={<Daywise />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/monthwise"
              element={
                <AuthRoute
                  path="/monthwise"
                  isAuthenticated={LoggedIn}
                  element={<Monthwise />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/gstwise"
              element={
                <AuthRoute
                  path="/gstwise"
                  isAuthenticated={LoggedIn}
                  element={<GstWise />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/refundbill"
              element={
                <AuthRoute
                  path="/refundbill"
                  isAuthenticated={LoggedIn}
                  element={<RefundBill />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/summaryreport"
              element={
                <AuthRoute
                  path="/summaryreport"
                  isAuthenticated={LoggedIn}
                  element={<SummaryReport />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/userwisereport"
              element={
                <AuthRoute
                  path="/userwisereport"
                  isAuthenticated={LoggedIn}
                  element={<Userwise />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/voidbill"
              element={
                <AuthRoute
                  path="/voidbill"
                  isAuthenticated={LoggedIn}
                  element={<Billwise status="DELETED" />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/aggregate"
              element={
                <AuthRoute
                  path="/aggregate"
                  isAuthenticated={LoggedIn}
                  element={<Aggregate />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/manageaccount/subscription"
              element={
                <AuthRoute
                  path="/manageaccount/subscription"
                  isAuthenticated={LoggedIn}
                  element={<Subscription />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            <Route
              path="/manageaccount/subscription-transaction"
              element={
                <AuthRoute
                  path="/manageaccount/subscription-transaction"
                  isAuthenticated={LoggedIn}
                  element={<SubscriptionTransactionDetails />}
                  isBusinessAvailable={isBusinessAvailable}
                />
              }
            />
            {/* add default navigate to 404 */}
            <Route path="*" element={<Navigate to="/404" />} />
            {/* </Route> */}
          </Routes>
        </AppShell.Main>
        <AppShell.Footer
          className={
            colorScheme === "dark"
              ? classes.darkFooter
              : colorScheme === "light"
              ? classes.lightFooter
              : ""
          }
        >
          <Grid justify="space-between">
            <Grid.Col style={{ display: "flex" }} span={8}>
              <Group display="flex">
                <Text
                  ml={10}
                  style={{ fontSize: isMobileOpened ? "10px" : "14px" }}
                >
                  NGX © 2023 Awatar Web Dashboard
                </Text>

                <Text style={{ fontSize: isMobileOpened ? "10px" : "14px" }}>
                  Version : {WEBAPPVERSION}
                </Text>

                <Text style={{ fontSize: isMobileOpened ? "10px" : "14px" }}>
                  APIVersion : {footerData?.apiver}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col
              span={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Group
                mt={isMobileOpened ? 0 : 10}
                mb={10}
                mr={10}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                  whiteSpace: "nowrap",
                  fontSize: isMobileOpened ? "10px" : "14px",
                }}
              >
                Powered by{" "}
                <Text
                  ml={5}
                  style={{ fontSize: isMobileOpened ? "10px" : "14px" }}
                  c="dimmed"
                >
                  NGX Technologies
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        </AppShell.Footer>
      </AppShell>
    );
  } else {
    // window.location.href = "/login";
    return <Navigate to="/login" replace />;
  }
}
