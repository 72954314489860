import React from "react";
import { Button, Grid, Modal, Select } from "@mantine/core";
import classes from "../../styleModules/AppShellMainStyles.module.css";

interface Props {
  itemListMapping: any;
  setItemListMapping: any;
  setItemListId: any;
  itemListData: any;
  selectedDevice: any;
  handleItemListMapping: any;
}

const ItemListMapping = ({
  itemListMapping,
  setItemListMapping,
  itemListData,
  setItemListId,
  handleItemListMapping,
  selectedDevice,
}: Props) => {
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Itemlist Mapping"
      closeOnClickOutside={false}
      withCloseButton
      opened={itemListMapping}
      onClose={() => {
        setItemListMapping(false);
        setItemListId("");
      }}
      size="sm"
      radius="md"
    >
      <Grid>
        <Grid.Col
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignContent: "center",
            justifyContent: "space-between",
            maxHeight: "100px",
          }}
        >
          <Select
            defaultSearchValue={
              selectedDevice?.itemListDetail?.[0]?.itemListName
            }
            radius="md"
            label="Itemlist"
            onChange={(e) => setItemListId(e ?? "")}
            placeholder="Select Itemlist"
            data={itemListData()}
            style={{ width: "70%", marginBottom: "230px" }}
          ></Select>
          <Button onClick={handleItemListMapping} style={{ marginTop: "25px" }}>
            Save
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default ItemListMapping;
