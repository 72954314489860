import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../awatarStore/store'
import { fetch,create,update, deleteItem, deleteAllItem, searchItem, download } from './itemActions';
import { DeleteProps, GetItemProps ,ItemProps} from './service/itemService';

 export const useGetItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['item/fetch'].loading);
    const error = useSelector((state: RootState) => state['item/fetch'].error);
    const data: any = useSelector((state: RootState) => state['item/fetch'].data)
  
    
    const fetchItem = async (itemData: GetItemProps) => {
            await dispatch(fetch(itemData));
    };

    return {
        fetchItem,
        loading,
        error,
        data,
    }
}

export const useCreateItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['item/create'].loading);
    const error = useSelector((state: RootState) => state['item/create'].error);
    const data = useSelector((state: RootState) => state['item/create'].data)
    const isCreated = useSelector((state: RootState) => state['item/create'].isCreated)
    const errorData = useSelector((state: RootState) => state['item/create'].errorData)
    const notDuplicate = useSelector((state: RootState) => state['item/create'].notDuplicate)

    const createItem = async (itemData: ItemProps) => {
            await dispatch(create(itemData));
    };

    return {createItem,loading,error,errorData,isCreated,notDuplicate,data}
}


export const useUpdateItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['item/update'].loading);
    const error = useSelector((state: RootState) => state['item/update'].error);
    const data = useSelector((state: RootState) => state['item/update'].data)
    const isUpdated = useSelector((state: RootState) => state['item/update'].isUpdated)
    const errorData = useSelector((state: RootState) => state['item/update'].errorData)

    const updateItem = async (itemData: ItemProps) => {
           const response= await dispatch(update(itemData));
           return response;
    };

    return {updateItem,loading,error,errorData,isUpdated,data}
}

export const useDeleteItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['item/delete'].loading);
    const error = useSelector((state: RootState) => state['item/delete'].error);
    const data = useSelector((state: RootState) => state['item/delete'].data)
    const isDeleted = useSelector((state: RootState) => state['item/delete'].isDeleted)
    const errorData = useSelector((state: RootState) => state['item/delete'].errorData)

    const deleteItemFunction = async (itemData: DeleteProps) => {
        await dispatch(deleteItem(itemData));
    };

    return {deleteItemFunction,loading,error,errorData,isDeleted,data}
}

export const useDeleteAllItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['item/deleteAll'].loading);
    const error = useSelector((state: RootState) => state['item/deleteAll'].error);
    const data = useSelector((state: RootState) => state['item/deleteAll'].data)
    const errorData = useSelector((state: RootState) => state['item/deleteAll'].errorData)
    const isDeleted = useSelector((state: RootState) => state['item/deleteAll'].isDeleted)

    const deleteAllItemFunction = async (itemData: DeleteProps) => {
        await dispatch(deleteAllItem(itemData));
    };

    return {deleteAllItemFunction,loading,error,errorData,isDeleted,data}
}

export const useSearchItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['item/search'].loading);
    const error = useSelector((state: RootState) => state['item/search'].error);
    const data = useSelector((state: RootState) => state['item/search'].data)
    const errorData = useSelector((state: RootState) => state['item/search'].errorData)

    const searchItemHook = async (itemData: GetItemProps) => {
        await dispatch(searchItem(itemData));
    };

    return {searchItemHook,loading,error,data,errorData}
}

export const useDownloadItem=()=>{
    const dispatch: AppDispatch = useDispatch();
    const downloadError = useSelector((state: RootState) => state['item/download'].error);
    const downloadLoading = useSelector((state: RootState) => state['item/download'].loading);
    const downloadData = useSelector((state: RootState) => state['item/download'].data)
    const errorData = useSelector((state: RootState) => state['item/download'].errorData)
   
    const downloadItems = async (itemData: GetItemProps) => {
        await dispatch(download(itemData));
    };

    return {downloadItems,downloadLoading,downloadError,downloadData,errorData}
}