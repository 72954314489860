import React from "react";
import { Button, Grid, Modal, Paper, Switch, TextInput } from "@mantine/core";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import { useCreateItem } from "../../../modules/item/itemHooks";
interface Props {
  createItemDialog: any;
  setCreateItemDialog: any;
  handleCreateItem: any;
  form: any;
}

const CreateItem = ({
  createItemDialog,
  setCreateItemDialog,
  handleCreateItem,
  form,
}: Props) => {
  const { loading } = useCreateItem();
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Create Item"
      closeOnClickOutside={false}
      withCloseButton
      opened={createItemDialog}
      onClose={() => {
        setCreateItemDialog(false);
        form.reset();
      }}
      size="xl"
      radius="md"
    >
      <form
        onSubmit={form.onSubmit((values: any) => {
          handleCreateItem(values);
        })}
      >
        <Grid>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              type="number"
              placeholder="2000 max"
              withAsterisk
              label="Item Code"
              error={form.errors.ICode}
              onChange={(e) => {
                form.setFieldValue(`ICode`, parseInt(e.currentTarget.value));
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              maxLength={24}
              placeholder="24 Max"
              withAsterisk
              label="Item Name"
              {...form.getInputProps("IName")}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 12, lg: 12 }}>
            <Paper withBorder style={{ padding: "10px" }}>
              {form.values.Price.map((price: any, index: any) => (
                <Grid key={index}>
                  <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                    <TextInput
                      placeholder="9999999 max"
                      type="number"
                      label={`Price ${index + 1}`}
                      error={form.getInputProps(`Price.${index}.Price`).error}
                      onChange={(e) => {
                        form.setFieldValue(
                          `Price.${index}.Price`,
                          parseInt(e.currentTarget.value)
                        );
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                    <TextInput
                      placeholder="100 max"
                      type="float"
                      label={`CGST % ${index + 1}`}
                      error={form.getInputProps(`Price.${index}.CGST`).error}
                      onChange={(e) => {
                        form.setFieldValue(
                          `Price.${index}.CGST`,
                          parseFloat(e.currentTarget.value)
                        );
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                    <TextInput
                      placeholder="100 max"
                      type="float"
                      label={`SGST % ${index + 1}`}
                      error={form.getInputProps(`Price.${index}.SGST`).error}
                      onChange={(e) => {
                        form.setFieldValue(
                          `Price.${index}.SGST`,
                          parseFloat(e.currentTarget.value)
                        );
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xl: 3, lg: 3, md: 6, sm: 6, xs: 12 }}>
                    <TextInput
                      placeholder="100 max"
                      type="float"
                      label={`IGST % ${index + 1}`}
                      error={form.getInputProps(`Price.${index}.IGST`).error}
                      onChange={(e) => {
                        form.setFieldValue(
                          `Price.${index}.IGST`,
                          parseFloat(e.currentTarget.value)
                        );
                      }}
                    />
                  </Grid.Col>
                </Grid>
              ))}
            </Paper>
          </Grid.Col>

          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.DCode}
              placeholder="999 max"
              type="number"
              label="DCode"
              onChange={(e) => {
                form.setFieldValue(`DCode`, parseInt(e.currentTarget.value));
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.GCode}
              placeholder="999 max"
              type="number"
              label="GCode"
              onChange={(e) => {
                form.setFieldValue(`GCode`, parseInt(e.currentTarget.value));
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.HSN}
              placeholder="9 max"
              label="HSN"
              maxLength={9}
              onChange={(e) => {
                form.setFieldValue(`HSN`, e.currentTarget.value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.DName}
              max={19}
              placeholder="19 max"
              label="DName"
              onChange={(e) => {
                form.setFieldValue(`DName`, e.currentTarget.value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.GName}
              max={19}
              placeholder="19 max"
              label="GName"
              onChange={(e) => {
                form.setFieldValue(`GName`, e.currentTarget.value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.Unit}
              maxLength={3}
              placeholder="3 Max"
              label="Unit"
              onChange={(e) => {
                form.setFieldValue(`Unit`, e.currentTarget.value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.CP}
              placeholder="9999999 max"
              type="float"
              label="CP"
              onChange={(e) => {
                form.setFieldValue(`CP`, parseFloat(e.currentTarget.value));
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.DiscPercent}
              type="float"
              placeholder="100 max"
              label="Discount Percentage"
              onChange={(e) => {
                form.setFieldValue(
                  `DiscPercent`,
                  parseFloat(e.currentTarget.value)
                );
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.CESS1}
              placeholder="100 max"
              type="float"
              label="CESS 1"
              onChange={(e) => {
                form.setFieldValue(`CESS1`, parseFloat(e.currentTarget.value));
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <TextInput
              error={form.errors.CESS2}
              placeholder="100 max"
              type="float"
              label="CESS 2"
              onChange={(e) => {
                form.setFieldValue(`CESS2`, parseFloat(e.currentTarget.value));
              }}
            />
          </Grid.Col>

          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <Switch
              style={{ margin: "10px" }}
              label="Open Price Flag"
              onLabel="ON"
              offLabel="OFF"
              onChange={(e) => {
                form.setFieldValue(`OpenPriceFlag`, e.currentTarget.checked);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 6 }}>
            <Switch
              style={{ margin: "10px" }}
              label="Decimal Disable Flag"
              onLabel="ON"
              offLabel="OFF"
              onChange={(e) => {
                form.setFieldValue(
                  `DecimalDisableFlag`,
                  e.currentTarget.checked
                );
              }}
            />
          </Grid.Col>
        </Grid>

        <Button loading={loading} type="submit" style={{ marginTop: "10px" }}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default CreateItem;
