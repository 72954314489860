import React, { useEffect, useRef, useState } from "react";
import {
  useDownloadItemWiseReport,
  useGetItemWise,
  useGetSummary,
} from "../../../modules/reports/reportHooks";
import {
  GetBillWiseSummaryReport,
  ItemWiseProps,
} from "../../../modules/reports/service/reportService";
import {
  Table,
  Pagination,
  Select,
  Text,
  Title,
  Divider,
  Tabs,
  Tooltip,
  ActionIcon,
  Checkbox,
  Grid,
  Button,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
import clases from "./selected.module.css";
import moment from "moment";
import { DatePickerInput } from "@mantine/dates";
import { GetOutletProps } from "../../../modules/outlet/service/outletService";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { notifications } from "@mantine/notifications";
import { useGetDevice } from "../../../modules/device/deviceHooks";
import { useGetOutlet } from "../../../modules/outlet/outletHooks";
import { Player } from "@lottiefiles/react-lottie-player";
import { convertToIndianCurrency } from "../../../utils/utils";
import {
  IconBuildingStore,
  IconCheck,
  IconDeviceLandlinePhone,
  IconDownload,
  IconX,
} from "@tabler/icons-react";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
import SkeletonTableLoader from "../../../utils/uiUtils/SkeletonTableLoder";

const ItemWise = () => {
  const [TotalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [outletId, setOutletId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [pageNumber, setPageNumber] = useState("1");
  const [reportByDevice, setReportByDevice] = useState(false);
  const [limit] = useState(10);
  const [date, setDate] = useState();
  const [activeTab, setActiveTab] = useState<string | null>("Outlet");
  const [selectedName, setSelectedName] = useState<string | null>("");
  const [exportLoading, setExportLoading] = useState(false);
  const [reportDataForDownload, setReportDataForDownload] = useState<any>([]);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(500);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  const pageNumberRef = useRef(pageNumber);
  const [searchpage, setSearchpage] = useState(1);

  let apiCallInterval: any;

  const {
    getItemWiseFunction,
    loading: Itemwiseloading,
    data: ItemData,
  } = useGetItemWise();
  const { downloadItemWiseReportFunction, data: downloadData } =
    useDownloadItemWiseReport();
  const {
    getSummaryFunction,
    loading: SummaryLoadingitem,
    data: SummmaryData,
  } = useGetSummary();

  const { fetchOutlet, data: outlet, loading: Ouletloading } = useGetOutlet();
  const {
    fetchDevice,
    loading: DeviceLoading,
    data: DeviceData,
  } = useGetDevice();

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  const outletData: GetOutletProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
  };

  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: true,
  };

  const ItemWiseData: ItemWiseProps = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    page: pageNumber,
    limit: limit.toString(),
    BillStatus: "CONFIRM",
    sort: {
      _id: true,
    },
  };
  const billWiseSummaryData: GetBillWiseSummaryReport = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    BillStatus: "CONFIRM",
  };

  function makeAPICall(page: any) {
    let getItemWiseProps: ItemWiseProps = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      page: page,
      limit: limit.toString(),
      BillStatus: "CONFIRM",
      sort: {
        _id: true,
      },
    };

    let getSummaryProps: GetBillWiseSummaryReport = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      BillStatus: "CONFIRM",
    };

    if (!reportByDevice) {
      getItemWiseProps.outletId = outletId;
      getItemWiseProps.deviceId = undefined;
      getSummaryProps.deviceId = undefined;
      getSummaryProps.outletId = outletId;
    } else {
      getItemWiseProps.outletId = undefined;
      getItemWiseProps.deviceId = deviceId;
      getSummaryProps.outletId = undefined;
      getSummaryProps.deviceId = deviceId;
    }

    getItemWiseFunction(getItemWiseProps);
    getSummaryFunction(getSummaryProps);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = (pageNumber: any) => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall(pageNumber);
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall(pageNumberRef.current);

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(
        () => scheduleAPICall(pageNumberRef.current),
        interval
      );

      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, reportByDevice, bothDates, outletId, deviceId]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    fetchOutlet(outletData);
    fetchDevice(getDeviceData);
  }, []);

  useEffect(() => {
    if (outlet?.docs?.length > 0) {
      setOutletId(outlet?.docs[0]?._id);
      if (!reportByDevice && !refreshSelected) {
        setSelectedName(outlet?.docs[0]?.outletName);
        ItemWiseData.outletId = outlet?.docs[0]?._id;
        ItemWiseData.deviceId = undefined;
        billWiseSummaryData.outletId = outlet?.docs[0]?._id;
        billWiseSummaryData.deviceId = undefined;
        getItemWiseFunction(ItemWiseData).then(() => setIsLoading(false));
        getSummaryFunction(billWiseSummaryData).then(() => setIsLoading(false));
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet, reportByDevice]);

  useEffect(() => {
    if (DeviceData?.docs?.length > 0) {
      setDeviceId(DeviceData?.docs[0]?._id);
      if (reportByDevice && !refreshSelected) {
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);
        ItemWiseData.outletId = undefined;
        ItemWiseData.deviceId = DeviceData?.docs[0]?._id;
        billWiseSummaryData.outletId = undefined;
        billWiseSummaryData.deviceId = DeviceData?.docs[0]?._id;
        getItemWiseFunction(ItemWiseData).then(() => setIsLoading(false));
        getSummaryFunction(billWiseSummaryData).then(() => setIsLoading(false));
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceData, reportByDevice]);

  useEffect(() => {
    if (ItemData?.total?.length > 0) {
      setTotalpage(ItemData?.total[0]?.count);
    } else setTotalRecords(0); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemData]);

  useEffect(() => {
    if (downloadData?.docs?.length > 0 && exportLoading) {
      setReportDataForDownload((prev: any) => [...prev, ...downloadData?.docs]);
    }
  }, [downloadData]);

  useEffect(() => {
    if (reportDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(
        downloadData?.total[0]?.count / downloadLimit
      );

      if (total_Pages < nextPage) {
        try {
          onExportClick(
            reportDataForDownload,
            reportTypes.itemWise,
            ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`,
            SummmaryData
          );
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        } catch (err) {
          notifications.show({
            title: "Export Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);

        const downloadItemWiseData: ItemWiseProps = {
          fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
          toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
          BillStatus: "CONFIRM",
          page: nextPage.toString(),
          limit: downloadLimit.toString(),
          sort: {
            _id: true,
          },
          outletId: !reportByDevice ? outletId : undefined,
          deviceId: reportByDevice ? deviceId : undefined,
        };

        if (downloadItemWiseData.deviceId === undefined) {
          delete downloadItemWiseData.deviceId;
        } else if (downloadItemWiseData.outletId === undefined) {
          delete downloadItemWiseData.outletId;
        }

        downloadItemWiseReportFunction(downloadItemWiseData);
      }
    }
  }, [reportDataForDownload]);

  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    stopAPICalls();
    ItemWiseData.page = page.toString();
    if (!reportByDevice) {
      ItemWiseData.outletId = outletId;
      ItemWiseData.deviceId = undefined;
    } else {
      ItemWiseData.outletId = undefined;
      ItemWiseData.deviceId = deviceId;
    }
    getItemWiseFunction(ItemWiseData).then(() => setIsLoading(false));
  };

  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);
    setTotalRecords(totalPages);
  };

  const handleOuletAndDeviceChange = (event: any, name?: any) => {
    setSelectedName(name);
    setPageNumber("1");
    stopAPICalls();
    if (reportByDevice) {
      setDeviceId(event);
      ItemWiseData.deviceId = event;
      ItemWiseData.page = "1";
      ItemWiseData.outletId = undefined;
      if (!ItemWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        billWiseSummaryData.deviceId = event;
        billWiseSummaryData.outletId = undefined;
        if (!refreshSelected) {
          getItemWiseFunction(ItemWiseData);
          getSummaryFunction(billWiseSummaryData);
        }
      }
    } else {
      setOutletId(event);
      ItemWiseData.page = "1";
      if (!ItemWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        ItemWiseData.outletId = event;
        ItemWiseData.deviceId = undefined;
        billWiseSummaryData.outletId = event;
        billWiseSummaryData.deviceId = undefined;
        if (!refreshSelected) {
          getItemWiseFunction(ItemWiseData);
          getSummaryFunction(billWiseSummaryData);
        }
      }
    }
  };

  const selectDeviceData = () => {
    let data: any = DeviceData?.docs?.map((item: any) => ({
      value: item._id,
      label: `${
        item?.userDeviceName
          ? item.userDeviceName + " [" + item.serialNo + "]"
          : item?.serialNo ?? ""
      }`,
    }));

    return data === undefined ? [] : data;
  };

  const selectOutletData = () => {
    let data: any = outlet?.docs?.map((item: any) => {
      return { value: item._id, label: item.outletName };
    });
    return data === undefined ? [] : data;
  };

  const handledate = (e: any) => {
    ItemWiseData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    ItemWiseData.toDate = moment(e[1]).format("YYYY/MM/DD");
    billWiseSummaryData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    billWiseSummaryData.toDate = moment(e[1]).format("YYYY/MM/DD");

    if (!reportByDevice) {
      ItemWiseData.outletId = outletId;
      ItemWiseData.deviceId = undefined;
      billWiseSummaryData.outletId = outletId;
      billWiseSummaryData.deviceId = undefined;
    } else {
      ItemWiseData.outletId = undefined;
      ItemWiseData.deviceId = deviceId;
      billWiseSummaryData.outletId = undefined;
      billWiseSummaryData.deviceId = deviceId;
    }

    if (!refreshSelected) {
      getItemWiseFunction(ItemWiseData);
      getSummaryFunction(billWiseSummaryData);
    }
  };

  const TableData: any = () => {
    return (
      <>
        {ItemData?.docs?.map((item: any, index: number) => (
          <Table.Tr key={index}>
            <Table.Td>{item._id.ICode}</Table.Td>
            <Table.Td>{item._id.IName}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item._id.IPrice)}</Table.Td>
            <Table.Td>{item.TotalQty}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalGST)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalDiscount)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalAmount)}</Table.Td>
          </Table.Tr>
        ))}
      </>
    );
  };

  const SummaryTable: any = () => {
    let totalQty = ItemData.docs
      .map((item: any) => item.TotalQty)
      .reduce((a: any, b: any) => a + b, 0);

    return (
      <>
        <Table.Tr
          style={{
            backgroundColor: "#deedfd",
            color: "black",
            fontWeight: 600,
          }}
        >
          <Table.Td>Total</Table.Td>
          <Table.Td>--</Table.Td>
          <Table.Td>--</Table.Td>
          <Table.Td>{totalQty ?? 0}</Table.Td>
          <Table.Td>{convertToIndianCurrency(SummmaryData?.TotalGST)}</Table.Td>
          <Table.Td>
            {convertToIndianCurrency(SummmaryData?.TotalDiscount)}
          </Table.Td>
          <Table.Td>
            {convertToIndianCurrency(SummmaryData?.TotalSale)}
          </Table.Td>
        </Table.Tr>
      </>
    );
  };

  const handleExportClick = () => {
    if (
      ItemData?.total?.[0]?.count > 0 &&
      ItemData?.total?.[0]?.count !== undefined
    ) {
      const downloadItemWiseData: ItemWiseProps = {
        fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
        toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
        BillStatus: "CONFIRM",
        page: "1",
        limit: downloadLimit.toString(),
        sort: {
          _id: true,
        },
        outletId: !reportByDevice ? outletId : undefined,
        deviceId: reportByDevice ? deviceId : undefined,
      };

      if (downloadItemWiseData.outletId === undefined) {
        delete downloadItemWiseData.outletId;
      } else if (downloadItemWiseData.deviceId === undefined) {
        delete downloadItemWiseData.deviceId;
      }

      downloadItemWiseReportFunction(downloadItemWiseData);
      setExportLoading(true);
    } else {
      notifications.show({
        title: "No Data Found",
        message: "No Data Found",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Itemwise Report" />
      <div>
        <Title order={1}>Itemwise Report</Title>
      </div>

      <div>
        <Divider my="md" />
        <Text size="sm" style={{ paddingBottom: "10px" }}>
          Filter By
        </Text>
        <Tabs
          color="grape"
          value={activeTab}
          onChange={(value) => {
            // switchStateChange(value === "Outlet" ? false : true);
            setActiveTab(value);
            setReportByDevice(value === "Outlet" ? false : true);
            setPageNumber("1");
            stopAPICalls();
          }}
          classNames={clases}
        >
          <Tabs.List>
            <Tooltip
              label="Select Outlet wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab value="Outlet" leftSection={<IconBuildingStore />}>
                Outlet
              </Tabs.Tab>
            </Tooltip>
            <Tooltip
              label="Select Device wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab
                value="Device"
                leftSection={<IconDeviceLandlinePhone />}
              >
                Device
              </Tabs.Tab>
            </Tooltip>
          </Tabs.List>
        </Tabs>
      </div>
      {Ouletloading || DeviceLoading ? (
        <></>
      ) : (
        <Grid pt={15}>
          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 2,
              xl: 2,
            }}
          >
            <Select
              label={!reportByDevice ? "Select Outlet" : "Select Device"}
              disabled={exportLoading}
              value={!reportByDevice ? outletId : deviceId}
              onChange={(event) => {
                let selectedName: any;
                if (!reportByDevice) {
                  outlet?.docs?.find((item: any) => {
                    if (item._id === event) {
                      selectedName = item?.outletName;
                      return item?.outletName;
                    }
                  });
                } else if (reportByDevice) {
                  DeviceData?.docs?.find((item: any) => {
                    if (item._id === event) {
                      selectedName = item?.userDeviceName;
                      return item?.userDeviceName;
                    }
                  });
                }

                handleOuletAndDeviceChange(event, selectedName);
              }}
              placeholder={!reportByDevice ? "Select Outlet" : "Select Device"}
              data={!reportByDevice ? selectOutletData() : selectDeviceData()}
            />
          </Grid.Col>

          <Grid.Col
            span={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 2,
              xl: 2,
            }}
          >
            <DatePickerInput
              label="Select Date"
              required
              allowSingleDateInRange
              valueFormat="DD/MM/YYYY"
              type="range"
              placeholder="Pick Dates Range"
              clearable
              defaultValue={[new Date(), new Date()]}
              value={date}
              onChange={(e: any) => {
                setDate(e);
                if (e[1]) {
                  stopAPICalls();
                  setBothDates({
                    fromDate: moment(e[0]).format("YYYY/MM/DD"),
                    toDate: moment(e[1]).format("YYYY/MM/DD"),
                  });
                }
                setPageNumber("1");
                if (e && e[0] !== null && e[1] !== null) {
                  handledate(e);
                }
              }}
            />
          </Grid.Col>
          <Grid.Col
            span={{
              xs: 6,
              sm: 6,
              md: 6,
              lg: 1.5,
              xl: 1.2,
            }}
          >
            <Checkbox
              style={{
                position: "relative",
                top: "15px",
              }}
              checked={refreshSelected}
              onChange={(event) =>
                setRefreshSelected(event.currentTarget.checked)
              }
              label="Auto Refresh"
              mt="md"
            />
          </Grid.Col>
          {SummmaryData?.NoOfBills > 0 && (
            <Grid.Col
              pt={20}
              span={{
                xs: 6,
                sm: 6,
                md: 4,
                lg: 0.2,
                xl: 0.2,
              }}
              display="flex"
              style={{
                gap: "15px",
                alignItems: "center",
              }}
            >
              <Tooltip label="Export Reports file">
                <ActionIcon
                  loading={exportLoading}
                  variant="default"
                  size="lg"
                  ml="md"
                  onClick={() => {
                    handleExportClick();
                  }}
                >
                  <IconDownload />
                </ActionIcon>
              </Tooltip>
            </Grid.Col>
          )}
          <Grid.Col style={{ paddingTop: "20px" }}>
            {isLoading || SummaryLoadingitem || Itemwiseloading ? (
              <SkeletonTableLoader />
            ) : (
              <>
                {ItemData?.docs?.length > 0 ? (
                  <>
                    <div>
                      <Table
                        verticalSpacing="xs"
                        horizontalSpacing="xs"
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                      >
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Item Code</Table.Th>
                            <Table.Th>Item Name</Table.Th>
                            <Table.Th>Item Price</Table.Th>
                            <Table.Th>Total Quantity</Table.Th>
                            <Table.Th>Total Gst</Table.Th>
                            <Table.Th>Total Discount</Table.Th>
                            <Table.Th>Total Amount</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{SummaryTable()}</Table.Tbody>
                        <Table.Tbody>{TableData()}</Table.Tbody>
                      </Table>
                    </div>
                    <div style={{ marginBottom: "50px" }}>
                      {TotalRecords > 1 && (
                        <Flex
                          p={15}
                          mih={50}
                          align="center"
                          gap="md"
                          justify="flex-end"
                          direction="row"
                          wrap="wrap"
                        >
                          {/* <div style={{ width: "5%" }}> */}
                          <Group>
                            <NumberInput
                              value={pageNumber}
                              w={60}
                              mt="sm"
                              placeholder="Page "
                              radius="sm"
                              min={1}
                              max={TotalRecords}
                              hideControls
                              onChange={(event) => {
                                setSearchpage(Number(event));
                              }}
                            />
                            <Button
                              mt="sm"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const pageNumber2 = searchpage;

                                if (
                                  pageNumber2 < 1 ||
                                  pageNumber2 > TotalRecords
                                ) {
                                  notifications.show({
                                    title: "Error",
                                    message: "Invalid page number",
                                    color: "red",
                                    icon: <IconX size="1.1rem" />,
                                  });
                                } else {
                                  handlePageChange(searchpage);
                                }
                              }}
                            >
                              Go
                            </Button>
                          </Group>

                          <Pagination
                            mt="sm"
                            total={TotalRecords}
                            onChange={handlePageChange}
                            value={Number(pageNumber)}
                            // radius="xl"
                          />
                        </Flex>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ paddingTop: "60px" }}>
                      <Player
                        autoplay
                        loop
                        src="./static/infographics/noRecordsFound.json"
                        style={{ height: "300px", width: "300px" }}
                        background="transparent"
                        controls={false}
                      ></Player>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default ItemWise;
