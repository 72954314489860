import { Autocomplete, Button, Modal, Select, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import { Country, State, City } from "country-state-city";

const countries = Country.getAllCountries();
const states = State.getAllStates();
const cities = City.getAllCities();
interface Props {
  createOutletDialog: boolean;
  setCreateOutletDialog: any;
  form: any;
  handleCreateItem: any;
  createLoading: boolean;
}

const CreateOutlet = ({
  createOutletDialog,
  handleCreateItem,
  setCreateOutletDialog,
  form,
  createLoading,
}: Props) => {
  const [countryCode, setCountryCode] = useState<any>("");
  const [filteredStates, setStates] = useState<any>([]);
  const [selectedStateCode, setSelectedStateCode] = useState<any>("");
  const [filteredCities, setCities] = useState<any>([]);
  // const [selectedCityCode, setSelectedCityCode] = useState<any>("");

  useEffect(() => {
    // setStates([]);
    states.map((state: any) => {
      if (state.countryCode === countryCode) {
        setStates((prev: any) => [...prev, state]);
      }
    });
  }, [countryCode]);

  useEffect(() => {
    setSelectedStateCode("");
  }, [filteredStates]);

  useEffect(() => {
    cities.map((city: any) => {
      if (
        city.stateCode === selectedStateCode &&
        city.countryCode === countryCode
      ) {
        setCities((prev: any) => [...prev, city]);
      }
    });
  }, [selectedStateCode]);

  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Create New outlet"
      opened={createOutletDialog}
      closeOnClickOutside={false}
      withCloseButton
      onClose={() => {
        setCountryCode("");
        setSelectedStateCode("");
        setCities([]);
        setStates([]);
        setCreateOutletDialog(false);
        form.reset();
      }}
      size="sm"
      radius="md"
    >
      <form onSubmit={form.onSubmit((values: any) => handleCreateItem(values))}>
        <TextInput
          maxLength={50}
          withAsterisk
          error={form.errors.outletName}
          label="Outlet Name"
          onChange={(e) => {
            form.setFieldValue(`outletName`, e.currentTarget.value);
          }}
        />
        <TextInput
          withAsterisk
          maxLength={15}
          error={form.errors.telephone}
          label="Contact Info"
          type="number"
          onChange={(e) => {
            form.setFieldValue(`telephone`, e.currentTarget.value);
          }}
        />
        <Select
          withAsterisk
          error={form.errors.country}
          allowDeselect={false}
          label="Country"
          placeholder="Select country"
          data={countries?.map((country) => ({
            value: country.isoCode,
            label: country.name,
          }))}
          searchable
          clearable
          onChange={(e) => {
            form.setFieldValue(
              `country`,
              countries.find((country: any) => country.isoCode === e)?.name
            );
            setStates([]);
            setCities([]);
            setSelectedStateCode("");
            setCountryCode(e);
          }}
        />

        <Select
          withAsterisk
          error={form.errors.state}
          allowDeselect={false}
          label="State"
          placeholder="Select state"
          data={filteredStates?.map((state: any) => ({
            value: state?.isoCode,
            label: state?.name ?? " ",
          }))}
          searchable
          clearable
          disabled={filteredStates?.length === 0}
          onChange={(value) => {
            form.setFieldValue(
              `state`,
              filteredStates.find((state: any) => state.isoCode === value)?.name
            );
            setCities([]);
            setSelectedStateCode(value);
          }}
        />

        <Autocomplete
          withAsterisk
          error={form.errors.city}
          maxLength={30}
          label="City"
          placeholder="Enter city name"
          data={filteredCities?.map((city: any) => ({
            value: city.name,
            label: city.name,
          }))}
          onChange={(value) => {
            form.setFieldValue(`city`, value);
            // setSelectedCityCode(value);
          }}
        />
        <TextInput
          maxLength={150}
          error={form.errors.address}
          label="Address"
          onChange={(e) => {
            form.setFieldValue(`address`, e.currentTarget.value);
          }}
        />
        <TextInput
          maxLength={15}
          error={form.errors.GSTN}
          label="GSTN"
          onChange={(e) => {
            form.setFieldValue(`GSTN`, e.currentTarget.value);
          }}
        />
        <TextInput
          type="number"
          maxLength={20}
          error={form.errors.fax}
          label="Fax"
          onChange={(e) => {
            form.setFieldValue(`fax`, e.currentTarget.value);
          }}
        />

        <Button
          loading={createLoading}
          type="submit"
          style={{ marginTop: "10px" }}
        >
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default CreateOutlet;
