import React from "react";
import { Card, Button, Grid, Skeleton } from "@mantine/core";

const DeviceSimmer = () => {
  return (
    <>
      <Card
        withBorder
        style={{
          marginTop: 1,
          padding: 2,
          alignItems: "center",
          transition: "box-shadow 0.3s ease-out",
          borderRadius: 5,
        }}
      >
        <Grid
          style={{
            padding: "20px",
            paddingTop: "30px",

            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
          <Grid.Col
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
            span={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          >
            <Skeleton height={15} width={120} />

            <Skeleton height={25} width={150} />
          </Grid.Col>
        </Grid>

        <Grid
          style={{
            display: "flex",
            flexWrap: "nowrap",
            backgroundColor: "grey",
            width: "100%",
            marginTop: "20px",
            justifyContent: "center",
          }}
        >
          <Grid.Col
            style={{
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              style={{
                paddingRight: "1px",

                backgroundColor: "transparent",
                textDecoration: "none",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Skeleton
                style={{ borderRadius: "5px" }}
                height={40}
                width={60}
              />
            </Button>
            <Button
              style={{
                backgroundColor: "transparent",
                textDecoration: "none",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Skeleton
                style={{ borderRadius: "5px" }}
                height={40}
                width={60}
              />
            </Button>

            <Button
              style={{
                paddingRight: "1px",
                backgroundColor: "transparent",
                textDecoration: "none",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Skeleton
                style={{ borderRadius: "5px" }}
                height={40}
                width={60}
              />
            </Button>
            <Button
              style={{
                paddingRight: "1px",

                backgroundColor: "transparent",
                textDecoration: "none",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Skeleton
                style={{ borderRadius: "5px" }}
                height={40}
                width={60}
              />
            </Button>
            <Button
              style={{
                paddingRight: "1px",

                backgroundColor: "transparent",
                textDecoration: "none",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Skeleton
                style={{ borderRadius: "5px" }}
                height={40}
                width={60}
              />
            </Button>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
};

export default DeviceSimmer;
