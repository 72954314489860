// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useRef, useState } from "react";
import {
  useDownloadBillWiseReports,
  useGetBillWise,
  useGetSummary,
} from "../../../modules/reports/reportHooks";
import {
  BillSummaryProps,
  BillWiseProps,
} from "../../../modules/reports/service/reportService";
import clases from "./selected.module.css";
import {
  Table,
  Pagination,
  Select,
  Modal,
  Text,
  Title,
  Divider,
  Tabs,
  Tooltip,
  ActionIcon,
  Checkbox,
  Grid,
  NumberInput,
  Button,
  Flex,
  Group,
} from "@mantine/core";
import moment from "moment";
import { useGetOutlet } from "../../../modules/outlet/outletHooks";
import { DatePickerInput } from "@mantine/dates";
import { notifications } from "@mantine/notifications";
import { useGetDevice } from "../../../modules/device/deviceHooks";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import Devicemodal from "./Billwisemodal/Billmodal";
import { GetOutletProps } from "../../../modules/outlet/service/outletService";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  IconBuildingStore,
  IconChartBar,
  IconCheck,
  IconDeviceLandlinePhone,
  IconDownload,
  IconTable,
  IconX,
} from "@tabler/icons-react";
import ChartUtils from "../../../utils/uiUtils/ChartUtils";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
import SkeletonTableLoder from "../../../utils/uiUtils/SkeletonTableLoder";

interface BillWiseFunctionProps {
  status: string;
}

function convertToIndianCurrency(input: any) {
  const num = typeof input === "string" ? parseFloat(input) : input;

  if (isNaN(num)) {
    throw new Error("Invalid input");
  }

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  return formatter.format(num);
}
///helooookdjfdfidfiodhfoihsdfihsdifhoisdfhsidfhisdf
const Billwise = ({ status }: BillWiseFunctionProps) => {
  const { getBillWiseFunction, data: BillData, loading } = useGetBillWise();
  const { downloadBillWiseReportsFunction, data: downloadBillWiseReportsData } =
    useDownloadBillWiseReports();
  const { getSummaryFunction, data: Summary } = useGetSummary();
  const { fetchOutlet, data: outlet, loading: outletLoading } = useGetOutlet();
  const {
    fetchDevice,
    data: DeviceData,
    loading: DeviceLoading,
  } = useGetDevice();
  const [reportByDevice, setReportByDevice] = useState(false);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState("1");
  const [limit] = useState(10);
  const [activeTab, setActiveTab] = useState<string | null>("Outlet");
  const [selectedName, setSelectedName] = useState<string | null>("");
  const [deviceId, setDeviceId] = useState("");
  const [outletId, setOutletId] = useState("");
  const [date, setDate] = useState();
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  const [deviceItemdata, setDeviceItemdata] = useState("");
  const [showmodal, setShowmodal] = useState(false);
  const [switchTableOrGraph, setSwitchTableOrGraph] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [reportDataForDownload, setReportDataForDownload] = useState<any>([]);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(500);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const pageNumberRef = useRef(pageNumber);
  const [searchpage, setSearchpage] = useState(1);

  let apiCallInterval: any;

  function makeAPICall(page: any) {
    let getBillWiseProps: BillWiseProps = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      BillStatus: status,
      page: page.toString(),
      limit: limit.toString(),
      sort: {
        _Date: true,
      },
    };

    let getSummaryProps: BillSummaryProps = {
      fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
      toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
      BillStatus: status,
    };

    if (!reportByDevice) {
      getBillWiseProps.outletId = outletId;
      getBillWiseProps.deviceId = undefined;
      getSummaryProps.deviceId = undefined;
      getSummaryProps.outletId = outletId;
    } else {
      getBillWiseProps.outletId = undefined;
      getBillWiseProps.deviceId = deviceId;
      getSummaryProps.outletId = undefined;
      getSummaryProps.deviceId = deviceId;
    }

    getBillWiseFunction(getBillWiseProps);
    getSummaryFunction(getSummaryProps);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = (pageNumber: any) => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall(pageNumber);
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall(pageNumberRef.current);

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(
        () => scheduleAPICall(pageNumberRef.current),
        interval
      );

      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, reportByDevice, bothDates, outletId, deviceId]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    if (status) {
      stopAPICalls();
      setPageNumber("1");
      setRefreshSelected(false);
    }
  }, [status]);

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  const billWiseData: BillWiseProps = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    BillStatus: status,
    page: pageNumber,
    limit: limit.toString(),
    sort: {
      _Date: true,
    },
  };

  const billSummaryData: BillSummaryProps = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    BillStatus: status,
  };

  const outletData: GetOutletProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
  };

  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: true,
  };

  useEffect(() => {
    fetchDevice(getDeviceData);
    fetchOutlet(outletData);
  }, []);

  useEffect(() => {
    if (outlet?.docs?.length > 0) {
      if (!outletId) {
        setOutletId(outlet?.docs[0]?._id);
        setSelectedName(outlet?.docs[0]?.outletName);
      }

      if (!reportByDevice && !refreshSelected) {
        billWiseData.outletId = outletId || outlet?.docs[0]?._id;
        billWiseData.deviceId = undefined;
        setSelectedName(outlet?.docs[0]?.outletName);

        billSummaryData.outletId = outletId || outlet?.docs[0]?._id;
        billSummaryData.deviceId = undefined;
        getBillWiseFunction(billWiseData);
        getSummaryFunction(billSummaryData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet, reportByDevice, status]);

  useEffect(() => {
    if (DeviceData?.docs?.length > 0) {
      if (!deviceId) {
        setDeviceId(DeviceData?.docs[0]?._id);
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);
      }
      if (reportByDevice && !refreshSelected) {
        billWiseData.outletId = undefined;
        billWiseData.deviceId = deviceId || DeviceData?.docs[0]?._id;
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);

        billSummaryData.outletId = undefined;
        billSummaryData.deviceId = deviceId || DeviceData?.docs[0]?._id;
        getBillWiseFunction(billWiseData);
        getSummaryFunction(billSummaryData);
      }
    }
  }, [DeviceData, reportByDevice, status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (BillData?.total?.length > 0) {
      setTotalpage(BillData?.total[0]?.count);
    } else {
      setTotalRecords(0);
    }
  }, [BillData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (downloadBillWiseReportsData?.docs?.length > 0 && exportLoading) {
      setReportDataForDownload((prev: any) => [
        ...prev,
        ...downloadBillWiseReportsData?.docs,
      ]);
    }
  }, [downloadBillWiseReportsData]);

  useEffect(() => {
    if (reportDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(
        downloadBillWiseReportsData?.total[0]?.count / downloadLimit
      );

      if (total_Pages < nextPage) {
        try {
          if (status === "CONFIRM") {
            onExportClick(
              reportDataForDownload,
              reportTypes.billWise,
              ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`,
              Summary
            );
          } else {
            onExportClick(
              reportDataForDownload,
              reportTypes.voidBillWise,
              ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`,
              Summary
            );
          }
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        } catch (err) {
          notifications.show({
            title: "Export Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);
        const downloadBillWiseData: BillWiseProps = {
          fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
          toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
          BillStatus: status,
          page: nextPage.toString(),
          limit: downloadLimit.toString(),
          sort: {
            _Date: true,
          },
          outletId: !reportByDevice ? outletId : undefined,
          deviceId: reportByDevice ? deviceId : undefined,
        };

        if (downloadBillWiseData.deviceId === undefined) {
          delete downloadBillWiseData.deviceId;
        } else if (downloadBillWiseData.outletId === undefined) {
          delete downloadBillWiseData.outletId;
        }

        downloadBillWiseReportsFunction(downloadBillWiseData);
      }
    }
  }, [reportDataForDownload]);

  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    billWiseData.page = page.toString();
    if (!reportByDevice) {
      billWiseData.outletId = outletId;
      billWiseData.deviceId = undefined;
    } else {
      billWiseData.outletId = undefined;
      billWiseData.deviceId = deviceId;
    }
    getBillWiseFunction(billWiseData);
  };
  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);
    setTotalRecords(totalPages);
  };

  const selectOutletData = () => {
    let data: any = outlet?.docs?.map((item: any) => {
      return { value: item._id, label: item.outletName };
    });

    return data === undefined ? [] : data;
  };

  const selectDeviceData = () => {
    let data: any = DeviceData?.docs?.map((item: any) => ({
      value: item?._id,
      label: `${
        item?.userDeviceName
          ? item.userDeviceName + " [" + item.serialNo + "]"
          : item?.serialNo ?? ""
      }`,
    }));
    return data === undefined ? [] : data;
  };
  const handleOuletAndDeviceChange = (event: any, selectedName?: any) => {
    setSelectedName(selectedName);
    stopAPICalls();
    setPageNumber("1");
    if (reportByDevice) {
      setDeviceId(event);

      billWiseData.deviceId = event;
      billWiseData.page = "1";
      billWiseData.outletId = undefined;
      if (!billWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        if (!refreshSelected) {
          getBillWiseFunction(billWiseData);
          billSummaryData.deviceId = event;
          billSummaryData.outletId = undefined;
          getSummaryFunction(billSummaryData);
        }
      }
    } else {
      setOutletId(event);
      billWiseData.outletId = event;
      billWiseData.deviceId = undefined;
      billWiseData.page = "1";

      if (!billWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        if (!refreshSelected) {
          billSummaryData.outletId = event;
          billSummaryData.deviceId = undefined;
          getSummaryFunction(billSummaryData);
          getBillWiseFunction(billWiseData);
        }
      }
    }
  };

  const handledate = (e: any) => {
    billWiseData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    billWiseData.toDate = moment(e[1]).format("YYYY/MM/DD");
    billSummaryData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    billSummaryData.toDate = moment(e[1]).format("YYYY/MM/DD");
    if (!reportByDevice) {
      billWiseData.outletId = outletId;
      billWiseData.deviceId = undefined;
      billSummaryData.deviceId = undefined;
      billSummaryData.outletId = outletId;
    } else {
      billWiseData.outletId = undefined;
      billWiseData.deviceId = deviceId;
      billSummaryData.outletId = undefined;
      billSummaryData.deviceId = deviceId;
    }
    if (!refreshSelected) {
      getBillWiseFunction(billWiseData);
      getSummaryFunction(billSummaryData);
    }
  };

  const SummaryReport = () => {
    return (
      <Table.Tr
        style={{ backgroundColor: "#deedfd", color: "black", fontWeight: 600 }}
      >
        <Table.Td>
          <span>Total</span>
        </Table.Td>
        <Table.Td></Table.Td>
        <Table.Td>
          {" "}
          <span>{Summary?.NoOfBills}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{Summary?.NoOfItems}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>
            {convertToIndianCurrency(Summary?.TotalDiscount || 0)}
          </span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(Summary?.TotalGST || 0)}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(Summary?.TotalSale || 0)} </span>{" "}
        </Table.Td>
      </Table.Tr>
    );
  };
  const VoidSummaryReport = () => {
    return (
      <Table.Tr
        style={{ backgroundColor: "#deedfd", color: "black", fontWeight: 600 }}
      >
        <Table.Td>
          <span>Total</span>
        </Table.Td>
        <Table.Td></Table.Td>
        <Table.Td>
          {" "}
          <span>{Summary?.NoOfDeletedbills}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{Summary?.NoOfDeletedItems}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(Summary?.DeletedDisc || 0)}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(Summary?.DeletedGST || 0)}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>
            {convertToIndianCurrency(Summary?.DeletedAmount || 0)}{" "}
          </span>{" "}
        </Table.Td>
      </Table.Tr>
    );
  };

  const TableData: any = () => {
    const data = BillData?.docs;

    return data?.map((item: any, index: any) => (
      <React.Fragment key={index}>
        <Table.Tr
          key={item._id}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            let _item = { ...item };
            let subTotal = 0;
            let cgst = 0;
            let sgst = 0;
            let tQty = 0;
            let tcess = 0;
            let cess1 = 0;
            _item.Item.map((sitem: any) => {
              subTotal += sitem.Price * sitem.Qty;
              cgst += sitem.CGSTValue;
              sgst += sitem.SGSTValue;
              tQty += sitem.Qty;
              tcess += sitem.CESS1Value + sitem.CESS2Value;
              cess1 += sitem.CESS1Value;
              return sitem;
            });
            _item.Date = moment.utc(_item._Date).format("DD/MM/YYYY");

            _item.SubTotal = subTotal;
            _item.CGST = cgst;
            _item.SGST = sgst;
            _item.TQty = tQty;
            _item.Tcess = tcess;
            _item.he = cess1;

            setDeviceItemdata(_item);
            setShowmodal(true);
          }}
        >
          {/* <Table.Td>{new Date(item._Date).toLocaleDateString()}</Table.Td> */}
          <Table.Td>{moment.utc(item._Date).format("DD/MM/YYYY")}</Table.Td>
          <Table.Td>{item.DeviceName}</Table.Td>
          <Table.Td>{item.BillNo}</Table.Td>
          <Table.Td>{item.NoOfItems}</Table.Td>
          <Table.Td>{convertToIndianCurrency(item?.DiscVal || 0)}</Table.Td>
          <Table.Td>{convertToIndianCurrency(item?.TotalGST || 0)}</Table.Td>
          <Table.Td>{convertToIndianCurrency(item?.TotalAmt || 0)}</Table.Td>
        </Table.Tr>
      </React.Fragment>
    ));
  };

  const handleExportClick = () => {
    if (
      BillData?.total?.[0]?.count > 0 &&
      BillData?.total?.[0]?.count !== undefined
    ) {
      const downloadBillWiseData: BillWiseProps = {
        fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
        toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
        BillStatus: status,
        page: "1",
        limit: downloadLimit.toString(),
        sort: {
          _Date: true,
        },
        outletId: !reportByDevice ? outletId : undefined,
        deviceId: reportByDevice ? deviceId : undefined,
      };

      if (downloadBillWiseData.deviceId === undefined) {
        delete downloadBillWiseData.deviceId;
      } else if (downloadBillWiseData.outletId === undefined) {
        delete downloadBillWiseData.outletId;
      }

      downloadBillWiseReportsFunction(downloadBillWiseData);
      setExportLoading(true);
    } else {
      notifications.show({
        title: "No Data Found",
        message: "No Data Found",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet
        title={
          status === "CONFIRM" ? "Billwise Report" : "Void Billwise Report"
        }
      />
      <div>
        <Title order={1}>
          {status === "CONFIRM" ? "Billwise Report" : "Void Billwise Report"}
        </Title>
      </div>
      <div>
        <Divider my="md" />
        <Text size="sm" style={{ paddingBottom: "10px" }}>
          Filter By
        </Text>
        <Tabs
          color="grape"
          value={activeTab}
          onChange={(value) => {
            stopAPICalls();
            setActiveTab(value);
            setReportByDevice(value === "Outlet" ? false : true);
            setPageNumber("1");
          }}
          classNames={clases}
        >
          <Tabs.List>
            <Tooltip
              label="Select Outlet wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab value="Outlet" leftSection={<IconBuildingStore />}>
                Outlet
              </Tabs.Tab>
            </Tooltip>
            <Tooltip
              label="Select Device wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab
                value="Device"
                leftSection={<IconDeviceLandlinePhone />}
              >
                Device
              </Tabs.Tab>
            </Tooltip>
          </Tabs.List>
        </Tabs>
      </div>
      {outletLoading || DeviceLoading ? (
        <></>
      ) : (
        <Grid>
          <Grid.Col>
            <Grid mt={15}>
              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <Select
                  label={!reportByDevice ? "Select Outlet" : "Select Device"}
                  disabled={exportLoading}
                  value={!reportByDevice ? outletId : deviceId}
                  onChange={(event) => {
                    let selectedName: any;
                    if (!reportByDevice) {
                      outlet?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.outletName;
                          return item?.outletName;
                        }
                      });
                    } else if (reportByDevice) {
                      DeviceData?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.userDeviceName;
                          return item?.userDeviceName;
                        }
                      });
                    }
                    handleOuletAndDeviceChange(event, selectedName);
                  }}
                  placeholder={
                    !reportByDevice ? "Select Outlet" : "Select Device"
                  }
                  data={
                    !reportByDevice ? selectOutletData() : selectDeviceData()
                  }
                  allowDeselect={false}
                />
              </Grid.Col>

              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <DatePickerInput
                  label="Select Date"
                  allowSingleDateInRange
                  valueFormat="DD/MM/YYYY"
                  required
                  type="range"
                  placeholder="Pick Dates Range"
                  clearable
                  defaultValue={[new Date(), new Date()]}
                  value={date}
                  onChange={(e: any) => {
                    setDate(e);
                    if (e[1]) {
                      stopAPICalls();
                      setBothDates({
                        fromDate: moment(e[0]).format("YYYY/MM/DD"),
                        toDate: moment(e[1]).format("YYYY/MM/DD"),
                      });
                    }
                    setPageNumber("1");
                    if (e && e[0] !== null && e[1] !== null) {
                      handledate(e);
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 1.5,
                  xl: 1.2,
                }}
              >
                <Checkbox
                  style={{
                    position: "relative",
                    top: "15px",
                  }}
                  checked={refreshSelected}
                  onChange={(event) =>
                    setRefreshSelected(event.currentTarget.checked)
                  }
                  label="Auto Refresh"
                  mt="md"
                />
              </Grid.Col>
              <Grid.Col
                pt={20}
                span={{
                  xs: 6,
                  sm: 6,
                  md: 4,
                  lg: 0.2,
                  xl: 0.2,
                }}
                display="flex"
                style={{
                  gap: "15px",
                  alignItems: "center",
                }}
              >
                {BillData?.docs?.length > 0 ? (
                  <>
                    <Tooltip
                      label={`Switch to ${
                        switchTableOrGraph ? "Graph" : "Table"
                      }`}
                      position="bottom-start"
                      offset={2}
                      arrowOffset={50}
                      arrowSize={8}
                      withArrow
                    >
                      <ActionIcon
                        variant="default"
                        size="lg"
                        onClick={() => {
                          if (switchTableOrGraph) {
                            setSwitchTableOrGraph(false);
                          } else {
                            setSwitchTableOrGraph(true);
                          }
                        }}
                      >
                        {switchTableOrGraph ? <IconTable /> : <IconChartBar />}
                      </ActionIcon>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
                {Summary?.NoOfBills > 0 && BillData?.docs?.length > 0 && (
                  <Tooltip label="Export Reports file">
                    <ActionIcon
                      loading={exportLoading}
                      variant="default"
                      size="lg"
                      onClick={() => {
                        handleExportClick();
                      }}
                    >
                      <IconDownload />
                    </ActionIcon>
                  </Tooltip>
                )}
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col>
            {loading ? (
              <SkeletonTableLoder />
            ) : (
              <>
                {BillData?.docs?.length ? (
                  switchTableOrGraph ? (
                    <ChartUtils
                      label="Total Sales"
                      xAxisTitle="Bill Numbers"
                      yAxisTitle="Total Amount"
                      pluginsTitle={
                        status === "CONFIRM" ? "Billwise" : "Void Billwise"
                      }
                      data={BillData?.docs}
                      labelsProp="BillNo"
                      dataSetProp="TotalAmt"
                      isMonthWise={false}
                    />
                  ) : (
                    <>
                      <div>
                        <Table
                          verticalSpacing="xs"
                          horizontalSpacing="xs"
                          highlightOnHover
                          withTableBorder
                          withColumnBorders
                        >
                          <Table.Thead>
                            <Table.Tr style={{ fontSize: "16px" }}>
                              <Table.Th>Date</Table.Th>
                              <Table.Th>Device</Table.Th>
                              <Table.Th>Bill No</Table.Th>
                              <Table.Th>No of Items</Table.Th>
                              <Table.Th>Discount Values</Table.Th>
                              <Table.Th>Total GST</Table.Th>
                              <Table.Th>Total Amount</Table.Th>
                            </Table.Tr>
                          </Table.Thead>

                          {status === "CONFIRM" ? (
                            <>
                              <Table.Tbody>{SummaryReport()}</Table.Tbody>
                              <Table.Tbody>{TableData()}</Table.Tbody>
                            </>
                          ) : (
                            <>
                              <Table.Tbody>{VoidSummaryReport()}</Table.Tbody>
                              <Table.Tbody>{TableData()}</Table.Tbody>
                            </>
                          )}
                        </Table>
                      </div>
                      {TotalRecords > 1 && (
                        <div style={{ marginBottom: "50px" }}>
                          <Flex
                            p={15}
                            mih={50}
                            align="center"
                            gap="md"
                            justify="flex-end"
                            direction="row"
                            wrap="wrap"
                          >
                            {/* <div style={{ width: "5%" }}> */}
                            <Group>
                              <NumberInput
                                value={pageNumber}
                                w={60}
                                size="xs"
                                mt="sm"
                                placeholder="Page "
                                radius="sm"
                                min={1}
                                max={TotalRecords}
                                hideControls
                                onChange={(event) => {
                                  setSearchpage(Number(event));
                                }}
                              />
                              {/* </div> */}
                              <Button
                                mt="sm"
                                variant="outline"
                                size="xs"
                                onClick={() => {
                                  const pageNumber2 = searchpage;

                                  if (
                                    pageNumber2 < 1 ||
                                    pageNumber2 > TotalRecords
                                  ) {
                                    notifications.show({
                                      title: "Error",
                                      message: "Invalid page number",
                                      color: "red",
                                      icon: <IconX size="1.1rem" />,
                                    });
                                  } else {
                                    handlePageChange(searchpage);
                                  }
                                }}
                              >
                                Go
                              </Button>
                            </Group>

                            <Pagination
                              mt="sm"
                              total={TotalRecords}
                              onChange={handlePageChange}
                              value={Number(pageNumber)}
                              // radius="xl"
                            />
                          </Flex>
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <div style={{ paddingTop: "60px" }}>
                      <Player
                        autoplay
                        loop
                        src="./static/infographics/noRecordsFound.json"
                        style={{ height: "300px", width: "300px" }}
                        background="transparent"
                        controls={false}
                      ></Player>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
      )}

      <Modal
        centered
        opened={showmodal}
        onClose={() => {
          setShowmodal(false);
        }}
        title={
          <Title order={3} style={{ height: "15px", paddingLeft: "10px" }}>
            Bill Details
          </Title>
        }
        size="xl"
        classNames={{
          body: clases.modalBody,
        }}
      >
        <Divider my="1" style={{ paddingTop: "10px" }} />
        <Devicemodal item={deviceItemdata} />
      </Modal>
    </div>
  );
};

export default Billwise;
