import React, { useEffect, useRef, useState } from "react";
import {
  useDownloadAggregateReport,
  useGetAggregate,
} from "../../../modules/reports/reportHooks";
import { GetAggregateReportProps } from "../../../modules/reports/service/reportService";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
import {
  Divider,
  Grid,
  Pagination,
  Table,
  Title,
  Skeleton,
  Tooltip,
  ActionIcon,
  Checkbox,
  Button,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
import { convertToIndianCurrency } from "../../../utils/utils";
import {
  IconTable,
  IconChartBar,
  IconDownload,
  IconX,
  IconCheck,
} from "@tabler/icons-react";
import { Player } from "@lottiefiles/react-lottie-player";
import ChartUtils from "../../../utils/uiUtils/ChartUtils";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
import { notifications } from "@mantine/notifications";
const Aggregate = () => {
  const {
    getAggregateFunction,
    data: AggregateData,
    loading,
  } = useGetAggregate();
  const { downloadAggregateReportFunction, data: downloadData } =
    useDownloadAggregateReport();

  const [TotalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState("1");
  const [date, setDate] = useState();
  const [switchTableOrGraph, setSwitchTableOrGraph] = useState(false);
  const [limit] = useState(10);
  const [exportLoading, setExportLoading] = useState(false);
  const [reportDataForDownload, setReportDataForDownload] = useState<any>([]);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(100);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const [searchpage, setSearchpage] = useState(1);
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  const pageNumberRef = useRef(pageNumber);

  let apiCallInterval: any;

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const getAggregateData: GetAggregateReportProps = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    page: pageNumber,
    limit: limit.toString(),
  };

  function makeAPICall(page: any) {
    let getMonthWiseProps: GetAggregateReportProps = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      page: page,
      limit: limit.toString(),
    };

    getAggregateFunction(getMonthWiseProps);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = (pageNumber: any) => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall(pageNumber);
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall(pageNumberRef.current);

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(
        () => scheduleAPICall(pageNumberRef.current),
        interval
      );

      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, bothDates]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    getAggregateFunction(getAggregateData); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AggregateData?.pages > 0) {
      setTotalpage(AggregateData?.total);
    } else {
      setTotalRecords(0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AggregateData]);

  useEffect(() => {
    if (downloadData?.docs?.length > 0 && exportLoading) {
      setReportDataForDownload((prev: any) => [...prev, ...downloadData?.docs]);
    }
  }, [downloadData]);

  useEffect(() => {
    if (reportDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(downloadData?.total / downloadLimit);

      if (total_Pages < nextPage) {
        try {
          onExportClick(
            reportDataForDownload,
            reportTypes.aggregate,
            `[${selectedFromDate}] - [${selectedtoDate}]`
          );
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        } catch (err) {
          notifications.show({
            title: "Export Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);

        const downloadItemWiseData: GetAggregateReportProps = {
          fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
          toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
          page: nextPage.toString(),
          limit: downloadLimit.toString(),
        };

        downloadAggregateReportFunction(downloadItemWiseData);
      }
    }
  }, [reportDataForDownload]);

  const handledate = (e: any) => {
    if (!refreshSelected) {
      getAggregateData.fromDate = moment(e[0]).format("YYYY/MM/DD");
      getAggregateData.toDate = moment(e[1]).format("YYYY/MM/DD");
      if (!refreshSelected) {
        getAggregateFunction(getAggregateData);
      }
    }
  };
  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);

    setTotalRecords(totalPages);
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    stopAPICalls();
    getAggregateData.page = page.toString();
    getAggregateFunction(getAggregateData);
  };

  const Tabledata: any = () => {
    return (
      <React.Fragment>
        {AggregateData?.docs?.map((item: any, index: number) => (
          <Table.Tr key={index}>
            {/* <td>{item.}</td> */}
            <Table.Td>{item.outletName}</Table.Td>
            <Table.Td>{item.NoOfBills}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item?.TotalGST)}</Table.Td>
            <Table.Td>
              {convertToIndianCurrency(item?.TotalCESS1 + item?.TotalCESS2)}
            </Table.Td>
            <Table.Td>{convertToIndianCurrency(item?.TotalDiscount)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalSale)}</Table.Td>
          </Table.Tr>
        ))}
      </React.Fragment>
    );
  };

  const handleExportClick = () => {
    if (AggregateData?.total > 0 && AggregateData?.total !== undefined) {
      const downloadUserWiseData: GetAggregateReportProps = {
        fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
        toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
        page: "1",
        limit: downloadLimit.toString(),
      };

      downloadAggregateReportFunction(downloadUserWiseData);
      setExportLoading(true);
    } else {
      notifications.show({
        title: "No Data Found",
        message: "No Data Found",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Aggregate" />

      <Title order={1}>Aggregate Report</Title>
      <Divider my="md" />
      <Grid justify="flex-start">
        <Grid.Col
          span={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 2,
            xl: 2,
          }}
        >
          <DatePickerInput
            label="Select Date Range"
            miw={230}
            maw={{ lg: 250, xl: 250, md: 250 }}
            valueFormat="DD/MM/YYYY"
            required
            type="range"
            placeholder="Pick Dates Range"
            allowSingleDateInRange
            clearable
            defaultValue={[new Date(), new Date()]}
            value={date}
            onChange={(e: any) => {
              setDate(e);
              if (e[1]) {
                stopAPICalls();
                setBothDates({
                  fromDate: moment(e[0]).format("YYYY/MM/DD"),
                  toDate: moment(e[1]).format("YYYY/MM/DD"),
                });
              }
              setPageNumber("1");
              if (e && e[0] !== null && e[1] !== null) {
                handledate(e);
              }
            }}
          />
        </Grid.Col>
        <Grid.Col
          span={{
            xs: 6,
            sm: 6,
            md: 6,
            lg: 1.5,
            xl: 1.2,
          }}
        >
          <Checkbox
            style={{
              position: "relative",
              top: "15px",
            }}
            checked={refreshSelected}
            onChange={(event) =>
              setRefreshSelected(event.currentTarget.checked)
            }
            label="Auto Refresh"
            mt="md"
          />
        </Grid.Col>
        {AggregateData?.docs?.length > 0 && (
          <Grid.Col
            pt={20}
            span={{
              xs: 6,
              sm: 6,
              md: 4,
              lg: 0.2,
              xl: 0.2,
            }}
            display="flex"
            style={{
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Tooltip
              label={`Switch to ${switchTableOrGraph ? "Graph" : "Table"}`}
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
            >
              <ActionIcon
                variant="default"
                size="lg"
                onClick={() => {
                  if (switchTableOrGraph) {
                    setSwitchTableOrGraph(false);
                  } else {
                    setSwitchTableOrGraph(true);
                  }
                }}
              >
                {switchTableOrGraph ? <IconTable /> : <IconChartBar />}
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Export Reports file">
              <ActionIcon
                loading={exportLoading}
                variant="default"
                size="lg"
                onClick={() => {
                  handleExportClick();
                }}
              >
                <IconDownload />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
        )}
      </Grid>

      <Grid mt={20} ml={10}>
        <Grid.Col>
          {loading ? (
            <div style={{ width: "100%" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    {[...Array(2)].map((_) => (
                      <th style={{ width: "50%" }}>
                        <Skeleton radius={0} height={60} />
                      </th>
                    ))}
                  </tr>
                </thead>
                <div style={{ width: "100%", height: "6px" }}></div>
                <tbody>
                  {[...Array(10)].map((_, index) => (
                    <tr key={index}>
                      {[...Array(7)].map(() => (
                        <td>
                          <Skeleton radius={0} height={35} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <React.Fragment>
              {AggregateData?.docs?.length ? (
                switchTableOrGraph ? (
                  <ChartUtils
                    label="Total Sales"
                    xAxisTitle="Outlet Name"
                    yAxisTitle="Total Amount"
                    pluginsTitle="Aggregate Bill"
                    data={AggregateData?.docs}
                    dataSetProp="TotalSale"
                    labelsProp="outletName"
                    // isMonthWise
                  />
                ) : (
                  <React.Fragment>
                    <div>
                      <Table
                        verticalSpacing="xs"
                        horizontalSpacing="xs"
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                      >
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Outlet Name</Table.Th>
                            <Table.Th>Total Bills</Table.Th>
                            <Table.Th>Total GST</Table.Th>
                            <Table.Th>Total CESS</Table.Th>
                            <Table.Th>Total Discount</Table.Th>
                            <Table.Th>Total Sale</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{Tabledata()}</Table.Tbody>
                      </Table>
                    </div>
                    {TotalRecords > 1 && (
                      <div style={{ marginBottom: "50px" }}>
                        <Flex
                          p={15}
                          mih={50}
                          align="center"
                          gap="md"
                          justify="flex-end"
                          direction="row"
                          wrap="wrap"
                        >
                          {/* <div style={{ width: "5%" }}> */}
                          <Group>
                            <NumberInput
                              value={pageNumber}
                              w={60}
                              size="xs"
                              mt="sm"
                              placeholder="Page "
                              radius="sm"
                              min={1}
                              max={TotalRecords}
                              hideControls
                              onChange={(event) => {
                                setSearchpage(Number(event));
                              }}
                            />
                            {/* </div> */}
                            <Button
                              mt="sm"
                              variant="outline"
                              size="xs"
                              onClick={() => {
                                const pageNumber2 = searchpage;

                                if (
                                  pageNumber2 < 1 ||
                                  pageNumber2 > TotalRecords
                                ) {
                                  notifications.show({
                                    title: "Error",
                                    message: "Invalid page number",
                                    color: "red",
                                    icon: <IconX size="1.1rem" />,
                                  });
                                } else {
                                  handlePageChange(searchpage);
                                }
                              }}
                            >
                              Go
                            </Button>
                          </Group>

                          <Pagination
                            mt="sm"
                            total={TotalRecords}
                            onChange={handlePageChange}
                            value={Number(pageNumber)}
                            // radius="xl"
                          />
                        </Flex>
                      </div>
                    )}
                  </React.Fragment>
                )
              ) : (
                <>
                  <div style={{ paddingTop: "60px" }}>
                    <Player
                      autoplay
                      loop
                      src="./static/infographics/noRecordsFound.json"
                      style={{ height: "300px", width: "300px" }}
                      background="transparent"
                      controls={false}
                    ></Player>
                  </div>
                </>
              )}
            </React.Fragment>
          )}
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default Aggregate;
