import axios  from 'axios';
import { getHeader } from "../../../utils/utils";
import endPoints from '../../../utils/apiEndPoints';

export class FooterService {
    private baseUrl: string;
  
    constructor(baseUrl: string) {
      this.baseUrl = baseUrl;
    }

    async fetchversion(): Promise<any> {
        const url = `${this.baseUrl}${endPoints.APIVersion}`
        let header = getHeader();
     
        try {
          const response = await axios.get(url, { headers: header });
            return response.data;
        } catch (err:any) {
            throw new Error(err);
        }
      };

}