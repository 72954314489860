
import { Player } from "@lottiefiles/react-lottie-player";


const NoRecordsFound = () => {
  return (
    <Player
      autoplay
      loop
      src="/static/infographics/noRecordsFound.json"
      style={{ height: "300px", width: "300px" }}
      background="transparent"
      controls={false}
    ></Player>
  );
};

export default NoRecordsFound;
