import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../awatarStore/store'
import { CreateOutletProps, GetOutletProps, UpdateOutletProps } from './service/outletService';
import { create, fetch, update } from './outletAction';

export const useGetOutlet = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['outlet/fetch'].loading);
    const error = useSelector((state: RootState) => state['outlet/fetch'].error);
    const data = useSelector((state: RootState) => state['outlet/fetch'].data)

    const fetchOutlet = async (outletProps: GetOutletProps) => {
        try {
            const res = await dispatch(fetch(outletProps));

            return res
        } catch (error: any) {
            console.log(error.message, "Error")

        }
    };

    return { fetchOutlet, data, loading, error }
}

export const useCreateOutlet = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['outlet/create'].loading);
    const error = useSelector((state: RootState) => state['outlet/create'].error);
    const data = useSelector((state: RootState) => state['outlet/create'].data)
    const errorData=useSelector((state: RootState) => state['outlet/create'].errorData)
    const isCreated=useSelector((state: RootState) => state['outlet/create'].isCreated)

    const createOutlet = async (outletProps: CreateOutletProps) => {
         await dispatch(create(outletProps));
    };

    return { createOutlet, loading, error, errorData,isCreated,data}
}

export const useUpdateOutlet = () => {
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['outlet/update'].loading);
    const error = useSelector((state: RootState) => state['outlet/update'].error);
    const data = useSelector((state: RootState) => state['outlet/update'].data)
    const errorData=useSelector((state: RootState) => state['outlet/update'].errorData)
    const isUpdated=useSelector((state: RootState) => state['outlet/update'].isUpdated)

    const updateOutlet = async (outletProps: UpdateOutletProps) => {
      await dispatch(update(outletProps));
  
    };

    return { updateOutlet, loading, error,errorData,isUpdated ,data}
}