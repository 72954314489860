import { createSlice } from "@reduxjs/toolkit";
import { create, fetch, update } from "./fileActions";

interface FetchFileState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: any;
    hasFile?:boolean|null
}

interface CreateFileState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: any;
    isCreated?:boolean
}

interface UpdateFileState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: any;
    isUpdated?:boolean
}

const initialFetchState:FetchFileState= {
    loading: false,
    errorData: null,
     error:false,
    data: [],
    hasFile:null
 };

 const initialCreateState:CreateFileState= {
    loading: false,
    errorData: null,
     error:false,
    data: [],
    isCreated:false
 };

    const initialUpdateState:UpdateFileState= {
        loading: false,
        errorData: null,
        error:false,
        data: [],
        isUpdated:false
    };


 export const fetchFileSlice = createSlice({
    name: "file/fetch",
    initialState: initialFetchState,
    reducers: {
        resetFile() {
            return {
              ...initialFetchState,
            };
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetch.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false 
                state.hasFile=false
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                if(action?.payload?.status===400){
                    state.hasFile=false   
                    state.error=true
                }else{
                    state.hasFile=true
                    state.data=action.payload?.data
                    state.error=false
                }
            })
            .addCase(fetch.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
                state.hasFile=false
            })
    }, 
});


export const createFileSlice = createSlice({
        name: "file/create",
        initialState: initialCreateState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(create.pending, (state) => {
                    state.loading=true;
                    state.errorData=null;
                    state.data=[]
                    state.error=false
                    state.isCreated=false
                })
                .addCase(create.fulfilled, (state, action) => {
                    state.loading=false;
                    state.errorData=null;
                    state.data=action.payload
                    state.error=false
                    state.isCreated=true
                    
                })
                .addCase(create.rejected, (state, action) => {
                    state.loading=false;
                    state.errorData=action.error.message;
                    state.data=[]
                    state.error=true
                    state.isCreated=false
                })
        },
    });

export const updateFileSlice = createSlice({
        name: "file/update",
        initialState: initialUpdateState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(update.pending, (state) => {
                    state.loading=true;
                    state.errorData=null;
                    state.data=[]
                    state.error=false
                    state.isUpdated=false
                })
                .addCase(update.fulfilled, (state, action) => {
                    state.loading=false;
                    state.errorData=null;
                    state.data=action.payload
                    state.error=false
                    state.isUpdated=true
                    
                })
                .addCase(update.rejected, (state, action) => {
                    state.loading=false;
                    state.errorData=action.error.message;
                    state.data=[]
                    state.error=true
                    state.isUpdated=false
                })
        }
    });


export const fetchFileReducer = fetchFileSlice.reducer;
export const createFileReducer = createFileSlice.reducer;
export const updateFileReducer = updateFileSlice.reducer;
export const { resetFile } = fetchFileSlice.actions;
