import React, { useEffect, useState } from "react";
import { useGetSummary } from "../../../modules/reports/reportHooks";
import { GetBillWiseSummaryReport } from "../../../modules/reports/service/reportService";
import {
  Select,
  Text,
  Divider,
  Tabs,
  Title,
  Tooltip,
  ActionIcon,
  Grid,
  Checkbox,
} from "@mantine/core";
import moment from "moment";
import { DatePickerInput } from "@mantine/dates";
import { GetOutletProps } from "../../../modules/outlet/service/outletService";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { notifications } from "@mantine/notifications";
import { useGetDevice } from "../../../modules/device/deviceHooks";
import { useGetOutlet } from "../../../modules/outlet/outletHooks";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  IconBuildingStore,
  IconChartBar,
  IconCheck,
  IconDeviceLandlinePhone,
  IconDownload,
  IconTable,
} from "@tabler/icons-react";
import clases from "./selected.module.css";
import SummaryCard from "./SummaryCard/SummaryCard";
import DonutChart from "./DonutChart";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
import SkeletonTableLoader from "../../../utils/uiUtils/SkeletonTableLoder";

const SummaryReport = () => {
  const [activeTab, setActiveTab] = useState<string | null>("Outlet");
  const [outletId, setOutletId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [reportByDevice, setReportByDevice] = useState(false);
  const [date, setDate] = useState();
  const [switchTableOrGraph, setSwitchTableOrGraph] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>("");
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  let apiCallInterval: any;

  const {
    getSummaryFunction,
    loading: SummaryDataLoading,
    data: FullSummaryData,
  } = useGetSummary();

  const { fetchOutlet, data: outlet, loading: Ouletloading } = useGetOutlet();
  const {
    fetchDevice,
    loading: DeviceLoading,
    data: DeviceData,
  } = useGetDevice();

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  const SummaryData: GetBillWiseSummaryReport = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    BillStatus: "CONFIRM",
  };
  const outletData: GetOutletProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
  };

  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: true,
  };

  function makeAPICall() {
    let getSummaryProps: GetBillWiseSummaryReport = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      BillStatus: "CONFIRM",
    };

    if (!reportByDevice) {
      getSummaryProps.deviceId = undefined;
      getSummaryProps.outletId = outletId;
    } else {
      getSummaryProps.outletId = undefined;
      getSummaryProps.deviceId = deviceId;
    }

    getSummaryFunction(getSummaryProps);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = () => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall();
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall();

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(() => scheduleAPICall(), interval);

      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, reportByDevice, bothDates, outletId, deviceId]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    fetchOutlet(outletData);
    fetchDevice(getDeviceData);
  }, []);

  useEffect(() => {
    if (outlet?.docs?.length > 0) {
      setOutletId(outlet?.docs[0]?._id);
      if (!reportByDevice && !refreshSelected) {
        setSelectedName(outlet?.docs[0]?.outletName);
        SummaryData.outletId = outletId ? outletId : outlet?.docs[0]?._id;
        SummaryData.deviceId = undefined;
        getSummaryFunction(SummaryData);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet, reportByDevice]);

  useEffect(() => {
    if (DeviceData?.docs?.length > 0) {
      setDeviceId(DeviceData?.docs[0]?._id);
      if (reportByDevice && !refreshSelected) {
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);
        SummaryData.outletId = undefined;
        SummaryData.deviceId = deviceId ? deviceId : DeviceData?.docs[0]?._id;
        getSummaryFunction(SummaryData);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceData, reportByDevice]);

  //selected data device
  const selectedDeviceData = () => {
    let data: any = DeviceData?.docs?.map((item: any) => ({
      value: item._id,
      label: `${
        item?.userDeviceName
          ? item.userDeviceName + " [" + item.serialNo + "]"
          : item?.serialNo ?? ""
      }`,
    }));

    return data === undefined ? [] : data;
  };
  //select data outlet
  const selectOutletData = () => {
    let data: any = outlet?.docs?.map((item: any) => {
      return { value: item._id, label: item.outletName };
    });
    return data === undefined ? [] : data;
  };

  const handleOuletAndDeviceChange = (event: any, name?: any) => {
    setSelectedName(name);
    stopAPICalls();
    if (reportByDevice) {
      setDeviceId(event);
      SummaryData.deviceId = event;
      // SummaryData.page = "1";
      SummaryData.outletId = undefined;
      if (!SummaryData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        if (!refreshSelected) {
          getSummaryFunction(SummaryData);
        }
      }
    } else {
      setOutletId(event);
      SummaryData.outletId = event;
      SummaryData.deviceId = undefined;
      // SummaryData.page = "1";

      if (!SummaryData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        if (!refreshSelected) {
          getSummaryFunction(SummaryData);
        }
      }
    }
  };

  const handledate = (e: any) => {
    SummaryData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    SummaryData.toDate = moment(e[1]).format("YYYY/MM/DD");

    if (!reportByDevice) {
      SummaryData.outletId = outletId;
      SummaryData.deviceId = undefined;
    } else {
      SummaryData.outletId = undefined;
      SummaryData.deviceId = deviceId;
    }

    if (!refreshSelected) {
      getSummaryFunction(SummaryData);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Summary Report" />
      <div>
        <Title order={1}>Summary Report</Title>
      </div>

      <div>
        <Divider my="md" />
        <Text size="sm" style={{ paddingBottom: "10px" }}>
          Filter By
        </Text>
        <Tabs
          color="grape"
          value={activeTab}
          onChange={(value) => {
            setActiveTab(value);
            setReportByDevice(value === "Outlet" ? false : true);
            stopAPICalls();
          }}
          classNames={clases}
        >
          <Tabs.List>
            <Tooltip
              label="Select Outlet wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab value="Outlet" leftSection={<IconBuildingStore />}>
                Outlet
              </Tabs.Tab>
            </Tooltip>
            <Tooltip
              label="Select Device wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab
                value="Device"
                leftSection={<IconDeviceLandlinePhone />}
              >
                Device
              </Tabs.Tab>
            </Tooltip>
          </Tabs.List>
        </Tabs>
      </div>
      {Ouletloading || DeviceLoading ? (
        <></>
      ) : (
        <Grid>
          <Grid.Col>
            <Grid mt={15}>
              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <Select
                  label={!reportByDevice ? "Select Outlet" : "Select Device"}
                  value={!reportByDevice ? outletId : deviceId}
                  onChange={(event: any) => {
                    let selectedName: any;
                    if (!reportByDevice) {
                      outlet?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.outletName;
                          return item?.outletName;
                        }
                      });
                    } else if (reportByDevice) {
                      DeviceData?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.userDeviceName;
                          return item?.userDeviceName;
                        }
                      });
                    }

                    handleOuletAndDeviceChange(event, selectedName);
                  }}
                  allowDeselect={false}
                  placeholder={
                    !reportByDevice ? "Select Outlet" : "Select Device"
                  }
                  data={
                    !reportByDevice ? selectOutletData() : selectedDeviceData()
                  }
                  nothingFoundMessage={
                    !reportByDevice ? "No Outlet Found" : "No Device Found"
                  }
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <DatePickerInput
                  label="Select Date"
                  required
                  allowSingleDateInRange
                  valueFormat="DD/MM/YYYY"
                  type="range"
                  placeholder="Pick Dates Range"
                  clearable
                  defaultValue={[new Date(), new Date()]}
                  value={date}
                  onChange={(e: any) => {
                    setDate(e);
                    if (e[1]) {
                      stopAPICalls();
                      setBothDates({
                        fromDate: moment(e[0]).format("YYYY/MM/DD"),
                        toDate: moment(e[1]).format("YYYY/MM/DD"),
                      });
                    }
                    if (e && e[0] !== null && e[1] !== null) {
                      handledate(e);
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 1.5,
                  xl: 1.2,
                }}
              >
                <Checkbox
                  style={{
                    position: "relative",
                    top: "15px",
                  }}
                  checked={refreshSelected}
                  onChange={(event) =>
                    setRefreshSelected(event.currentTarget.checked)
                  }
                  label="Auto Refresh"
                  mt="md"
                />
              </Grid.Col>
              {FullSummaryData?.NoOfBills > 0 && (
                <Grid.Col
                  pt={20}
                  span={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 0.2,
                    xl: 0.2,
                  }}
                  display="flex"
                  style={{
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    label={`Switch to ${
                      switchTableOrGraph ? "Graph" : "Table"
                    }`}
                    position="bottom-start"
                    offset={2}
                    arrowOffset={50}
                    arrowSize={8}
                    withArrow
                  >
                    <ActionIcon
                      variant="default"
                      size="lg"
                      onClick={() => {
                        if (switchTableOrGraph) {
                          setSwitchTableOrGraph(false);
                        } else {
                          setSwitchTableOrGraph(true);
                        }
                      }}
                    >
                      {switchTableOrGraph ? <IconTable /> : <IconChartBar />}
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Export Reports file">
                    <ActionIcon
                      variant="default"
                      size="lg"
                      onClick={() => {
                        onExportClick(
                          FullSummaryData,
                          reportTypes.summary,
                          ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`
                        );
                      }}
                    >
                      <IconDownload />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>

          <Grid.Col style={{ paddingTop: "20px" }}>
            {SummaryDataLoading ? (
              <SkeletonTableLoader />
            ) : (
              <>
                {FullSummaryData ? (
                  switchTableOrGraph ? (
                    <DonutChart data={FullSummaryData} />
                  ) : (
                    <SummaryCard data={FullSummaryData} />
                  )
                ) : (
                  <>
                    <div style={{ paddingTop: "60px" }}>
                      <Player
                        autoplay
                        loop
                        src="./static/infographics/noRecordsFound.json"
                        style={{ height: "300px", width: "300px" }}
                        background="transparent"
                        controls={false}
                      ></Player>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default SummaryReport;
