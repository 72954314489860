import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Card,
  Grid,
  Select,
  Tabs,
  Divider,
  useMantineColorScheme,
  Skeleton,
  Title,
  Box,
} from "@mantine/core";
import {
  useCreateHeaderFooter,
  useGetDevice,
  useGetHeaderFooter,
  useUpdateHeaderFooter,
} from "../../../modules/device/deviceHooks";
import { Accordion } from "@mantine/core";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { DeviceLoginPayload } from "../../../modules/auth/service/authService";
import { useDeviceLogin } from "../../../modules/auth/authHooks";
import { Helmet } from "react-helmet-async";
// import classes from "../../styleModules/AppShellMainStyles.module.css";
import SomethingWentWrong from "../../../utils/uiUtils/SomethingWentWrong";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import SelectDevice from "../../../utils/uiUtils/SelectDevice";
import { useForm } from "@mantine/form";

export interface Header {
  LineNumber: number;
  Text?: string;
  Alignment: string;
  Height: boolean;
  Width: boolean;
}

export interface Footer {
  LineNumber: number;
  Text?: string;
  Alignment: string;
  Height: boolean;
  Width: boolean;
}

export interface HFProps {
  Header: Header[];
  Footer: Footer[];
  deviceId: string;
}

const HeaderFooter = () => {
  const { fetchDevice, data: deviceData } = useGetDevice();
  const {
    getHeaderFooterFunction,
    loading: HFLoading,
    data: HFData,
    hasData,
  } = useGetHeaderFooter();
  const {
    deviceLoginUser,
    error: loginError,
    errorData: loginErrorData,
    isLoggedIn,
  } = useDeviceLogin();
  const {
    updateHeaderFooterFunction,

    error: updateError,
    errorData: updateErrorData,
    isUpdated,
  } = useUpdateHeaderFooter();
  const {
    createHeaderFooterFunction,

    error: createError,
    errorData: createErrorData,
    isCreated,
  } = useCreateHeaderFooter();

  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === "dark";

  const [activeTab, setActiveTab] = useState<string | null>("first");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [createFlag, setCreateFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);

  const initialHeaderValues: Header = {
    LineNumber: 0,
    Text: "",
    Alignment: "Center",
    Height: false,
    Width: false,
  };

  const initialFooterValues: Footer = {
    LineNumber: 0,
    Text: "",
    Alignment: "Center",
    Height: false,
    Width: false,
  };

  const initialHeaderArray: Header[] = Array(10)
    .fill(null)
    .map((_, index) =>
      Object.assign({}, initialHeaderValues, {
        LineNumber: index + 1,
        Width: false,
        Height: false,
      })
    );

  const initialFooterArray = Array(10)
    .fill(null)
    .map((_, index) =>
      Object.assign({}, initialFooterValues, {
        LineNumber: index + 1,
        Width: false,
        Height: false,
      })
    );

  const form = useForm({
    initialValues: {
      Header: [...initialHeaderArray],
      Footer: [...initialFooterArray],
    },
  });

  useEffect(() => {
    const getDeviceData: GetDeviceProps = {
      page: "1",
      limit: "1000",
      businessId: localStorage.getItem("businessId") ?? "",
      includeReplacedDevice: false,
    };

    fetchDevice(getDeviceData);
  }, []);

  useEffect(() => {
    if (HFData) {
      HFData?.Header?.forEach((newHeader: any) => {
        const lineNumber = parseInt(newHeader?.LineNumber);

        if (!isNaN(lineNumber)) {
          const matchingIndex = form?.values?.Header?.findIndex(
            (item: Header) => item?.LineNumber === lineNumber
          );

          if (matchingIndex !== -1) {
            form.setFieldValue(
              `Header.${matchingIndex}.Alignment`,
              newHeader?.Alignment
            );
            form.setFieldValue(
              `Header.${matchingIndex}.Width`,
              newHeader?.Width
            );
            form.setFieldValue(
              `Header.${matchingIndex}.Height`,
              newHeader?.Height
            );
            if (newHeader?.Text !== undefined && newHeader?.Text !== null) {
              form.setFieldValue(
                `Header.${matchingIndex}.Text`,
                newHeader?.Text
              );
            }
          }
        }
      });

      HFData?.Footer?.forEach((newFooter: any) => {
        const lineNumber = parseInt(newFooter?.LineNumber);

        if (!isNaN(lineNumber)) {
          const matchingIndex = form?.values?.Footer?.findIndex(
            (item: Footer) => item?.LineNumber === lineNumber
          );

          if (matchingIndex !== -1) {
            form.setFieldValue(
              `Footer.${matchingIndex}.Alignment`,
              newFooter?.Alignment
            );
            form.setFieldValue(
              `Footer.${matchingIndex}.Width`,
              newFooter?.Width
            );
            form.setFieldValue(
              `Footer.${matchingIndex}.Height`,
              newFooter?.Height
            );
            if (newFooter?.Text !== undefined && newFooter?.Text !== null) {
              form.setFieldValue(
                `Footer.${matchingIndex}.Text`,
                newFooter?.Text
              );
            }
          }
        }
      });
    }
  }, [HFData]);

  useEffect(() => {
    if (
      selectedDeviceId !== "" &&
      selectedDeviceId !== undefined &&
      selectedDeviceId !== null
    ) {
      form.reset();
      if (isLoggedIn) {
        const getHeaderFooterData = {
          deviceId: selectedDeviceId,
        };
        getHeaderFooterFunction(getHeaderFooterData);
      } else if (loginError) {
        notifications.show({
          title: "Error",
          message: `Login Error : ${loginErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    }
  }, [isLoggedIn, loginError]);

  useEffect(() => {
    if (selectedDeviceId !== "") {
      if (isUpdated && updateFlag) {
        notifications.show({
          title: "Success",
          message: "HF Updated Successfully",
          color: "green",
          icon: <IconCheck size="1.1rem" />,
        });
      } else if (updateError && updateFlag) {
        notifications.show({
          title: "Error",
          message: `HF Update Error :${updateErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    }
  }, [isUpdated, updateError]);

  useEffect(() => {
    if (selectedDeviceId !== "") {
      if (isCreated && createFlag) {
        notifications.show({
          title: "Success",
          message: "HF Created Successfully",
          color: "green",
          icon: <IconCheck size="1.1rem" />,
        });
        const getHeaderFooterData = {
          deviceId: selectedDeviceId,
        };

        getHeaderFooterFunction(getHeaderFooterData);
      } else if (createError && createFlag) {
        notifications.show({
          title: "Error",
          message: `HF Creation Error :${createErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    }
  }, [isCreated, createError]);

  const devices = (): { value: string; label: string }[] => {
    let data: any = deviceData?.docs
      ?.filter((item: any) => item?.outletId)
      ?.map((item: any) => {
        return {
          value: item?._id ?? "",
          label: `${
            item?.userDeviceName
              ? item.userDeviceName + " [" + item.serialNo + "]"
              : item?.serialNo ?? ""
          }`,
        };
      });
    return data === undefined ? [] : data;
  };

  const handleDeviceChange = (event: any) => {
    setSelectedDeviceId(event);

    const deviceLoginData: DeviceLoginPayload = {
      deviceId: event,
      token: localStorage.getItem("access_token") ?? "",
    };

    deviceLoginUser(deviceLoginData);
  };

  const handleUpdateHF = (header: any, footer: any) => {
    header.map((item: any) => {
      if (item?.Text === "") {
        delete item.Text;
      }
    });
    footer.map((item: any) => {
      if (item?.Text === "") {
        delete item.Text;
      }
    });
    const UpdateHFData: HFProps = {
      deviceId: selectedDeviceId,
      Header: header,
      Footer: footer,
    };

    if (hasData) {
      updateHeaderFooterFunction(UpdateHFData);
      setUpdateFlag(true);
    } else if (!hasData) {
      createHeaderFooterFunction(UpdateHFData);
      setCreateFlag(true);
    }
  };

  return (
    <>
      <Helmet title="Header/Footer" />
      <Grid gutter="lg">
        <Grid.Col>
          <Title order={2} style={{ margin: "10px" }}>
            Header/Footer
          </Title>
          <Divider my="md" />
        </Grid.Col>
      </Grid>
      <Grid ml={15} gutter="lg">
        <Grid.Col span={{ sm: 12, md: 6, lg: 3, xl: 3 }}>
          <Select
            allowDeselect={false}
            radius="md"
            label="Devices"
            onChange={handleDeviceChange}
            placeholder="Select Device"
            data={devices()}
          />
        </Grid.Col>
      </Grid>
      <Grid ml={15} gutter={"lg"}>
        <Grid.Col mt={20} span={{ sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card title="Edit Header/Footer" withBorder mb={50}>
            {loginError &&
            selectedDeviceId !== "" &&
            selectedDeviceId !== undefined &&
            selectedDeviceId !== null ? (
              <SomethingWentWrong />
            ) : (
              <>
                <>
                  {selectedDeviceId !== "" &&
                  selectedDeviceId !== undefined &&
                  selectedDeviceId !== null ? (
                    <>
                      <form
                        onSubmit={form.onSubmit((values) => {
                          handleUpdateHF(values.Header, values.Footer);
                        })}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button type="submit" radius={"md"}>
                            Update
                          </Button>
                        </Box>

                        <Tabs value={activeTab} onChange={setActiveTab}>
                          <Tabs.List>
                            <Tabs.Tab value="first" style={{ width: "50%" }}>
                              Header
                            </Tabs.Tab>
                            <Tabs.Tab value="second" style={{ width: "50%" }}>
                              Footer
                            </Tabs.Tab>
                          </Tabs.List>

                          <Tabs.Panel mt={10} value="first">
                            <Grid>
                              <Grid.Col>
                                {HFLoading ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "17px",
                                    }}
                                  >
                                    {[...Array(10)].map((_, index) => (
                                      <Skeleton key={index} height={50} />
                                    ))}
                                  </div>
                                ) : (
                                  <Accordion variant="separated">
                                    {form.values.Header !== undefined &&
                                      form.values?.Header !== null &&
                                      form.values?.Header?.map(
                                        (header: any, index: any) => (
                                          <HeaderComponent
                                            key={index}
                                            index={index}
                                            form={form}
                                            isDark={isDark}
                                            header={header}
                                          />
                                        )
                                      )}
                                  </Accordion>
                                )}
                              </Grid.Col>
                            </Grid>
                          </Tabs.Panel>

                          <Tabs.Panel mt={10} value="second">
                            <Grid>
                              <Grid.Col>
                                {HFLoading ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "17px",
                                    }}
                                  >
                                    {[...Array(10)].map((_, index) => (
                                      <Skeleton key={index} height={50} />
                                    ))}
                                  </div>
                                ) : (
                                  <Accordion
                                    variant="separated"
                                    multiple={false}
                                  >
                                    {form.values.Footer !== undefined &&
                                      form.values?.Footer !== null &&
                                      form.values?.Footer?.map(
                                        (footer: any, index: any) => (
                                          <FooterComponent
                                            key={index}
                                            index={index}
                                            form={form}
                                            isDark={isDark}
                                            footer={footer}
                                          />
                                        )
                                      )}
                                  </Accordion>
                                )}
                              </Grid.Col>
                            </Grid>
                          </Tabs.Panel>
                        </Tabs>
                      </form>
                    </>
                  ) : (
                    <Grid mih={600}>
                      <Grid.Col>
                        <SelectDevice />
                      </Grid.Col>
                    </Grid>
                  )}
                </>
              </>
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default HeaderFooter;
