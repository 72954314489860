import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";

import {
  CreateItemListProps,
  GetItemListProps,
  ItemListService,
  UpdateItemListProps,
} from "./service/itemListService";

export const fetch = createAsyncThunk(
  "itemList/fetch",
  async (itemListData: GetItemListProps, thunkAPI) => {
    try {
      const itemListService = new ItemListService(APIPATH);
      const response = await itemListService.getItemList(itemListData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const create = createAsyncThunk(
  "itemList/create",
  async (itemListData: CreateItemListProps, thunkAPI) => {
    try {
      const itemListService = new ItemListService(APIPATH);
      const response = await itemListService.createItemList(itemListData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const update = createAsyncThunk(
  "itemList/update",
  async (itemListData: UpdateItemListProps, thunkAPI) => {
    try {
      const itemListService = new ItemListService(APIPATH);
      const response = await itemListService.updateItemList(itemListData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
