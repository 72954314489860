import { Card, Grid, useMantineColorScheme } from "@mantine/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BarChart } from "../../components/Chart";

interface Props {
  label: string;
  xAxisTitle: string;
  yAxisTitle: string;
  data: any;
  pluginsTitle: string;
  labelsProp?: string;
  dataSetProp?: string;
  multipleDataSets?: boolean;
  secondLabel?: string;
  secondDataSetProp?: string;
  isMonthWise?: boolean;
}

const ChartUtils = ({
  label,
  xAxisTitle,
  yAxisTitle,
  data,
  pluginsTitle,
  labelsProp,
  dataSetProp,
  multipleDataSets,
  secondDataSetProp,
  secondLabel,
  isMonthWise,
}: Props) => {
  const { colorScheme } = useMantineColorScheme();
  const [dailySalesChartData, setdailySalesChartData] = useState({
    labels: [moment().format("MMM-DD")],
    datasets: [
      {
        label: label,

        data: [0],
        backgroundColor: ["#ffbb11"],
        barThickness: 0,
      },
    ],
  });

  // console.log(
  //   "Month data",
  //   data?.map((data: any) => data._id.month)
  // );
  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          color: colorScheme === "dark" ? "Gray" : "#000",
          text: xAxisTitle,
          align: "start",
          font: {
            weight: "bold",
            style: "italic",
          },
        },
      },
      y: {
        title: {
          display: true,
          color: colorScheme === "dark" ? "Gray" : "#000",
          text: yAxisTitle,
          align: "start",
          font: {
            weight: "bold",
            style: "italic",
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        color: colorScheme === "dark" ? "Gray" : "#000",
        text: pluginsTitle,
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      legend: {
        display: multipleDataSets ? true : false,
        position: "bottom",
      },
      datalabels: {
        display: true,
        color: colorScheme === "dark" ? "Gray" : "#000",
        formatter: Math.round,
        anchor: "end",
        offset: -20,
        align: "start",
      },
    },
  };

  useEffect(() => {
    if (data) {
      if (multipleDataSets) {
        setdailySalesChartData({
          labels: data?.map((data: any, i: number) =>
            moment(data["Date"], "YYYY-MM-DD").format("MMM-DD")
          ),
          datasets: [
            {
              label: label,
              data: data?.map((data: any) => {
                return data[`${dataSetProp}`];
              }),
              backgroundColor: ["#e0218a"],
              barThickness: 25,
            },
            {
              label: secondLabel ?? "",
              data: data?.map((data: any) => {
                return data[`${secondDataSetProp}`];
              }),
              backgroundColor: ["#2a71d0"],
              barThickness: 25,
            },
          ],
        });
      } else {
        setdailySalesChartData({
          labels: isMonthWise
            ? data?.map((data: any) =>
                moment(
                  `${data._id["month"]}-${data._id["year"]}-01`,
                  "MM-YYYY-DD"
                ).format("MMM-YYYY")
              )
            : data?.map((data: any) => data[`${labelsProp}`]),
          datasets: [
            {
              label: label,
              data: data?.map((data: any) => {
                return data[`${dataSetProp}`];
              }),
              backgroundColor: ["#e0218a"],
              barThickness: 30,
            },
          ],
        });
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <div>
      <Grid>
        <Grid.Col span={{ md: 8 }}>
          <Card radius={"md"} shadow="sm" withBorder>
            <BarChart chartData={dailySalesChartData} options={chartOptions} />
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default ChartUtils;
