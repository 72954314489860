import React from "react";
import { Button, Grid, Modal, Select } from "@mantine/core";
import classes from "../../styleModules/AppShellMainStyles.module.css";
interface Props {
  outletMapping: any;
  setOutletMapping: any;
  setOutletId: any;
  outletMapData: any;
  mapToOutlet: any;
  selectedDevice: any;
}

const OutletMapping = ({
  outletMapData,
  outletMapping,
  setOutletId,
  setOutletMapping,
  mapToOutlet,
  selectedDevice,
}: Props) => {
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Outlet Mapping"
      closeOnClickOutside={false}
      withCloseButton
      opened={outletMapping}
      onClose={() => {
        setOutletMapping(false);
        setOutletId("");
      }}
      size="sm"
      radius="sm"
      style={{ maxHeight: "100px" }}
    >
      <Grid>
        <Grid.Col
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignContent: "center",
            justifyContent: "space-between",
            maxHeight: "100px",
          }}
        >
          <Select
            defaultSearchValue={selectedDevice?.outletDetail?.[0]?.outletName}
            radius="md"
            label="Outlet"
            onChange={(e) => setOutletId(e ?? "")}
            placeholder="Select Outlet"
            data={outletMapData()}
            style={{ width: "70%", marginBottom: "230px" }}
          ></Select>
          <Button onClick={mapToOutlet} style={{ marginTop: "25px" }}>
            Save
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default OutletMapping;
