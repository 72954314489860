import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";

import {
  CreateOutletProps,
  GetOutletProps,
  OutletService,
  UpdateOutletProps,
} from "./service/outletService";

export const fetch = createAsyncThunk(
  "outlet/fetch",
  async (outletData: GetOutletProps, thunkAPI) => {
    try {
      const outletService = new OutletService(APIPATH);
      const response = await outletService.getOutlet(outletData);

      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const create = createAsyncThunk(
  "outlet/create",
  async (outletData: CreateOutletProps, thunkAPI) => {
    try {
      const outletService = new OutletService(APIPATH);
      const response = await outletService.createOutlet(outletData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const update = createAsyncThunk(
  "outlet/update",
  async (outletData: UpdateOutletProps, thunkAPI) => {
    try {
      const outletService = new OutletService(APIPATH);
      const response = await outletService.updateOutlet(outletData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
