import { saveAs } from "file-saver";
import { notifications } from "@mantine/notifications";
import moment from "moment";

export const reportTypes = {
  itemWise: "ITEMWISE",
  monthlyWise: "MONTHLYWISE",
  userWise: "USERWISE",
  GSTWise: "GSTWISE",
  refundBillWise: "REFUNDBILLWISE",
  dayWise: "DAYWISE",
  billWise: "BILLWISE",
  summary: "SUMMARY",
  aggregate: "AGGREGATE",
  voidBillWise: "VOIDBILLWISE",
};

function getBillReportForExport(
  reportData: any,
  isVoidBillReport: boolean,
  summary: any,
  fileDate?: any
) {
  try {
    let reportArray: any = [];
    if (reportData.length > 0) {
      let tempObject: any;
      for (let i = 0; i < reportData.length; i++) {
        tempObject = {};

        const date = moment(reportData[i]._Date);
        tempObject._Date = date.utc().format("DD/MM/YYYY");

        tempObject.UserNo = reportData[i].UserNo;
        if (reportData[i].DeviceName !== undefined)
          tempObject.DeviceName = reportData[i].DeviceName;
        else tempObject.DeviceName = "";
        tempObject.BillNo = reportData[i].BillNo;
        tempObject.NoOfItems = reportData[i].NoOfItems;
        tempObject.DiscVal = reportData[i].DiscVal;
        tempObject.TotalGST = reportData[i].TotalGST;
        tempObject.TotalCESS1 = reportData[i].TotalCESS1;
        tempObject.TotalCESS2 = reportData[i].TotalCESS2;
        tempObject.TotalAmt = reportData[i].TotalAmt;
        tempObject.PaymentMode = reportData[i].PaymentMode;
        reportArray[i] = Object.assign(tempObject);
      }
      let index = reportArray.length;
      reportArray[index] = {};
      reportArray[index + 1] = {};
      if (isVoidBillReport) {
        reportArray[index + 1].BillNo = summary.NoOfDeletedbills;
        reportArray[index + 1].DeviceName = "Total";
        reportArray[index + 1].DiscVal = summary.DeletedDisc;
        reportArray[index + 1].NoOfItems = summary.NoOfDeletedItems;
        reportArray[index + 1].TotalAmt = summary.DeletedAmount;
        reportArray[index + 1].TotalCESS1 = summary.DeletedCess1;
        reportArray[index + 1].TotalCESS2 = summary.DeletedCess2;
        reportArray[index + 1].TotalGST = summary.DeletedGST;
        downloadReportFile(reportArray, `VoidBillReport${fileDate}.csv`);
      } else if (!isVoidBillReport) {
        reportArray[index + 1].DeviceName = "Total";
        reportArray[index + 1].BillNo = summary.NoOfBills ?? 0;
        reportArray[index + 1].DiscVal = summary.TotalDiscount ?? 0;
        reportArray[index + 1].NoOfItems = summary.NoOfItems ?? 0;
        reportArray[index + 1].TotalAmt = summary.TotalSale ?? 0;
        reportArray[index + 1].TotalCESS1 = summary.TotalCESS1 ?? 0;
        reportArray[index + 1].TotalCESS2 = summary.TotalCESS2 ?? 0;
        reportArray[index + 1].TotalGST = summary.TotalGST ?? 0;

        downloadReportFile(reportArray, `BillReport${fileDate}.csv`);
      } else {
        notifications.show({
          title: "Error",
          color: "red",
          message: "Bill Export: No Bills Found",
        });
      }
    } else if (reportData.length === 0) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "Bill Export: No Bills Found",
      });
    }
  } catch (error) {
    notifications.show({
      title: "Error",
      color: "red",
      message: "Bill Export: Error in Fetching Bill Details for this device",
    });
  }
}

function getDayReportForExport(reportData: any, fileDate: any, summary: any) {
  try {
    const reportArray: any = [];
    if (reportData.length > 0) {
      let tempObject: any;
      let index = reportData.length;
      for (let i = 0; i < index; i++) {
        tempObject = {};
        tempObject.Date = moment(reportData[i].Date).utc().format("DD/MM/YYYY");
        tempObject.TotalSale = reportData[i].TotalSale;
        tempObject.TotalGST = reportData[i].TotalGST;
        tempObject.TotalDiscount = reportData[i].TotalDiscount;
        tempObject.NoOfBills = reportData[i].NoOfBills;
        tempObject.TotalCESS =
          reportData[i].TotalCESS1 + reportData[i].TotalCESS2;
        reportArray[i] = Object.assign(tempObject);
      }
      tempObject = {};
      tempObject.Date = "Total";
      tempObject.NoOfBills = summary.NoOfBills;
      tempObject.TotalDiscount = summary.TotalDiscount;
      tempObject.TotalCESS = summary.TotalCESS1 + summary.TotalCESS2;
      tempObject.TotalGST = summary.TotalGST;
      tempObject.TotalSale = summary.TotalSale;
      reportArray[index] = {}; // This is for placing blank line between all records and total record
      reportArray[index + 1] = Object.assign(tempObject);
      downloadReportFile(reportArray, `DayReport${fileDate}.csv`);
    } else if (reportData.length === 0) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "Bill Export: No Bills Found",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: "Bill Export Error in Fetching Bill Details for this device",
    });
  }
}

export function getItemReportForExport(
  reportData: any,
  fileDate: any,
  summary: any
) {
  try {
    let reportArray: any = [];
    let totalQty = 0;
    if (reportData.length > 0) {
      let tempObject: any;
      let index = reportData.length;

      for (let i = 0; i < index; i++) {
        tempObject = {};
        tempObject.ICode = reportData[i]._id.ICode;
        tempObject.Name = reportData[i]._id.IName;
        tempObject.Price = reportData[i]._id.IPrice;
        tempObject.TotalQty = reportData[i].TotalQty;
        tempObject.TotalGST = reportData[i].TotalGST;
        tempObject.TotalDiscount = reportData[i].TotalDiscount;
        tempObject.TotalAmount = reportData[i].TotalAmount;
        totalQty += reportData[i].TotalQty;
        reportArray[i] = Object.assign(tempObject);
      }

      tempObject = {};
      tempObject.ICode = "Total";
      tempObject.TotalDiscount = summary.TotalDiscount;
      tempObject.TotalAmount = summary.TotalSale;
      tempObject.TotalGST = summary.TotalGST;
      tempObject.TotalQty = totalQty;
      reportArray[index] = {}; // This is for placing blank line between all records and total record
      reportArray[index + 1] = Object.assign(tempObject);
      downloadReportFile(reportArray, `ItemReport${fileDate}.csv`);
    } else if (reportData.length === 0) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "Itemwise: No Bills Found",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: "Itemwise: Export Error",
    });
  }
}

function getMonthlyReportExport(reportData: any, fileDate?: any) {
  try {
    let reportArray: any = [];
    if (reportData.length > 0) {
      let tempObj: any;
      let TotalGST = 0,
        TotalDiscount = 0,
        TotalCess = 0,
        TotalAmt = 0;
      let Index = reportData.length;
      for (let i = 0; i < reportData.length; i++) {
        tempObj = {};
        tempObj.Month = reportData[i]._id.month + "/" + reportData[i]._id.year;

        tempObj.TotalGST = reportData[i].TotalGST;
        TotalGST += reportData[i].TotalGST;

        tempObj.CESSAmount = reportData[i].CESSAmount;
        TotalCess += reportData[i].CESSAmount;

        tempObj.TotalDiscount = reportData[i].TotalDiscount;
        TotalDiscount += reportData[i].TotalDiscount;

        tempObj.TotalAmount = reportData[i].TotalAmount;
        TotalAmt += reportData[i].TotalAmount;

        reportArray[i] = {};
        reportArray[i] = Object.assign(tempObj);
      }
      tempObj = {};
      reportArray[Index] = {};
      tempObj.Month = "Total";
      tempObj.TotalGST = TotalGST;
      tempObj.CESSAmount = TotalCess;
      tempObj.TotalDiscount = TotalDiscount;
      tempObj.TotalAmount = TotalAmt;
      reportArray[Index + 1] = Object.assign(tempObj);

      downloadReportFile(reportArray, `MonthlyReport${fileDate}.csv`);
    } else if (reportData.length === 0 || reportData.length === undefined) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "MontlyWise: No bills exist for the given date",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: `Monthlywise: Export Error`,
    });
  }
}

function getGSTReportExport(reportData: any, fileDate?: any) {
  try {
    let reportArray: any = [];
    let TotalAmt = 0;
    let TotalGST = 0;
    if (reportData.length > 0) {
      let tempObj: any;

      let Index = reportData.length;
      for (let i = 0; i < reportData.length; i++) {
        tempObj = {};
        tempObj.GST = reportData[i]._id;
        tempObj.BasePrice = reportData[i].BasePrice;
        tempObj.TotalGST = reportData[i].TotalGST;
        TotalGST += reportData[i].TotalGST;
        TotalAmt += reportData[i].BasePrice;

        reportArray[i] = {};
        reportArray[i] = Object.assign(tempObj);
      }
      tempObj = {};
      reportArray[Index] = {};
      tempObj.GST = "Total";
      tempObj.TotalGST = TotalGST;
      tempObj.BasePrice = TotalAmt;

      reportArray[Index + 1] = Object.assign(tempObj);
      downloadReportFile(reportArray, `GSTReport${fileDate}.csv`);
    } else if (reportData.length === 0 || reportData.length === undefined) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "GSTReport: No bills exist for the given date",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: `GSTReport: Export Error`,
    });
  }
}

function getUserReportExport(reportData: any, fileDate?: any) {
  try {
    let reportArray: any = [];
    let TotalGST = 0,
      TotalDiscount = 0,
      TotalCess = 0,
      TotalAmt = 0,
      NoOfBills = 0;

    if (reportData.length > 0) {
      let tempObj: any;

      let Index = reportData.length;
      for (let i = 0; i < reportData.length; i++) {
        tempObj = {};
        tempObj.UserNo = reportData[i]._id;
        tempObj.NoOfBills = reportData[i].NoOfBills;
        NoOfBills += reportData[i].NoOfBills;

        tempObj.TotalGST = reportData[i].TotalGST;
        TotalGST += reportData[i].TotalGST;

        tempObj.TotalCESS = reportData[i].TotalCESS;
        TotalCess += reportData[i].TotalCESS;

        tempObj.TotalDiscount = reportData[i].TotalDiscount;
        TotalDiscount += reportData[i].TotalDiscount;

        tempObj.TotalAmount = reportData[i].TotalAmount;
        TotalAmt += reportData[i].TotalAmount;

        reportArray[i] = {};
        reportArray[i] = Object.assign(tempObj);
      }
      tempObj = {};
      reportArray[Index] = {};
      tempObj.UserNo = "Total";
      tempObj.NoOfBills = NoOfBills;
      tempObj.TotalGST = TotalGST;
      tempObj.TotalCESS = TotalCess;
      tempObj.TotalDiscount = TotalDiscount;
      tempObj.TotalAmount = TotalAmt;
      reportArray[Index + 1] = Object.assign(tempObj);

      downloadReportFile(reportArray, `UserReport${fileDate}.csv`);
    } else if (reportData.length === 0 || reportData.length === undefined) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "UserWise: No Bills Exist for this device",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: `UserWise: Export Error`,
    });
  }
}

function getRefundBillwiseReport(reportData: any, fileDate: any, summary: any) {
  try {
    let reportArray: any = [];

    if (reportData.length > 0) {
      let tempObj: any;

      for (let i = 0; i < reportData.length; i++) {
        tempObj = {};
        tempObj.BillDate = moment(reportData[i].BillDate)
          .utc()
          .format("DD/MM/YYYY");
        tempObj.RefundDate = moment(reportData[i].RefundDate)
          .utc()
          .format("DD/MM/YYYY");
        if (reportData[i].DeviceName !== undefined)
          tempObj.DeviceName = reportData[i].DeviceName;
        else tempObj.DeviceName = "";
        tempObj.BillNo = reportData[i].BillNo;
        tempObj.NoOfItems = reportData[i].NoOfItems;
        tempObj.DiscVal = reportData[i].DiscVal;
        tempObj.TotalGST = reportData[i].TotalGST;
        tempObj.TotalCESS1 = reportData[i].TotalCESS1;
        tempObj.TotalCESS2 = reportData[i].TotalCESS2;
        tempObj.BillAmount = reportData[i].TotalAmt;
        tempObj.RefundAmount = reportData[i].RefundAmt;
        reportArray[i] = {};
        reportArray[i] = Object.assign(tempObj);
      }
      let index = reportData.length;
      reportArray[index] = {};
      reportArray[index + 1] = {};
      reportArray[index + 1].DeviceName = "Total";
      reportArray[index + 1].BillNo = summary.NoOfBills;
      reportArray[index + 1].DiscVal = summary.TotalDiscount;
      reportArray[index + 1].NoOfItems = summary.NoOfRefundItems;
      reportArray[index + 1].BillAmount = summary.TotalSale;
      reportArray[index + 1].TotalCESS1 = summary.TotalCESS1;
      reportArray[index + 1].TotalCESS2 = summary.TotalCESS2;
      reportArray[index + 1].TotalGST = summary.TotalGST;
      reportArray[index + 1].RefundAmount = summary.RefundAmt;
      downloadReportFile(reportArray, `RefundBillsReport${fileDate}.csv`);
    } else if (reportData.length === 0 || reportData.length === undefined) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "RefundBillWise: No Bills Exist for this device",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: `RefundBillWise: Export Error`,
    });
  }
}

function getAggregateReportExport(reportData: any, fileDate?: any) {
  try {
    let reportArray: any = [];

    if (reportData.length > 0) {
      let tempObj: any;

      for (let i = 0; i < reportData.length; i++) {
        tempObj = {};
        tempObj.OutletName = reportData[i].outletName;
        tempObj.NoOfBills = reportData[i].NoOfBills;
        tempObj.TotalSale = reportData[i].TotalSale;
        tempObj.TotalGST = reportData[i].TotalGST;
        tempObj.TotalDiscount = reportData[i].TotalDiscount;
        tempObj.NoOfItems = reportData[i].NoOfItems;
        tempObj.TotalCESS = reportData[i].TotalCESS1 + reportData[i].TotalCESS2;

        reportArray[i] = {};
        reportArray[i] = Object.assign(tempObj);
      }

      downloadReportFile(reportArray, `Aggregate${fileDate}.csv`);
    } else if (reportData.length === 0 || reportData.length === undefined) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "Aggregate: No Bills Exist for this device",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: `Aggregate: Export Error`,
    });
  }
}

function getSummaryReportExport(reportData: any, fileDate?: any) {
  try {
    let reportArray: any = [];

    if (reportData && reportData.NoOfBills > 0) {
      let tempObj: any;

      tempObj = {};
      tempObj.NoOfBills = reportData.NoOfBills;
      tempObj.TotalSale = reportData.TotalSale;
      tempObj.TotalGST = reportData.TotalGST;
      tempObj.TotalDiscount = reportData.TotalDiscount;
      tempObj.NoOfItems = reportData.NoOfItems;
      tempObj.TotalCESS = reportData.TotalCESS1 + reportData.TotalCESS2;
      tempObj.AmountByOtherThanCashAndCard =
        reportData.AmountByOtherThanCashAndCard;
      tempObj.AmountByCASH = reportData.AmountByCASH;
      tempObj.AmountByCARD = reportData.AmountByCARD;
      tempObj.DeletedGST = reportData.DeletedGST;
      tempObj.DeletedDisc = reportData.DeletedDisc;
      tempObj.DeletedCess = reportData.DeletedCess1 + reportData.DeletedCess2;
      tempObj.RefundBaseAmt = reportData.RefundBaseAmt;
      tempObj.RefundCESS = reportData.RefundCESS1 + reportData.RefundCESS2;
      tempObj.RefundDiscount = reportData.RefundDiscount;
      tempObj.RefundGSTAmt = reportData.RefundGSTAmt;
      tempObj.TotalBaseAmt = reportData.TotalBaseAmt;
      // tempObj.TotalCashCollected = reportData.TotalCashCollected;

      reportArray[0] = {};
      reportArray[0] = Object.assign(tempObj);

      downloadReportFile(reportArray, `Summary${fileDate}.csv`);
    } else {
      notifications.show({
        title: "Error",
        color: "red",
        message: "Summary: No Bills Exist for this device",
      });
    }
  } catch (error: any) {
    notifications.show({
      title: "Error",
      color: "red",
      message: `Summary: Export Error`,
    });
  }
}

export function downloadReportFile(data: any, fileName: any) {
  const replacer = (key: any, value: any) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(data[0]);
  let csv = data.map((row: any) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(",")
  );
  csv.unshift(header.join(","));
  let csvArray = csv.join("\r\n");

  var blob = new Blob([csvArray], { type: "text/csv" });
  var modifiedFilename = fileName.replace(/\//g, "-");
  saveAs(blob, modifiedFilename);
}

export const onExportClick = (
  data: any,
  type: any,
  fileDate?: any,
  summary?: any
) => {
  if (type === reportTypes.itemWise) {
    getItemReportForExport(data, fileDate, summary);
  } else if (type === reportTypes.monthlyWise) {
    getMonthlyReportExport(data, fileDate);
  } else if (type === reportTypes.userWise) {
    getUserReportExport(data, fileDate);
  } else if (type === reportTypes.GSTWise) {
    getGSTReportExport(data, fileDate);
  } else if (type === reportTypes.refundBillWise) {
    getRefundBillwiseReport(data, fileDate, summary);
  } else if (type === reportTypes.dayWise) {
    getDayReportForExport(data, fileDate, summary);
  } else if (type === reportTypes.billWise) {
    getBillReportForExport(data, false, summary, fileDate);
  } else if (type === reportTypes.summary) {
    getSummaryReportExport(data, fileDate);
  } else if (type === reportTypes.aggregate) {
    getAggregateReportExport(data, fileDate);
  } else if (type === reportTypes.voidBillWise) {
    getBillReportForExport(data, true, summary, fileDate);
  } else {
    console.log("invalid type");
  }
};
