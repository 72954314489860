import React, { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Select,
  Switch,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import {
  useCreateDeviceSettings,
  useGetDevice,
  useGetDeviceSettings,
  useUpdateDeviceSettings,
} from "../../../modules/device/deviceHooks";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDeviceLogin } from "../../../modules/auth/authHooks";
import { useForm } from "@mantine/form";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import SomethingWentWrong from "../../../utils/uiUtils/SomethingWentWrong";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import SettingsSimmer from "./SettingsSimmer";
import SelectDevice from "../../../utils/uiUtils/SelectDevice";

const Settings = () => {
  const { data: DeviceData, fetchDevice } = useGetDevice();
  const {
    getDeviceSettingsFunction,
    data: SettingsData,
    hasData,
    loading: settingsLoading,
  } = useGetDeviceSettings();

  const {
    deviceLoginUser,

    error: loginError,
    errorData: loginErrorData,
    isLoggedIn,
  } = useDeviceLogin();

  const {
    updateDeviceSettingsFunction,

    error: updateError,
    isUpdated,
    errorData: updateErrorData,
  } = useUpdateDeviceSettings();
  const {
    createDeviceSettingsFunction,

    error: createError,
    isCreated,
    errorData: createErrorData,
  } = useCreateDeviceSettings();

  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === "dark";
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [loginFlag, setLoginFlag] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);

  useEffect(() => {
    const getDeviceData: GetDeviceProps = {
      page: "1",
      limit: "1000",
      businessId: localStorage.getItem("businessId") ?? "",
      includeReplacedDevice: false,
    };

    fetchDevice(getDeviceData);
  }, []);

  useEffect(() => {
    if (
      selectedDeviceId !== "" &&
      selectedDeviceId !== undefined &&
      selectedDeviceId !== null
    ) {
      if (isLoggedIn && loginFlag) {
        const getSettingsData = {
          deviceId: selectedDeviceId,
        };

        getDeviceSettingsFunction(getSettingsData);
      } else if (loginError && loginFlag) {
        notifications.show({
          title: "Error",
          message: `Login Error : ${loginErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    }
  }, [isLoggedIn, loginError]);

  const handleDeviceChange = (event: any) => {
    setSelectedDeviceId(event);

    const loginData = {
      deviceId: event,
      token: localStorage.getItem("access_token") ?? "",
    };
    deviceLoginUser(loginData);
    setLoginFlag(true);
  };

  useEffect(() => {
    if (SettingsData !== undefined && SettingsData !== null) {
      form.setValues({
        deviceId: selectedDeviceId,
        CloudBuzzerStatus: SettingsData?.CloudBuzzerStatus,
        HFStatus: SettingsData?.HFStatus,
        LogoStatus: SettingsData?.LogoStatus,
        TokenLogoStatus: SettingsData?.TokenLogoStatus,
        BillResetStatus: SettingsData?.BillResetStatus,
        ItemDiscountStatus: SettingsData?.ItemDiscountStatus,
        DiscountStatus: SettingsData?.DiscountStatus,
        DuplicateBillStatus: SettingsData?.DuplicateBillStatus,
        CashCalculatorStatus: SettingsData?.CashCalculatorStatus,
        RoundOff: SettingsData?.RoundOff,
        PaymentStatus: SettingsData?.PaymentStatus,
        MergeStatus: SettingsData?.MergeStatus,
        BuzzerStatus: SettingsData?.BuzzerStatus,
        BillSaveStatus: SettingsData?.BillSaveStatus,
        HSN: SettingsData?.HSN,
        SerialNumStatus: SettingsData?.SerialNumStatus,
        SubtotalStatus: SettingsData?.SubtotalStatus,
        ReversePritingStatus: SettingsData?.ReversePritingStatus,
        CustomerNameStatus: SettingsData?.CustomerNameStatus,
        GSTStatus: SettingsData?.GSTStatus,
        ReverseTaxStatus: SettingsData?.ReverseTaxStatus,
        UniterritoryStatus: SettingsData?.UniterritoryStatus,
        GSTTypeStatus: SettingsData?.GSTTypeStatus,
        BasePrice: SettingsData?.BasePrice,
        KOTStatus: SettingsData?.KOTStatus,
        KOTTokenPrint: SettingsData?.KOTTokenPrint,
        KOTTokenDelete: SettingsData?.KOTTokenDelete,
        WaiterStatus: SettingsData?.WaiterStatus,
        GuestStatus: SettingsData?.GuestStatus,
        TokenHFStatus: SettingsData?.TokenHFStatus,
        StockStatus: SettingsData?.StockStatus,
        BillWithoutStock: SettingsData?.BillWithoutStock,
        ChickenToken: SettingsData?.ChickenToken,
        ConsolidatedVatStatus: SettingsData?.ConsolidatedVatStatus,
      });
    }
  }, [SettingsData]);

  useEffect(() => {
    if (selectedDeviceId !== "") {
      if (isUpdated && updateFlag) {
        notifications.show({
          title: "Success",
          message: "Settings Updated Successfully",
          color: "green",
          icon: <IconCheck size="1.1rem" />,
        });
        const getSettingsData = {
          deviceId: selectedDeviceId,
        };

        getDeviceSettingsFunction(getSettingsData);
      } else if (updateError && updateFlag) {
        notifications.show({
          title: "Error",
          message: `Settings Update Error :${updateErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    }
  }, [isUpdated, updateError]);

  useEffect(() => {
    if (selectedDeviceId !== "") {
      if (isCreated && createFlag) {
        notifications.show({
          title: "Success",
          message: "Settings Created Successfully",
          color: "green",
          icon: <IconCheck size="1.1rem" />,
        });
        const getSettingsData = {
          deviceId: selectedDeviceId,
        };

        getDeviceSettingsFunction(getSettingsData);
      } else if (createError && createFlag) {
        notifications.show({
          title: "Error",
          message: `Settings Creation Error :${createErrorData}`,
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    }
  }, [isCreated, createError]);

  const form = useForm({
    initialValues: {
      deviceId: selectedDeviceId,
      CloudBuzzerStatus: SettingsData?.CloudBuzzerStatus,
      HFStatus: SettingsData?.HFStatus,
      LogoStatus: SettingsData?.LogoStatus,
      TokenLogoStatus: SettingsData?.TokenLogoStatus,
      BillResetStatus: SettingsData?.BillResetStatus,
      ItemDiscountStatus: SettingsData?.ItemDiscountStatus,
      DiscountStatus: SettingsData?.DiscountStatus,
      DuplicateBillStatus: SettingsData?.DuplicateBillStatus,
      CashCalculatorStatus: SettingsData?.CashCalculatorStatus,
      RoundOff: SettingsData?.RoundOff,
      PaymentStatus: SettingsData?.PaymentStatus,
      MergeStatus: SettingsData?.MergeStatus,
      BuzzerStatus: SettingsData?.BuzzerStatus,
      BillSaveStatus: SettingsData?.BillSaveStatus,
      HSN: SettingsData?.HSN,
      SerialNumStatus: SettingsData?.SerialNumStatus,
      SubtotalStatus: SettingsData?.SubtotalStatus,
      ReversePritingStatus: SettingsData?.ReversePritingStatus,
      CustomerNameStatus: SettingsData?.CustomerNameStatus,
      GSTStatus: SettingsData?.GSTStatus,
      ReverseTaxStatus: SettingsData?.ReverseTaxStatus,
      UniterritoryStatus: SettingsData?.UniterritoryStatus,
      GSTTypeStatus: SettingsData?.GSTTypeStatus,
      BasePrice: SettingsData?.BasePrice,
      KOTStatus: SettingsData?.KOTStatus,
      KOTTokenPrint: SettingsData?.KOTTokenPrint,
      KOTTokenDelete: SettingsData?.KOTTokenDelete,
      WaiterStatus: SettingsData?.WaiterStatus,
      GuestStatus: SettingsData?.GuestStatus,
      TokenHFStatus: SettingsData?.TokenHFStatus,
      StockStatus: SettingsData?.StockStatus,
      BillWithoutStock: SettingsData?.BillWithoutStock,
      ChickenToken: SettingsData?.ChickenToken,
      ConsolidatedVatStatus: SettingsData?.ConsolidatedVatStatus,
    },
  });

  const deviceData = (): { value: string; label: string }[] => {
    let data: any = DeviceData?.docs
      ?.filter((item: any) => item?.outletId)
      ?.map((item: any) => {
        return {
          value: item?._id ?? "",
          label: `${
            item?.userDeviceName
              ? item.userDeviceName + " [" + item.serialNo + "]"
              : item?.serialNo ?? ""
          }`,
        };
      });
    return data === undefined ? [] : data;
  };

  const handleSettingsUpdate = () => {
    if (
      selectedDeviceId !== null &&
      selectedDeviceId !== undefined &&
      selectedDeviceId !== "" &&
      form.values !== null &&
      form.values !== undefined
    ) {
      if (hasData) {
        let values = form.values;
        if (values.KOTStatus && values.StockStatus) {
          values.BillWithoutStock = true;
        }
        updateDeviceSettingsFunction(values);
        setUpdateFlag(true);
      } else if (!hasData) {
        let values = form.values;
        if (
          values.CloudBuzzerStatus === undefined ||
          values.CloudBuzzerStatus === null
        ) {
          values.CloudBuzzerStatus = true;
        }
        if (
          values.BillResetStatus === undefined ||
          values.BillResetStatus === null
        ) {
          values.BillResetStatus = true;
        }
        if (values.BuzzerStatus === undefined || values.BuzzerStatus === null) {
          values.BuzzerStatus = true;
        }
        if (
          values.SubtotalStatus === undefined ||
          values.SubtotalStatus === null
        ) {
          values.SubtotalStatus = true;
        }
        if (values.RoundOff === undefined || values.RoundOff === null) {
          values.RoundOff = true;
        }
        if (
          values.ReversePritingStatus === undefined ||
          values.ReversePritingStatus === null
        ) {
          values.ReversePritingStatus = true;
        }
        if (values.MergeStatus === undefined || values.MergeStatus === null) {
          values.MergeStatus = true;
        }
        if (values.HFStatus === undefined || values.HFStatus === null) {
          values.HFStatus = false;
        }
        if (values.LogoStatus === undefined || values.LogoStatus === null) {
          values.LogoStatus = false;
        }
        if (
          values.ItemDiscountStatus === undefined ||
          values.ItemDiscountStatus === null
        ) {
          values.ItemDiscountStatus = false;
        }
        if (
          values.DiscountStatus === undefined ||
          values.DiscountStatus === null
        ) {
          values.DiscountStatus = false;
        }
        if (
          values.DuplicateBillStatus === undefined ||
          values.DuplicateBillStatus === null
        ) {
          values.DuplicateBillStatus = false;
        }
        if (
          values.CashCalculatorStatus === undefined ||
          values.CashCalculatorStatus === null
        ) {
          values.CashCalculatorStatus = false;
        }
        if (
          values.PaymentStatus === undefined ||
          values.PaymentStatus === null
        ) {
          values.PaymentStatus = false;
        }
        if (
          values.BillSaveStatus === undefined ||
          values.BillSaveStatus === null
        ) {
          values.BillSaveStatus = false;
        }
        if (values.HSN === undefined || values.HSN === null) {
          values.HSN = false;
        }
        if (
          values.SerialNumStatus === undefined ||
          values.SerialNumStatus === null
        ) {
          values.SerialNumStatus = false;
        }
        if (
          values.CustomerNameStatus === undefined ||
          values.CustomerNameStatus === null
        ) {
          values.CustomerNameStatus = false;
        }
        if (values.GSTStatus === undefined || values.GSTStatus === null) {
          values.GSTStatus = false;
        }
        if (
          values.ReverseTaxStatus === undefined ||
          values.ReverseTaxStatus === null
        ) {
          values.ReverseTaxStatus = false;
        }
        if (
          values.UniterritoryStatus === undefined ||
          values.UniterritoryStatus === null
        ) {
          values.UniterritoryStatus = false;
        }
        if (
          values.GSTTypeStatus === undefined ||
          values.GSTTypeStatus === null
        ) {
          values.GSTTypeStatus = false;
        }
        if (values.BasePrice === undefined || values.BasePrice === null) {
          values.BasePrice = false;
        }
        if (values.KOTStatus === undefined || values.KOTStatus === null) {
          values.KOTStatus = false;
        }
        if (
          values.KOTTokenPrint === undefined ||
          values.KOTTokenPrint === null
        ) {
          values.KOTTokenPrint = false;
        }
        if (
          values.KOTTokenDelete === undefined ||
          values.KOTTokenDelete === null
        ) {
          values.KOTTokenDelete = false;
        }
        if (values.WaiterStatus === undefined || values.WaiterStatus === null) {
          values.WaiterStatus = false;
        }
        if (values.GuestStatus === undefined || values.GuestStatus === null) {
          values.GuestStatus = false;
        }
        if (
          values.TokenHFStatus === undefined ||
          values.TokenHFStatus === null
        ) {
          values.TokenHFStatus = false;
        }
        if (values.StockStatus === undefined || values.StockStatus === null) {
          values.StockStatus = false;
        }

        if (
          values.BillWithoutStock === undefined ||
          values.BillWithoutStock === null ||
          values.BillWithoutStock === false
        ) {
          if (values.KOTStatus && values.StockStatus) {
            values.BillWithoutStock = true;
          } else if (
            values.BillWithoutStock === undefined ||
            values.BillWithoutStock === null
          ) {
            values.BillWithoutStock = false;
          }
        }
        if (values.ChickenToken === undefined || values.ChickenToken === null) {
          values.ChickenToken = false;
        }
        if (
          values.ConsolidatedVatStatus === undefined ||
          values.ConsolidatedVatStatus === null
        ) {
          values.ConsolidatedVatStatus = false;
        }
        if (
          values.TokenLogoStatus === undefined ||
          values.TokenLogoStatus === null
        ) {
          values.TokenLogoStatus = false;
        }
        createDeviceSettingsFunction(values);
        setCreateFlag(true);
      }
    }
  };

  const handleValueChange = (value: any, field: any) => {
    if (field === "BillWithoutStock") {
      if (form.values.KOTStatus && form.values.StockStatus) {
        form.setFieldValue(field, true);
      } else {
        form.setFieldValue(field, value);
      }
    } else {
      form.setFieldValue(field, value);
    }
  };

  function generateCardAndState(
    label: any,
    state: boolean,
    fieldValue: any,
    description: any,
    defaultForNewDevice: boolean,
    handleValueChange?: any
  ) {
    const words = description?.split(/\s+/);

    const filteredWords = words.filter((word: any) => word.trim() !== "");
    const firstPart = filteredWords?.slice(0, 4).join(" ");
    const secondPart = filteredWords?.slice(4).join(" ");

    const checkedState = () => {
      if (!hasData && state === undefined) {
        return defaultForNewDevice;
      } else if (state === undefined) {
        return false;
      } else {
        return state;
      }
    };
    const newState = checkedState();

    return (
      <Grid.Col
        span={{ lg: 6, xl: 4, md: 8, sm: 10, xs: 12 }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          withBorder
          w={500}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            gap: "10px",
          }}
          pl={35}
          pr={30}
        >
          <Group style={{ display: "flex", flexDirection: "column" }}>
            <Text
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "18px",
                fontWeight: "bolder",
              }}
            >
              {label}
            </Text>
            <Text
              style={{
                fontSize: "14px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                opacity: "0.8",
              }}
            >
              {firstPart}
              <br />
              {secondPart}
            </Text>
            {fieldValue === "BillWithoutStock" && (
              <Text fz={13} c={colorScheme === "dark" ? "#FFFF00" : "green"}>
                Note:- If KOT and Stock enabled,Bill without stock is enabled.
              </Text>
            )}
          </Group>
          <Group>
            <Switch
              classNames={{
                thumb:
                  colorScheme === "dark" && newState === false
                    ? classes.switchButtonInactive
                    : colorScheme === "dark" && newState === undefined
                    ? classes.switchButtonInactive
                    : classes.switchButtonActive,
              }}
              size="md"
              label=""
              checked={newState}
              onChange={(e) =>
                handleValueChange(e.currentTarget.checked, fieldValue)
              }
            />
          </Group>
        </Card>
      </Grid.Col>
    );
  }

  return (
    <>
      <Helmet title="Settings" />
      <Grid className={classes.mainGrid}>
        <Grid.Col className={classes.mainTitleCol}>
          <Text className={classes.mainTitle}>Settings</Text>
        </Grid.Col>
        <Divider style={{ width: "100%" }} />
        <form
          style={{ width: "95%", margin: "8px" }}
          onSubmit={form.onSubmit(() => handleSettingsUpdate())}
        >
          <Grid className={classes.mainNav} mb="sm" p={0}>
            <Grid.Col span={{ xl: 6, md: 6, lg: 6, sm: 12, xs: 12 }}>
              <Select
                w={{
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "330px",
                  xl: "330px",
                }}
                allowDeselect={false}
                radius="md"
                classNames={{
                  label: classes.mantineLabel,
                }}
                label="Devices"
                onChange={handleDeviceChange}
                placeholder="Select Device"
                data={deviceData()}
              ></Select>
            </Grid.Col>
            <Grid.Col
              span={{ xl: 6, md: 6, lg: 6, sm: 12, xs: 12 }}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                mt={{
                  xl: "10px",
                  lg: "10px",
                  md: "10px",
                  sm: "0px",
                  xs: "0px",
                }}
                style={{
                  display: !selectedDeviceId || loginError ? "none" : "block",
                }}
                type="submit"
              >
                Update
              </Button>
            </Grid.Col>
          </Grid>

          <Card
            withBorder
            className={classes.settingsCard}
            style={{
              backgroundColor: isDark ? "#272B2F" : "#f2f3f5d3",
            }}
          >
            {selectedDeviceId !== "" ? (
              <>
                {loginError ? (
                  <SomethingWentWrong />
                ) : (
                  <>
                    {settingsLoading ? (
                      <Grid className={classes.settingsGrid}>
                        <Grid.Col>
                          <Text
                            style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Cloud Settings
                          </Text>
                          <SettingsSimmer />
                        </Grid.Col>

                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          >
                            Device Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsDataCol}>
                          <Grid>
                            {[...Array(18)].map((_, index) => (
                              <SettingsSimmer key={index} />
                            ))}
                          </Grid>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bolder" }}
                          >
                            GST Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsDataCol}>
                          <Grid>
                            {[...Array(6)].map((_, index) => (
                              <SettingsSimmer key={index} />
                            ))}
                          </Grid>
                        </Grid.Col>

                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bolder" }}
                          >
                            KOT Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsDataCol}>
                          <Grid>
                            {[...Array(7)].map((_, index) => (
                              <SettingsSimmer key={index} />
                            ))}
                          </Grid>
                        </Grid.Col>

                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bolder" }}
                          >
                            Stock Settings
                          </Text>
                        </Grid.Col>
                        <Divider style={{ marginTop: "10px" }} />
                        <Grid.Col
                          className={classes.settingsDataCol}
                          style={{ marginBottom: "50px" }}
                        >
                          {[...Array(2)].map((_, index) => (
                            <SettingsSimmer key={index} />
                          ))}
                        </Grid.Col>
                      </Grid>
                    ) : (
                      <Grid className={classes.settingsGrid}>
                        <Grid.Col>
                          <Text
                            style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Cloud Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12, sm: 12, md: 11.5 }}>
                          {generateCardAndState(
                            "Cloud Buzzer Status",
                            form.values.CloudBuzzerStatus,
                            "CloudBuzzerStatus",
                            "Enables the buzzer on successful sync.",
                            true,
                            handleValueChange
                          )}
                        </Grid.Col>

                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          >
                            Device Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsDataCol}>
                          <Grid>
                            {generateCardAndState(
                              "Header Footer Status",
                              form.values.HFStatus,
                              "HFStatus",
                              "To print Header and Footer on Bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Logo Status",
                              form.values.LogoStatus,
                              "LogoStatus",
                              "This option prints Logo on Bill print.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Bill Reset Status",
                              form.values.BillResetStatus,
                              "BillResetStatus",
                              "It resets the Bill number from 1 daily.",
                              true,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Discount Per Item Status",
                              form.values.ItemDiscountStatus,
                              "ItemDiscountStatus",
                              "This option allows for individual item discount.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Discount Status",
                              form.values.DiscountStatus,
                              "DiscountStatus",
                              "This option allows for give discounts on bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Duplicate Bill Status",
                              form.values.DuplicateBillStatus,
                              "DuplicateBillStatus",
                              "Allows user to print duplicate copy of current bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Cash Calculator Status",
                              form.values.CashCalculatorStatus,
                              "CashCalculatorStatus",
                              "Assists user for cash returns.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Round-off Status",
                              form.values.RoundOff,
                              "RoundOff",
                              "Rounds off the total amount to 50 paise.",
                              true,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Payment Mode Status",
                              form.values.PaymentStatus,
                              "PaymentStatus",
                              "Before finalization of bill, lists the Mode of Payments.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Qty Merge Status",
                              form.values.MergeStatus,
                              "MergeStatus",
                              "Quantity of the Item gets merge.",
                              true,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Buzzer Status",
                              form.values.BuzzerStatus,
                              "BuzzerStatus",
                              "Enables Buzzer on key stroke.",
                              true,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Bill Save Status",
                              form.values.BillSaveStatus,
                              "BillSaveStatus",
                              "Can skip printing during billing.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "HSN Status",
                              form.values.HSN,
                              "HSN",
                              "Items HSN code Prints on the Cash bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Serial Number Status",
                              form.values.SerialNumStatus,
                              "SerialNumStatus",
                              "Sl.no is printed before each item on the cash bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Subtotal Print Status",
                              form.values.SubtotalStatus,
                              "SubtotalStatus",
                              "Prints Subtotal of all items on the Cash bill.",
                              true,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Reverse Printing Status",
                              form.values.ReversePritingStatus,
                              "ReversePritingStatus",
                              "Does Reverse feed before printing.",
                              true,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Manual Token Status",
                              form.values.ChickenToken,
                              "ChickenToken",
                              "Allows to enter the Token no before bill finalization.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Customer Name Status",
                              form.values.CustomerNameStatus,
                              "CustomerNameStatus",
                              "Allows to enter the customer's name before bill prints.",
                              false,
                              handleValueChange
                            )}
                          </Grid>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bolder" }}
                          >
                            GST Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsDataCol}>
                          <Grid>
                            {generateCardAndState(
                              "GST Status",
                              form.values.GSTStatus,
                              "GSTStatus",
                              "Considers GST calculation during the bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Reverse GST Status",
                              form.values.ReverseTaxStatus,
                              "ReverseTaxStatus",
                              "Considers Reverse GST calculation.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Base price",
                              form.values.BasePrice,
                              "BasePrice",
                              "Prints taxable amount of each item.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Consolidated GST Status",
                              form.values.ConsolidatedVatStatus,
                              "ConsolidatedVatStatus",
                              "Items Gst gets consolidate & prints in the cash bill.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Union Territory Status",
                              form.values.UniterritoryStatus,
                              "UniterritoryStatus",
                              "Configures the business is in a Union Territory.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "GST Selection Status",
                              form.values.GSTTypeStatus,
                              "GSTTypeStatus",
                              "Allows to select the type of GST.",
                              false,
                              handleValueChange
                            )}
                          </Grid>
                        </Grid.Col>

                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bolder" }}
                          >
                            KOT Settings
                          </Text>
                        </Grid.Col>
                        <Grid.Col className={classes.settingsDataCol}>
                          <Grid>
                            {generateCardAndState(
                              "KOT Status",
                              form.values.KOTStatus,
                              "KOTStatus",
                              "It enables KOT functionality.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "KOT Token Print",
                              form.values.KOTTokenPrint,
                              "KOTTokenPrint",
                              "This option allows to print token.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "KOT Token Delete",
                              form.values.KOTTokenDelete,
                              "KOTTokenDelete",
                              "Allows cashier to delete or edit items added in KOT Token.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "UDN Status",
                              form.values.WaiterStatus,
                              "WaiterStatus",
                              "Allows to select Waiter details in Table Orders.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Guest Status",
                              form.values.GuestStatus,
                              "GuestStatus",
                              "Allows to set Guest number during Table Order.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Token HF Status",
                              form.values.TokenHFStatus,
                              "TokenHFStatus",
                              "Print Header and Footer on KOT Token.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Token Logo Status",
                              form.values.TokenLogoStatus,
                              "TokenLogoStatus",
                              "Prints Logo on KOT Token.",
                              false,
                              handleValueChange
                            )}
                          </Grid>
                        </Grid.Col>

                        <Grid.Col className={classes.settingsGridCol}>
                          <Text
                            style={{ fontSize: "25px", fontWeight: "bolder" }}
                          >
                            Stock Settings
                          </Text>
                        </Grid.Col>
                        <Divider style={{ marginTop: "10px" }} />
                        <Grid.Col>
                          <Grid>
                            {generateCardAndState(
                              "Stock Status",
                              form.values.StockStatus,
                              "StockStatus",
                              "This option enables maintaining stock for items.",
                              false,
                              handleValueChange
                            )}
                            {generateCardAndState(
                              "Bill Without Stock",
                              form.values.KOTStatus && form.values.StockStatus
                                ? true
                                : form.values.BillWithoutStock,
                              "BillWithoutStock",
                              "Allows billing even if items are out of stock.",
                              false,
                              handleValueChange
                            )}
                          </Grid>
                        </Grid.Col>
                      </Grid>
                    )}
                  </>
                )}
              </>
            ) : (
              <Grid.Col
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <SelectDevice />
              </Grid.Col>
            )}
          </Card>
        </form>
      </Grid>
    </>
  );
};

export default Settings;
