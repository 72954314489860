import { Card, Text, Button, Grid, useMantineColorScheme } from "@mantine/core";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import moment from "moment";

export interface DeviceProps {
  _id: string;
  serialNo: string;
  productModelName: string;
  warrantyExpireDate: string;
  userDeviceName: string;
  key: string;
  subscriptionExpiry: string;
  outletName: string;
  itemListName?: string;
  itemListDetail?: any;
  outletDetail?: any;
}

export interface DeviceFunctionProps {
  data: DeviceProps;
  handleDeviceLogin: (data: any, type: string) => void;
  handleOutletMapping: (device: any) => void;
}

export function DeviceCard({
  data,
  handleDeviceLogin,
  handleOutletMapping,
}: DeviceFunctionProps) {
  const { colorScheme } = useMantineColorScheme();

  const warrantyExpired = () => {
    const warrantyExpireDate = moment
      .utc(data?.warrantyExpireDate)
      .startOf("day");
    const currentDate = moment().startOf("day");

    if (warrantyExpireDate.isBefore(currentDate)) {
      return true;
    }
    return false;
  };

  const subscriptionExpired = () => {
    const subscriptionExpiryDate = moment
      .utc(data?.subscriptionExpiry)
      .startOf("day");
    const currentDate = moment().startOf("day");

    if (subscriptionExpiryDate.isBefore(currentDate)) {
      return true;
    }
    return false;
  };

  const warrantyExpiredState = warrantyExpired();
  const subscriptionExpiredState = subscriptionExpired();
  const isDark = colorScheme === "dark";
  const spanValue = { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 };
  let warrantyDate = moment(data.warrantyExpireDate).format("DD-MM-YYYY");
  let subscriptionDate = moment(data.subscriptionExpiry).format("DD-MM-YYYY");

  const titles = {
    serialNo: "Device Serial No",
    key: "Device Key",
    deviceName: "Device Name",
    productModelName: "Product Model Name",
    warranty: "Warranty Expiry",
    subscription: "Subscription Expiry",
    itemList: "Itemlist Name",
    outlet: "Outlet Name",
  };

  const componentStyles = {
    cardBackgroundColor: isDark ? "#272B2F" : "#F5F6FA",
    buttonBackgroundColor: isDark ? "rgba(187, 190, 201, 0.863)" : "#fff",
    warrantyTextColor: warrantyExpiredState
      ? "rgb(238, 21, 14)"
      : "rgb(14, 238, 14)",
    subscriptionTextColor: subscriptionExpiredState
      ? "rgb(238, 21, 14)"
      : "rgb(14, 238, 14)",
    mainGrid: {
      paddingTop: "30px",
      display: "flex",
      justifyContent: "space-evenly",
    },
    buttonListGridCol: {
      backgroundColor: isDark ? "#272b2fb7" : "#e6e7ebf6",
    },
  };

  return (
    <>
      <Card
        withBorder
        style={{
          backgroundColor: componentStyles.cardBackgroundColor,
        }}
        className={classes.deviceComponentCard}
      >
        <Grid style={componentStyles.mainGrid}>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              SERIAL NO
            </Text>
            <Text title={titles.serialNo} fz="md" fw={700}>
              {data.serialNo}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Key
            </Text>
            <Text title={titles.key} fz="md" fw={700}>
              {data.key ?? "No Key"}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Device Name
            </Text>
            <Text title={titles.deviceName} fz="md" fw={700}>
              {data.userDeviceName}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Model
            </Text>
            <Text title={titles.productModelName} fz="md" fw={700}>
              {data.productModelName}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Warranty Expiry
            </Text>
            <Text
              style={{
                color: componentStyles.warrantyTextColor,
              }}
              title={titles.warranty}
              fz="md"
              fw={700}
            >
              {warrantyDate ?? "No Warranty"}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Subscription Expiry
            </Text>
            <Text
              style={{
                color: componentStyles.subscriptionTextColor,
              }}
              title={titles.subscription}
              fz="md"
              fw={700}
            >
              {subscriptionDate ?? "No subscription"}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Itemlist Name
            </Text>

            <Text
              pb={data.itemListName ? 0 : 25}
              title={titles.itemList}
              fz="md"
              fw={700}
            >
              {data?.itemListDetail?.[0]?.itemListName ?? " "}
            </Text>
          </Grid.Col>
          <Grid.Col className={classes.deviceTitles} span={spanValue}>
            <Text c="dimmed" fz="xs">
              Outlet Name
            </Text>
            <Text
              pb={data.outletName ? 0 : 25}
              title={titles.outlet}
              style={{ whiteSpace: "pre-wrap" }}
              fz="md"
              fw={700}
            >
              {data?.outletDetail?.[0]?.outletName ?? " "}
            </Text>
          </Grid.Col>
        </Grid>

        <Grid className={classes.buttonListGrid}>
          <Grid.Col
            mt={{ xl: 5 }}
            mb={{ xl: 5 }}
            style={componentStyles.buttonListGridCol}
            className={classes.buttonListGridCol}
          >
            <Button
              onClick={() => {
                handleDeviceLogin(data, "name");
              }}
              size="xs"
              style={{
                backgroundColor: componentStyles.buttonBackgroundColor,
                textDecoration: "none",
                color: "black",
              }}
            >
              NAME
            </Button>
            <Button
              size="xs"
              style={{
                backgroundColor: componentStyles.buttonBackgroundColor,
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => {
                handleDeviceLogin(data, "logo");
              }}
            >
              LOGO
            </Button>

            <Button
              size="xs"
              style={{
                backgroundColor: componentStyles.buttonBackgroundColor,
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => {
                handleOutletMapping(data);
              }}
            >
              OUTLET
            </Button>
            <Button
              size="xs"
              style={{
                backgroundColor: componentStyles.buttonBackgroundColor,
                textDecoration: "none",
                color: "black",
              }}
              fs={{ xs: "xs", sm: "xs", md: "xs", lg: "xs", xl: "xl" }}
              onClick={() => {
                handleDeviceLogin(data, "itemList");
              }}
            >
              ITEMLIST
            </Button>

            <Button
              size="xs"
              style={{
                backgroundColor: componentStyles.buttonBackgroundColor,
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => {
                handleDeviceLogin(data, "tc");
              }}
            >
              TC
            </Button>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}
