import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";
import {
  DeviceService,
  GetDeviceProps,
  ActivateDeviceProps,
  UpdateDeviceNameProps,
  GetWarrantyTCProps,
  GetHeaderFooterProps,
  GetSettingsProps,
  MapToItemListProps,
  MapToOutletProps,
  SettingsProps,
  HeaderFooterProps,
} from "./service/deviceService";

export const fetch = createAsyncThunk(
  "device/fetch",
  async (deviceData: GetDeviceProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.getDevice(deviceData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const create = createAsyncThunk(
  "device/activate",
  async (deviceData: ActivateDeviceProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.activateDevice(deviceData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const update = createAsyncThunk(
  "device/updateName",
  async (deviceData: UpdateDeviceNameProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.updateDeviceName(deviceData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const mapToOutlet = createAsyncThunk(
  "device/mapToOutlet",
  async (mapOutletData: MapToOutletProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.mapToOutlet(mapOutletData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const mapToItemList = createAsyncThunk(
  "device/mapToItemList",
  async (mapItemListData: MapToItemListProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.mapToItemList(mapItemListData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getDeviceSettings = createAsyncThunk(
  "device/getDeviceSettings",
  async (settingsData: GetSettingsProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.getSettings(settingsData);
      return response[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const createDeviceSettings = createAsyncThunk(
  "device/createDeviceSettings",
  async (settingsData: SettingsProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.createSettings(settingsData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const updateDeviceSettings = createAsyncThunk(
  "device/updateDeviceSettings",
  async (settingsData: SettingsProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.updateSettings(settingsData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getHeaderFooter = createAsyncThunk(
  "device/getHeaderFooter",
  async (getHeaderFooterData: GetHeaderFooterProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.getHeaderFooter(getHeaderFooterData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const createHeaderFooter = createAsyncThunk(
  "device/createHeaderFooter",
  async (createHeaderFooterData: HeaderFooterProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.createHeaderFooter(
        createHeaderFooterData
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const updateHeaderFooter = createAsyncThunk(
  "device/updateHeaderFooter",
  async (updateHeaderFooterData: HeaderFooterProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.updateHeaderFooter(
        updateHeaderFooterData
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getWarrantyTC = createAsyncThunk(
  "device/getWarrantyTC",
  async (warrantyData: GetWarrantyTCProps, thunkAPI) => {
    try {
      const deviceService = new DeviceService(APIPATH);
      const response = await deviceService.getWarrantyTC(warrantyData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
