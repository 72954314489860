import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";
import {
  BillRefundProps,
  BillWiseProps,
  DayWiseProps,
  GSTSummaryProps,
  GetAggregateReportProps,
  GetBillRefundSummaryReport,
  GetBillWiseSummaryReport,
  ItemWiseProps,
  MonthlyWiseProps,
  ReportService,
  UserReportProps,
} from "./service/reportService";

export const getDashboardData = createAsyncThunk(
  "report/getDashboardData",
  async (thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getDashboardData();
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getBillWise = createAsyncThunk(
  "report/getBillWise",
  async (billwiseData: BillWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getBillWiseReport(billwiseData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getDayWise = createAsyncThunk(
  "report/getDayWise",
  async (daywiseData: DayWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getDayWiseReport(daywiseData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getItemWise = createAsyncThunk(
  "report/getItemWise",
  async (itemWiseData: ItemWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getItemWiseReport(itemWiseData);

      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getMonthlyWise = createAsyncThunk(
  "report/getMonthlyWise",
  async (montlyWiseReportData: MonthlyWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getMonthlyWiseReport(
        montlyWiseReportData
      );
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getGSTSummary = createAsyncThunk(
  "report/getGSTSummary",
  async (GSTSummaryData: GSTSummaryProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getGSTSummaryReport(GSTSummaryData);

      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getSummary = createAsyncThunk(
  "report/getSummary",
  async (summaryData: GetBillWiseSummaryReport, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getSummary(summaryData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getBillRefundSummary = createAsyncThunk(
  "report/getBillRefundSummary",
  async (billRefundSummaryData: GetBillRefundSummaryReport, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getBillRefundSummary(
        billRefundSummaryData
      );

      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getUserWise = createAsyncThunk(
  "report/getUserWise",
  async (userReportData: UserReportProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getUserReport(userReportData);
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getVoidBillWise = createAsyncThunk(
  "report/getVoidBillWise",
  async (billwiseData: BillWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getVoidBillWiseReport(billwiseData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getBillRefund = createAsyncThunk(
  "report/getBillRefund",
  async (billRefundData: BillRefundProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getBillRefundReport(billRefundData);
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getAggregate = createAsyncThunk(
  "report/getAggregate",
  async (getAggregateData: GetAggregateReportProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getAggregateReport(getAggregateData);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadBillWiseReports = createAsyncThunk(
  "report/downloadBillWiseReport",
  async (billwiseData: BillWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getBillWiseReport(
        billwiseData
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadDayWiseReports = createAsyncThunk(
  "report/downloadDayWiseReport",
  async (daywiseData: DayWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getDayWiseReport(daywiseData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadItemWiseReports = createAsyncThunk(
  "report/downloadItemWiseReport",
  async (itemWiseData: ItemWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getItemWiseReport(itemWiseData);

      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadMonthlyWiseReports = createAsyncThunk(
  "report/downloadMonthlyWiseReport",
  async (montlyWiseReportData: MonthlyWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getMonthlyWiseReport(
        montlyWiseReportData
      );
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadGSTSummaryReports = createAsyncThunk(
  "report/downloadGSTReport",
  async (GSTSummaryData: GSTSummaryProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getGSTSummaryReport(GSTSummaryData);

      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadSummaryReports = createAsyncThunk(
  "report/downloadSummaryReport",
  async (summaryData: GetBillWiseSummaryReport, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getSummary(summaryData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);


export const downloadUserWiseReports = createAsyncThunk(
  "report/downloadUserWiseReport",
  async (userReportData: UserReportProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getUserReport(userReportData);
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadVoidBillWiseReports = createAsyncThunk(
  "report/downloadVoidBillWiseReport",
  async (billwiseData: BillWiseProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getVoidBillWiseReport(billwiseData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadBillRefundReports = createAsyncThunk(
  "report/downloadBillRefundReport",
  async (billRefundData: BillRefundProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getBillRefundReport(billRefundData);
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const downloadAggregateReports = createAsyncThunk(
  "report/downloadAggregateReport",
  async (getAggregateData: GetAggregateReportProps, thunkAPI) => {
    try {
      const reportService = new ReportService(APIPATH);
      const response = await reportService.getAggregateReport(getAggregateData);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
