import {
  Accordion,
  Grid,
  Group,
  Select,
  Switch,
  TextInput,
} from "@mantine/core";
import React from "react";
import classes from "../../styleModules/AppShellMainStyles.module.css";

interface Props {
  isDark: boolean;
  index: number;
  form: any;
  header: any;
}

const HeaderComponent = ({ isDark, index, form, header }: Props) => {
  return (
    <div style={{ marginTop: "5px" }}>
      <Accordion.Item
        style={{
          backgroundColor: isDark ? "#191C24" : "#F0F2F5",
        }}
        value={`header-${index}`}
        key={index}
      >
        <Accordion.Control>Header {index + 1}</Accordion.Control>
        <Accordion.Panel>
          <Grid>
            <Grid.Col span={{ sm: 12, md: 6, lg: 6, xl: 6 }}>
              <TextInput
                maxLength={50}
                label="Text"
                placeholder="Enter Text"
                value={header.Text}
                onChange={(e) => {
                  form.setFieldValue(
                    `Header.${index}.Text`,
                    e.currentTarget.value
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6, lg: 6, xl: 6 }}>
              <Select
                allowDeselect={false}
                radius="md"
                label="Alignment"
                placeholder="Select Alignment"
                value={header.Alignment}
                // nothingFound="No options"
                onChange={(e) => {
                  form.setFieldValue(`Header.${index}.Alignment`, e);
                }}
                data={["Center", "Left"]}
              />
            </Grid.Col>
            <Grid.Col
              mt={{ sm: 10, md: 10, lg: 10, xl: 10 }}
              span={{ sm: 12, md: 6, lg: 6, xl: 6 }}
            >
              <Group>
                <Switch
                  classNames={{
                    thumb:
                      isDark && header.Height === false
                        ? classes.switchButtonInactive
                        : isDark && header.Height === undefined
                        ? classes.switchButtonInactive
                        : classes.switchButtonActive,
                  }}
                  defaultChecked={false}
                  labelPosition="left"
                  label="Height"
                  checked={form.values?.Header[index]?.Height}
                  onChange={(e) => {
                    form.setFieldValue(
                      `Header.${index}.Height`,
                      e.currentTarget.checked
                    );
                  }}
                />
                <Switch
                  classNames={{
                    thumb:
                      isDark && header.Width === false
                        ? classes.switchButtonInactive
                        : isDark && header.Width === undefined
                        ? classes.switchButtonInactive
                        : classes.switchButtonActive,
                  }}
                  labelPosition="left"
                  defaultChecked={false}
                  label="Width"
                  checked={form.values?.Header[index]?.Width}
                  onChange={(e) => {
                    form.setFieldValue(
                      `Header.${index}.Width`,
                      e.currentTarget.checked
                    );
                  }}
                />
              </Group>
            </Grid.Col>
          </Grid>
        </Accordion.Panel>
      </Accordion.Item>
    </div>
  );
};

export default HeaderComponent;
