import axios from "axios";
import { getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetItemProps {
  ItemListId: string;
  page: number;
  limit: number;
  IName?: string;
}

export interface ItemProps {
  ItemListId: string;
  isEnforce?: boolean;
  item: [
    {
      ICode: number;
      IName: string;
      Price: [
        items?: {
          Price?: number;
          IGST?: number;
          SGST?: number;
          CGST?: number;
        }
      ];

      DCode?: number;
      GCode?: number;
      SKU?: number;
      HSN?: number;
      DName?: number;
      GName?: number;
      Unit?: number;
      CP?: number;
      DiscPercent?: number;
      CESS1?: number;
      CESS2?: number;
      OpenPriceFlag?: boolean;
      DecimalDisableFlag?: boolean;
    }
  ];
}

export interface DeleteProps {
  itemId?: string | null;
  itemListId: string;
}

export class ItemService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getItem(itemData: GetItemProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item}?ItemListId=${itemData.ItemListId}&Limit=${itemData.limit}&Page=${itemData.page}&SortBy=Timestamp`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async createItem(itemData: ItemProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item}`;

      const response = await axios.post(url, itemData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateItem(itemData: ItemProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item}`;

      const response = await axios.put(url, itemData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async deleteItem(itemData: DeleteProps): Promise<any> {
    try {
      let header = getHeader();
      let url = `${this.baseUrl}${api_routes.api_path_item}?ItemListId=${itemData.itemListId}&itemId=${itemData.itemId}`;

      const response = await axios.delete(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async deleteAllItem(itemData: DeleteProps): Promise<any> {
    try {
      let header = getHeader();
      let url = `${this.baseUrl}${api_routes.api_path_item_delete_all}?ItemListId=${itemData.itemListId}`;

      const response = await axios.delete(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async searchItem(itemData: GetItemProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_item}?ItemListId=${itemData.ItemListId}&Limit=${itemData.limit}&Page=${itemData.page}&SortBy=Timestamp&IName=${itemData.IName}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

}

