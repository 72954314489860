import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";

import {
  GetBusinessProps,
  BusinessService,
  CreateBusinessProps,
  UpdateBusinessProps,
} from "./service/businessService";

export const fetch = createAsyncThunk(
  "business/fetch",
  async (businessData: GetBusinessProps, thunkAPI) => {
    try {
      const businessService = new BusinessService(APIPATH);
      const response = await businessService.getBusiness(businessData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const create = createAsyncThunk(
  "business/create",
  async (businessData: CreateBusinessProps, thunkAPI) => {
    try {
      const businessService = new BusinessService(APIPATH);
      const response = await businessService.createBusiness(businessData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const update = createAsyncThunk(
  "business/update",
  async (businessData: UpdateBusinessProps, thunkAPI) => {
    try {
      const businessService = new BusinessService(APIPATH);
      const response = await businessService.updateBusiness(businessData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
