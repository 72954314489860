import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AuthService,
  BusinessPayload,
  ForgotPasswordPayload,
  ResetPayload,
  SignInPayload,
  SignUpPayload,
  DeviceLoginPayload,
  EditUserPayload,
} from "./service/authService";
import { APIPATH } from "../../utils/utils";

export const signIn = createAsyncThunk(
  "user/login",
  async (loginData: SignInPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.signIn(loginData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const isLoggedIn = createAsyncThunk(
  "user/isLoggedIn",
  async (thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH); //here it does not matter if you dont pass the uri
      const response = await userService.isLoggedIn();
      return response;
    } catch (error) {
      throw new Error("Failed to register");
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (userId: string, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.getProfile(userId);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response);
    }
  }
);

export const editUserProfile = createAsyncThunk(
  "user/editProfile",
  async (editProfileData: EditUserPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.editUserProfile(editProfileData);
      return response;
    } catch (error: any) {
      throw new Error(error.response);
    }
  }
);

export const businessSignIn = createAsyncThunk(
  "user/businessLogin",
  async (data: BusinessPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.businessSignIn(data);
      return response;
    } catch (error) {
      throw new Error("Failed to login");
    }
  }
);

export const deviceLogin = createAsyncThunk(
  "user/deviceLogin",
  async (credentials: DeviceLoginPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.deviceLogin(credentials);
      return response;
    } catch (error) {
      throw new Error("Failed to register");
    }
  }
);

export const signUp = createAsyncThunk(
  "user/register",
  async (registerData: SignUpPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.signUp(registerData);
      return response;
    } catch (error:any) {
      throw new Error(error);
    }
  }
);

export const authenticator = createAsyncThunk(
  "user/authenticate",
  async (thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.authenticate();
      return response;
    } catch (error: any) {
      throw new Error(error.response);
    }
  }
);

export const editPassword = createAsyncThunk(
  "user/editPassword",
  async (editPasswordData: ResetPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.editPassword(editPasswordData);
      return response;
    } catch (error: any) {
      throw new Error(error.response);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (resetData: ForgotPasswordPayload, thunkAPI) => {
    try {
      const userService = new AuthService(APIPATH);
      const response = await userService.forgotPassword(resetData);
      return response;
    } catch (error: any) {
      throw new Error(error.response);
    }
  }
);

export const logout = createAsyncThunk("user/logout", async (thunkAPI) => {
  try {
    const userService = new AuthService(APIPATH); //here it does not matter if you dont pass the uri
    const response = await userService.logout();
    return response;
    // if (response === true) {
    // }
  } catch (error) {
    throw new Error("Logout Failed");
  }
});
