import { createSlice } from "@reduxjs/toolkit";
import { fetch,create,update} from "./itemListActions";


interface FetchItemListState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
}

interface CreateItemListState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
    isCreated:boolean
}

interface UpdateItemListState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
    isUpdated:boolean
}



const initialFetchState: FetchItemListState = {
   loading: false,
   errorData: null,
    error:false,
   data: [],
};

const initialCreateState: CreateItemListState = {
    loading: false,
    errorData: null,
    error:false,
    data: [],
    isCreated:false
 };

const initialUpdateState: UpdateItemListState = {
    loading: false,
    errorData: null,
    error:false,
    data: [],
    isUpdated:false
 };

export const fetchItemListSlice = createSlice({
    name: 'itemList/fetch',
    initialState: initialFetchState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetch.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false

                
            })
            .addCase(fetch.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
              
            })
    },
}
);

export const createItemListSlice = createSlice({
    name: 'itemList/create',
    initialState: initialCreateState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
                state.isCreated=false
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
                state.isCreated=true
                
            })
            .addCase(create.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
                state.isCreated=false
            })
    },
}
);

export const updateItemListSlice = createSlice({
    name: 'itemList/update',
    initialState: initialUpdateState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(update.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
                state.isUpdated=false
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
                state.isUpdated=true
                
            })
            .addCase(update.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
                state.isUpdated=false
              
            })
    },
}
);


export const fetchItemListReducer=fetchItemListSlice.reducer
export const createItemListReducer=createItemListSlice.reducer
export const updateItemListReducer=updateItemListSlice.reducer

