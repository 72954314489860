import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";
import {
  GetSubscriptionProps,
  GetSubscriptionTransactionProps,
  GetSubscriptionTransactionPatchProps,
  RazorPayProps,
  SubscriptionService,
  GetFetchSubscriptionProps,
} from "./service/subscriptionService";

export const getDeviceSubscriptionInfo = createAsyncThunk(
  "subscription/deviceSubscriptionInfo",
  async (thunkAPI) => {
    try {
      const subscriptionService = new SubscriptionService(APIPATH);
      const response = await subscriptionService.getDeviceSubscriptionInfo();
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getSubscriptionOffers = createAsyncThunk(
  "subscription/subscriptionOffers",
  async (subscriptionOfferData: GetSubscriptionProps, thunkAPI) => {
    try {
      const subscriptionService = new SubscriptionService(APIPATH);
      const response = await subscriptionService.getSubscriptionOffers(
        subscriptionOfferData
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const razorPay = createAsyncThunk(
  "subscription/razorPay",
  async (razorPayData: RazorPayProps, thunkAPI) => {
    try {
      const subscriptionService = new SubscriptionService(APIPATH);
      const response = await subscriptionService.setRazorPay(razorPayData);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const subscriptionTransaction = createAsyncThunk(
  "subscription/subscriptionTransaction",
  async (subscriptionData: GetSubscriptionTransactionProps, thunkAPI) => {
    try {
      const subscriptionService = new SubscriptionService(APIPATH);
      const response = await subscriptionService.setSubscriptionTransaction(
        subscriptionData
      );
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const subscriptionTransactionPatch = createAsyncThunk(
  "subscription/subscriptionTransactionPatch",
  async (subscriptionData: GetSubscriptionTransactionPatchProps, thunkAPI) => {
    try {
      const subscriptionService = new SubscriptionService(APIPATH);
      const response =
        await subscriptionService.setSubscriptionTransactionPatch(
          subscriptionData
        );
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const FetchSubscriptionTransactions = createAsyncThunk(
  "subscription/getFetchSubscriptionTransactions",
  async (subscriptionFetchData: GetFetchSubscriptionProps, thunkAPI) => {
    try {
      const subscriptionService = new SubscriptionService(APIPATH);
      const response =
        await subscriptionService.getFetchSubscriptionTransactions(
          subscriptionFetchData
        );

      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);
