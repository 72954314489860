import React from "react";
import { Provider } from "react-redux";
import phinStore from "./store"; // Import named export from redux store

type PhinProviderProps = {
  children: React.ReactNode;
};

const PhinProvider: React.FC<PhinProviderProps> = ({ children }) => {
  return <Provider store={phinStore}>{children}</Provider>;
};

export default PhinProvider;
