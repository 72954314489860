import axios from "axios";
import { getDeviceHeader, getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetDeviceProps {
  page: string;
  limit: string;
  businessId?: string;
  includeReplacedDevice: boolean;
}

export interface ActivateDeviceProps {
  businessId: string;
  dealercode: string;
  installedBy: string;
  serialNo: string;
  userDeviceName: string;
}

export interface UpdateDeviceNameProps {
  deviceId: string;
  userDeviceName: string;
}

export interface GetWarrantyTCProps {
  warrantyTCId: string;
}

export interface GetSettingsProps {
  deviceId: string;
}

export interface MapToItemListProps {
  itemListId: string;
  serialNo: string;
}

export interface MapToOutletProps {
  outletId: string;
  serialNo: string;
}

export interface GetHeaderFooterProps {
  deviceId: string;
}

export interface HeaderItem {
  Text?: string;
  Width?: boolean;
  Height?: boolean;
  Alignment?: string;
  LineNumber: number;
}

export interface FooterItem {
  Text?: string;
  Width?: boolean;
  Height?: boolean;
  Alignment?: string;
  LineNumber: number;
}

export interface HeaderFooterProps {
  Header: HeaderItem[];
  Footer: FooterItem[];
  deviceId: string;
}

export interface SettingsProps {
  BasePrice: boolean;
  BillResetStatus: boolean;
  BillSaveStatus: boolean;
  BillWithoutStock: boolean;
  BuzzerStatus: boolean;
  CashCalculatorStatus: boolean;
  ChickenToken: boolean;
  CloudBuzzerStatus: boolean;
  // CloudSyncStatus: boolean;
  ConsolidatedVatStatus: boolean;
  CustomerNameStatus: boolean;
  DiscountStatus: boolean;
  DuplicateBillStatus: boolean;
  GSTStatus: boolean;
  GSTTypeStatus: boolean;
  GuestStatus: boolean;
  HFStatus: boolean;
  ItemDiscountStatus: boolean;
  KOTStatus: boolean;
  KOTTokenDelete: boolean;
  KOTTokenPrint: boolean;
  LogoStatus: boolean;
  MergeStatus: boolean;
  PaymentStatus: boolean;
  ReversePritingStatus: boolean;
  ReverseTaxStatus: boolean;
  RoundOff: boolean;
  SerialNumStatus: boolean;
  StockStatus: boolean;
  SubtotalStatus: boolean;
  TokenHFStatus: boolean;
  TokenLogoStatus: boolean;
  UniterritoryStatus: boolean;
  WaiterStatus: boolean;
  deviceId: string;
}

export class DeviceService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getDevice(deviceData: GetDeviceProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device}?page=${deviceData.page}&limit=${deviceData.limit}&businessId=${deviceData.businessId}&includeReplacedDevice=${deviceData.includeReplacedDevice}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async activateDevice(deviceData: ActivateDeviceProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_activation}`;

      const response = await axios.patch(url, deviceData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateDeviceName(deviceData: UpdateDeviceNameProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_update_name}`;

      const response = await axios.patch(url, deviceData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async mapToOutlet(outletData: MapToOutletProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_map_outlet}`;

      const response = await axios.patch(url, outletData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async mapToItemList(itemListData: MapToItemListProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_map_item_list}`;

      const response = await axios.patch(url, itemListData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getSettings(settingsData: GetSettingsProps): Promise<any> {
    try {
      let header = getDeviceHeader();

      const url = `${this.baseUrl}${api_routes.api_path_device_settings}?deviceId=${settingsData.deviceId}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async createSettings(settingsData: SettingsProps): Promise<any> {
    try {
      let header = getDeviceHeader();

      const url = `${this.baseUrl}${api_routes.api_path_device_settings}`;

      const response = await axios.post(url, settingsData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateSettings(settingsData: SettingsProps): Promise<any> {
    try {
      let header = getDeviceHeader();

      const url = `${this.baseUrl}${api_routes.api_path_device_settings}`;

      const response = await axios.patch(url, settingsData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getWarrantyTC(warrantyData: GetWarrantyTCProps): Promise<any> {
    try {
      let header = getDeviceHeader();
      const url = `${this.baseUrl}${api_routes.api_path_warranty}?warrantyTCId=${warrantyData.warrantyTCId}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getHeaderFooter(headerFooterData: GetHeaderFooterProps): Promise<any> {
    try {
      let header = getDeviceHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_header_footer}?deviceId=${headerFooterData.deviceId}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async createHeaderFooter(headerFooterData: HeaderFooterProps): Promise<any> {
    try {
      let header = getDeviceHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_header_footer}`;

      const response = await axios.post(url, headerFooterData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateHeaderFooter(headerFooterData: HeaderFooterProps): Promise<any> {
    try {
      let header = getDeviceHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_header_footer}`;

      const response = await axios.patch(url, headerFooterData, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
