import React, { useEffect, useState } from "react";
import { useFetchSubscriptionTransaction } from "../../../modules/subscription/subscriptionHooks";
import {
  Divider,
  Grid,
  Skeleton,
  Table,
  Title,
  Pagination,
  Modal,
  Button,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
// import moment from "moment";
// import { DatePickerInput } from "@mantine/dates";
import { Helmet } from "react-helmet-async";
import { convertToIndianCurrency } from "../../../utils/utils";
import SubscriptionModalDetails from "./SubscriptionModalDetails";

import NoRecordsFound from "../../../utils/uiUtils/NoRecordsFound";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import moment from 'moment-timezone';

const SubscriptionTransactionDetails = () => {
  const { FetchSubscriptionTrsactionsFunction, loading, data } =
    useFetchSubscriptionTransaction();
  const [date] = useState();
  const [pageNumber, setPageNumber] = useState("1");
  const [limit] = useState(10);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [showmodal, setShowmodal] = useState(false);
  const [searchpage, setSearchpage] = useState(1);

  const [deviceItemdata, setDeviceItemdata] = useState("");

  // const selectedFromDate = date?.[0]
  //   ? moment(date?.[0]).format("YYYY/MM/DD")
  //   : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  // const handledate = (e: any) => {
  //   getTransactionsDetails.fromDate = moment(e[0]).format("YYYY/MM/DD");
  //   getTransactionsDetails.toDate = moment(e[1]).format("YYYY/MM/DD");
  //   FetchSubscriptionTrsactionsFunction(getTransactionsDetails);
  // };

  const getTransactionsDetails = {
    // fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    fromDate: moment("01/01/2018").format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    page: 1,

    limit: limit,
  };
  useEffect(() => {
    FetchSubscriptionTrsactionsFunction(getTransactionsDetails);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.total?.length > 0) {
      setTotalpage(data?.total[0]?.count);
    } else {
      setTotalRecords(0);
    }
  }, [data]);

  // const getdata = () => {
  //   FetchSubscriptionTrsactionsFunction(getTransactionsDetails);
  //   console.log(data);
  // };

  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    getTransactionsDetails.page = page;
    FetchSubscriptionTrsactionsFunction(getTransactionsDetails);
  };
  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);
    setTotalRecords(totalPages);
  };

  const Tabledata: any = () => {
    return (
      <>
        {data?.docs?.map((item: any, index: number) => (
          <Table.Tr
            key={index}
            onClick={() => {
              setDeviceItemdata(item);
              setShowmodal(true);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {/* <td>{item.}</td> */}
            <Table.Td>{moment(item?.date).tz('Asia/Kolkata').format("DD/MM/YYYY")}</Table.Td>
            <Table.Td>{item?.orderId}</Table.Td>
            <Table.Td>{item?.items?.length}</Table.Td>
            <Table.Td>{item?.paymentMode}</Table.Td>
            <Table.Td>
              {convertToIndianCurrency(item?.amount / 100 || 0)}
            </Table.Td>
            <Table.Td
              style={{
                color:
                  item?.paymentStatus === "CONFIRM"
                    ? "green"
                    : item?.paymentStatus === "PENDING"
                      ? "orange"
                      : "#D2042D",
              }}
            >
              {item?.paymentStatus}
            </Table.Td>
          </Table.Tr>
        ))}
      </>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Transactions" />

      <Title order={2}>Subscription Transactions</Title>
      <Divider my="md" />
      <Grid style={{}}>
        <Grid.Col span={{ sm: 6, md: 4, lg: 3, xl: 2.4 }}>
          {/* <DatePickerInput
            required
            allowSingleDateInRange
            valueFormat="DD/MM/YYYY"
            type="range"
            label="Pick Dates Range"
            placeholder="Pick Dates Range"
            clearable
            defaultValue={[new Date(), new Date()]}
            value={date}
            onChange={(e: any) => {
              setDate(e);
              if (e && e[0] !== null && e[1] !== null) {
                handledate(e);
              }
            }}
          /> */}
        </Grid.Col>
        <Grid.Col>
          {loading ? (
            <div style={{ width: "100%" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    {[...Array(2)].map((_) => (
                      <th style={{ width: "50%" }}>
                        <Skeleton radius={0} height={60} />
                      </th>
                    ))}
                  </tr>
                </thead>
                <div style={{ width: "100%", height: "6px" }}></div>
                <tbody>
                  {[...Array(10)].map((_, index) => (
                    <tr key={index}>
                      {[...Array(7)].map(() => (
                        <td>
                          <Skeleton radius={0} height={35} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {data?.docs?.length ? (
                <>
                  <Table
                    verticalSpacing="xs"
                    horizontalSpacing="xs"
                    highlightOnHover
                    withTableBorder
                    withColumnBorders
                  >
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Date</Table.Th>
                        <Table.Th>Order ID</Table.Th>
                        <Table.Th>Items</Table.Th>
                        <Table.Th>Payment Mode</Table.Th>
                        <Table.Th>Amount</Table.Th>
                        <Table.Th> Payment Status</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{Tabledata()}</Table.Tbody>
                  </Table>
                  {TotalRecords > 1 && (
                    <div style={{ marginBottom: "50px" }}>
                      <Flex
                        p={15}
                        mih={50}
                        align="center"
                        gap="md"
                        justify="flex-end"
                        direction="row"
                        wrap="wrap"
                      >
                        {/* <div style={{ width: "5%" }}> */}
                        <Group>
                          <NumberInput
                            value={pageNumber}
                            w={60}
                            size="xs"
                            mt="sm"
                            placeholder="Page "
                            radius="sm"
                            min={1}
                            max={TotalRecords}
                            hideControls
                            onChange={(event) => {
                              setSearchpage(Number(event));
                            }}
                          />
                          {/* </div> */}
                          <Button
                            mt="sm"
                            variant="outline"
                            size="xs"
                            onClick={() => {
                              const pageNumber2 = searchpage;

                              if (
                                pageNumber2 < 1 ||
                                pageNumber2 > TotalRecords
                              ) {
                                notifications.show({
                                  title: "Error",
                                  message: "Invalid page number",
                                  color: "red",
                                  icon: <IconX size="1.1rem" />,
                                });
                              } else {
                                handlePageChange(searchpage);
                              }
                            }}
                          >
                            Go
                          </Button>
                        </Group>

                        <Pagination
                          mt="sm"
                          total={TotalRecords}
                          onChange={handlePageChange}
                          value={Number(pageNumber)}
                        // radius="xl"
                        />
                      </Flex>
                    </div>
                  )}
                </>
              ) : (
                <div style={{ paddingTop: "60px" }}>
                  <NoRecordsFound />
                </div>
              )}
            </>
          )}
        </Grid.Col>
      </Grid>
      <Modal
        centered
        opened={showmodal}
        onClose={() => {
          setShowmodal(false);
        }}
        title={
          <Title order={3} style={{ height: "15px", paddingLeft: "10px" }}>
            Subscription Details
          </Title>
        }
        size="xl"
      >
        <Divider my="1" style={{ paddingTop: "10px" }} />
        <SubscriptionModalDetails item={deviceItemdata} />
      </Modal>
    </div>
  );
};

export default SubscriptionTransactionDetails;
