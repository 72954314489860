import { createSlice } from "@reduxjs/toolkit";
import { fetch,create,update, deleteItem, deleteAllItem, searchItem, download} from "./itemActions";

interface FetchItemState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: any;
}

const initialFetchState: FetchItemState = {
    loading: false,
    errorData: null,
     error:false,
    data: [],
 };
 
 interface CreateItemState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
    notDuplicate?:boolean|null;
    isCreated?:boolean
}



const initialCreateState: CreateItemState = {
    loading: false,
    errorData: null,
     error:false,
    data: [],
    isCreated:false,
    notDuplicate:null
 };
 


 interface UpdateItemState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
    isUpdated?:boolean
}

interface DeleteItemState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
    isDeleted?:boolean
}


interface DeleteAllItemState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean,
    data?: [];
    isDeleted?:boolean
}


const initialUpdateState: UpdateItemState = {
    loading: false,
    errorData: null,
     error:false,
    data: [],
    isUpdated:false
 };

 const initialDeleteAllState: DeleteAllItemState = {
    loading: false,
    errorData: null,
     error:false,
    data: [],
    isDeleted:false

 };

 const initialDeleteState:DeleteItemState={
    loading: false,
    errorData: null,
     error:false,
    data: [],
    isDeleted:false
 }

 const initialDownloadState:FetchItemState={
    loading: false,
    errorData: null,
     error:false,
    data: [],
 }
 
 export const fetchItemSlice = createSlice({
    name: 'item/fetch',
    initialState: initialFetchState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetch.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
            })
            .addCase(fetch.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false

                
            })
            .addCase(fetch.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
              
            })
    },
}
);

export const downloadItemSlice = createSlice({
    name: 'item/download',
    initialState: initialDownloadState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(download.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
            })
            .addCase(download.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false

                
            })
            .addCase(download.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
            })
    },
})

export const createItemSlice = createSlice({
    name: 'item/create',
    initialState: initialCreateState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
                state.isCreated=false
                state.notDuplicate=null

            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
                state.isCreated=true
                if(typeof(action.payload.message)=="string" && action.payload.message!== undefined){
                    if( (action.payload.message)?.includes("duplicate") ){
                        state.notDuplicate=false
                    }
                }
               else{
                    state.notDuplicate=true
                }

                
            })
            .addCase(create.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
                state.isCreated=false
            })
    },
}
);

export const updateItemSlice = createSlice({
    name: 'item/update',
    initialState: initialUpdateState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(update.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
                state.isUpdated=false
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
                state.isUpdated=true
                
            })
            .addCase(update.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true    
                state.isUpdated=false
            })
    },
}
);

export const deleteItemSlice = createSlice({
    name: 'item/delete',
    initialState: initialDeleteState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteItem.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
                state.isDeleted=false
            })
            .addCase(deleteItem.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
                state.isDeleted=true
            })
            .addCase(deleteItem.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
                state.isDeleted=false
            })
    },
}
);


export const deleteAllItemSlice = createSlice({
    name: 'item/deleteAll',
    initialState: initialDeleteAllState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteAllItem.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
                state.isDeleted=false
            })
            .addCase(deleteAllItem.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
                state.isDeleted=true
                
            })
            .addCase(deleteAllItem.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
                state.isDeleted=false
            })
    },
}
);

export const searchItemSlice = createSlice({
    name: 'item/search',
    initialState: initialFetchState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchItem.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
            })
            .addCase(searchItem.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false

                
            })
            .addCase(searchItem.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
              
            })
    },
}
);

export const fetchItemReducer=fetchItemSlice.reducer
export const downloadItemReducer=downloadItemSlice.reducer
export const createItemReducer=createItemSlice.reducer
export const updateItemReducer=updateItemSlice.reducer
export const deleteItemReducer=deleteItemSlice.reducer
export const deleteAllItemReducer=deleteAllItemSlice.reducer
export const searchItemReducer=searchItemSlice.reducer



