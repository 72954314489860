import { createSlice } from "@reduxjs/toolkit";
import { fetch, create, update } from "./businessAction";

interface fetchBusinessState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: [];
  multiOutlet?: boolean;
  businessName?: string | null;
}

interface createBusinessState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: [];
  isCreated?: boolean;
}

interface updateBusinessState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: [];
  isUpdated?: boolean;
}

const initialFetchState: fetchBusinessState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  multiOutlet: false,
  businessName: null,
};

const initialCreateState: createBusinessState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isCreated: false,
};

const initialUpdateState: updateBusinessState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isUpdated: false,
};

export const fetchBusinessSlice = createSlice({
  name: "business/fetch",
  initialState: initialFetchState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.multiOutlet = false;
        state.businessName = null;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.multiOutlet = action.payload.docs?.[0]?.multiOutlet;
        state.businessName = action.payload.docs?.[0]?.businessName;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.multiOutlet = false;
        state.businessName = null;
      });
  },
});

export const createBusinessSlice = createSlice({
  name: "business/create",
  initialState: initialCreateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.error = false;
        state.data = [];
        state.isCreated = false;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isCreated = true;
      })
      .addCase(create.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isCreated = false;
      });
  },
});

export const updateBusinessSlice = createSlice({
  name: "business/update",
  initialState: initialUpdateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(update.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.error = false;
        state.data = [];
        state.isUpdated = false;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isUpdated = true;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isUpdated = false;
      });
  },
});

export const getBusinessReducer = fetchBusinessSlice.reducer;
export const createBusinessReducer = createBusinessSlice.reducer;
export const updateBusinessReducer = updateBusinessSlice.reducer;
