import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../awatarStore/store";
import {
  CreateBusinessProps,
  GetBusinessProps,
  UpdateBusinessProps,
} from "./service/businessService";
import { create, fetch, update } from "./businessAction";

export const useGetBusiness = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["business/fetch"].loading
  );
  const error = useSelector(
    (state: RootState) => state["business/fetch"].error
  );
  const data: any = useSelector(
    (state: RootState) => state["business/fetch"].data
  );
  const multiOutlet = useSelector(
    (state: RootState) => state["business/fetch"].multiOutlet
  );
  const businessName = useSelector(
    (state: RootState) => state["business/fetch"].businessName
  );

  const fetchBusiness = async (business: GetBusinessProps) => {
    await dispatch(fetch(business));
  };

  return { fetchBusiness, loading, error, data, multiOutlet, businessName };
};

export const useCreateBusiness = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["business/create"].loading
  );
  const error = useSelector(
    (state: RootState) => state["business/create"].error
  );
  const data = useSelector((state: RootState) => state["business/create"].data);
  const isCreated = useSelector(
    (state: RootState) => state["business/create"].isCreated
  );

  const createBusiness = async (business: CreateBusinessProps) => {
    await dispatch(create(business));
  };

  return { createBusiness, loading, error, data, isCreated };
};

export const useUpdateBusiness = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["business/update"].loading
  );
  const error = useSelector(
    (state: RootState) => state["business/update"].error
  );
  const data = useSelector((state: RootState) => state["business/update"].data);
  const isUpdated = useSelector(
    (state: RootState) => state["business/update"].isUpdated
  );
  const errorData = useSelector(
    (state: RootState) => state["business/update"].errorData
  );

  const updateBusiness = async (business: UpdateBusinessProps) => {
    await dispatch(update(business));
  };

  return { updateBusiness, loading, error, data, isUpdated, errorData };
};
