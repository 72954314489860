import {
  IconNotes,
  IconGauge,
  IconDeviceMobile,
  IconChartInfographic,
  IconNotebook,
  IconBuildingStore,
} from "@tabler/icons-react";

export const sideBarData = [
  { label: "Dashboard", icon: IconGauge, path: "/", iconColor: "#26D36D" },

  { label: "Outlets", icon: IconBuildingStore, path: "/outlet", iconColor: "#26D36D" },
  {
    label: "Device Management",
    icon: IconDeviceMobile,
    // initiallyOpened: true,
    links: [
      { label: "My Devices", link: "/deviceManagement/device" },
      { label: "Settings", link: "/deviceManagement/settings" },
      { label: "Header/Footer", link: "/deviceManagement/hf" },
    ],
    iconColor: "#26D36D",
  },
  {
    label: "Item Management",
    icon: IconNotes,
    links: [
      { label: "Itemlist", link: "/itemManagement/itemList" },
      { label: "Item", link: "/itemManagement/items" },
    ],
    iconColor: "#26D36D",
  },
  {
    label: "Reports",
    icon: IconChartInfographic,
    links: [
      { label: "Billwise", link: "/billwise" },
      { label: "Daywise", link: "/daywise" },
      {
        label: "Itemwise",
        link: "/itemwise",
      },
      { label: "Monthwise", link: "/monthwise" },
      { label: "GSTwise", link: "/gstwise" },
      { label: "Refund Bill", link: "/refundbill" },
      { label: "Summary", link: "/summaryreport" },
      { label: "Userwise ", link: "/userwisereport" },
      { label: "Void Billwise", link: "/voidbill" },
      { label: "Aggregate", link: "/aggregate" },
    ],
    iconColor: "#26D36D",
  },
  {
    label: "Manage Account",
    icon: IconNotebook,
    links: [
      { label: "Subscription", link: "/manageaccount/subscription" },
      {
        label: "Subscription Transactions",
        link: "/manageaccount/subscription-transaction",
      },
      // { label: "Firmwares", link: "/manageaccount/firmware" },
    ],
    iconColor: "#26D36D",
  },
];
