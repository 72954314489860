import { AppShell, Burger, Group, useMantineColorScheme } from "@mantine/core";
import { ScrollArea } from "@mantine/core";
import classes from "../appShell/SideBarNested.module.css";
import { LinksGroup } from "./SideBarLinksGroup";
import { useEffect, useState } from "react";
import { Logo } from "../appShell/_logo";

interface SideBarProps {
  sideMenuItems: any;
  mobileOpened: boolean;
  desktopOpened: boolean;
  userClick: any;
  toggleMobile: any;
}

function SideBar({
  toggleMobile,
  mobileOpened,
  userClick,
  desktopOpened,
  sideMenuItems,
}: SideBarProps) {
  const [active, setActive] = useState(-1);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const { colorScheme } = useMantineColorScheme();

  const handleSelectedLink = (index: any, e: any) => {
    setActive(index);

    if (openDropdownIndex === index) {
      setOpenDropdownIndex(-1);
    } else {
      setOpenDropdownIndex(index);
    }
  };

  useEffect(() => {
    if (active === -1) {
      if (window.location.pathname === "/") {
        setActive(0);
        setOpenDropdownIndex(0);
      }
      if (window.location.pathname === "/outlet") {
        setActive(1);
        setOpenDropdownIndex(1);
      }
      if (window.location.pathname === "/device") {
        setActive(2);
        setOpenDropdownIndex(2);
      }
      if (
        window.location.pathname === "/deviceManagement/device" ||
        window.location.pathname === "/deviceManagement/hf" ||
        window.location.pathname === "/deviceManagement/settings"
      ) {
        setActive(2);
        setOpenDropdownIndex(2);
      }

      if (
        window.location.pathname === "/itemManagement/itemList" ||
        window.location.pathname === "/itemManagement/items"
      ) {
        setActive(3);
        setOpenDropdownIndex(3);
      }
      if (window.location.pathname === "/reports/*") {
        setActive(3);
        setOpenDropdownIndex(3);
      }
      if (window.location.pathname === "/manageAccount/subscription") {
        setActive(3);
        setOpenDropdownIndex(3);
      }
      if (window.location.pathname === "/manageAccount/") {
        setActive(3);
        setOpenDropdownIndex(3);
      }
    }
  }, []);

  const items = sideMenuItems.map((item: any, index: any) => (
    <LinksGroup
      {...item}
      key={item.label}
      active={index === active}
      index={index}
      activeIndex={active}
      handleSelectedLink={handleSelectedLink}
      isOpen={index === openDropdownIndex}
      toggleMobile={toggleMobile}
    />
  ));

  return (
    <AppShell.Navbar
      p="lg"
      style={{
        width: mobileOpened ? "300px" : "300px",
      }}
      className={
        colorScheme === "dark"
          ? classes.darkNavbar
          : colorScheme === "light"
          ? classes.lightnavbar
          : ""
      }
    >
      <Group justify="space-between">
        <Logo userClick={userClick} />
        <Burger
          opened={mobileOpened}
          onClick={toggleMobile}
          hiddenFrom="sm"
          size="sm"
        />
      </Group>

      <ScrollArea style={{ overflowX: "hidden" }} className={classes.links}>
        <div className={classes.linksInner}>{items}</div>
      </ScrollArea>
    </AppShell.Navbar>
  );
}

export default SideBar;
