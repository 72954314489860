import React from "react";
import { Group, Modal, Skeleton, Table, Text } from "@mantine/core";
import classes from "../../styleModules/AppShellMainStyles.module.css";

interface Props {
  termsAndCondition: any;
  setTermsAndConditions: any;
  TCData: any;
  data: any;
  warrantyLoading: any;
}

const WarrantyTC = ({
  termsAndCondition,
  setTermsAndConditions,
  TCData,
  data,
  warrantyLoading,
}: Props) => {
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      closeOnClickOutside={false}
      withCloseButton
      opened={termsAndCondition}
      onClose={() => setTermsAndConditions(false)}
      size="lg"
      radius="md"
      title="Warranty Terms and Conditions"
    >
      <Group
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <Text>
          {" "}
          <b>Product Model name: </b>
          {TCData[0]?.productmodelName}
        </Text>
        <Text>
          <b>Device Serial Number: </b>
          {data.serialNo}
        </Text>
      </Group>
      {warrantyLoading ? (
        <>
          <Table withRowBorders withColumnBorders withTableBorder>
            <thead>
              <Table.Tr style={{ backgroundColor: "grey" }}>
                <Table.Th style={{ color: "white" }}>Sl No</Table.Th>
                <Table.Th style={{ color: "white" }}>
                  Warranty Conditions
                </Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {[...Array(2)].map((_, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    {" "}
                    <Skeleton height={50} width={40} />
                  </Table.Td>

                  <Table.Td>
                    <Skeleton height={50} width={500} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </tbody>
          </Table>

          <Table
            withRowBorders
            withColumnBorders
            withTableBorder
            style={{ marginTop: "20px" }}
          >
            <thead>
              <Table.Tr style={{ backgroundColor: "grey" }}>
                <Table.Th style={{ color: "white" }}>Parts Name</Table.Th>
                <Table.Th style={{ color: "white" }}>
                  Warranty Duration
                </Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {[...Array(2)].map((_, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    {" "}
                    <Skeleton height={30} width={300} />
                  </Table.Td>

                  <Table.Td>
                    <Skeleton height={30} width={200} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Table withRowBorders withColumnBorders withTableBorder>
            <thead>
              <Table.Tr style={{ backgroundColor: "grey" }}>
                <Table.Th style={{ color: "white", whiteSpace: "nowrap" }}>
                  Sl No
                </Table.Th>
                <Table.Th style={{ color: "white" }}>
                  Warranty Conditions
                </Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {TCData[0]?.conditions?.map((element: any, index: any) => (
                <Table.Tr key={element._id}>
                  <Table.Td>{index + 1}</Table.Td>
                  <Table.Td>{element.Description}</Table.Td>
                </Table.Tr>
              ))}
            </tbody>
          </Table>

          <Table
            withRowBorders
            withColumnBorders
            withTableBorder
            style={{ marginTop: "20px" }}
          >
            <thead>
              <Table.Tr style={{ backgroundColor: "grey" }}>
                <Table.Th style={{ color: "white" }}>Parts Name</Table.Th>
                <Table.Th style={{ color: "white" }}>
                  Warranty Duration
                </Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {TCData[0]?.partswarranty?.map((element: any) => (
                <Table.Tr key={element._id}>
                  <Table.Td>{element.partsName}</Table.Td>
                  <Table.Td>{element.duration}</Table.Td>
                </Table.Tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Modal>
  );
};

export default WarrantyTC;
