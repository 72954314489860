import axios from "axios";
import { getDeviceHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetFileData{
  fileName: string;
}
export interface FileUploadProps {
  checksum: number;
  description: string;
  deviceId: string;
  file: {
    name: string;
    mimetype: string;
    encoding: string;
    size: number;
    truncated: boolean;
    data: number[];
  };
  encoding: string;
  mimetype: string;
  name: string;
  size: number;
  truncated: boolean;
}

export class FileService {
    private baseUrl: string;
  
    constructor(baseUrl: string) {
      this.baseUrl = baseUrl;
    }

    async getFile(fileData: GetFileData): Promise<any> {
        try {
          let header = getDeviceHeader();
          const url = `${this.baseUrl}${api_routes.api_path_device_logo}`;
          const response = await axios.get(url, {
            headers: header,
            params: fileData,
            responseType:'arraybuffer',
            validateStatus: function (status) {
              if (status === 400) {
                  return true;
              }
              return status >= 200 && status < 300;
          },
          });
          return response;
        } catch (error: any) {
          throw new Error(error);
        }
      }

      async create(fileData: GetFileData): Promise<any> {
        try {
          let header = getDeviceHeader();
          const url = `${this.baseUrl}${api_routes.api_path_device_logo}`;

          const response = await axios.post(url, fileData,{
            headers: header,
          });
    
          return response.data;
        } catch (error: any) {
          throw new Error(error.response.data.message);
        }
      }

      async update(fileData: GetFileData): Promise<any> {
        try {
          let header = getDeviceHeader();
          const url = `${this.baseUrl}${api_routes.api_path_device_logo}`;

          const response = await axios.put(url,fileData, {
            headers: header,
          });
    
          return response.data;
        } catch (error: any) {
          throw new Error(error.response.data.message);
        }
      }



}  