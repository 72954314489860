import {
  Menu,
  rem,
  ActionIcon,
  useMantineColorScheme,
  Tooltip,
} from "@mantine/core";
import {
  IconUser,
  IconPrinter,
  IconPencil,
  IconPhone,
  IconMail,
  IconId,
  IconDeviceMobile,
  IconBuilding,
  IconAddressBook,
  IconOutlet,
  IconBuildingSkyscraper,
  IconCalculatorFilled,
  IconBusinessplan,
  IconCheck,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  useEditUserProfile,
  useGetUserProfile,
} from "../modules/auth/authHooks";
import {
  useGetBusiness,
  useUpdateBusiness,
} from "../modules/business/businessHooks";
import classes from "../appShell/SideBarNested.module.css";
import EditUserProfile from "./EditUserProfile";
import EditBusinessProfile from "./EditBusinessProfile";
// import ResetPassword from "./ResetPassword";
import { notifications } from "@mantine/notifications";

export function Profile() {
  const {
    getUserProfile,
    loading: profileLoading,
    profileData,
  } = useGetUserProfile();
  const {
    fetchBusiness,
    // loading: businessLoading,
    error: businessError,
    data: businessData,
  } = useGetBusiness();
  const {
    updateBusiness,
    isUpdated: isBusinessUpdated,
    errorData: businessErrorData,
  } = useUpdateBusiness();
  const {
    editProfile,

    error: editProfileError,
    isEdited: isProfileEdited,
    errorData: editProfileErrorData,
  } = useEditUserProfile();
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === "dark";
  const isLight = colorScheme === "light";

  const [editUserOpened, setEditUserOpened] = useState(false);
  const [editBusinessOpened, setEditBusinessOpened] = useState(false);
  const [userEditFlag, setUserEditFlag] = useState(false);
  const [businessEditFlag, setBusinessEditFlag] = useState(false);

  // useEffect(() => {
  //   const userId = localStorage.getItem("userId");
  //   const getBusinessData = {
  //     page: 1,
  //     limit: 10,
  //   };

  //   getUserProfile(userId ?? "");
  //   fetchBusiness(getBusinessData);
  // }, []);

  useEffect(() => {
    if (businessData !== undefined && businessData !== null) {
      localStorage.setItem(
        "businessName",
        businessData?.docs?.[0]?.businessName
      );
      localStorage.setItem("city", businessData?.docs?.[0]?.city);
      localStorage.setItem("state", businessData?.docs?.[0]?.state);
    }
  }, [businessData]);

  useEffect(() => {
    if (isProfileEdited && userEditFlag) {
      notifications.show({
        title: "Success",
        message: "User Profile Updated Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      handleUserEditClose();
      const userId = localStorage.getItem("userId");
      getUserProfile(userId ?? "");
    } else if (editProfileError && userEditFlag) {
      notifications.show({
        title: "Error",
        message: editProfileErrorData,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isProfileEdited, editProfileError]);

  useEffect(() => {
    if (isBusinessUpdated && businessEditFlag) {
      notifications.show({
        title: "Success",
        message: "Business Profile Updated Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      handleBusinessClose();
      const getBusinessData = {
        page: 1,
        limit: 10,
      };
      fetchBusiness(getBusinessData);
    } else if (businessError && businessEditFlag) {
      notifications.show({
        title: "Error",
        message: businessErrorData,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isBusinessUpdated, businessError]);

  const handleUserEditClose = () => {
    setEditUserOpened(false);
  };
  const handleBusinessClose = () => {
    setEditBusinessOpened(false);
  };

  const handleUserProfileEdit = (values: any) => {
    if (values.lastname.length === 0) {
      values.lastname = " ";
      // delete values.lastname;
    }

    editProfile(values);
    setUserEditFlag(true);
  };

  const handleBusinessProfileEdit = (values: any) => {
    if (
      values.GSTN.length < 1 ||
      values.GSTN === null ||
      values.GSTN === undefined
    ) {
      values.GSTN = " ".repeat(15);
    }
    if (
      values.CIN.length < 1 ||
      values.CIN === null ||
      values.CIN === undefined
    ) {
      values.CIN = " ".repeat(21);
    }
    if (
      values.fax.length < 1 ||
      values.fax === null ||
      values.fax === undefined
    ) {
      values.fax = " ".repeat(15);
    }

    updateBusiness(values);
    setBusinessEditFlag(true);
  };

  return (
    <>
      <EditUserProfile
        editUserOpened={editUserOpened}
        handleUserProfileEdit={handleUserProfileEdit}
        handleUserEditClose={handleUserEditClose}
        data={profileData}
      />
      <EditBusinessProfile
        editBusinessOpened={editBusinessOpened}
        handleBusinessProfileEdit={handleBusinessProfileEdit}
        data={
          businessData !== undefined && businessData !== null
            ? businessData?.docs?.[0]
            : null
        }
        handleBusinessClose={handleBusinessClose}
      />

      <Menu
        onOpen={() => {
          const userId = localStorage.getItem("userId");
          const getBusinessData = {
            page: 1,
            limit: 10,
          };

          getUserProfile(userId ?? "");
          fetchBusiness(getBusinessData);
        }}
        shadow="md"
        radius="xs"
      >
        <Menu.Target>
          <Tooltip label="Profile">
            <ActionIcon loading={profileLoading} variant="default" size={30}>
              <IconUser size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={() => {
              setEditUserOpened(true);
            }}
            className={classes.TitleItem}
            rightSection={
              <IconPencil
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isLight ? classes.titleLight : isDark ? classes.titleDark : ""
              }
              style={{ fontSize: "16px" }}
            >
              User Profile
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconUser
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >{`${profileData?.firstname ?? ""}${" "}${
              profileData?.lastname ?? ""
            }`}</Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconPhone
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              {profileData?.mobile ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconMail
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              {profileData?.email ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconId
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              {profileData?.address ?? ""}
            </Menu.Label>
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item
            onClick={() => setEditBusinessOpened(true)}
            className={classes.TitleItem}
            rightSection={
              <IconPencil
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isLight ? classes.titleLight : isDark ? classes.titleDark : ""
              }
              style={{ fontSize: "16px" }}
            >
              Business Profile
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconDeviceMobile
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              Telephone : {businessData?.docs?.[0]?.telephone ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconBusinessplan
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              Business Name : {businessData?.docs?.[0]?.businessName ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconCalculatorFilled
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              GSTN : {businessData?.docs?.[0]?.GSTN ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconBuildingSkyscraper
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              CIN : {businessData?.docs?.[0]?.CIN ?? ""}
            </Menu.Label>
          </Menu.Item>

          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconPrinter
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              Fax : {businessData?.docs?.[0]?.fax ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconBuilding
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              City : {businessData?.docs?.[0]?.city ?? ""}
            </Menu.Label>
          </Menu.Item>

          <Menu.Item
            disabled
            rightSection={
              <IconAddressBook
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
              style={{
                width: "250px",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              Address : {businessData?.docs?.[0]?.address ?? ""}
            </Menu.Label>
          </Menu.Item>
          <Menu.Item
            disabled
            style={{ height: "30px" }}
            rightSection={
              <IconOutlet
                className={
                  isLight ? classes.titleLight : isDark ? classes.titleDark : ""
                }
                style={{ width: rem(14), height: rem(14) }}
              />
            }
          >
            <Menu.Label
              className={
                isDark ? classes.darkMenuLabel : classes.lightMenuLabel
              }
            >
              MultiOutlet :{" "}
              {(businessData?.docs?.[0]?.multiOutlet === true ? "Yes" : "No") ??
                ""}
            </Menu.Label>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
