import React, { useEffect, useRef, useState } from "react";
import {
  useDownloadMonthlyWiseReport,
  useGetMonthlyWise,
} from "../../../modules/reports/reportHooks";
import { MonthlyWiseProps } from "../../../modules/reports/service/reportService";
import {
  Table,
  Pagination,
  Select,
  Text,
  Title,
  Divider,
  Tabs,
  Tooltip,
  ActionIcon,
  Checkbox,
  Grid,
  Button,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
import moment from "moment";
import { notifications } from "@mantine/notifications";
import { DatePickerInput } from "@mantine/dates";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { GetOutletProps } from "../../../modules/outlet/service/outletService";
import { useGetDevice } from "../../../modules/device/deviceHooks";
import { useGetOutlet } from "../../../modules/outlet/outletHooks";
import { Player } from "@lottiefiles/react-lottie-player";
import clases from "./selected.module.css";
import {
  IconBuildingStore,
  IconChartBar,
  IconCheck,
  IconDeviceLandlinePhone,
  IconDownload,
  IconTable,
  IconX,
} from "@tabler/icons-react";
import { convertToIndianCurrency } from "../../../utils/utils";
import ChartUtils from "../../../utils/uiUtils/ChartUtils";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
import SkeletonTableLoder from "../../../utils/uiUtils/SkeletonTableLoder";
const Monthwise = () => {
  const {
    getMonthlyWiseFunction,
    loading,

    data: MonthData,
  } = useGetMonthlyWise();
  const { downloadMonthlyWiseReportFunction, data: downloadData } =
    useDownloadMonthlyWiseReport();
  const { fetchOutlet, data: outlet, loading: Ouletloading } = useGetOutlet();
  const {
    fetchDevice,
    loading: DeviceLoading,
    data: DeviceData,
  } = useGetDevice();

  const [TotalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [outletId, setOutletId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [pageNumber, setPageNumber] = useState("1");
  const [reportByDevice, setReportByDevice] = useState(false);
  const [limit] = useState(10);
  const [date, setDate] = useState();
  const [activeTab, setActiveTab] = useState<string | null>("Outlet");
  const [switchTableOrGraph, setSwitchTableOrGraph] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>("");
  const [exportLoading, setExportLoading] = useState(false);
  const [reportDataForDownload, setReportDataForDownload] = useState<any>([]);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(500);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  const [searchpage, setSearchpage] = useState(1);
  const pageNumberRef = useRef(pageNumber);
  let apiCallInterval: any;

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  const monthWiseData: MonthlyWiseProps = {
    fromDate: selectedFromDate
      ? selectedFromDate
      : moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ? selectedtoDate : moment().format("YYYY/MM/DD"),
    BillStatus: "CONFIRM",
    page: pageNumber,
    limit: limit.toString(),
    sort: {
      TotalAmount: true,
    },
  };
  const outletData: GetOutletProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
  };

  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: true,
  };

  function makeAPICall(page: any) {
    let getMonthWiseProps: MonthlyWiseProps = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      BillStatus: "CONFIRM",
      page: page,
      limit: limit.toString(),
      sort: {
        TotalAmount: true,
      },
    };

    if (!reportByDevice) {
      getMonthWiseProps.outletId = outletId;
      getMonthWiseProps.deviceId = undefined;
    } else {
      getMonthWiseProps.outletId = undefined;
      getMonthWiseProps.deviceId = deviceId;
    }

    getMonthlyWiseFunction(getMonthWiseProps);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = (pageNumber: any) => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall(pageNumber);
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall(pageNumberRef.current);

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(
        () => scheduleAPICall(pageNumberRef.current),
        interval
      );

      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, reportByDevice, bothDates, outletId, deviceId]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    fetchOutlet(outletData);
    fetchDevice(getDeviceData);
  }, []);

  useEffect(() => {
    if (outlet?.docs?.length > 0) {
      if (!outletId) {
        setOutletId(outlet?.docs[0]?._id);
      }
      if (!reportByDevice && !refreshSelected) {
        setSelectedName(outlet?.docs[0]?.outletName);
        monthWiseData.outletId = outletId ? outletId : outlet?.docs[0]?._id;
        monthWiseData.deviceId = undefined;
        getMonthlyWiseFunction(monthWiseData);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlet, reportByDevice]);

  useEffect(() => {
    if (DeviceData?.docs?.length > 0) {
      if (!deviceId) {
        setDeviceId(DeviceData?.docs[0]?._id);
      }

      if (reportByDevice && !refreshSelected) {
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);
        monthWiseData.outletId = undefined;
        monthWiseData.deviceId = deviceId ? deviceId : DeviceData?.docs[0]?._id;
        getMonthlyWiseFunction(monthWiseData);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DeviceData, reportByDevice]);

  useEffect(() => {
    if (MonthData?.total?.length > 0) {
      setTotalpage(MonthData?.total[0]?.count);
    } else setTotalRecords(0); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MonthData]);

  useEffect(() => {
    if (downloadData?.docs?.length > 0 && exportLoading) {
      setReportDataForDownload((prev: any) => [...prev, ...downloadData?.docs]);
    }
  }, [downloadData]);

  useEffect(() => {
    if (reportDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(
        downloadData?.total[0]?.count / downloadLimit
      );

      if (total_Pages < nextPage) {
        try {
          onExportClick(
            reportDataForDownload,
            reportTypes.monthlyWise,
            ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`
          );
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        } catch (err) {
          notifications.show({
            title: "Export Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
          setReportDataForDownload([]);
          setDownloadPage(1);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);

        const downloadItemWiseData: MonthlyWiseProps = {
          fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
          toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
          BillStatus: "CONFIRM",
          page: nextPage.toString(),
          limit: downloadLimit.toString(),
          sort: {
            TotalAmount: true,
          },
          outletId: !reportByDevice ? outletId : undefined,
          deviceId: reportByDevice ? deviceId : undefined,
        };

        if (downloadItemWiseData.deviceId === undefined) {
          delete downloadItemWiseData.deviceId;
        } else if (downloadItemWiseData.outletId === undefined) {
          delete downloadItemWiseData.outletId;
        }

        downloadMonthlyWiseReportFunction(downloadItemWiseData);
      }
    }
  }, [reportDataForDownload]);

  //handlepage change
  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    stopAPICalls();
    monthWiseData.page = page.toString();
    if (!reportByDevice) {
      monthWiseData.outletId = outletId;
      monthWiseData.deviceId = undefined;
    } else {
      monthWiseData.outletId = undefined;
      monthWiseData.deviceId = deviceId;
    }
    getMonthlyWiseFunction(monthWiseData).then(() => setIsLoading(false));
  };
  //handle outlet change
  const handleOuletAndDeviceChange = (event: any, name?: any) => {
    setSelectedName(name);
    setPageNumber("1");
    stopAPICalls();
    if (reportByDevice) {
      setDeviceId(event);
      monthWiseData.deviceId = event;
      monthWiseData.page = "1";
      monthWiseData.outletId = undefined;
      if (!monthWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        if (!refreshSelected) {
          getMonthlyWiseFunction(monthWiseData);
        }
      }
    } else {
      setOutletId(event);
      monthWiseData.outletId = event;
      monthWiseData.deviceId = undefined;
      monthWiseData.page = "1";

      if (!monthWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        if (!refreshSelected) {
          getMonthlyWiseFunction(monthWiseData);
        }
      }
    }
  };
  //handle date
  const handledate = (e: any) => {
    monthWiseData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    monthWiseData.toDate = moment(e[1]).format("YYYY/MM/DD");

    if (!reportByDevice) {
      monthWiseData.outletId = outletId;
      monthWiseData.deviceId = undefined;
    } else {
      monthWiseData.outletId = undefined;
      monthWiseData.deviceId = deviceId;
    }

    if (!refreshSelected) {
      getMonthlyWiseFunction(monthWiseData).then(() => setIsLoading(false));
    }
  };
  //selected data device
  const selectedDeviceData = () => {
    let data: any = DeviceData?.docs?.map((item: any) => ({
      value: item._id,
      label: `${
        item?.userDeviceName
          ? item.userDeviceName + " [" + item.serialNo + "]"
          : item?.serialNo ?? ""
      }`,
    }));
    return data === undefined ? [] : data;
  };
  //select data outlet
  const selectOutletData = () => {
    let data: any = outlet?.docs?.map((item: any) => {
      return { value: item._id, label: item.outletName };
    });
    return data === undefined ? [] : data;
  };
  //set page
  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);
    setTotalRecords(totalPages);
  };

  const Tabledata: any = () => {
    return (
      <>
        {MonthData?.docs?.map((item: any, index: number) => (
          <Table.Tr key={index}>
            {/* <td>{item.}</td> */}
            <Table.Td>{`${item._id.month}/${item._id.year}`}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.TotalGST || 0)}</Table.Td>
            <Table.Td>{convertToIndianCurrency(item.CESSAmount || 0)}</Table.Td>
            <Table.Td>
              {convertToIndianCurrency(item.TotalDiscount || 0)}
            </Table.Td>
            <Table.Td>
              {convertToIndianCurrency(item.TotalAmount || 0)}
            </Table.Td>
          </Table.Tr>
        ))}
      </>
    );
  };

  const handleExportClick = () => {
    if (
      MonthData?.total?.[0]?.count > 0 &&
      MonthData?.total?.[0]?.count !== undefined
    ) {
      const downloadItemWiseData: MonthlyWiseProps = {
        fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
        toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
        BillStatus: "CONFIRM",
        page: "1",
        limit: "100",
        sort: {
          TotalAmount: true,
        },
        outletId: !reportByDevice ? outletId : undefined,
        deviceId: reportByDevice ? deviceId : undefined,
      };

      if (downloadItemWiseData.outletId === undefined) {
        delete downloadItemWiseData.outletId;
      } else if (downloadItemWiseData.deviceId === undefined) {
        delete downloadItemWiseData.deviceId;
      }

      downloadMonthlyWiseReportFunction(downloadItemWiseData);
      setExportLoading(true);
    } else {
      notifications.show({
        title: "No Data Found",
        message: "No Data Found",
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Monthwise Report" />
      <div>
        <Title order={1}>Monthwise Report</Title>
      </div>
      <div>
        <Divider my="md" />
        <Text size="sm" style={{ paddingBottom: "10px" }}>
          Filter By
        </Text>
        <Tabs
          color="grape"
          value={activeTab}
          onChange={(value) => {
            // switchStateChange(value === "Outlet" ? false : true);
            setActiveTab(value);
            setReportByDevice(value === "Outlet" ? false : true);
            setPageNumber("1");
            stopAPICalls();
          }}
          classNames={clases}
        >
          <Tabs.List>
            <Tooltip
              label="Select Outlet wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab value="Outlet" leftSection={<IconBuildingStore />}>
                Outlet
              </Tabs.Tab>
            </Tooltip>
            <Tooltip
              label="Select Device wise Reports"
              position="bottom-start"
              offset={2}
              arrowOffset={50}
              arrowSize={8}
              withArrow
              openDelay={500}
            >
              <Tabs.Tab
                value="Device"
                leftSection={<IconDeviceLandlinePhone />}
              >
                Device
              </Tabs.Tab>
            </Tooltip>
          </Tabs.List>

          {/* <Tabs.Panel value="first">Outlet</Tabs.Panel>
            <Tabs.Panel value="second">Device</Tabs.Panel> */}
        </Tabs>
      </div>
      {Ouletloading || DeviceLoading ? (
        <></>
      ) : (
        <Grid>
          <Grid.Col>
            <Grid mt={15}>
              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <Select
                  label={!reportByDevice ? "Select Outlet" : "Select Device"}
                  allowDeselect={false}
                  value={!reportByDevice ? outletId : deviceId}
                  onChange={(event) => {
                    let selectedName: any;
                    if (!reportByDevice) {
                      outlet?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.outletName;
                          return item?.outletName;
                        }
                      });
                    } else if (reportByDevice) {
                      DeviceData?.docs?.find((item: any) => {
                        if (item._id === event) {
                          selectedName = item?.userDeviceName;
                          return item?.userDeviceName;
                        }
                      });
                    }

                    handleOuletAndDeviceChange(event, selectedName);
                  }}
                  placeholder={
                    !reportByDevice ? "Select Outlet" : "Select Device"
                  }
                  data={
                    !reportByDevice ? selectOutletData() : selectedDeviceData()
                  }
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 2,
                  xl: 2,
                }}
              >
                <DatePickerInput
                  label="Select Date"
                  required
                  allowSingleDateInRange
                  valueFormat="DD/MM/YYYY"
                  type="range"
                  placeholder="Pick Dates Range"
                  clearable
                  defaultValue={[new Date(), new Date()]}
                  value={date}
                  onChange={(e: any) => {
                    setDate(e);
                    if (e[1]) {
                      stopAPICalls();
                      setBothDates({
                        fromDate: moment(e[0]).format("YYYY/MM/DD"),
                        toDate: moment(e[1]).format("YYYY/MM/DD"),
                      });
                    }
                    setPageNumber("1");
                    if (e && e[0] !== null && e[1] !== null) {
                      handledate(e);
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={{
                  xs: 6,
                  sm: 6,
                  md: 6,
                  lg: 1.5,
                  xl: 1.2,
                }}
              >
                <Checkbox
                  style={{
                    position: "relative",
                    top: "15px",
                  }}
                  checked={refreshSelected}
                  onChange={(event) =>
                    setRefreshSelected(event.currentTarget.checked)
                  }
                  label="Auto Refresh"
                  mt="md"
                />
              </Grid.Col>
              {MonthData?.docs?.length > 0 && (
                <Grid.Col
                  pt={20}
                  span={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 0.2,
                    xl: 0.2,
                  }}
                  display="flex"
                  style={{
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    label={`Switch to ${
                      switchTableOrGraph ? "Graph" : "Table"
                    }`}
                    position="bottom-start"
                    offset={2}
                    arrowOffset={50}
                    arrowSize={8}
                    withArrow
                  >
                    <ActionIcon
                      variant="default"
                      size="lg"
                      onClick={() => {
                        if (switchTableOrGraph) {
                          setSwitchTableOrGraph(false);
                        } else {
                          setSwitchTableOrGraph(true);
                        }
                      }}
                    >
                      {switchTableOrGraph ? <IconTable /> : <IconChartBar />}
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Export Reports file">
                    <ActionIcon
                      loading={exportLoading}
                      variant="default"
                      size="lg"
                      onClick={() => {
                        handleExportClick();
                      }}
                    >
                      <IconDownload />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>
          <Grid.Col style={{ paddingTop: "20px" }}>
            {isLoading || loading ? (
              <SkeletonTableLoder />
            ) : (
              <>
                {MonthData?.docs?.length ? (
                  switchTableOrGraph ? (
                    <ChartUtils
                      label="Total Sales"
                      xAxisTitle="Months"
                      yAxisTitle="Total Amount"
                      pluginsTitle="Monthwise Sales"
                      data={MonthData?.docs}
                      dataSetProp="TotalAmount"
                      isMonthWise
                    />
                  ) : (
                    <div>
                      <Table
                        verticalSpacing="xs"
                        horizontalSpacing="xs"
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                      >
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Date</Table.Th>
                            <Table.Th>Total GST</Table.Th>
                            <Table.Th>Total CESS</Table.Th>
                            <Table.Th>Total Discount</Table.Th>
                            <Table.Th>Total Amount</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{Tabledata()}</Table.Tbody>
                      </Table>
                      {TotalRecords > 1 && (
                        <div style={{ marginBottom: "50px" }}>
                          <Flex
                            p={15}
                            mih={50}
                            align="center"
                            gap="md"
                            justify="flex-end"
                            direction="row"
                            wrap="wrap"
                          >
                            {/* <div style={{ width: "5%" }}> */}
                            <Group>
                              <NumberInput
                                value={pageNumber}
                                w={60}
                                size="xs"
                                mt="sm"
                                placeholder="Page "
                                radius="sm"
                                min={1}
                                max={TotalRecords}
                                hideControls
                                onChange={(event) => {
                                  setSearchpage(Number(event));
                                }}
                              />
                              {/* </div> */}
                              <Button
                                mt="sm"
                                variant="outline"
                                size="xs"
                                onClick={() => {
                                  const pageNumber2 = searchpage;

                                  if (
                                    pageNumber2 < 1 ||
                                    pageNumber2 > TotalRecords
                                  ) {
                                    notifications.show({
                                      title: "Error",
                                      message: "Invalid page number",
                                      color: "red",
                                      icon: <IconX size="1.1rem" />,
                                    });
                                  } else {
                                    handlePageChange(searchpage);
                                  }
                                }}
                              >
                                Go
                              </Button>
                            </Group>

                            <Pagination
                              mt="sm"
                              total={TotalRecords}
                              onChange={handlePageChange}
                              value={Number(pageNumber)}
                              // radius="xl"
                            />
                          </Flex>
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <>
                    <div style={{ paddingTop: "60px" }}>
                      <Player
                        autoplay
                        loop
                        src="./static/infographics/noRecordsFound.json"
                        style={{ height: "300px", width: "300px" }}
                        background="transparent"
                        controls={false}
                      ></Player>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default Monthwise;
