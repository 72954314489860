import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useBusinessLogin, useLogin } from "../../modules/auth/authHooks";
import { useGetBusiness } from "../../modules/business/businessHooks";
import { useEffect, useState } from "react";
import { BusinessPayload } from "../../modules/auth/service/authService";
import { useNavigate } from "react-router-dom";
import { upperFirst } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import classes from "../styleModules/AppShellMainStyles.module.css";
import CreateBusiness from "../business/CreateBusiness";

const Login = () => {
  const history = useNavigate();
  const form: any = useForm({
    initialValues: {
      user: "",
      password: "",
    },
    validate: {
      user: (val: string) =>
        val.length <= 2
          ? "User name should include at least 2 characters"
          : null,
      password: (val: string) =>
        val.length <= 5
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const { loginUser } = useLogin();
  const { businessLoginUser } = useBusinessLogin();
  const { data: fetchBusinessData, fetchBusiness } = useGetBusiness();
  const [createBusinessOpened, setCreateBusinessOpened] = useState(false);

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  useEffect(() => {
    if (fetchBusinessData.docs?.length > 0) {
      // if (fetchBusinessData.docs?.length === 1) {
      let payLoad: BusinessPayload = {
        businessId: fetchBusinessData.docs[0]._id,
        token: localStorage.getItem("access_token") ?? "",
      };
      businessLoginUser(payLoad, (sucess, err, resp) => {
        if (sucess) {
          if (resp?.isBusinessAvailable === true) {
            history("/");
          }
        } else {
          history("/login");
        }
      });
    } else if (fetchBusinessData.docs?.length > 1) {
      let payLoad: BusinessPayload = {
        businessId: fetchBusinessData.docs[0]._id,
        token: localStorage.getItem("access_token") ?? "",
      };

      businessLoginUser(payLoad, (sucess, err, resp) => {
        if (sucess) {
          if (resp?.isBusinessAvailable === true) {
            history("/");
          }
        } else {
          history("/login");
        }
      });
    } else if (fetchBusinessData.docs?.length === 0) {
      setCreateBusinessOpened(true);
    }
  }, [fetchBusinessData]);

  const handleUserLogin = async () => {
    let data = {
      user: form.values.user,
      password: form.values.password,
    };

    await loginUser(data, async (sucess, err, res) => {
      if (sucess) {
        notifications.show({
          title: "Success",
          message: "Login sucessfull",
          color: "green",
        });
        form.reset();

        let payload = {
          page: 1,
          limit: 100,
        };
        await fetchBusiness(payload);

        // let data = localStorage.getItem('access_token')?? '';
        // if(data !== null){
        //   await businessLoginUser(data, (sucess, err, resp) => {
        //     if (sucess) {
        //     } else {
        //     }
        // })
        // }
      } else if (err) {
        notifications.show({
          title: "Error",
          message: `Login Error: Invalid Credentials`,
          color: "red",
        });
      }
    });
  };

  const handleModeToggle = (e: any) => {
    e.preventDefault();
    form.reset();
    history("/register");
  };

  return (
    <>
      <CreateBusiness
        createBusinessOpened={createBusinessOpened}
        setCreateBusinessOpened={setCreateBusinessOpened}
      />
      <Container size={420} my={40}>
        <Title>Welcome back!</Title>

        <Text color="dimmed" mt={5}>
          Login to your account
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form
            onSubmit={form.onSubmit(() => {
              handleUserLogin();
            })}
          >
            <Stack>
              <TextInput
                classNames={{
                  label: classes.mantineLabel,
                }}
                label="User"
                placeholder="Username or Email or Mobile"
                value={form.values.user}
                onChange={(event) => {
                  form.setFieldValue("user", event.currentTarget.value);
                }}
                error={form.errors.user}
                radius="md"
              />

              <PasswordInput
                classNames={{
                  label: classes.mantineLabel,
                }}
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={form.errors.password}
                radius="md"
              />
            </Stack>

            <Group mt="xl">
              <Anchor
                component="button"
                type="button"
                color="dimmed"
                onClick={handleModeToggle}
                size="xs"
              >
                Don't have an account? Register
              </Anchor>
              <Button type="submit" radius="xl" ml="auto">
                {upperFirst("Login")}
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default Login;
