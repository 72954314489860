import { Title, Text, Button, Container, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import classes from "./page404.module.css";

const Page404 = () => {
  const history = useNavigate();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>ERROR ENCOUNTERED</Title>
      <Text size="lg" className={classes.description}>
        Unfortunately, this is only a 404 page. There may be a problem in the
        request or the page may not exist.
      </Text>
      <Group>
        <Button variant="subtle" size="md" onClick={() => history("/")}>
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
};

export default Page404;
