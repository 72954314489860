import axios from "axios";
import { getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetFirmwareProps {
  MotherboardVersion: string;
  getFileDetails: boolean;
}

export class FirmwareService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getFirmwareDetails(firmwareData: GetFirmwareProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_get_firmware}?MotherboardVersion=${firmwareData.MotherboardVersion}&getFileDetails=${firmwareData.getFileDetails}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
