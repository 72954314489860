import {
  Card,
  Text,
  Button,
  useMantineColorScheme,
  Grid,
  Modal,
} from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import UpdateItem from "./UpdateItem";

export interface Props {
  itemData: any;
  handleUpdateItem: any;
  handleDeleteItem: any;
  editItemDialog: boolean;
  setEditItemDialog: any;
  selectedItemCode: string;
  setSelectedItemCode: any;
}

export function ItemComponent({
  itemData,
  handleUpdateItem,
  handleDeleteItem,
  editItemDialog,
  setEditItemDialog,
  selectedItemCode,
  setSelectedItemCode,
}: Props) {
  // const [editItemDialog, setEditItemDialog] = useState(false);
  const { colorScheme } = useMantineColorScheme();

  const isDark = colorScheme === "dark";

  interface UpdateItemProps {
    ICode: number;
    IName: string;
    Price: {
      Price: number;
      IGST: number;
      SGST: number;
      CGST: number;
    }[];
    DCode?: number;
    GCode?: number;
    HSN?: string;
    DName?: string;
    GName?: string;
    Unit?: string;
    CP?: number;
    DiscPercent?: number;
    CESS1?: number;
    CESS2?: number;
    OpenPriceFlag?: boolean;
    DecimalDisableFlag?: boolean;
  }
  const form = useForm<UpdateItemProps>({
    initialValues: {
      ICode: itemData?.ICode ?? "",
      IName: itemData?.IName ?? "",
      Price: [
        {
          Price: itemData?.Price[0]?.Price ?? "",
          CGST: itemData?.Price[0]?.CGST ?? "",
          SGST: itemData?.Price[0]?.SGST ?? "",
          IGST: itemData?.Price[0]?.IGST ?? "",
        },
        {
          Price: itemData?.Price[1]?.Price ?? "",
          CGST: itemData?.Price[1]?.CGST ?? "",
          SGST: itemData?.Price[1]?.SGST ?? "",
          IGST: itemData?.Price[1]?.IGST ?? "",
        },
        {
          Price: itemData?.Price[2]?.Price ?? "",
          CGST: itemData?.Price[2]?.CGST ?? "",
          SGST: itemData?.Price[2]?.SGST ?? "",
          IGST: itemData?.Price[2]?.IGST ?? "",
        },
      ],
      DCode: itemData?.DCode ?? "",
      GCode: itemData?.GCode ?? "",
      HSN:
        itemData?.HSN !== undefined && itemData?.HSN !== null
          ? itemData?.HSN?.trim()
          : "",
      DName: itemData?.DName ?? "",
      GName: itemData?.GName ?? "",
      Unit:
        itemData?.Unit !== undefined && itemData?.Unit !== null
          ? itemData?.Unit?.trim()
          : "",
      CP: itemData?.CP ?? "",
      DiscPercent: itemData?.DiscPercent ?? "",
      CESS1: itemData?.CESS1 ?? "",
      CESS2: itemData?.CESS2 ?? "",
      OpenPriceFlag: itemData?.OpenPriceFlag ?? false,
      DecimalDisableFlag: itemData?.DecimalDisableFlag ?? false,
    },
    validate: {
      ICode: (value) =>
        value === 0 || value === undefined
          ? "must be greater than 0"
          : value > 2000
          ? "Must be 2000 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      IName: (value) =>
        value.length === 0
          ? "Should not be empty"
          : value.length > 24
          ? "Must be 24 or less"
          : null,

      DCode: (value) =>
        value !== undefined && value > 999
          ? "Must be 999 or less"
          : value !== undefined && value < 0
          ? "Must not be negative"
          : null,
      GCode: (value) =>
        value !== undefined && value > 999
          ? "Must be 999 or less"
          : value !== undefined && value < 0
          ? "Must not be negative"
          : null,
      HSN: (value) =>
        value !== undefined
          ? value?.length === 0
            ? null
            : value?.length < 2
            ? "Must be 2 or more characters"
            : value?.length > 9
            ? "Must be less than 10 characters"
            : null
          : null,
      DName: (value) =>
        value !== undefined && value.length > 19 ? "Must be 19 or less" : null,
      GName: (value) =>
        value !== undefined && value.length > 19 ? "Must be 19 or less" : null,
      Unit: (value) =>
        value !== undefined
          ? value?.length === 0
            ? null
            : value?.length > 3
            ? "Must be 3 or less"
            : null
          : null,
      CP: (value) =>
        value !== undefined && value > 9999999
          ? "Must be 9999999 or less"
          : value !== undefined && value < 0
          ? "Must not be negative"
          : null,
      DiscPercent: (value) =>
        value !== undefined && value > 100
          ? "Must be 100 or less"
          : value !== undefined && value < 0
          ? "Must not be negative"
          : null,
      CESS1: (value) =>
        value !== undefined && value > 100
          ? "Must be 100 or less"
          : value !== undefined && value < 0
          ? "Must not be negative"
          : null,
      CESS2: (value) =>
        value !== undefined && value > 100
          ? "Must be 100 or less"
          : value !== undefined && value < 0
          ? "Must not be negative"
          : null,
      Price: {
        Price: (value: number) =>
          value > 9999999
            ? "Must be 9999999 or less"
            : value < 0
            ? "Must not be negative"
            : null,
        CGST: (value: number) =>
          value > 100
            ? "Must be 100 or less"
            : value < 0
            ? "Must not be negative"
            : null,
        SGST: (value: number) =>
          value > 100
            ? "Must be 100 or less"
            : value < 0
            ? "Must not be negative"
            : null,
        IGST: (value: number) =>
          value > 100
            ? "Must be 100 or less"
            : value < 0
            ? "Must not be negative"
            : null,
      },
    },
  });

  return (
    <>
      <Modal
        classNames={{
          close: classes.modalCloseButton,
        }}
        opened={editItemDialog && selectedItemCode === itemData.ICode}
        withCloseButton
        onClose={() => {
          setEditItemDialog(false);
          form.setValues({
            ICode: itemData?.ICode ?? "",
            IName: itemData?.IName ?? "",
            Price: [
              {
                Price: itemData?.Price[0]?.Price ?? "",
                CGST: itemData?.Price[0]?.CGST ?? "",
                SGST: itemData?.Price[0]?.SGST ?? "",
                IGST: itemData?.Price[0]?.IGST ?? "",
              },
              {
                Price: itemData?.Price[1]?.Price ?? "",
                CGST: itemData?.Price[1]?.CGST ?? "",
                SGST: itemData?.Price[1]?.SGST ?? "",
                IGST: itemData?.Price[1]?.IGST ?? "",
              },
              {
                Price: itemData?.Price[2]?.Price ?? "",
                CGST: itemData?.Price[2]?.CGST ?? "",
                SGST: itemData?.Price[2]?.SGST ?? "",
                IGST: itemData?.Price[2]?.IGST ?? "",
              },
            ],
            DCode: itemData?.DCode ?? "",
            GCode: itemData?.GCode ?? "",
            HSN: itemData?.HSN ?? "",
            DName: itemData?.DName ?? "",
            GName: itemData?.GName ?? "",
            Unit: itemData?.Unit ?? "",
            CP: itemData?.CP ?? "",
            DiscPercent: itemData?.DiscPercent ?? "",
            CESS1: itemData?.CESS1 ?? "",
            CESS2: itemData?.CESS2 ?? "",
            OpenPriceFlag: itemData?.OpenPriceFlag ?? false,
            DecimalDisableFlag: itemData?.DecimalDisableFlag ?? false,
          });
        }}
        size="lg"
        radius="md"
        closeOnClickOutside={false}
        title="Edit Item"
      >
        <UpdateItem
          item={itemData}
          form={form}
          editItemDialog={editItemDialog}
          setEditItemDialog={setEditItemDialog}
          handleUpdateItem={handleUpdateItem}
        />
      </Modal>
      <Card withBorder radius="md" className={classes.itemCard}>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={9.5}>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                whiteSpace: "nowrap",
                flexDirection: "column",
              }}
            >
              <Text mr={5} style={{ whiteSpace: "nowrap" }} fz="sm" c="dimmed">
                Item Name
              </Text>

              <Text
                fw={600}
                fz={20}
                style={{ width: "100%", whiteSpace: "nowrap" }}
              >
                {itemData.IName}
              </Text>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Text
                mr={5}
                style={{
                  whiteSpace: "nowrap",
                }}
                fz="sm"
                c="dimmed"
              >
                HSN
              </Text>
              <Text
                style={{
                  lineHeight: 0,
                }}
                fw={500}
                fz={15}
              >
                {itemData.HSN ?? ""}
              </Text>
            </Grid.Col>
            <Grid.Col display="flex" span={12}>
              <Grid.Col
                span={6}
                style={{
                  // minHeight: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text fz="sm" c="dimmed">
                  Item Code
                </Text>
                <Text fw={500} fz={15}>
                  {itemData.ICode}
                </Text>
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{
                  // minHeight: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text fz="sm" c="dimmed">
                  Item Price
                </Text>
                <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
                  ₹{itemData?.Price[0]?.Price}
                </Text>
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={2.5}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              className={
                isDark ? classes.itemButtonDark : classes.itemButtonLight
              }
              onClick={() => {
                setEditItemDialog(true);
                setSelectedItemCode(itemData?.ICode);
              }}
              rightSection={
                <IconPencil
                  style={{ color: "green", height: "20px", width: "20px" }}
                />
              }
            />

            <Button
              rightSection={
                <IconTrash
                  style={{ color: "red", height: "20px", width: "20px" }}
                />
              }
              className={
                isDark ? classes.itemButtonDark : classes.itemButtonLight
              }
              onClick={() => {
                handleDeleteItem(itemData);
              }}
            />
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}
