import axios from "axios";
import { getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetOutletProps {
  page: string;
  limit: string;
  businessId: string;
}

export interface CreateOutletProps {
  outletName: string;
  telephone: string;
  city: string;
  state: string;
  country: string;
  businessId: string;
}

export interface UpdateOutletProps {
  outletId: string;
  outletName: string;
  telephone: string;
  city: string;
  state: string;
  country: string;
  businessId: string;
}

export class OutletService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getOutlet(outletData: GetOutletProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_business_outlet}?page=${outletData.page}&limit=${outletData.limit}&businessId=${outletData.businessId}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async createOutlet(outletData: CreateOutletProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_business_outlet}`;

      const response = await axios.post(url, outletData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateOutlet(outletData: UpdateOutletProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_business_outlet}`;

      const response = await axios.patch(url, outletData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
