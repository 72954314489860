import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import {
  businessLoginReducer,
  deviceLoginReducer,
  userEditPasswordReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userProfileReducer,
  userRegisterReducer,
  editUserProfileReducer,
} from "../auth/authSlice";

import {
  createBusinessReducer,
  getBusinessReducer,
  updateBusinessReducer,
} from "../business/businessSlice";

import {
  getOutletReducer,
  createOutletReducer,
  updateOutletReducer,
} from "../outlet/outletSlice";

import {
  fetchItemListReducer,
  createItemListReducer,
  updateItemListReducer,
} from "../itemList/itemListSlice";

import {
  fetchItemReducer,
  createItemReducer,
  updateItemReducer,
  deleteItemReducer,
  deleteAllItemReducer,
  searchItemReducer,
  downloadItemReducer,
} from "../item/itemSlice";

import {
  activateDeviceReducer,
  getDeviceSettingsReducer,
  createDeviceSettingsReducer,
  updateDeviceSettingsReducer,
  getDeviceWarrantyTCReducer,
  getDeviceHeaderFooterReducer,
  fetchDeviceReducer,
  updateDeviceReducer,
  mapToOutletReducer,
  mapToItemListReducer,
  createDeviceHeaderFooterReducer,
  updateDeviceHeaderFooterReducer,
} from "../device/deviceSlice";

import {
  getDashboardDataReducer,
  getAggregateReducer,
  getBillRefundReducer,
  getBillRefundSummaryReducer,
  getBillWiseReducer,
  getDayWiseReducer,
  getGSTSummaryReducer,
  getItemWiseReducer,
  getMonthlyWiseReducer,
  getSummaryReducer,
  getUserReportReducer,
  getVoidBillWiseReducer,
  downloadBillWiseReducer,
  downloadItemWiseReducer,
  downloadMonthlyWiseReducer,
  downloadGSTSummaryReducer,
  downloadSummaryReducer,
  downloadVoidBillWiseReducer,
  downloadAggregateReducer,
  downloadUserReportReducer,
  downloadDayWiseReducer,
  downloadBillRefundReducer
} from "../reports/reportSlice";

import { getFirmwareReducer } from "../firmware/firmwareSlice";

import {
  getSubscriptionOffersReducer,
  mysliceReducer,
  razorPayReducer,
  setSubscriptionTransactionReducer,
  getDeviceSubscriptionInfoReducer,
  getFetchSubscriptionTransactionsReducer,
} from "../subscription/subscriptionSlice";
import { createFileReducer, fetchFileReducer, updateFileReducer } from "../file/fileSlice";
import { getFooterReducer } from "../footer/footerSlice";

const store = configureStore({
  reducer: {
    "user/login": userLoginReducer,
    "user/getProfile": userProfileReducer,
    "user/editUserProfile": editUserProfileReducer,
    "user/businessLogin": businessLoginReducer,
    "user/register": userRegisterReducer,
    "user/editPassword": userEditPasswordReducer,
    "user/forgotPassword": userForgotPasswordReducer,
    "user/deviceLogin": deviceLoginReducer,

    "business/fetch": getBusinessReducer,
    "business/create": createBusinessReducer,
    "business/update": updateBusinessReducer,

    "outlet/fetch": getOutletReducer,
    "outlet/create": createOutletReducer,
    "outlet/update": updateOutletReducer,

    "report/getDashboardData": getDashboardDataReducer,
    "report/getBillWise": getBillWiseReducer,
    "report/getDayWise": getDayWiseReducer,
    "report/getItemWise": getItemWiseReducer,
    "report/getMonthlyWise": getMonthlyWiseReducer,
    "report/getGSTSummary": getGSTSummaryReducer,
    "report/getBillRefundSummary": getBillRefundSummaryReducer,
    "report/getUserWise": getUserReportReducer,
    "report/getSummary": getSummaryReducer,
    "report/getVoidBillWise": getVoidBillWiseReducer,
    "report/getBillRefund": getBillRefundReducer,
    "report/getAggregate": getAggregateReducer,

    "itemList/fetch": fetchItemListReducer,
    "itemList/create": createItemListReducer,
    "itemList/update": updateItemListReducer,

    "item/fetch": fetchItemReducer,
    "item/download": downloadItemReducer,
    "item/create": createItemReducer,
    "item/update": updateItemReducer,
    "item/delete": deleteItemReducer,
    "item/deleteAll": deleteAllItemReducer,
    "item/search":searchItemReducer,

    "device/fetch": fetchDeviceReducer,
    "device/activate": activateDeviceReducer,
    "device/updateName": updateDeviceReducer,
    "device/mapToItemList": mapToItemListReducer,
    "device/mapToOutlet": mapToOutletReducer,
    "device/getDeviceSettings": getDeviceSettingsReducer,
    "device/createDeviceSettings": createDeviceSettingsReducer,
    "device/updateDeviceSettings": updateDeviceSettingsReducer,
    "device/getHeaderFooter": getDeviceHeaderFooterReducer,
    "device/createHeaderFooter": createDeviceHeaderFooterReducer,
    "device/updateHeaderFooter": updateDeviceHeaderFooterReducer,
    "device/getWarrantyTC": getDeviceWarrantyTCReducer,
    "subscription/deviceSubscriptionInfo": getDeviceSubscriptionInfoReducer,
    "subscription/subscriptionOffers": getSubscriptionOffersReducer,
    "subscription/razorPay": razorPayReducer,
    "subscription/subscriptionTransaction": setSubscriptionTransactionReducer,
    "subscription/getFetchSubscriptionTransactions":
      getFetchSubscriptionTransactionsReducer,
    "firmware/getFirmware": getFirmwareReducer,

    "set/data": mysliceReducer,
    "subscription/subscriptionTransactionPatch":
      setSubscriptionTransactionReducer,

    'file/fetch':fetchFileReducer,
    'file/create':createFileReducer,
    'file/update':updateFileReducer,

    'footer/fetch':getFooterReducer,

    'report/downloadBillWiseReport':downloadBillWiseReducer,
    'report/downloadItemWiseReport':downloadItemWiseReducer,
    'report/downloadMonthlyWiseReport':downloadMonthlyWiseReducer,
    'report/downloadGSTReport':downloadGSTSummaryReducer,
    'report/downloadBillRefundReport':downloadBillRefundReducer,
    'report/downloadSummaryReport':downloadSummaryReducer,
    'report/downloadVoidBillWiseReport':downloadVoidBillWiseReducer,
    'report/downloadAggregateReport':downloadAggregateReducer,
    'report/downloadUserWiseReport':downloadUserReportReducer,
    'report/downloadDayWiseReport':downloadDayWiseReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
