import { Card, Grid, Group, Skeleton } from "@mantine/core";
import React from "react";

const SettingsSimmer = () => {
  return (
    <>
      {" "}
      <Grid.Col
        span={{ lg: 6, xl: 4, md: 8, sm: 10, xs: 12 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Card
          withBorder
          w={500}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            gap: "10px",
            alignItems: "center",
          }}
          pl={35}
          pr={30}
          h={130}
        >
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Skeleton height={20} width={180} />
            <Skeleton
              style={{ display: "flex", justifyContent: "flex-start" }}
              height={10}
              width={140}
            />
            <Skeleton
              style={{ display: "flex", justifyContent: "flex-start" }}
              height={10}
              width={140}
            />

            {/* <Skeleton height={10} width={100} /> */}
          </Group>
          <Skeleton style={{ borderRadius: "50px" }} height={28} width={55} />
        </Card>
      </Grid.Col>
    </>
  );
};

export default SettingsSimmer;
