import { Card, Grid } from "@mantine/core";

import React, { useEffect, useState } from "react";
import { DoughnutChart } from "../../../components/Chart";

interface Props {
  data: any;
}

const DonutChart = ({ data }: Props) => {
  // const { colorScheme } = useMantineColorScheme();
  const [dailySalesChartData, setdailySalesChartData] = useState({
    labels: ["Total CESS", "Total Discount", "Total GST", "Total Sale"],
    datasets: [
      {
        label: "Summary",
        data: [0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Sales Trend",
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      legend: {
        display: true,
        position: "bottom",
      },
      datalabels: {
        display: false,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -20,
        align: "start",
      },
    },
  };

  useEffect(() => {
    if (data) {
      setdailySalesChartData({
        labels: ["Total CESS", "Total Discount", "Total GST", "Total Sale"],
        datasets: [
          {
            label: "Summary",
            data: [
              data.TotalCESS1 + data.TotalCESS2,
              data.TotalDiscount,
              data.TotalGST,
              data.TotalSale,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data]);
  return (
    <div>
      <Grid>
        <Grid.Col span={{ md: 4 }}>
          <Card radius={"md"} shadow="sm" withBorder>
            <DoughnutChart
              chartData={dailySalesChartData}
              options={chartOptions}
            />
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default DonutChart;
