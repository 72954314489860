import { Button, Modal, TextInput } from "@mantine/core";
import React from "react";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import { useUpdateItemList } from "../../../modules/itemList/itemListHooks";

interface Props {
  opened: boolean;
  close: any;
  form: any;
  handleChangeItemListName: any;
}

const UpdateItemList = ({
  opened,
  close,
  form,
  handleChangeItemListName,
}: Props) => {
  const { loading } = useUpdateItemList();
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      size="sm"
      closeOnClickOutside={false}
      opened={opened}
      onClose={close}
      title="Edit Itemlist"
    >
      <form
        onSubmit={form.onSubmit((values: any) =>
          handleChangeItemListName(values)
        )}
      >
        <TextInput
          error={form?.errors?.newName}
          value={form.values.newName}
          withAsterisk
          label="Enter new name"
          maxLength={30}
          placeholder=""
          {...form.getInputProps("newName")}
        />

        <Button loading={loading} type="submit" mt={12}>
          Update
        </Button>
      </form>
    </Modal>
  );
};

export default UpdateItemList;
