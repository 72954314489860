import {
  Grid,
  Paper,
  ScrollArea,
  SimpleGrid,
  Space,
  Table,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import React from "react";
import { convertToIndianCurrency } from "../../../utils/utils";
import moment from "moment";

const SubscriptionModalDetails: React.FC<{ item: any }> = ({ item }) => {
  const { colorScheme } = useMantineColorScheme();
  // const isLightMode = colorScheme === "light";
  const isDarkMode = colorScheme === "dark";

  let items = item.items;
  let subtotal = 0;
  let totalDiscount = 0;
  let GST = 0;

  for (let i = 0; i < items.length; i++) {
    subtotal += items[i].amount;
    totalDiscount += items[i].Discount;
    GST += items[i].GSTValue;
  }

  const rows = item.items.map((sitem: any) => (
    <Table.Tr key={sitem?.serialNumber}>
      <Table.Td>{sitem?.serialNumber}</Table.Td>
      <Table.Td>
        {sitem?.duration} {""}Months
      </Table.Td>
      <Table.Td>{sitem?.OfferName}</Table.Td>
      <Table.Td>{sitem?.PlanName}</Table.Td>
      <Table.Td>{convertToIndianCurrency(sitem.Discount)}</Table.Td>
      <Table.Td>{sitem.GSTPercentage}%</Table.Td>
      <Table.Td>{convertToIndianCurrency(sitem.amount || 0)}</Table.Td>
    </Table.Tr>
  ));

  return (
    <div>
      <Grid>
        <Grid.Col span={{ md: 12, sm: 12, xs: 12 }}>
          <Paper shadow="xl" p="md">
            <SimpleGrid cols={3} spacing="xs">
              <Text>No of Items: {item.items.length}</Text>

              <Text> Date: {moment(item.date).format("DD/MM/YYYY")}</Text>
            </SimpleGrid>
          </Paper>
          <Space h="xs" />
          <Paper shadow="xl" p="md">
            <ScrollArea h={250}>
              <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Thead style={{ position: "sticky", top: 0 }}>
                  <Table.Tr>
                    <Table.Th>Serial Number</Table.Th>
                    <Table.Th>Duration</Table.Th>
                    <Table.Th>Offer Name</Table.Th>
                    <Table.Th>Plan Name</Table.Th>
                    <Table.Th>Discount</Table.Th>
                    <Table.Th>GST</Table.Th>
                    <Table.Th>Amount</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody key={item.orderId}>{rows}</Table.Tbody>
              </Table>
            </ScrollArea>
          </Paper>
          <Space h="xs" />
          <Paper shadow="xl" p="md">
            <SimpleGrid cols={2} spacing="xs">
              <Text>SubTotal : {convertToIndianCurrency(subtotal || 0)}</Text>
              <Text>
                Total Discount : {convertToIndianCurrency(totalDiscount || 0)}
              </Text>
              <Text>Total Gst : {convertToIndianCurrency(GST || 0)}</Text>
              {/* <Text>CGST : {convertToIndianCurrency(item.CGST || 0)}</Text>
              <Text>SGST : {convertToIndianCurrency(item.SGST || 0)}</Text>
             
              <Text>
                Total Cess1 : {convertToIndianCurrency(item.TotalCESS1 || 0)}
              </Text>
              <Text>
                Total Cess2 : {convertToIndianCurrency(item.TotalCESS2 || 0)}
              </Text> */}
              <Text
                style={{
                  color: isDarkMode ? "#50d8dd" : "#213555",
                  fontWeight: "bold",
                }}
              >
                Grand Total : {convertToIndianCurrency(item.amount / 100 || 0)}
              </Text>

              <Text>Payment Mode : {item.paymentMode}</Text>
              <Text>Payment Status : {item.paymentStatus}</Text>
            </SimpleGrid>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default SubscriptionModalDetails;
