import { Card, Divider, Grid, ScrollArea, Text, Title } from "@mantine/core";

import { convertToIndianCurrency } from "../../../../utils/utils";
interface SummaryCardProps {
  data: any;
}

const createCard = (title: any, value: any) => (
  <Grid.Col span={{ base: 8, md: 3 }}>
    <Card
      withBorder
      radius={"lg"}
      style={{
        boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
        //margin: "10px",
      }}
    >
      <Grid>
        <Grid.Col>
          <Text style={{ fontSize: "20px" }} c={"#5C5470"}>
            {title}
          </Text>
        </Grid.Col>
        <Grid.Col>
          <Text style={{ fontSize: "30px" }}>{value}</Text>
        </Grid.Col>
      </Grid>
    </Card>
  </Grid.Col>
);

const detailsCard = (title: any, value: any) => (
  <Grid.Col span={{ md: 12, lg: 12, sm: 12, xs: 12 }}>
    <Card
      withBorder
      radius={"sm"}
      style={{
        boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
        //margin: "10px",
      }}
    >
      <Grid>
        <Grid.Col span="auto">
          <Text style={{ fontSize: "15px" }}>{title}</Text>
        </Grid.Col>
        <Grid.Col span="auto">
          <Text style={{ fontSize: "20px" }}>{value}</Text>
        </Grid.Col>
      </Grid>
    </Card>
  </Grid.Col>
);

const SummaryCard = ({ data }: SummaryCardProps) => {
  return (
    <>
      <ScrollArea type="scroll" mb={50}>
        <Grid>
          {createCard(
            "Net Sale",
            convertToIndianCurrency(data?.TotalSale - data?.RefundAmt || 0)
          )}
          {createCard(
            "Net GST",
            convertToIndianCurrency(data?.TotalGST - data?.RefundGSTAmt || 0)
          )}
          {createCard(
            "Net CESS",
            convertToIndianCurrency(
              data?.TotalCESS1 +
                data?.TotalCESS2 -
                data?.RefundCESS1 -
                data?.RefundCESS2 || 0
            )
          )}
          {createCard(
            "Total Bills",
            data?.NoOfBills + data?.NoOfDeletedbills || 0
          )}
          {createCard("Total Void Bills", data?.NoOfDeletedbills || 0)}
          {createCard("Total Refund Bills", data?.NoOfRefundBills || 0)}
          {createCard("CASH", convertToIndianCurrency(data?.AmountByCASH || 0))}
          {createCard("CARD", convertToIndianCurrency(data?.AmountByCARD || 0))}
          {createCard(
            "Other Payments",
            convertToIndianCurrency(data?.AmountByOtherThanCashAndCard || 0)
          )}
        </Grid>
        <div style={{ width: "100%", marginTop: "15px" }}>
          <Title order={4} style={{ marginBottom: "-10px" }}>
            Details
          </Title>
          <Divider my="md" />
        </div>

        <Grid gutter="xs" style={{ marginTop: "10px" }}>
          <Grid.Col span={{ md: 6 }}>
            {detailsCard(
              "Total Taxable Amount",
              convertToIndianCurrency(
                data?.TotalBaseAmt + data?.DeletedBaseAmt || 0
              )
            )}
            {detailsCard(
              "Total GST",
              convertToIndianCurrency(data?.TotalGST + data?.DeletedGST || 0)
            )}
            {detailsCard(
              "Total CESS",
              convertToIndianCurrency(
                data?.TotalCESS1 +
                  data?.TotalCESS2 +
                  data?.DeletedCess1 +
                  data?.DeletedCess2 || 0
              )
            )}
            {detailsCard(
              "Total Discount",
              convertToIndianCurrency(
                data?.TotalDiscount + data?.DeletedDisc || 0
              )
            )}
            {detailsCard(
              "Gross Sale",
              convertToIndianCurrency(
                data?.TotalSale + data?.DeletedAmount || 0
              )
            )}
          </Grid.Col>
          <Grid.Col span={{ md: 6 }}>
            {detailsCard(
              "Void Bills Taxable Amount",
              convertToIndianCurrency(data?.DeletedBaseAmt || 0)
            )}
            {detailsCard(
              "Refund Bills Taxable Amount",
              convertToIndianCurrency(data?.RefundBaseAmt || 0)
            )}
            {detailsCard(
              "GST Deducted",
              convertToIndianCurrency(data.RefundGSTAmt + data.DeletedGST || 0)
            )}
            {detailsCard(
              "CESS Deducted",
              convertToIndianCurrency(
                data?.RefundCESS1 +
                  data?.RefundCESS2 +
                  data?.DeletedCess1 +
                  data?.DeletedCess2 || 0
              )
            )}
            {detailsCard(
              "Total Void Bills Amount",
              convertToIndianCurrency(data?.DeletedAmount || 0)
            )}
            {detailsCard(
              "Total Refund Bills Amount",
              convertToIndianCurrency(data?.RefundAmt || 0)
            )}
          </Grid.Col>
        </Grid>
      </ScrollArea>
    </>
  );
};

export default SummaryCard;
