import {
  ActionIcon,
  Card,
  Divider,
  Grid,
  Group,
  Input,
  Pagination,
  Select,
  Skeleton,
  Text,
  Tooltip,
  rem,
  Button,
  Flex,
  NumberInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useGetItemList } from "../../modules/itemList/itemListHooks";
import {
  useCreateItem,
  useDeleteAllItem,
  useDeleteItem,
  useDownloadItem,
  useGetItem,
  useSearchItem,
  useUpdateItem,
} from "../../modules/item/itemHooks";
import {
  GetItemProps,
  ItemProps,
} from "../../modules/item/service/itemService";
import { ItemComponent } from "./item/ItemComponent";
import NoRecordsFound from "../../utils/uiUtils/NoRecordsFound";
import {
  IconCheck,
  IconDownload,
  IconPlus,
  IconSearch,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { Helmet } from "react-helmet-async";
import { useForm } from "@mantine/form";
import classes from "../styleModules/AppShellMainStyles.module.css";
import CreateItem from "./item/CreateItem";
import { notifications } from "@mantine/notifications";
import BulkUploadModal from "./item/BulkUploadModal";
import { saveAs } from "file-saver";
import { modals } from "@mantine/modals";

export interface onDeleteCallbackProps {
  itemId: String;
  itemListId: String;
}

export interface CreateItemProps {
  ICode: number;
  IName: string;
  Price: {
    Price: number;
    IGST: number;
    SGST: number;
    CGST: number;
  }[];
  DCode?: number;
  GCode?: number;
  HSN?: string;
  DName?: string;
  GName?: string;
  Unit?: string;
  CP?: number;
  DiscPercent?: number;
  CESS1?: number;
  CESS2?: number;
  OpenPriceFlag?: boolean;
  DecimalDisableFlag?: boolean;
}

interface ItemListProp {
  _id: string;
  itemListName: string;
}

export const Items = () => {
  const {
    loading: itemsListLoading,

    data: fetchItemsListData,
    fetchItemList,
  } = useGetItemList();
  const { loading, data: itemData, fetchItem } = useGetItem();
  const { downloadData, downloadItems } = useDownloadItem();
  const {
    createItem,

    error: createItemError,
    errorData: createErrorData,
    isCreated,
    notDuplicate,
  } = useCreateItem();
  const {
    updateItem,
    error: updateError,
    errorData: updateErrorData,
    isUpdated,
  } = useUpdateItem();
  const {
    deleteItemFunction,

    error: deleteError,
    errorData: deleteErrorData,
    isDeleted,
  } = useDeleteItem();
  const {
    deleteAllItemFunction,
    error: deleteAllError,
    errorData: deleteAllErrorData,
    loading: deleteAllLoading,
    isDeleted: bulkDeleted,
  } = useDeleteAllItem();
  const {
    searchItemHook,
    loading: searchLoading,
    data: searchData,
  } = useSearchItem();

  const [activePage, setActivePage] = useState(1);
  const [selectedItemListId, setSelectedItemListId] = useState("");
  const [limit] = useState(10);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(0);
  const [currentData, setCurrentData] = useState([]);
  const [itemDataForDownload, setItemDataForDownload] = useState<any>([]);
  const [createItemDialog, setCreateItemDialog] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteAllFlag, setDeleteAllFlag] = useState(false);
  const [showDropZone, setShowDropZone] = useState(false);
  const [searchSelected, setSearchSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [editItemDialog, setEditItemDialog] = useState(false);
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [searchpage, setSearchpage] = useState(activePage);

  let samplefileformat = [
    {
      HSN: " ",
      ICODE: 1,
      INAME: "IDLI",
      UNIT: "Pcs",
      IPRICE1: 250,
      CGST1: 5,
      SGST1: 5,
      IGST1: 8,
      IPRICE2: 200,
      CGST2: 4,
      SGST2: 4,
      IGST2: 4,
      IPRICE3: 150,
      CGST3: 3,
      SGST3: 3,
      IGST3: 7,
      CP: 1,
      GCODE: 1,
      GNAME: " ",
      DCODE: 1,
      DNAME: " ",
      OPF: 1,
      DEC_NO: 1,
      DIS: 1,
      CESS1: 0,
      CESS2: 0,
    },
  ];

  useEffect(() => {
    setSearchpage(activePage);
  }, [activePage]);

  useEffect(() => {
    const getItemListData = {
      businessId: localStorage.getItem("businessId") ?? "",
      page: 1,
      limit: 1000,
    };
    fetchItemList(getItemListData);
  }, []);

  useEffect(() => {
    if (fetchItemsListData?.docs?.length > 0) {
      setSelectedItemListId(fetchItemsListData?.docs[0]?._id);
      setActivePage(1);

      const getItemsData: GetItemProps = {
        ItemListId: fetchItemsListData?.docs[0]?._id,
        page: 1,
        limit: limit,
      };
      fetchItem(getItemsData);
    }
  }, [fetchItemsListData]);

  useEffect(() => {
    if (!searchSelected) {
      if (itemData?.docs !== undefined) {
        setTotalPages(itemData?.pages);
        setCurrentData(itemData?.docs);
      } else {
        setCurrentData([]);
        setTotalPages(0);
      }
    } else if (searchSelected) {
      if (searchData?.docs !== undefined) {
        setTotalPages(searchData?.pages);
        setCurrentData(searchData?.docs);
      } else {
        setCurrentData([]);
        setTotalPages(0);
      }
    }
  }, [itemData, searchData]);

  useEffect(() => {
    let updatedItemsExport: any = [];

    if (downloadData?.docs !== undefined && exportLoading) {
      if (downloadData?.docs?.length > 0) {
        try {
          updatedItemsExport = downloadData?.docs?.map((item: any) => {
            return {
              HSN: item?.HSN,
              ICODE: item?.ICode,
              INAME: item?.IName,
              UNIT: item?.Unit,
              IPRICE1: item?.Price[0]?.Price ?? 0,
              CGST1: item?.Price[0]?.CGST ?? 0,
              SGST1: item?.Price[0]?.SGST ?? 0,
              IGST1: item?.Price[0]?.IGST ?? 0,
              IPRICE2: item?.Price[1]?.Price ?? 0,
              CGST2: item?.Price[1]?.CGST ?? 0,
              SGST2: item?.Price[1]?.SGST ?? 0,
              IGST2: item?.Price[1]?.IGST ?? 0,
              IPRICE3: item?.Price[2]?.Price ?? 0,
              CGST3: item?.Price[2]?.CGST ?? 0,
              SGST3: item?.Price[2]?.SGST ?? 0,
              IGST3: item?.Price[2]?.IGST ?? 0,
              CP: item?.CP,
              GCODE: item?.GCode,
              GNAME: item?.GName,
              DCODE: item?.DCode,
              DNAME: item?.DName,
              OPF: item?.OpenPriceFlag ? 1 : 0,
              DEC_NO: item?.DecimalDisableFlag ? 1 : 0,
              DIS: item?.DiscPercent ?? 0,
              CESS1: item?.CESS1 ?? 0,
              CESS2: item?.CESS2 ?? 0,
            };
          });

          setItemDataForDownload((prevArray: any) => [
            ...prevArray,
            ...updatedItemsExport,
          ]);
        } catch (e) {
          notifications.show({
            title: "Error",
            message: `Export Error :${e}`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
        }
      }
    }
  }, [downloadData]);

  useEffect(() => {
    if (itemDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(downloadData?.total / downloadLimit);

      if (total_Pages < nextPage) {
        try {
          downloadReportFile(itemDataForDownload, "Item.csv");
          setExportLoading(false);
          setItemDataForDownload([]);
          setDownloadPage(1);
        } catch (err) {
          notifications.show({
            title: "Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
          setItemDataForDownload([]);
          setDownloadPage(1);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);
        const downloadProps = {
          ItemListId: selectedItemListId,
          page: nextPage,
          limit: downloadLimit,
        };
        downloadItems(downloadProps);
      }
    }
  }, [itemDataForDownload]);

  useEffect(() => {
    if (isCreated && createFlag) {
      if (notDuplicate) {
        notifications.show({
          title: "Success",
          message: "Item Created Successfully",
          color: "green",
          icon: <IconCheck size="1.1rem" />,
        });
        const getItemsData: GetItemProps = {
          ItemListId: selectedItemListId,
          page: activePage,
          limit: limit,
        };
        const searchItemsData = {
          ItemListId: selectedItemListId,
          page: 1,
          limit: limit,
          IName: searchQuery,
        };
        if (searchSelected) {
          searchItemHook(searchItemsData);
        } else {
          fetchItem(getItemsData);
        }
        setCreateItemDialog(false);
        form.reset();
      } else if (!notDuplicate) {
        notifications.show({
          title: "Error",
          message: "Item already exists,creation failed",
          color: "red",
          icon: <IconX size="1.1rem" />,
        });
      }
    } else if (createItemError && createFlag) {
      notifications.show({
        title: "Error",
        message: `Create Item Error :${createErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isCreated, createItemError]);

  useEffect(() => {
    if (isUpdated && updateFlag) {
      notifications.show({
        title: "Success",
        message: "Item Updated Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      const getItemsData: GetItemProps = {
        ItemListId: selectedItemListId,
        page: activePage,
        limit: limit,
      };
      const searchItemsData = {
        ItemListId: selectedItemListId,
        page: 1,
        limit: limit,
        IName: searchQuery,
      };

      if (searchSelected) {
        searchItemHook(searchItemsData);
      } else {
        fetchItem(getItemsData);
      }
      // fetchItem(getItemsData);
      setEditItemDialog(false);
    } else if (updateError && updateFlag) {
      notifications.show({
        title: "Error",
        message: `Item Update Error :${updateErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isUpdated, updateError]);

  useEffect(() => {
    if (isDeleted && deleteFlag) {
      notifications.show({
        title: " Success",
        message: "Item Deleted Successfully",
        color: "red",
        icon: <IconTrash size="1.1rem" />,
      });
      const getItemsData: GetItemProps = {
        ItemListId: selectedItemListId,
        page: activePage,
        limit: limit,
      };
      const searchItemsData = {
        ItemListId: selectedItemListId,
        page: 1,
        limit: limit,
        IName: searchQuery,
      };
      if (searchSelected) {
        searchItemHook(searchItemsData);
      } else {
        fetchItem(getItemsData);
      }
    } else if (deleteError && deleteFlag) {
      notifications.show({
        title: "Error",
        message: `Item Delete Error :${deleteErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isDeleted, deleteError]);

  useEffect(() => {
    if (bulkDeleted && deleteAllFlag) {
      notifications.show({
        title: "Success",
        message: "Deleted All Items Successfully",
        color: "red",
        icon: <IconTrash size="1.1rem" />,
      });
      const getItemsData: GetItemProps = {
        ItemListId: selectedItemListId,
        page: 1,
        limit: limit,
      };

      fetchItem(getItemsData);
    } else if (deleteAllError && deleteAllFlag) {
      notifications.show({
        title: "Error",
        message: `Item Delete Error :${deleteAllErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [bulkDeleted, deleteAllError]);

  const handleItemListChange = (event: any) => {
    setSearchQuery("");
    setItemDataForDownload([]);
    setDownloadPage(1);
    setSearchSelected(false);
    setSelectedItemListId(event);
    setExportLoading(false);
    setItemDataForDownload([]);
    const getItemsData: GetItemProps = {
      ItemListId: event,
      page: 1,
      limit: limit,
    };
    fetchItem(getItemsData);
    setActivePage(1);
  };

  function handleCreateItem(values: any) {
    let createValues = { ...values };
    if (
      createValues.HSN === undefined ||
      createValues.HSN.length === 0 ||
      createValues.HSN === null
    ) {
      delete createValues.HSN;
    }
    if (
      createValues.Unit === undefined ||
      createValues.Unit.length === 0 ||
      createValues.Unit === null
    ) {
      delete createValues.Unit;
    }
    if (
      createValues.DCode === undefined ||
      createValues.DCode === null ||
      isNaN(createValues.DCode) ||
      createValues.DCode === ""
    ) {
      delete createValues.DCode;
    }
    if (
      createValues.GCode === undefined ||
      createValues.GCode === null ||
      isNaN(createValues.GCode) ||
      createValues.GCode === ""
    ) {
      delete createValues.GCode;
    }
    if (
      createValues.DName === undefined ||
      createValues.DName === null ||
      createValues.DName.length === 0
    ) {
      delete createValues.DName;
    }
    if (
      createValues.GName === undefined ||
      createValues.GName === null ||
      createValues.GName.length === 0
    ) {
      delete createValues.GName;
    }
    if (
      createValues.CP === undefined ||
      createValues.CP === null ||
      isNaN(createValues.CP) ||
      createValues.CP === ""
    ) {
      delete createValues.CP;
    }
    if (
      createValues.DiscPercent === undefined ||
      createValues.DiscPercent === null ||
      isNaN(createValues.DiscPercent) ||
      createValues.DiscPercent === ""
    ) {
      delete createValues.DiscPercent;
    }
    if (
      createValues.CESS1 === undefined ||
      createValues.CESS1 === null ||
      isNaN(createValues.CESS1) ||
      createValues.CESS1 === ""
    ) {
      delete createValues.CESS1;
    }
    if (
      createValues.CESS2 === undefined ||
      createValues.CESS2 === null ||
      isNaN(createValues.CESS2) ||
      createValues.CESS2 === ""
    ) {
      delete createValues.CESS2;
    }
    if (
      createValues.Price[0].Price === undefined ||
      createValues.Price[0].Price === null ||
      isNaN(createValues.Price[0].Price)
    ) {
      createValues.Price[0].Price = 0;
    }
    if (
      createValues.Price[0].CGST === undefined ||
      createValues.Price[0].CGST === null ||
      isNaN(createValues.Price[0].CGST) ||
      createValues.Price[0].CGST === ""
    ) {
      createValues.Price[0].CGST = 0;
    }
    if (
      createValues.Price[0].SGST === undefined ||
      createValues.Price[0].SGST === null ||
      isNaN(createValues.Price[0].SGST) ||
      createValues.Price[0].SGST === ""
    ) {
      createValues.Price[0].SGST = 0;
    }
    if (
      createValues.Price[0].IGST === undefined ||
      createValues.Price[0].IGST === null ||
      isNaN(createValues.Price[0].IGST) ||
      createValues.Price[0].IGST === ""
    ) {
      createValues.Price[0].IGST = 0;
    }
    if (
      createValues.Price[1].Price === undefined ||
      createValues.Price[1].Price === null ||
      isNaN(createValues.Price[1].Price)
    ) {
      createValues.Price[1].Price = 0;
    }
    if (
      createValues.Price[1].CGST === undefined ||
      createValues.Price[1].CGST === null ||
      isNaN(createValues.Price[1].CGST) ||
      createValues.Price[1].CGST === ""
    ) {
      createValues.Price[1].CGST = 0;
    }
    if (
      createValues.Price[1].SGST === undefined ||
      createValues.Price[1].SGST === null ||
      isNaN(createValues.Price[1].SGST) ||
      createValues.Price[1].SGST === ""
    ) {
      createValues.Price[1].SGST = 0;
    }
    if (
      createValues.Price[1].IGST === undefined ||
      createValues.Price[1].IGST === null ||
      isNaN(createValues.Price[1].IGST) ||
      createValues.Price[1].IGST === ""
    ) {
      createValues.Price[1].IGST = 0;
    }
    if (
      createValues.Price[2].Price === undefined ||
      createValues.Price[2].Price === null ||
      isNaN(createValues.Price[2].Price)
    ) {
      createValues.Price[2].Price = 0;
    }
    if (
      createValues.Price[2].CGST === undefined ||
      createValues.Price[2].CGST === null ||
      isNaN(createValues.Price[2].CGST) ||
      createValues.Price[2].CGST === ""
    ) {
      createValues.Price[2].CGST = 0;
    }
    if (
      createValues.Price[2].SGST === undefined ||
      createValues.Price[2].SGST === null ||
      isNaN(createValues.Price[2].SGST) ||
      createValues.Price[2].SGST === ""
    ) {
      createValues.Price[2].SGST = 0;
    }
    if (
      createValues.Price[2].IGST === undefined ||
      createValues.Price[2].IGST === null ||
      isNaN(createValues.Price[2].IGST) ||
      createValues.Price[2].IGST === ""
    ) {
      createValues.Price[2].IGST = 0;
    }

    if (
      createValues.OpenPriceFlag === undefined ||
      createValues.OpenPriceFlag === null
    ) {
      createValues.OpenPriceFlag = false;
    }
    if (
      createValues.DecimalDisableFlag === undefined ||
      createValues.DecimalDisableFlag === null
    ) {
      createValues.DecimalDisableFlag = false;
    }
    const createItemData: ItemProps = {
      ItemListId: selectedItemListId.toString(),
      item: [createValues],
    };

    createItem(createItemData);
    setCreateFlag(true);
  }

  const handleDeleteItem = (item: any) => {
    modals.openConfirmModal({
      title: `Delete Confirmation `,
      size: "md",
      centered: true,
      children: (
        <>
          <Text size="md">Do you want to delete Item - {item.IName} ?</Text>
        </>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const deleteData = {
          itemId: item._id,
          itemListId: item.ItemListId,
        };

        deleteItemFunction(deleteData);
        setDeleteFlag(true);
      },
    });
  };

  const handleDeleteAllItem = () => {
    modals.openConfirmModal({
      title: "Delete Confirmation",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete All the Items? This action is
          destructive and you will lose all the Items Data.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const deleteAllData = {
          itemListId: selectedItemListId,
        };
        setDeleteAllFlag(true);
        deleteAllItemFunction(deleteAllData);
      },
    });
  };

  function handleUpdateItem(itemListId: any, values: any) {
    let updateValues = { ...values };
    if (
      updateValues.HSN === undefined ||
      updateValues.HSN.length === 0 ||
      updateValues.HSN === null
    ) {
      delete updateValues.HSN;
    }
    if (
      updateValues.Unit === undefined ||
      updateValues.Unit.length === 0 ||
      updateValues.Unit === null
    ) {
      delete updateValues.Unit;
    }
    if (
      updateValues.DCode === undefined ||
      updateValues.DCode === null ||
      isNaN(updateValues.DCode) ||
      updateValues.DCode === ""
    ) {
      delete updateValues.DCode;
    }
    if (
      updateValues.GCode === undefined ||
      updateValues.GCode === null ||
      isNaN(updateValues.GCode) ||
      updateValues.GCode === ""
    ) {
      delete updateValues.GCode;
    }
    if (
      updateValues.DName === undefined ||
      updateValues.DName === null ||
      updateValues.DName.length === 0
    ) {
      delete updateValues.DName;
    }
    if (
      updateValues.GName === undefined ||
      updateValues.GName === null ||
      updateValues.GName.length === 0
    ) {
      delete updateValues.GName;
    }
    if (
      updateValues.CP === undefined ||
      updateValues.CP === null ||
      isNaN(updateValues.CP) ||
      updateValues.CP === ""
    ) {
      delete updateValues.CP;
    }
    if (
      updateValues.DiscPercent === undefined ||
      updateValues.DiscPercent === null ||
      isNaN(updateValues.DiscPercent) ||
      updateValues.DiscPercent === ""
    ) {
      delete updateValues.DiscPercent;
    }
    if (
      updateValues.CESS1 === undefined ||
      updateValues.CESS1 === null ||
      isNaN(updateValues.CESS1) ||
      updateValues.CESS1 === ""
    ) {
      delete updateValues.CESS1;
    }
    if (
      updateValues.CESS2 === undefined ||
      updateValues.CESS2 === null ||
      isNaN(updateValues.CESS2) ||
      updateValues.CESS2 === ""
    ) {
      delete updateValues.CESS2;
    }
    if (
      updateValues.Price[0].Price === undefined ||
      updateValues.Price[0].Price === null ||
      isNaN(updateValues.Price[0].Price)
    ) {
      updateValues.Price[0].Price = 0;
    }
    if (
      updateValues.Price[0].CGST === undefined ||
      updateValues.Price[0].CGST === null ||
      isNaN(updateValues.Price[0].CGST) ||
      updateValues.Price[0].CGST === ""
    ) {
      updateValues.Price[0].CGST = 0;
    }
    if (
      updateValues.Price[0].SGST === undefined ||
      updateValues.Price[0].SGST === null ||
      isNaN(updateValues.Price[0].SGST) ||
      updateValues.Price[0].SGST === ""
    ) {
      updateValues.Price[0].SGST = 0;
    }
    if (
      updateValues.Price[0].IGST === undefined ||
      updateValues.Price[0].IGST === null ||
      isNaN(updateValues.Price[0].IGST) ||
      updateValues.Price[0].IGST === ""
    ) {
      updateValues.Price[0].IGST = 0;
    }
    if (
      updateValues.Price[1].Price === undefined ||
      updateValues.Price[1].Price === null ||
      isNaN(updateValues.Price[1].Price)
    ) {
      updateValues.Price[1].Price = 0;
    }
    if (
      updateValues.Price[1].CGST === undefined ||
      updateValues.Price[1].CGST === null ||
      isNaN(updateValues.Price[1].CGST) ||
      updateValues.Price[1].CGST === ""
    ) {
      updateValues.Price[1].CGST = 0;
    }
    if (
      updateValues.Price[1].SGST === undefined ||
      updateValues.Price[1].SGST === null ||
      isNaN(updateValues.Price[1].SGST) ||
      updateValues.Price[1].SGST === ""
    ) {
      updateValues.Price[1].SGST = 0;
    }
    if (
      updateValues.Price[1].IGST === undefined ||
      updateValues.Price[1].IGST === null ||
      isNaN(updateValues.Price[1].IGST) ||
      updateValues.Price[1].IGST === ""
    ) {
      updateValues.Price[1].IGST = 0;
    }
    if (
      updateValues.Price[2].Price === undefined ||
      updateValues.Price[2].Price === null ||
      isNaN(updateValues.Price[2].Price)
    ) {
      updateValues.Price[2].Price = 0;
    }
    if (
      updateValues.Price[2].CGST === undefined ||
      updateValues.Price[2].CGST === null ||
      isNaN(updateValues.Price[2].CGST) ||
      updateValues.Price[2].CGST === ""
    ) {
      updateValues.Price[2].CGST = 0;
    }
    if (
      updateValues.Price[2].SGST === undefined ||
      updateValues.Price[2].SGST === null ||
      isNaN(updateValues.Price[2].SGST) ||
      updateValues.Price[2].SGST === ""
    ) {
      updateValues.Price[2].SGST = 0;
    }
    if (
      updateValues.Price[2].IGST === undefined ||
      updateValues.Price[2].IGST === null ||
      isNaN(updateValues.Price[2].IGST) ||
      updateValues.Price[2].IGST === ""
    ) {
      updateValues.Price[2].IGST = 0;
    }

    if (
      updateValues.OpenPriceFlag === undefined ||
      updateValues.OpenPriceFlag === null
    ) {
      updateValues.OpenPriceFlag = false;
    }
    if (
      updateValues.DecimalDisableFlag === undefined ||
      updateValues.DecimalDisableFlag === null
    ) {
      updateValues.DecimalDisableFlag = false;
    }

    const updateItemData: ItemProps = {
      ItemListId: itemListId,
      isEnforce: false,
      item: [updateValues],
    };

    updateItem(updateItemData);
    setUpdateFlag(true);
  }

  const onPageChange = (page: any) => {
    console.log("page----------------------", page);
    if (searchSelected) {
      const getItemsData: GetItemProps = {
        ItemListId: selectedItemListId,
        page: page,
        limit: limit,
        IName: searchQuery,
      };
      searchItemHook(getItemsData);
    } else {
      const getItemsData: GetItemProps = {
        ItemListId: selectedItemListId,
        page: page,
        limit: limit,
      };
      fetchItem(getItemsData);
    }
    setActivePage(page);
  };

  function handleDropZoneShow() {
    setShowDropZone(!showDropZone);
  }

  const form = useForm<CreateItemProps>({
    initialValues: {
      ICode: 0,
      IName: "",
      Price: [
        { Price: 0, CGST: 0, SGST: 0, IGST: 0 },
        { Price: 0, CGST: 0, SGST: 0, IGST: 0 },
        { Price: 0, CGST: 0, SGST: 0, IGST: 0 },
      ],
      DCode: 0,
      GCode: 0,
      DName: "",
      GName: "",
      CP: 0,
      DiscPercent: 0,
      CESS1: 0,
      CESS2: 0,
      OpenPriceFlag: false,
      DecimalDisableFlag: false,
    },
    validate: {
      ICode: (value) =>
        value === 0 || value === undefined
          ? "must be greater than 0"
          : value > 2000
          ? "Must be 2000 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      IName: (value) =>
        value.length === 0
          ? "Should not be empty"
          : value.length > 24
          ? "Must be 24 or less"
          : null,

      DCode: (value) =>
        value === undefined || value > 999
          ? "Must be 999 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      GCode: (value) =>
        value === undefined || value > 999
          ? "Must be 999 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      HSN: (value) =>
        value !== undefined
          ? value?.length === 0
            ? null
            : value?.length < 2
            ? "Must be 2 or more characters"
            : value?.length > 9
            ? "Must be less than 10 characters"
            : null
          : null,
      DName: (value) =>
        value === undefined || value.length > 19 ? "Must be 19 or less" : null,
      GName: (value) =>
        value === undefined || value.length > 19 ? "Must be 19 or less" : null,
      Unit: (value) =>
        value !== undefined
          ? value?.length === 0
            ? null
            : value?.length > 3
            ? "Must be 3 or less"
            : null
          : null,
      CP: (value) =>
        value === undefined || value > 9999999
          ? "Must be 9999999 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      DiscPercent: (value) =>
        value === undefined || value > 100
          ? "Must be 100 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      CESS1: (value) =>
        value === undefined || value > 100
          ? "Must be 100 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      CESS2: (value) =>
        value === undefined || value > 100
          ? "Must be 100 or less"
          : value < 0
          ? "Must not be negative"
          : null,
      Price: {
        Price: (value: number) =>
          value > 9999999
            ? "Must be 9999999 or less"
            : value < 0
            ? "Must not be negative"
            : null,
        CGST: (value: number) =>
          value > 100
            ? "Must be 100 or less"
            : value < 0
            ? "Must not be negative"
            : null,
        SGST: (value: number) =>
          value > 100
            ? "Must be 100 or less"
            : value < 0
            ? "Must not be negative"
            : null,
        IGST: (value: number) =>
          value > 100
            ? "Must be 100 or less"
            : value < 0
            ? "Must not be negative"
            : null,
      },
    },
  });

  const itemListData = (): { value: string; label: string }[] => {
    let data: any = fetchItemsListData?.docs?.map((item: ItemListProp) => {
      return {
        value: item?._id,
        label: item?.itemListName ?? " ",
      };
    });
    return data === undefined ? [] : data;
  };

  function downloadReportFile(data: any, fileName: any) {
    try {
      const replacer = (key: any, value: any) => (value === null ? "" : value);
      const header = Object.keys(data[0]);
      let csv = data.map((row: any) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      let csvArray = csv.join("\r\n");

      var blob = new Blob([csvArray], { type: "text/csv" });
      saveAs(blob, fileName);
    } catch (e) {
      notifications.show({
        title: "Error",
        message: `Export Error :${e}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }

  function onItemsExport() {
    if (itemData?.docs?.length === 0 || itemData?.docs === undefined) {
      downloadReportFile(samplefileformat, "Item.csv");
    } else {
      const getItemsData: GetItemProps = {
        ItemListId: selectedItemListId,
        page: activePage,
        limit: downloadLimit,
      };

      downloadItems(getItemsData);
      setExportLoading(true);
    }
  }

  const handleSearch = (e: any) => {
    // setSearchQuery(e);
    setSearchSelected(true);

    const getItemsData: GetItemProps = {
      ItemListId: selectedItemListId,
      page: 1,
      limit: limit,
      IName: e,
    };
    searchItemHook(getItemsData);
  };

  return (
    <>
      <Helmet title="Items" />
      <CreateItem
        handleCreateItem={handleCreateItem}
        setCreateItemDialog={setCreateItemDialog}
        createItemDialog={createItemDialog}
        form={form}
      />
      <Grid className={classes.mainGrid}>
        <Grid.Col className={classes.mainTitleCol}>
          <Text className={classes.mainTitle}>Items</Text>
        </Grid.Col>
        <Divider style={{ width: "100%" }} />
        <Grid.Col className={classes.mainNav}>
          <Group
            style={{
              position: "relative",
              top: "1px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!itemsListLoading && (
              <>
                <Select
                  disabled={exportLoading}
                  radius="md"
                  selectFirstOptionOnChange={false}
                  allowDeselect={false}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  value={selectedItemListId}
                  label="ItemList"
                  onChange={(e) => {
                    if (e !== undefined && e !== null) {
                      handleItemListChange(e);
                    }
                  }}
                  placeholder="Select ItemList"
                  data={itemListData()}
                />
                <Group
                  style={{ display: "flex", flexWrap: "wrap" }}
                  mt={{ md: 20, lg: 20, xl: 20 }}
                >
                  <Input
                    disabled={
                      itemData?.docs?.length === 0 ||
                      itemData?.docs === undefined
                    }
                    placeholder="Search"
                    leftSection={
                      <IconSearch
                        style={{ width: rem(16), height: rem(16) }}
                        stroke={1.5}
                      />
                    }
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.currentTarget.value);
                      if (e.currentTarget.value.length === 0) {
                        setSearchSelected(false);
                        const getItemsData: GetItemProps = {
                          ItemListId: selectedItemListId,
                          page: 1,
                          limit: limit,
                        };
                        fetchItem(getItemsData);
                      } else {
                        handleSearch(e.currentTarget.value);
                      }
                    }}
                  />
                  <Group>
                    <Tooltip label="Add Item">
                      <ActionIcon
                        // variant="default"
                        size="lg"
                        onClick={() => {
                          setCreateItemDialog(!createItemDialog);
                        }}
                      >
                        <IconPlus />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Import Items">
                      <ActionIcon
                        variant="default"
                        size="lg"
                        onClick={() => handleDropZoneShow()}
                      >
                        <IconDownload />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Export Items/Sample item file">
                      <ActionIcon
                        loading={exportLoading}
                        variant="default"
                        size="lg"
                        onClick={onItemsExport}
                        // disabled={
                        //   itemData?.docs?.length === 0 ||
                        //   itemData?.docs === undefined
                        // }
                      >
                        <IconUpload />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Delete All Items">
                      <ActionIcon
                        disabled={
                          itemData?.docs?.length === 0 ||
                          itemData?.docs === undefined
                        }
                        loading={deleteAllLoading}
                        size="lg"
                        style={{
                          background: " var(--mantine-color-red-7)",
                        }}
                        onClick={() => {
                          if (
                            itemData?.docs !== undefined &&
                            itemData.docs.length > 0
                          ) {
                            handleDeleteAllItem();
                          }
                        }}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  </Group>

                  <BulkUploadModal
                    fetchItem={fetchItem}
                    selectedItemListId={selectedItemListId}
                    showDropZone={showDropZone}
                    handleDropZoneClose={handleDropZoneShow}
                  />
                </Group>
              </>
            )}
          </Group>
        </Grid.Col>
        <Grid.Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "calc(90vh - 200px)",
          }}
        >
          <Card className={classes.itemMainCard} withBorder radius="md">
            {(loading || searchLoading) && (
              <div
                style={{
                  margin: "15px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "37px",
                }}
              >
                <Grid className={classes.itemMainCard}>
                  {[...Array(1)].map((_, index) => (
                    <Grid.Col
                      key={index}
                      span={{ md: 6, xs: 12, sm: 12, lg: 6, xl: 4 }}
                    >
                      <Card
                        withBorder
                        key={index}
                        style={{
                          height: "230px",
                          minWidth: "300px",
                          width: "auto",
                          // margin: "20px",
                        }}
                      >
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="70%" radius="xl" />
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </div>
            )}
            <Grid style={{ overflowY: "auto", height: "100%" }}>
              {currentData?.map((item: any, index: any) => (
                <Grid.Col
                  key={index}
                  span={{ md: 6, xs: 12, sm: 12, lg: 6, xl: 4 }}
                >
                  <ItemComponent
                    handleDeleteItem={handleDeleteItem}
                    handleUpdateItem={handleUpdateItem}
                    itemData={item}
                    setEditItemDialog={setEditItemDialog}
                    editItemDialog={editItemDialog}
                    setSelectedItemCode={setSelectedItemCode}
                    selectedItemCode={selectedItemCode}
                  />
                </Grid.Col>
              ))}
            </Grid>
            {!loading &&
              !searchLoading &&
              (currentData?.length === 0 ||
                currentData?.length === undefined) && (
                <Grid.Col>
                  <NoRecordsFound />
                </Grid.Col>
              )}
          </Card>
        </Grid.Col>
        <Grid.Col>
          <Flex
            mb={40}
            p={15}
            pt={5}
            mih={50}
            align="center"
            gap="md"
            justify="flex-end"
            direction="row"
            wrap="wrap"
          >
            <Group>
              <NumberInput
                value={searchpage}
                w={60}
                size="xs"
                mt="sm"
                placeholder="Page"
                radius="sm"
                min={1}
                // max={totalPages}
                hideControls
                onChange={(event) => {
                  setSearchpage(Number(event));
                }}
              />

              <Button
                mt="sm"
                size="xs"
                variant="outline"
                onClick={() => {
                  const pageNumber2 = searchpage;
                  if (pageNumber2 < 1 || pageNumber2 > totalPages) {
                    notifications.show({
                      title: "Error",
                      message: "Invalid page number",
                      color: "red",
                      icon: <IconX size="1.1rem" />,
                    });
                  } else {
                    onPageChange(searchpage);
                  }
                }}
              >
                Go
              </Button>
            </Group>
            <Pagination
              // radius="xl"
              style={{
                // width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              value={activePage}
              onChange={onPageChange}
              total={totalPages}
            />
          </Flex>{" "}
        </Grid.Col>
      </Grid>
    </>
  );
};
