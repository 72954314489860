import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../awatarStore/store";
import {
  BillRefundProps,
  BillWiseProps,
  DayWiseProps,
  GSTSummaryProps,
  GetAggregateReportProps,
  GetBillRefundSummaryReport,
  GetBillWiseSummaryReport,
  ItemWiseProps,
  MonthlyWiseProps,
  UserReportProps,
} from "./service/reportService";
import {
  downloadAggregateReports,
  downloadBillRefundReports,
  downloadBillWiseReports,
  downloadDayWiseReports,
  downloadGSTSummaryReports,
  downloadItemWiseReports,
  downloadMonthlyWiseReports,
  downloadUserWiseReports,
  getAggregate,
  getBillRefund,
  getBillRefundSummary,
  getBillWise,
  getDashboardData,
  getDayWise,
  getGSTSummary,
  getItemWise,
  getMonthlyWise,
  getSummary,
  getUserWise,
  getVoidBillWise,
} from "./reportActions";

export const useGetDashboardData = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getDashboardData"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getDashboardData"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getDashboardData"].data
  );
  const errorData = useSelector(
    (state: RootState) => state["report/getDashboardData"].errorData
  );


  const getDashboardDataFunction = async () => {
    try {
      await dispatch(getDashboardData());
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getDashboardDataFunction, loading, error, data,errorData };
}

export const useGetBillWise = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getBillWise"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getBillWise"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getBillWise"].data
  );

  const getBillWiseFunction = async (billWiseProps: BillWiseProps) => {
    try {
      await dispatch(getBillWise(billWiseProps));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getBillWiseFunction, data, loading, error };
};
export const useGetDayWise = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getDayWise"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getDayWise"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getDayWise"].data
  );

  const getDayWiseFunction = async (dayWiseProps: DayWiseProps) => {
    try {
      await dispatch(getDayWise(dayWiseProps));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getDayWiseFunction, loading, error, data };
};
export const useGetItemWise = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getItemWise"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getItemWise"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getItemWise"].data
  );

  const getItemWiseFunction = async (itemWiseData: ItemWiseProps) => {
    try {
      await dispatch(getItemWise(itemWiseData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getItemWiseFunction, loading, error, data };
};
export const useGetMonthlyWise = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getMonthlyWise"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getMonthlyWise"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getMonthlyWise"].data
  );

  const getMonthlyWiseFunction = async (montlyWiseData: MonthlyWiseProps) => {
    try {
      await dispatch(getMonthlyWise(montlyWiseData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getMonthlyWiseFunction, loading, error, data };
};
export const useGetGSTSummary = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getGSTSummary"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getGSTSummary"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getGSTSummary"].data
  );

  const getGSTSummaryFunction = async (GSTSummaryProps: GSTSummaryProps) => {
    try {
      await dispatch(getGSTSummary(GSTSummaryProps));
    } catch (error: any) {}
  };

  return { getGSTSummaryFunction, loading, error, data };
};
export const useGetSummary = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getSummary"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getSummary"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getSummary"].data
  );

  const getSummaryFunction = async (summaryProps: GetBillWiseSummaryReport) => {
    try {
      await dispatch(getSummary(summaryProps));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getSummaryFunction, loading, error, data };
};
export const useGetBillRefundSummary = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getBillRefundSummary"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getBillRefundSummary"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getBillRefundSummary"].data
  );

  const getBillRefundSummaryFunction = async (
    billRefundSummaryProps: GetBillRefundSummaryReport
  ) => {
    try {
      await dispatch(getBillRefundSummary(billRefundSummaryProps));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getBillRefundSummaryFunction, loading, error, data };
};
export const useGetUserWise = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getUserWise"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getUserWise"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getUserWise"].data
  );

  const getUserWiseFunction = async (userReportData: UserReportProps) => {
    try {
      await dispatch(getUserWise(userReportData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getUserWiseFunction, loading, error, data };
};
export const useGetVoidBillWise = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getVoidBillWise"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getVoidBillWise"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getVoidBillWise"].data
  );

  const getVoidBillWiseFunction = async (
    billWiseProps: BillWiseProps,
    onComplete: (success: boolean, error: boolean, res: any) => void
  ) => {
    try {
      const res = await dispatch(getVoidBillWise(billWiseProps));
      if (res.meta.requestStatus === "fulfilled") {
        onComplete(true, false, res.payload);
      } else if (res.meta.requestStatus === "rejected") {
        onComplete(false, true, res.payload);
      }
    } catch (error: any) {
      console.log(error.message, "Error");
      onComplete(false, true, error.message);
    }
  };

  return { getVoidBillWiseFunction, loading, error ,data};
};
export const useGetBillRefund = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getBillRefund"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getBillRefund"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getBillRefund"].data
  );

  const getBillRefundFunction = async (billRefundData: BillRefundProps) => {
    try {
      await dispatch(getBillRefund(billRefundData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getBillRefundFunction, loading, error, data };
};

export const useGetAggregate = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/getAggregate"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/getAggregate"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/getAggregate"].data
  );

  const getAggregateFunction = async (
    aggregateReportData: GetAggregateReportProps
  ) => {
    try {
      await dispatch(getAggregate(aggregateReportData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getAggregateFunction, loading, error, data };
};


export const useDownloadBillWiseReports = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadBillWiseReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadBillWiseReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadBillWiseReport"].data
  );

  const downloadBillWiseReportsFunction = async (
    billwiseData: BillWiseProps
  ) => {
    try {
      await dispatch(downloadBillWiseReports(billwiseData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadBillWiseReportsFunction, loading, error, data };
};


export const useDownloadUserWiseReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadUserWiseReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadUserWiseReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadUserWiseReport"].data
  );

  const downloadUserWiseReportFunction = async (
    userReportData: UserReportProps
  ) => {
    try {
      await dispatch(downloadUserWiseReports(userReportData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadUserWiseReportFunction, loading, error, data };
};

export const useDownloadDayWiseReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadDayWiseReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadDayWiseReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadDayWiseReport"].data
  );

  const downloadDayWiseReportFunction = async (
    dayWiseData: DayWiseProps
  ) => {
    try {
      await dispatch(downloadDayWiseReports(dayWiseData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadDayWiseReportFunction, loading, error, data };
};

export const useDownloadItemWiseReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadItemWiseReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadItemWiseReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadItemWiseReport"].data
  );

  const downloadItemWiseReportFunction = async (
    itemWiseData: ItemWiseProps
  ) => {
    try {
      await dispatch(downloadItemWiseReports(itemWiseData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadItemWiseReportFunction, loading, error, data };
};

export const useDownloadMonthlyWiseReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadMonthlyWiseReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadMonthlyWiseReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadMonthlyWiseReport"].data
  );

  const downloadMonthlyWiseReportFunction = async (
    monthlyWiseData: MonthlyWiseProps
  ) => {
    try {
      await dispatch(downloadMonthlyWiseReports(monthlyWiseData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadMonthlyWiseReportFunction, loading, error, data };
};

export const useDownloadGSTReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadGSTReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadGSTReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadGSTReport"].data
  );

  const downloadGSTReportFunction = async (
    gstData: GSTSummaryProps
  ) => {
    try {
      await dispatch(downloadGSTSummaryReports(gstData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadGSTReportFunction, loading, error, data };
};

export const useDownloadAggregateReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadAggregateReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadAggregateReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadAggregateReport"].data
  );

  const downloadAggregateReportFunction = async (
    aggregateReportData: GetAggregateReportProps
  ) => {
    try {
      await dispatch(downloadAggregateReports(aggregateReportData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return {  downloadAggregateReportFunction, loading, error, data };
};

export const useDownloadBillRefundReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["report/downloadBillRefundReport"].loading
  );
  const error = useSelector(
    (state: RootState) => state["report/downloadBillRefundReport"].error
  );
  const data = useSelector(
    (state: RootState) => state["report/downloadBillRefundReport"].data
  );

  const downloadBillRefundReportFunction = async (
    billRefundData: BillRefundProps
  ) => {
    try {
      await dispatch(downloadBillRefundReports(billRefundData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { downloadBillRefundReportFunction, loading, error, data };
}