import axios from "axios";
import { getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface BillWiseProps {
  fromDate: any;
  toDate: any;
  page: string;
  limit: string;
  BillStatus: string;
  sort: {
    _Date: boolean;
  };
  outletId?: string | undefined;
  deviceId?: string | undefined;
}

export interface BillSummaryProps {
  fromDate: string;
  toDate: string;
  BillStatus: string;
  page?: string;
  limit?: string;
  outletId?: string | undefined;
  deviceId?: string | undefined;
}

export interface DayWiseProps {
  fromDate: any;
  toDate: any;
  page: string;
  limit: string;
  BillStatus: string;
  sort: {
    Date: boolean;
  };
  outletId?: string | undefined;
  deviceId?: string | undefined;
}

export interface ItemWiseProps {
  fromDate: string;
  toDate: string;
  page: string;
  limit: string;
  BillStatus: string;
  sort: {
    _id: boolean;
  };
  outletId?: string | undefined;
  deviceId?: string | undefined;
}

export interface MonthlyWiseProps {
  fromDate: string;
  toDate: string;
  page: string;
  limit: string;
  BillStatus: string;
  sort: {
    TotalAmount: boolean;
  };
  outletId?: string | undefined;
  deviceId?: string | undefined;
}

export interface GSTSummaryProps {
  fromDate: string;
  toDate: string;
  page: string;
  limit: string;
  BillStatus: string;
  sort: {
    _id: boolean;
  };
  outletId?: string | undefined;
  deviceId?: string | undefined;
}

export interface GetBillWiseSummaryReport {
  fromDate: string;
  toDate: string;
  BillStatus: string;
  outletId?: string;
  deviceId?: string;
}

export interface GetBillRefundSummaryReport {
  fromDate: string;
  toDate: string;
  page?: string;
  limit?: string;
  outletId?: string;
  deviceId?: string;
}

export interface UserReportProps {
  fromDate: string;
  toDate: string;
  page: string;
  limit: string;
  BillStatus: string;
  sort: {
    _id: boolean;
  };
  outletId?: string;
  deviceId?: string;
}

export interface BillRefundProps {
  fromDate: string;
  toDate: string;
  page: string;
  limit: string;
  sort: {
    _Date: boolean;
  };
  outletId?: string;
  deviceId?: string;
}

export interface GetAggregateReportProps {
  fromDate: string;
  toDate: string;
  page: string;
  limit: string;
}

const removeUndefinedProperties = (obj: any) => {
  for (let prop in obj) {
    if (obj[prop] === undefined) {
      delete obj[prop];
    }
  }
  return obj;
};
export class ReportService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }


  async getDashboardData(): Promise<any> {
    try {
        let header = getHeader();
        const url = `${this.baseUrl}${api_routes.api_path_report_dashboard}`;

        const response = await axios.get(url, {
            headers: header,
        });

        return response.data;

    }
    catch (error: any) {
        throw new Error(error.response.data.message);
    }
}


  async getBillWiseReport(billWiseData: BillWiseProps): Promise<any> {
    try {
      const billWiseDataEdit = removeUndefinedProperties(billWiseData);
      // console.log("billWiseDataEdit:", billWiseDataEdit);
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_billwise}`;

      const response = await axios.post(url, billWiseDataEdit, {
        headers: header,
      });
      // console.log("resp:", response.data[0]);
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getDayWiseReport(dayWiseData: DayWiseProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_daywise}`;

      const response = await axios.post(url, dayWiseData, {
        headers: header,
      });

      return response.data[0];
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getItemWiseReport(itemWiseData: ItemWiseProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_itemwise}`;

      const response = await axios.post(url, itemWiseData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getMonthlyWiseReport(monthlyWiseData: MonthlyWiseProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_monthly}`;

      const response = await axios.post(url, monthlyWiseData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getGSTSummaryReport(gstSummaryData: GSTSummaryProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_gst}`;

      const response = await axios.post(url, gstSummaryData, {
        headers: header,
      });

      return response.data[0];
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getSummary(getSummaryData: GetBillWiseSummaryReport): Promise<any> {
    try {
      let header = getHeader();
      const getSummaryData1 = removeUndefinedProperties(getSummaryData);
      const url = `${this.baseUrl}${
        api_routes.api_path_report_summary
      }?fromDate=${getSummaryData1.fromDate}&toDate=${
        getSummaryData1.toDate
      }&BillStatus=${getSummaryData1.BillStatus}${
        getSummaryData1.outletId
          ? `&outletId=${getSummaryData1.outletId}`
          : getSummaryData1.deviceId
          ? `&deviceId=${getSummaryData1.deviceId}`
          : ""
      }`;

      const response = await axios.get(url, {
        headers: header,
      });
      // console.log("resp:", response.data[0]);
      return response.data[0];
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  //PENDING FOR TESTING
  async getBillRefundSummary(
    getSummaryData: GetBillRefundSummaryReport
  ): Promise<any> {
    try {
      let header = getHeader();
      let url = `${this.baseUrl}${api_routes.api_path_report_refund_summary}?fromDate=${getSummaryData.fromDate}&toDate=${getSummaryData.toDate}`;
      if (getSummaryData.outletId === undefined) {
        url = `${url}&deviceId=${getSummaryData.deviceId}`;
      }
      if (getSummaryData.deviceId === undefined) {
        url = `${url}&outletId=${getSummaryData.outletId}`;
      }
      const response = await axios.get(url, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getUserReport(userReportData: UserReportProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_user}`;

      const response = await axios.post(url, userReportData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getVoidBillWiseReport(billWiseData: BillWiseProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_billwise}`;

      const response = await axios.post(url, billWiseData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
  async getBillRefundReport(billWiseData: BillRefundProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_refund}`;

      const response = await axios.post(url, billWiseData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getAggregateReport(
    aggregateReportData: GetAggregateReportProps
  ): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_report_aggregate}?fromDate=${aggregateReportData.fromDate}&toDate=${aggregateReportData.toDate}&page=${aggregateReportData.page}&limit=${aggregateReportData.limit}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
