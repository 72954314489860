import React, { useEffect, useState } from "react";
import { useMantineColorScheme } from "@mantine/core";
import {
  // createStyles,
  Group,
  Paper,
  Grid,
  Text,
} from "@mantine/core";

import classes from "../styleModules/AppShellMainStyles.module.css";
import {
  useGetAggregate,
  useGetDashboardData,
} from "../../modules/reports/reportHooks";
import { Bar, Doughnut } from "react-chartjs-2";
import { GetAggregateReportProps } from "../../modules/reports/service/reportService";
import moment from "moment";
import Marquee from "react-fast-marquee";
import { convertToIndianCurrency } from "../../utils/utils";
import { useGetDeviceSubscriptionInfo } from "../../modules/subscription/subscriptionHooks";
import { Helmet } from "react-helmet-async";

export function DashBoard() {
  const {
    getDashboardDataFunction,

    data: dashboardData,
  } = useGetDashboardData();
  const { getAggregateFunction, data: AggregateData } = useGetAggregate();
  const { getDeviceSubscriptionInfoFunction, data: deviceSubscriptionData } =
    useGetDeviceSubscriptionInfo();

  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === "dark";

  const [dashboardDataState, setDashboardDataState] = React.useState<any>({});
  const [aggregateDataState, setAggregateDataState] = React.useState<any>({});
  const [doghnutData, setDoghnutData] = React.useState<any>([]);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  let apiCallInterval: any;

  function makeAPICall() {
    const getAggregateData: GetAggregateReportProps = {
      fromDate: moment().format("YYYY/MM/DD"),
      toDate: moment().format("YYYY/MM/DD"),
      page: "1",
      limit: "100",
    };

    getDashboardDataFunction();
    getDeviceSubscriptionInfoFunction();
    getAggregateFunction(getAggregateData);

    setApiCallScheduled(false);
  }
  const scheduleAPICall = () => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall();
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls //f
    clearInterval(apiCallInterval);
  };

  useEffect(() => {
    makeAPICall();

    // Schedule API calls to occur every 1 minute
    const interval = 30 * 1000; // 1 minute in milliseconds
    apiCallInterval = setInterval(scheduleAPICall, interval);

    // Add event listeners for page navigation events
    window.addEventListener("beforeunload", stopAPICalls);
    // window.addEventListener("unload", stopAPICalls);

    return () => {
      // Clear the interval and remove event listeners when the component unmounts
      clearInterval(apiCallInterval);
      window.removeEventListener("beforeunload", stopAPICalls);
      // window.removeEventListener("unload", stopAPICalls);
    };
  }, []);

  useEffect(() => {
    if (dashboardData !== undefined && dashboardData !== null) {
      setDashboardDataState(dashboardData);
    }
  }, [dashboardData]);
  useEffect(() => {
    if (AggregateData !== undefined && AggregateData !== null) {
      setAggregateDataState(AggregateData);

      const modifiedData = AggregateData?.docs?.map((doc: any) => {
        return {
          TotalSale: doc.TotalSale,
          TotalGST: doc.TotalGST,
          TotalDiscount: doc.TotalDiscount,
          outletName: doc.outletName,
        };
      });

      setDoghnutData(modifiedData);
    }
  }, [AggregateData]);

  const data = [
    {
      title: "Invoices",
      icon: "rupeee",
      iconColor: "#008944",
      textColor: "#fff",

      value: dashboardDataState.NoOfBills ?? 0,
      diff: -30,
      backgroundColor: "linear-gradient(to right,#2980B9,#63bada)",
    },
    {
      title: "Today Sales",
      icon: "receipt",
      iconColor: "#5588ff",
      textColor: "#fff",
      value: convertToIndianCurrency(dashboardDataState.TotalSale ?? 0),
      diff: 34,
      backgroundColor: "linear-gradient(to left,#4b79a1,#283e51)",
    },
    {
      title: "Total GST",
      icon: "coin",
      iconColor: "#f5bf03",
      textColor: "#fff",
      value: convertToIndianCurrency(dashboardDataState.TotalGST ?? 0),
      diff: -13,
      backgroundColor: "linear-gradient(to right,#136a8a,#267871)",
    },
    {
      title: "Total Discount",
      icon: "discount",
      iconColor: "#ff8c00",
      textColor: "#fff",
      value: convertToIndianCurrency(dashboardDataState.TotalDiscount ?? 0),
      diff: 18,
      backgroundColor: "linear-gradient(to right,#283048,#687275)",
    },
    {
      title: "Total CESS",
      icon: "rupeee",
      iconColor: "#008944",
      textColor: "#fff",

      value: convertToIndianCurrency(
        (dashboardDataState.TotalCESS1 ?? 0) +
          (dashboardDataState.TotalCESS2 ?? 0)
      ),
      diff: -30,
      backgroundColor: "linear-gradient(to right, #333333,#aaaaaa)",
    },
  ] as const;

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: isDark ? "white" : "grey",
          boxWidth: 0,
        },
      },
      title: {
        display: true,
        text: "Summary of Invoice",
        color: isDark ? "#fff" : "grey",
      },
      datalabels: {
        color: isDark ? "white" : "grey",
      },
    },
    scales: {
      type: "linear",
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45,
        },
      },
      y: {
        type: "linear",
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  const totalSaleOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: isDark ? "white" : "grey",
          boxWidth: 0,
        },
      },
      title: {
        display: true,
        text: "Summary of Sales",
        color: isDark ? "#fff" : "grey",
      },
      datalabels: {
        color: isDark ? "white" : "grey",
      },
    },
    scales: {
      y: {
        ticks: {
          autoSkip: false,
          // maxRotation: 180,
          // minRotation: 180,
        },
      },
    },
  };
  const sortedDocs = aggregateDataState?.docs
    ? [...aggregateDataState.docs].sort(
        (a: any, b: any) => b.NoOfBills - a.NoOfBills
      )
    : [];
  const labels = sortedDocs?.map((doc: any) => doc.outletName);

  const noOfBillsData = {
    labels,
    datasets: [
      {
        label: "No of Bills",
        data: sortedDocs?.map((doc: any) => doc?.NoOfBills),
        backgroundColor: "#6ecee9d2",
        maxBarThickness: 50,
        barPercentage: 0.8,
        color: "green",
      },
    ],
  };

  const totalSaleSorted = aggregateDataState?.docs
    ? [...aggregateDataState.docs].sort(
        (a: any, b: any) => b.TotalSale - a.TotalSale
      )
    : [];

  const labelssales = totalSaleSorted.map((doc: any) => doc.outletName);

  const totalSalesData = {
    labels: labelssales,
    datasets: [
      {
        label: "Total Sales",
        data: totalSaleSorted.map((doc: any) =>
          doc?.TotalSale > 0 ? (doc?.TotalSale).toFixed(2) : 0
        ),
        maxBarThickness: 50,
        barPercentage: 0.8,
        backgroundColor: [
          "rgba(255, 159, 64, 0.7)",
          "rgba(255, 205, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(201, 203, 207, 0.7)",
          "rgba(255, 99, 132, 0.7)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
          "rgb(255, 99, 132)",
        ],
      },
    ],

    borderWidth: 1,
  };

  const outlets = doghnutData?.map((doc: any, index: any) => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
          labels: {
            color: isDark ? "white" : "grey",
          },
        },
        title: {
          display: true,
          text: `Summary of Sales - ${doc.outletName}`,
          color: isDark ? "#fff" : "grey",
        },
        datalabels: {
          color: isDark ? "white" : "grey",
        },
      },
    };

    const labels = [];

    for (let key in doc) {
      if (doc.hasOwnProperty(key) && key !== "outletName") {
        labels.push(key);
      }
    }

    const chartData = {
      labels,
      datasets: [
        {
          data: [
            doc?.TotalSale > 0 ? doc?.TotalSale?.toFixed(2) : 0,
            doc?.TotalGST > 0 ? doc?.TotalGST?.toFixed(2) : 0,
            doc?.TotalDiscount > 0 ? doc?.TotalDiscount?.toFixed(2) : 0,
          ],
          backgroundColor: [
            "rgba(255, 99, 132, 0.7)",
            "rgba(54, 162, 235, 0.7)",
            "rgba(255, 206, 86, 0.7)",
            "rgba(75, 192, 192, 0.7)",
            "rgba(153, 102, 255, 0.7)",
            "rgba(255, 159, 64, 0.7)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
          cutout: "60%",
        },
      ],
    };

    return (
      <React.Fragment key={index}>
        {!(
          doc.TotalSale === 0 &&
          doc.TotalGST === 0 &&
          doc.TotalDiscount === 0
        ) ? (
          <Grid.Col key={index} span={{ xl: 4, lg: 6, md: 6, sm: 10, xs: 12 }}>
            <Paper
              key={index}
              p="md"
              style={{ height: "100%", width: "100%" }}
              withBorder
            >
              <Doughnut
                style={{ marginBottom: "20px" }}
                options={options}
                data={chartData}
              />
            </Paper>
          </Grid.Col>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  });

  const stats = data.map((stat, index) => {
    return (
      <Grid.Col key={index} span={{ xl: 2.4, lg: 3, md: 6, sm: 6, xs: 12 }}>
        <Paper
          style={{
            padding: "20px",
            height: "130px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: stat.backgroundColor,
          }}
          withBorder={colorScheme === "dark" ? false : true}
          p="md"
          radius="md"
          key={stat.title}
        >
          <Group justify="space-between">
            <Text
              size="xs"
              style={{
                fontWeight: "700",
                color: stat.textColor,
                textTransform: "uppercase",
              }}
              className={classes.title}
            >
              {stat.title}
            </Text>
          </Group>

          <Group align="flex-end" gap="xs" mt={25}>
            <Text
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: stat.textColor,
              }}
              className={classes.value}
            >
              {stat.value}
            </Text>
          </Group>
        </Paper>
      </Grid.Col>
    );
  });

  return (
    <>
      <Helmet title="Dashboard" />

      <Grid className={classes.dashboardGrid}>
        <Grid.Col mt={10}>
          {(deviceSubscriptionData?.data?.ExpiredDevices ||
            deviceSubscriptionData?.data?.FutureExpiryingDevices) && (
            <Marquee speed={150} gradient={false}>
              {deviceSubscriptionData?.data?.ExpiredDevices && (
                <Text variant="h5" style={{ color: "#D21312" }}>
                  {" "}
                  Subscription of few devices are expired.
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
              )}
              {deviceSubscriptionData?.data?.FutureExpiryingDevices && (
                <Text variant="h5" style={{ color: "#E86A33" }}>
                  Subscription of few devices will be expired soon.
                </Text>
              )}
            </Marquee>
          )}
        </Grid.Col>
        <Grid.Col
          span={{ xl: 12, lg: 12 }}
          style={{ display: "flex", flexWrap: "wrap", height: "100%" }}
        >
          {stats}
        </Grid.Col>
        <Grid.Col
          style={{ display: "flex", flexWrap: "wrap", height: "100%" }}
          span={{ xl: 12, lg: 12 }}
        >
          {/* {!aggLoading f ( */}
          <>
            <Grid.Col
              span={{
                xl: !(aggregateDataState?.docs?.length > 13) ? 6 : 12,
                lg: !(aggregateDataState?.docs?.length > 13) ? 6 : 12,
                md: 12,
                sm: 12,
              }}
            >
              <Paper
                style={{ height: "100%", width: "100%", minHeight: "50vh" }}
                p="md"
                withBorder
              >
                <Bar options={options} data={noOfBillsData} />
              </Paper>
            </Grid.Col>
            <Grid.Col
              span={{
                xl: !(aggregateDataState?.docs?.length > 13) ? 6 : 12,
                lg: !(aggregateDataState?.docs?.length > 13) ? 6 : 12,
                md: 12,
                sm: 12,
              }}
            >
              <Paper
                style={{
                  height: "100%",
                  width: "100%",
                  minHeight: !(aggregateDataState?.docs?.length > 13)
                    ? "50vh"
                    : "100vh",
                }}
                p="md"
                withBorder
              >
                <Bar options={totalSaleOptions} data={totalSalesData} />
              </Paper>
            </Grid.Col>
          </>
          {/* // ) : (
          //   <></>
          // )} */}
        </Grid.Col>
        <Grid.Col
          span={{ xl: 12, lg: 12 }}
          style={{ display: "flex", flexWrap: "wrap", height: "100%" }}
        >
          {outlets}
        </Grid.Col>
      </Grid>
    </>
  );
}
export default DashBoard;
