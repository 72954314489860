import axios from "axios";
import { getHeader } from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface GetBusinessProps {
  page: number;
  limit: number;
}

export interface CreateBusinessProps {
  businessName: string;
  telephone: string;
  address: string;
  city: string;
  state: string;
  country: string;
  multiOutlet?: boolean;
  pin: string;
  CIN?: string;
  GSTN?: string;
  fax?: string;
}

export interface UpdateBusinessProps {
  businessId: string;
  businessName: string;
  city: string;
  multiOutlet?: boolean;

  GSTN?: string;
  telephone?: string;
  fax?: string;
  CIN?: string;
  address?: string;
  pin?: string;
  state?: string;
  country?: string;
}

export class BusinessService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getBusiness(data: GetBusinessProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_business}?limit=${data.limit}&page=${data.page}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async createBusiness(createBusinessData: CreateBusinessProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_business}`;

      const response = await axios.post(url, createBusinessData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async updateBusiness(updateBusinessData: UpdateBusinessProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_business}`;

      const response = await axios.patch(url, updateBusinessData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
