import { APIPATH } from "./utils";

const endPoints = {
  baseUrl: APIPATH,
  user: "/user",
  item: "/item",
  issues: "/servicerequest",
  videos: "/videos",
  issueslog: "/servicerequestlog",
  product: "/product",
  contact: "/contact",
  dbToken: "/dbtoken",
  lead: "/lead",
  leadlog: "/leadlog",
  priority: "/priority",
  events: "/event",
  activity: "/activity",
  status: "/status",
  organization: "/companydetail",
  productfamily: "/ProductFamily",
  userContactList: "/userContactList",
  productregistration: "/productregistration",
  productmovementlog: "/productmovementlog",
  frequentlyoccurredissues: "/frequentlyoccurredissues",
  business: "/business",
  subscription: "/subscription",
  subscriptionTransaction: "/subscriptiontransaction",
  deviceSetting: "/setting",
  deviceHF: "/conf",
  device: "/device",
  outlet: "/outlet",
  file: "/fileUpload",
  itemList: "/itemlist",
  items: "/item",
  itemCodes: "/item/getItemCodes",
  bill: "/bill",
  billRefund: "/billRefund",
  razorpay: "/razorpay",
  daywise: "/daywise",
  itemwise: "/itemwise",
  billwise: "/billwise",
  summary: "/summary",
  monthly: "/monthlyreport",
  GSTwise: "/gstsummary",
  userwise: "/userreport",
  tags: "/offers",
  settings: "/setting",
  editUsertype: "/user/editUsertype",
  warrantytcs: "/warrantyTC",
  APIVersion: "/APIVersion",
  firmware: "/firmware",
  dashboard: "/dashboard",
  dashboardSummary: "/dashboardsummary",
  subscriptioninfo: "/subscriptioninfo",
  aggregatereport: "/outletwisereport",
};

export const api_routes = {
  api_path_auth: "/user/authenticate",
  //sign up
  api_path_signup: "/user", // same end point for get user, create user, update user
  // login
  api_path_login: "/user/login",
  // profile
  api_path_profile: "/user/admin/get/", //add user id at end to get profile of that user.
  //user editpassword
  api_path_editpassword: "/user/editpassword",
  // otp
  api_path_otp: "/user/mobile/otp/", //add mobile at end to send otp to.
  //forgot password
  api_path_forgotpassword: "/user/forgetpassword",
  // edit mobile number
  api_path_editmobile: "/user/editmobile/", // add mobile at end send otp to.

  //business
  api_path_business: "/business", // same end point for get business, create business, update business

  //business outlet
  api_path_business_outlet: "/outlet",

  //Devices
  // Get all devices
  api_path_device: "/device",
  //Device Activation
  api_path_device_activation: "/device/activation",
  // Map to ItemList
  api_path_device_map_item_list: "/device/maptoitemlist",
  // Map to Outlet
  api_path_device_map_outlet: "/device/maptooutlet",
  // Update Device Name
  api_path_device_update_name: "/device/update_device_name",
  // Device Subscription Info
  api_path_device_subscription_info: "/device/subscriptioninfo",

  //Device Settings
  // Get device settings
  api_path_device_settings: "/setting", // same end point for get settings, create settings, update settings(PATCH)

  // Device Header Footer
  api_path_device_header_footer: "/conf", // same end point for get settings, create settings, update settings(PUT)

  //Device Logo
  api_path_device_logo: "/fileUpload", // same end point for get settings, create settings, update settings(PUT)

  //Item List
  api_path_item_list: "/itemlist", // same end point for get item list, create item list, update item list
  //Item
  api_path_item: "/item", // same end point for get item, create item, update item
  //delete all item
  api_path_item_delete_all: "/item/deleteall",

  //get warranty by id
  api_path_warranty: "/warrantyTC/byId", // same end point for get warranty, create warranty, update warranty

  //subscription offer
  api_path_subscription_offer: "/subscription/offers", // to get offers(POST)

  //Subscription purchase and get transaction details
  api_path_subscription_purchase: "/subscriptiontransaction", // to purchase subscription(POST) and to up transaction details (PATCH). Get transaction details(GET)

  // create razorpay order
  api_path_razorpay_order: "/razorpay",

  //Reports
  // Dashboard Report
  api_path_report_dashboard: "/bill/dashboardsummary",

  //Summary Report
  api_path_report_summary: "/bill/summary", // GET

  //Daywise Report
  api_path_report_daywise: "/bill/daywise", // POST

  //Billwise Report
  api_path_report_billwise: "/bill/billwise", // POST
  //itemwise Report
  api_path_report_itemwise: "/bill/itemwise", // POST
  //monthly Report
  api_path_report_monthly: "/bill/monthlyreport", // POST

  //gst Report
  api_path_report_gst: "/bill/gstsummary", // POST
  //user Report
  api_path_report_user: "/bill/userreport", // POST
  //refund Report

  api_path_report_refund: "/billRefund/billwise", // POST
  api_path_report_refund_summary: "/billRefund/summary", // POST

  // Aggregate report
  api_path_report_aggregate: "/bill/outletwisereport", // POST

  api_path_get_firmware: "/firmware/latest",
};

export default endPoints;
