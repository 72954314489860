import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../awatarStore/store'
import { create, fetch, update } from './fileActions';


export const useGetFile=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['file/fetch'].loading);
    const error = useSelector((state: RootState) => state['file/fetch'].error);
    const data: any = useSelector((state: RootState) => state['file/fetch'].data)
    const hasFile: any = useSelector((state: RootState) => state['file/fetch'].hasFile)
  
    const fetchFile = async (fileData: any) => {
            await dispatch(fetch(fileData));
    };

    return {
        fetchFile,
        loading,
        error,
        data,
        hasFile
    }
}

export const useCreateFile=()=>{
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['file/create'].loading);
    const error = useSelector((state: RootState) => state['file/create'].error);
    const data: any = useSelector((state: RootState) => state['file/create'].data)
    const isCreated: any = useSelector((state: RootState) => state['file/create'].isCreated)
  
    const createFile = async (fileData: any) => {
            await dispatch(create(fileData));
    };

    return {
        createFile,
        loading,
        error,
        data,
        isCreated
    }
}

export const useUpdateFile=()=>{    
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state['file/update'].loading);
    const error = useSelector((state: RootState) => state['file/update'].error);
    const data: any = useSelector((state: RootState) => state['file/update'].data)
    const isUpdated: any = useSelector((state: RootState) => state['file/update'].isUpdated)

    const updateFile = async (fileData: any) => {
            await dispatch(update(fileData));
    };

    return {
        updateFile,
        loading,
        error,
        data,
        isUpdated
    }
}