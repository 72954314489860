import { ActionIcon, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconLogout } from "@tabler/icons-react";
import React from "react";

interface LogoutButtonProps {
  handleLogout: () => void;
}

const LogoutButton = ({ handleLogout }: LogoutButtonProps) => {
  const handleIconClick = () =>
    modals.openConfirmModal({
      title: "Logout Account",
      // centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to logout from your account?
        </Text>
      ),
      labels: { confirm: "Logout", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        handleLogout();
      },
    });

  return (
    <>
      <Tooltip label="Logout">
        <ActionIcon onClick={handleIconClick} variant="default" size={30}>
          <IconLogout size="1rem" />
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default LogoutButton;
