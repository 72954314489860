import { Button, Modal, TextInput } from "@mantine/core";
import React from "react";
import classes from "../../styleModules/AppShellMainStyles.module.css";
import { useCreateItemList } from "../../../modules/itemList/itemListHooks";

interface Props {
  createItemListDialog: boolean;
  setCreateItemListDialog: any;
  createForm: any;
  handleCreateItemList: any;
}

const CreateItemList = ({
  createItemListDialog,
  setCreateItemListDialog,
  createForm,
  handleCreateItemList,
}: Props) => {
  const { loading } = useCreateItemList();
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Create Itemlist"
      closeOnClickOutside={false}
      opened={createItemListDialog}
      withCloseButton
      onClose={() => setCreateItemListDialog(false)}
      size="xs"
      radius="md"
    >
      <form
        onSubmit={createForm.onSubmit((values: any) =>
          handleCreateItemList(values)
        )}
      >
        <TextInput
          error={createForm?.errors?.itemListName}
          maxLength={30}
          label="Itemlist Name"
          onChange={(e) => {
            createForm.setFieldValue(`itemListName`, e.currentTarget.value);
          }}
        />
        <Button
          loading={loading}
          type="submit"
          size="sm"
          style={{ marginTop: "10px" }}
        >
          Create
        </Button>
      </form>
    </Modal>
  );
};

export default CreateItemList;
