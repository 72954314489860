
import { Player } from "@lottiefiles/react-lottie-player";


const SomethingWentWrong = () => {
  return (
    <Player
      autoplay
      loop
      src="/static/infographics/somethingWentWrong.json"
      style={{ height: "350px", width: "350px" }}
      background="transparent"
      controls={false}
    ></Player>
  );
};

export default SomethingWentWrong;
