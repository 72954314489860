import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { TextInput, Button, Card, Modal } from "@mantine/core";
import { useCreateBusiness } from "../../modules/business/businessHooks";
import classes from "../styleModules/AppShellMainStyles.module.css";
import { notifications } from "@mantine/notifications";
import { useLogout } from "../../modules/auth/authHooks";
import { CreateBusinessProps } from "../../modules/business/service/businessService";

const CreateBusiness = ({
  createBusinessOpened,
  setCreateBusinessOpened,
}: any) => {
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      businessName: "",
      telephone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pin: "",
      fax: "",
      GSTN: "",
      CIN: "",
    },
    validate: {
      businessName: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 50
          ? null
          : "Must be 50 or less",
      telephone: (value) =>
        value.length >= 15
          ? "Must be 15 or less"
          : value.length < 6
          ? "Must be 6 or more"
          : null,
      address: (value) =>
        value.length > 150
          ? "Must be 150 or less"
          : value.length < 2
          ? "Must be atleast 2 characters"
          : null,
      city: (value) =>
        value.length < 2
          ? "Must be 2 or more"
          : value.length <= 30
          ? null
          : "Must be 30 or less",
      state: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 20
          ? null
          : "Must be 20 or less",
      country: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 20
          ? null
          : "Must be 20 or less",
      pin: (value) =>
        value.length !== 6 || value.length < 1 ? "Must be 6 characters" : null,
      GSTN: (value) =>
        value.length !== 15 && value.length > 0
          ? "Must be 15 characters"
          : null,
      CIN: (value) =>
        value.length !== 21 && value.length > 0
          ? "Must be 21 characters"
          : null,

      fax: (value) => (value.length <= 20 ? null : "Must be 20 or less"),
    },
  });

  const { loading, error, createBusiness, isCreated } = useCreateBusiness();
  const { logoutUser } = useLogout();

  useEffect(() => {
    if (isCreated) {
      notifications.show({
        title: "Success",
        message: "Business created successfully",
        color: "green",
      });
      logoutUser();
    } else if (error) {
      notifications.show({
        title: "Failed",
        message: "Business creation failed",
        color: "red",
      });
    }
  }, [isCreated, error]);

  const handleBusinessCreate = () => {
    let payload: CreateBusinessProps = {
      businessName: form.values.businessName,
      telephone: form.values.telephone,
      address: form.values.address,
      city: form.values.city,
      state: form.values.state,
      country: form.values.country,
      pin: form.values.pin,
      fax: form.values.fax,
      GSTN: form.values.GSTN,
      CIN: form.values.CIN,
    };

    if (
      form.values.GSTN === "" ||
      form.values.GSTN === null ||
      form.values.GSTN === undefined
    ) {
      delete payload.GSTN;
    }
    if (
      form.values.CIN === "" ||
      form.values.CIN === null ||
      form.values.CIN === undefined
    ) {
      delete payload.CIN;
    }
    if (
      form.values.fax === "" ||
      form.values.fax === null ||
      form.values.fax === undefined
    ) {
      delete payload.fax;
    }

    createBusiness(payload);
  };

  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Create New Business"
      opened={createBusinessOpened}
      closeOnClickOutside={false}
      withCloseButton
      size="sm"
      radius="md"
      onClose={() => {
        setCreateBusinessOpened(false);
        logoutUser();
      }}
    >
      <Card withBorder mx="auto">
        <form onSubmit={form.onSubmit(handleBusinessCreate)}>
          <TextInput
            withAsterisk
            label="Business Name"
            placeholder="Enter your business name"
            value={form.values.businessName}
            error={form.errors.businessName}
            onChange={(event) =>
              form.setFieldValue("businessName", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />
          <TextInput
            withAsterisk
            label="Telephone"
            type="number"
            placeholder="Enter your telephone number"
            value={form.values.telephone}
            error={form.errors.telephone}
            onChange={(event) =>
              form.setFieldValue("telephone", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <TextInput
            label="GSTN"
            placeholder="Enter your GSTN"
            value={form.values.GSTN}
            error={form.errors.GSTN}
            onChange={(event) =>
              form.setFieldValue("GSTN", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />
          <TextInput
            type="number"
            value={form.values.CIN}
            error={form.errors.CIN}
            label="CIN"
            placeholder="30 MAX"
            onChange={(e) => form.setFieldValue("CIN", e.currentTarget.value)}
          />
          <TextInput
            withAsterisk
            label="Address"
            placeholder="Enter your address"
            value={form.values.address}
            error={form.errors.address}
            onChange={(event) =>
              form.setFieldValue("address", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <TextInput
            withAsterisk
            label="City"
            placeholder="Enter your city"
            value={form.values.city}
            error={form.errors.city}
            onChange={(event) =>
              form.setFieldValue("city", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <TextInput
            withAsterisk
            label="State"
            placeholder="Enter your state"
            error={form.errors.state}
            value={form.values.state}
            onChange={(event) =>
              form.setFieldValue("state", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <TextInput
            withAsterisk
            label="Country"
            placeholder="Enter your country"
            error={form.errors.country}
            value={form.values.country}
            onChange={(event) =>
              form.setFieldValue("country", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <TextInput
            label="Pin"
            placeholder="Enter your pin"
            value={form.values.pin}
            error={form.errors.pin}
            onChange={(event) =>
              form.setFieldValue("pin", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <TextInput
            label="Fax"
            placeholder="Enter your fax"
            error={form.errors.fax}
            value={form.values.fax}
            onChange={(event) =>
              form.setFieldValue("fax", event.currentTarget.value)
            }
            radius="md"
            variant="filled"
          />

          <Button
            loading={loading}
            type="submit"
            mt="sm"
            display="flex"
            justify="flex-end"
          >
            Submit
          </Button>
        </form>
      </Card>
    </Modal>
  );
};

export default CreateBusiness;
