import { Card, Group, Avatar, Text } from "@mantine/core";
import React from "react";
import classes from "../../styleModules/AppShellMainStyles.module.css";

interface Props {
  item: any;
  stringAvatar: any;
  handleItemListNameEdit: any;
}

const ItemListComponent = ({
  item,
  handleItemListNameEdit,
  stringAvatar,
}: Props) => {
  const awatarText = stringAvatar(item?.itemListName);

  return (
    <>
      <Card
        onClick={() => {
          handleItemListNameEdit(item._id, item.itemListName);
        }}
        className={classes.itemListCard}
        withBorder
        radius="md"
      >
        <Group
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginLeft: "20px",
          }}
        >
          <Avatar
          size={50}
            style={{
              backgroundColor: awatarText?.sx?.bgcolor,
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "500" }}>
              {awatarText?.children}
            </Text>
          </Avatar>
          <Group
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Text>{item?.itemListName}</Text>
          </Group>
        </Group>
      </Card>
    </>
  );
};

export default ItemListComponent;
