import React, { useEffect } from "react";

import {
  useRazorPay,
  useSubscriptionTransaction,
  useSubscriptionTransactionPatch,
} from "../../modules/subscription/subscriptionHooks";
import {
  GetSubscriptionTransactionProps,
  RazorPayProps,
} from "../../modules/subscription/service/subscriptionService";
import moment from "moment";
import { RAZORPAYKEY } from "../../utils/env";
import {
  Button,
  Card,
  Divider,
  Grid,
  Table,
  Title,
  // useMantineColorScheme,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { convertToIndianCurrency } from "../../utils/utils";
import {
  razorPaySlice,
  reset,
} from "../../modules/subscription/subscriptionSlice";
import { RazorpayOptions } from "./razorpay";
import { Loader } from '@mantine/core';
import { notifications } from "@mantine/notifications";

const SubscriptionTransaction = ({ onButtonClick }: any) => {
  const passData = useSelector((state: any) => state["set/data"].gtArray);

  const { setSubscriptionPatchFunction } = useSubscriptionTransactionPatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(razorPaySlice.actions.resetState());
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  }, []);
  // const clearArrays = () => {
  //   clearArray();
  // };
  // const handleRemoveData = (id: any) => {
  //   dispatch(removeData({ id }));
  // };
  // useEffect(() => {
  //   if (passData.Duration !== undefined) {
  //   }
  // }, [passData]);
  let keys = ["amount", "GSTValue", "CESSValue", "Discount"];
  let result = passData.reduce((a: any, v: any) => {
    keys.forEach((k) => {
      a[k] = (a[k] ?? 0) + +v[k];
    });
    return a;
  }, {});

  let amount =
    result?.amount -
    result?.Discount +
    result?.GSTValue +
    result?.CESSValue || 0;

  const { data, razorPayFunction, loading } = useRazorPay();
  const { setSubscriptionFunction } = useSubscriptionTransaction();

  const razorPayData: RazorPayProps = {
    amount: amount.toFixed(2) * 100,
    payment_capture: true,
    receipt: `${moment().unix()}`,
  };
  const patch: any = {};
  const getSubscriptionTransactionProps: GetSubscriptionTransactionProps = {
    businessId: `${localStorage.getItem("businessId")}`,
    paymentMode: "RazorPay",
    date: `${moment()}`,
    amount: amount * 100,
    orderId: data?.id,

    items: passData,
  };

  useEffect(() => {
    const fetchData = async () => {
      if (loading) {
        return;
      }
      if (data.length === 0) {
        return;
      }
      if (data?.id) {
        try {
          const response = await setSubscriptionFunction(
            getSubscriptionTransactionProps
          );
          if (!response.error && !loading && Object.keys(response).length > 0) {
            openRazorpay(data);
          } else {
            throw new Error("Error in Subscription Order Creation");
          }
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: "Subscription Order Creation Failed",
          });
        }
      }
      else {
        notifications.show({
          color: "red",
          title: "Error",
          message: "Razorpay Order Creation Failed",
        });
      }
    };

    fetchData();
  }, [data, loading]);

  const openRazorpay = (payData: any) => {
    var note_serialnumbers: any = [];
    passData.forEach((device: any) => {
      note_serialnumbers.push(device.serialNumber);
    });

    const options: RazorpayOptions = {
      key: RAZORPAYKEY,
      // amount: String(+payData?.amount * 100),
      amount: String(amount * 100),
      currency: "INR",
      name: "NGX",
      order_id: payData?.id,
      description: "Subscription Payment",
      handler: async (response) => {
        if (response.razorpay_signature) {
          // patch.razorpay_signature = response.razorpay_signature;   ///if we add signeture veification use this line to get razorpay signature
          patch.RazorpayPaymentId = response.razorpay_payment_id;
          patch.orderId = response.razorpay_order_id;
          await setSubscriptionPatchFunction(patch);

          // alert(JSON.stringify(response));
          notifications.show({
            color: "green",
            title: "Success",
            message: "Subscription Payment Successful",
          });
          dispatch(reset());
          dispatch(razorPaySlice.actions.resetState());
          onButtonClick();
        } else {
          notifications.show({
            color: "red",
            title: "Error",
            message: "Subscription Payment Failed",
          });
        }
      },
      modal: {
        ondismiss: () => {
          patch.paymentStatus = "CANCELLED";
          patch.orderId = data?.id;
          setSubscriptionPatchFunction(patch);
          notifications.show({
            color: "red",
            title: "Cancelled!",
            message: "Subscription Payment Cancelled",
          });
        },
      },
      theme: {
        color: "#3572C2",
      },

      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {
        "serial_numbers ": note_serialnumbers?.toString(),
        payment_from: "Web Dashboard",
      },
    };

    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
  };

  const paymentInit = async (razorPayData: any) => {
    await razorPayFunction(razorPayData);
  };

  return (
    <>
      {amount === 0 ? (
        <span></span>
      ) : (
        <Card
          withBorder
          radius="sm"
          style={{
            marginTop: 5,
            marginBottom: 75,
            // backgroundColor: isLight ? "#FFFFFF" : "",
            // padding: 10,
          }}
        >
          <Title style={{ paddingLeft: 10 }} order={3}>
            Total Subscription Details
          </Title>
          <Divider my={5} />

          <Grid p={10}>
            <Grid.Col span={{ xl: 6, lg: 8, md: 12, sm: 12, xs: 8 }}>
              <Table highlightOnHover withTableBorder>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>Selected Device</Table.Td>
                    <Table.Td>{passData?.length}</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td> Amount</Table.Td>
                    <Table.Td>
                      {convertToIndianCurrency(result?.amount || 0)}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>Total GST</Table.Td>
                    <Table.Td>
                      {convertToIndianCurrency(result?.GSTValue || 0)}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>Total CESS</Table.Td>
                    <Table.Td>
                      {convertToIndianCurrency(result?.CESSValue || 0)}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>Total Discount</Table.Td>
                    <Table.Td>
                      {convertToIndianCurrency(result?.Discount || 0)}
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
              <Table withTableBorder>
                <Table.Tbody>
                  <Table.Tr style={{ fontSize: "20px" }}>
                    <Table.Td>Total Amount</Table.Td>
                    <Table.Td>
                      <Button
                        // leftSection={<IconCurrencyRupee height={20} />}

                        // style={{ width: "80%" }}
                        variant="gradient"
                        gradient={{ from: "blue", to: "cyan", deg: 360 }}
                        onClick={async () => {
                          if (passData.length > 0) {
                            await paymentInit(razorPayData);
                          } else {
                            console.log("no data");
                          }
                        }}
                      >
                        {loading ? <Loader color="rgba(255, 255, 255, 1)" type="dots" size={19} /> : `Pay ${convertToIndianCurrency(amount || 0)}`}
                      </Button>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </Grid.Col>
          </Grid>

          {/* <Grid>
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Button
                // leftSection={<IconCurrencyRupee height={20} />}

                style={{ width: "80%" }}
                variant="gradient"
                gradient={{ from: "blue", to: "cyan", deg: 360 }}
                onClick={async () => {
                  if (passData.length > 0) {
                    await paymentInit(razorPayData);
                  } else {
                    console.log("no data");
                  }
                }}
              >
                pay {convertToIndianCurrency(amount || 0)}
              </Button>
            </Grid.Col>
          </Grid> */}
        </Card>
      )}
    </>
  );
};

export default SubscriptionTransaction;
