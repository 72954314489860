import React from "react";
import { Button, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import classes from "../../styleModules/AppShellMainStyles.module.css";

interface Props {
  activateDeviceDialog: any;
  setactivateDeviceDialog: any;
  handleDeviceActivation: any;
}

const ActivateDevice = ({
  activateDeviceDialog,
  setactivateDeviceDialog,
  handleDeviceActivation,
}: Props) => {
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      dealerCode: "",
      installedBy: "",
      serialNo: " ",
      userDeviceName: "",
    },
    validate: {
      dealerCode: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 10
          ? null
          : "Must be 10 or less",
      installedBy: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 20
          ? null
          : "Must be 20 or less",
      serialNo: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 15
          ? null
          : "Must be 15 or less",
      userDeviceName: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 10
          ? null
          : "Must be 10 or less",
    },
  });

  return (
    <>
      <Modal
        classNames={{
          close: classes.modalCloseButton,
        }}
        title="Activate Device"
        closeOnClickOutside={false}
        withCloseButton
        opened={activateDeviceDialog}
        onClose={() => {
          setactivateDeviceDialog(false);
          form.reset();
        }}
        size="xs"
        radius="md"
      >
        <form
          onSubmit={form.onSubmit((values: any) => {
            handleDeviceActivation(values);
          })}
        >
          <TextInput
            classNames={{
              label: classes.deviceActivationLabel,
            }}
            error={form.errors.serialNo}
            value={form.values.serialNo}
            // max={15}
            type="number"
            placeholder="15 max"
            withAsterisk
            label="Serial Number"
            onChange={(e) => {
              form.setFieldValue("serialNo", e.currentTarget.value);
            }}
          />
          <TextInput
            classNames={{
              label: classes.deviceActivationLabel,
            }}
            error={form.errors.userDeviceName}
            value={form.values.userDeviceName}
            placeholder="10 Max"
            maxLength={10}
            withAsterisk
            label="Device Name"
            onChange={(e) => {
              form.setFieldValue("userDeviceName", e.currentTarget.value);
            }}
          />

          <TextInput
            classNames={{
              label: classes.deviceActivationLabel,
            }}
            error={form.errors.dealerCode}
            value={form.values.dealerCode}
            maxLength={10}
            withAsterisk
            placeholder="10 max"
            label="Dealer Code"
            onChange={(e) => {
              form.setFieldValue("dealerCode", e.currentTarget.value);
            }}
          />
          <TextInput
            classNames={{
              label: classes.deviceActivationLabel,
            }}
            error={form.errors.installedBy}
            value={form.values.installedBy}
            maxLength={20}
            withAsterisk
            placeholder="20 max"
            label="Installing by"
            onChange={(e) => {
              form.setFieldValue("installedBy", e.currentTarget.value);
            }}
          />

          <Button type="submit" style={{ marginTop: "10px" }}>
            Submit
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default ActivateDevice;
