import axios from "axios";
import {
  getHeader,
} from "../../../utils/utils";
import { api_routes } from "../../../utils/apiEndPoints";

export interface PaymentItem {
  deviceId: string;
  serialNumber: string;
  OfferName: string;
  PlanName: string;
  amount: number;
  duration: number;
  Discount: number;
  GSTPercentage: number;
  CESSPercentage: number;
  OtherPercentage: number;
  GSTValue: number;
  CESSValue: number;
  OtherValue: number;
}

export interface GetSubscriptionTransactionProps {
  businessId: string;
  paymentMode: string;
  date: string;
  amount: number;
  orderId: any;
  items: PaymentItem[];
}
export interface GetSubscriptionTransactionPatchProps {
  businessId?: string;
  paymentStatus: string;
  date?: string;
  amount?: number;
  orderId: any;
  items?: PaymentItem[];
}

export interface GetFetchSubscriptionProps {
  fromDate: string;
  toDate: string;
  page: any;
  limit: any;
}

export interface RazorPayProps {
  receipt: string;
  amount: number;
  payment_capture: boolean;
}

export interface Tag {
  Description: string;
}
interface TagItem {
  Description: string;
}

export interface GetSubscriptionProps {
  Tag: TagItem[];
}

export class SubscriptionService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getSubscriptionOffers(
    getSubscriptionData: GetSubscriptionProps
  ): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_subscription_offer}`;

      const response = await axios.post(url, getSubscriptionData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getDeviceSubscriptionInfo(): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_device_subscription_info}`;
      const response = await axios.get(url, {
        headers: header,
      });
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async setRazorPay(razorPayData: RazorPayProps): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_razorpay_order}`;

      const response = await axios.post(url, razorPayData, {
        headers: header,
      });

      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async setSubscriptionTransaction(
    getSubscriptionData: GetSubscriptionTransactionProps
  ): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_subscription_purchase}`;

      const response = await axios.post(url, getSubscriptionData, {
        headers: header,
      });
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async setSubscriptionTransactionPatch(
    getSubscriptionPatchData: GetSubscriptionTransactionPatchProps
  ): Promise<any> {
    try {
      let header = getHeader();
      const url = `${this.baseUrl}${api_routes.api_path_subscription_purchase}`;

      const response = await axios.patch(url, getSubscriptionPatchData, {
        headers: header,
      });
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  async getFetchSubscriptionTransactions(
    getFetchSubscriptionTransactions: GetFetchSubscriptionProps
  ): Promise<any> {
    try {
      let header = getHeader();

      const url = `${this.baseUrl}${api_routes.api_path_subscription_purchase}?fromDate=${getFetchSubscriptionTransactions.fromDate}&toDate=${getFetchSubscriptionTransactions.toDate}&page=${getFetchSubscriptionTransactions.page}&limit=${getFetchSubscriptionTransactions.limit}`;

      const response = await axios.get(url, {
        headers: header,
      });

      return response.data[0];
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
}
