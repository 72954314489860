import React, { useEffect, useRef, useState } from "react";
import {
  useDownloadDayWiseReport,
  useGetDayWise,
  useGetSummary,
} from "../../../modules/reports/reportHooks";
import {
  BillSummaryProps,
  DayWiseProps,
} from "../../../modules/reports/service/reportService";
import {
  Table,
  Pagination,
  Select,
  Skeleton,
  Text,
  Title,
  SimpleGrid,
  Tabs,
  Grid,
  Divider,
  Tooltip,
  ActionIcon,
  Checkbox,
  Button,
  Flex,
  Group,
  NumberInput,
} from "@mantine/core";
import moment from "moment";
import { notifications } from "@mantine/notifications";
import { DatePickerInput } from "@mantine/dates";
import { GetDeviceProps } from "../../../modules/device/service/deviceService";
import { GetOutletProps } from "../../../modules/outlet/service/outletService";
import { useGetDevice } from "../../../modules/device/deviceHooks";
import { useGetOutlet } from "../../../modules/outlet/outletHooks";
import { Player } from "@lottiefiles/react-lottie-player";
import { convertToIndianCurrency } from "../../../utils/utils";
import {
  IconDeviceLandlinePhone,
  IconBuildingStore,
  IconChartBar,
  IconTable,
  IconDownload,
  IconX,
  IconCheck,
} from "@tabler/icons-react";
import clases from "./selected.module.css";
import ChartUtils from "../../../utils/uiUtils/ChartUtils";
import { onExportClick, reportTypes } from "./ExportReports";
import { Helmet } from "react-helmet-async";
const Daywise = () => {
  const { getDayWiseFunction, data: Daydata, loading } = useGetDayWise();
  const { fetchOutlet, data: outlet, loading: Ouletloading } = useGetOutlet();
  const { downloadDayWiseReportFunction, data: downloadData } =
    useDownloadDayWiseReport();
  const {
    fetchDevice,
    data: DeviceData,
    loading: DeviceLoading,
  } = useGetDevice();
  const {
    getSummaryFunction,
    data: Summary,
    // loading: SummaryLoading,
  } = useGetSummary();

  const [date, setDate] = useState();
  const [outletId, setOutletId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [reportByDevice, setReportByDevice] = useState(false);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState("1");
  const [limit] = useState(10);
  const [activeTab, setActiveTab] = useState<string | null>("Outlet");
  const [switchTableOrGraph, setSwitchTableOrGraph] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>("");
  const [exportLoading, setExportLoading] = useState(false);
  const [reportDataForDownload, setReportDataForDownload] = useState<any>([]);
  const [downloadPage, setDownloadPage] = useState(1);
  const [downloadLimit] = useState(500);
  const [refreshSelected, setRefreshSelected] = useState(false);
  const [apiCallScheduled, setApiCallScheduled] = useState(false);
  const [bothDates, setBothDates] = useState<any>({
    fromDate: "",
    toDate: "",
  });
  const [searchpage, setSearchpage] = useState(1);
  const pageNumberRef = useRef(pageNumber);

  let apiCallInterval: any;

  const selectedFromDate = date?.[0]
    ? moment(date?.[0]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");
  const selectedtoDate = date?.[1]
    ? moment(date?.[1]).format("YYYY/MM/DD")
    : moment().format("YYYY/MM/DD");

  const dayWiseData: DayWiseProps = {
    fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
    page: pageNumber,
    BillStatus: "CONFIRM",
    limit: limit.toString(),
    sort: {
      Date: true,
    },
  };

  const billSummaryData: BillSummaryProps = {
    fromDate: selectedFromDate
      ? selectedFromDate
      : moment().format("YYYY/MM/DD"),
    toDate: selectedtoDate ? selectedtoDate : moment().format("YYYY/MM/DD"),
    BillStatus: "CONFIRM",
  };

  const outletData: GetOutletProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
  };

  const getDeviceData: GetDeviceProps = {
    page: "1",
    limit: "100",
    businessId: localStorage.getItem("businessId") ?? "",
    includeReplacedDevice: true,
  };

  const setTotalpage = (totalCount: number) => {
    const totalPages = Math.ceil(totalCount / limit);
    setTotalRecords(totalPages);
  };

  function makeAPICall(page: any) {
    let getDayWiseProps: DayWiseProps = {
      fromDate: date?.[0]
        ? moment(date?.[0]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      toDate: date?.[1]
        ? moment(date?.[1]).format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD"),
      BillStatus: "CONFIRM",
      page: page.toString(),
      limit: limit.toString(),
      sort: {
        Date: true,
      },
    };

    let getSummaryProps: BillSummaryProps = {
      fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
      toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
      BillStatus: "CONFIRM",
    };

    if (!reportByDevice) {
      getDayWiseProps.outletId = outletId;
      getDayWiseProps.deviceId = undefined;
      getSummaryProps.deviceId = undefined;
      getSummaryProps.outletId = outletId;
    } else {
      getDayWiseProps.outletId = undefined;
      getDayWiseProps.deviceId = deviceId;
      getSummaryProps.outletId = undefined;
      getSummaryProps.deviceId = deviceId;
    }

    getDayWiseFunction(getDayWiseProps);
    getSummaryFunction(getSummaryProps);
    setApiCallScheduled(false);
  }
  const scheduleAPICall = (pageNumber: any) => {
    // Check if an API call is already scheduled, if not, schedule one
    if (!apiCallScheduled) {
      makeAPICall(pageNumber);
      setApiCallScheduled(true);
    }
  };

  const stopAPICalls = () => {
    // Clear the interval timer to stop API calls
    clearInterval(apiCallInterval);
    setApiCallScheduled(false);
  };

  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    if (refreshSelected) {
      makeAPICall(pageNumberRef.current);

      // Schedule API calls to occur every 1 minute
      const interval = 60 * 1000; // 1 minute in milliseconds
      apiCallInterval = setInterval(
        () => scheduleAPICall(pageNumberRef.current),
        interval
      );
      // Add event listeners for page navigation events
      window.addEventListener("beforeunload", stopAPICalls);
      // window.addEventListener("unload", stopAPICalls);

      return () => {
        // Clear the interval and remove event listeners when the component unmounts
        clearInterval(apiCallInterval);
        window.removeEventListener("beforeunload", stopAPICalls);
        // window.removeEventListener("unload", stopAPICalls);
      };
    } else {
      stopAPICalls();
    }
  }, [refreshSelected, reportByDevice, bothDates, outletId, deviceId]);

  useEffect(() => {
    if (refreshSelected && !apiCallScheduled) {
      notifications.show({
        title: "Auto Refresh",
        message: "Auto Refresh Enabled",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
    }
  }, [refreshSelected]);

  useEffect(() => {
    if (outlet?.docs?.length > 0) {
      setOutletId(outlet?.docs[0]?._id);
      if (!reportByDevice && !refreshSelected) {
        setSelectedName(outlet?.docs[0]?.outletName);
        dayWiseData.outletId = outlet?.docs[0]?._id;
        dayWiseData.outletId = outlet?.docs[0]?._id;
        dayWiseData.deviceId = undefined;
        billSummaryData.outletId = outlet?.docs[0]?._id;
        billSummaryData.deviceId = undefined;
        getDayWiseFunction(dayWiseData);
        getSummaryFunction(billSummaryData);
      }
    }
  }, [outlet, reportByDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (DeviceData?.docs?.length > 0) {
      setDeviceId(DeviceData?.docs[0]?._id);
      if (reportByDevice && !refreshSelected) {
        dayWiseData.outletId = undefined;
        setSelectedName(DeviceData?.docs[0]?.userDeviceName);
        dayWiseData.deviceId = DeviceData?.docs[0]?._id;
        billSummaryData.outletId = undefined;
        billSummaryData.deviceId = DeviceData?.docs[0]?._id;
        getDayWiseFunction(dayWiseData);
        getSummaryFunction(billSummaryData);
      }
    }
  }, [DeviceData, reportByDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchOutlet(outletData);
    fetchDevice(getDeviceData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Daydata?.total?.length > 0) {
      setTotalpage(Daydata?.total[0]?.count);
    } else setTotalRecords(0);
  }, [Daydata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (downloadData?.docs?.length > 0 && exportLoading) {
      setReportDataForDownload((prev: any) => [...prev, ...downloadData?.docs]);
    }
  }, [downloadData]);

  useEffect(() => {
    if (reportDataForDownload?.length > 0) {
      let nextPage = downloadPage + 1;
      let total_Pages = Math.ceil(
        downloadData?.total[0]?.count / downloadLimit
      );

      if (total_Pages < nextPage) {
        try {
          onExportClick(
            reportDataForDownload,
            reportTypes.dayWise,
            ` ${selectedName} [${selectedFromDate}] - [${selectedtoDate}]`,
            Summary
          );
          setExportLoading(false);
          setReportDataForDownload([]);
        } catch (err) {
          notifications.show({
            title: "Export Error",
            message: `Export Error :Error in Downloading file`,
            color: "red",
            icon: <IconX size="1.1rem" />,
          });
          setExportLoading(false);
        }
      }
      if (total_Pages >= nextPage) {
        setDownloadPage(nextPage);
        downloadDayWiseReportFunction({
          fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
          toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
          BillStatus: "CONFIRM",
          page: nextPage.toString(),
          limit: downloadLimit.toString(),
          sort: {
            Date: true,
          },
        });
      }
    }
  }, [reportDataForDownload]);

  //handle page change
  const handlePageChange = (page: number) => {
    setPageNumber(page.toString());
    stopAPICalls();
    dayWiseData.page = page.toString();
    if (!reportByDevice) {
      dayWiseData.outletId = outletId;
      dayWiseData.deviceId = undefined;
    } else {
      dayWiseData.outletId = undefined;
      dayWiseData.deviceId = deviceId;
    }
    getDayWiseFunction(dayWiseData);
  };

  //Handle outlet
  const handleOuletAndDeviceChange = (event: any, selectedName?: any) => {
    setSelectedName(selectedName);
    setPageNumber("1");
    stopAPICalls();
    if (reportByDevice) {
      setDeviceId(event);
      dayWiseData.deviceId = event;
      dayWiseData.page = "1";
      dayWiseData.outletId = undefined;
      if (!dayWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        billSummaryData.deviceId = event;
        billSummaryData.outletId = undefined;
        if (!refreshSelected) {
          getDayWiseFunction(dayWiseData);
          getSummaryFunction(billSummaryData);
        }
      }
    } else {
      setOutletId(event);
      dayWiseData.outletId = event;
      dayWiseData.deviceId = undefined;
      dayWiseData.page = "1";

      if (!dayWiseData.toDate) {
        notifications.show({
          title: "Error",
          message: "please enter from date and to date ",
        });
      } else {
        dayWiseData.outletId = event;
        dayWiseData.deviceId = undefined;
        billSummaryData.outletId = event;
        billSummaryData.deviceId = undefined;
        if (!refreshSelected) {
          getDayWiseFunction(dayWiseData);
          getSummaryFunction(billSummaryData);
        }
      }
    }
  };
  //date map in select in device
  const seletcedDataDevice = () => {
    let data: any = DeviceData?.docs?.map((item: any) => ({
      value: item._id,
      label: `${
        item?.userDeviceName
          ? item.userDeviceName + " [" + item.serialNo + "]"
          : item?.serialNo ?? ""
      }`,
    }));

    return data === undefined ? [] : data;
  };

  //date map in select in outlet
  const selectOutletData = () => {
    let data: any = outlet?.docs?.map((item: any) => {
      return { value: item._id, label: item.outletName };
    });

    return data === undefined ? [] : data;
  };

  const handledate = (e: any) => {
    dayWiseData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    dayWiseData.toDate = moment(e[1]).format("YYYY/MM/DD");
    billSummaryData.fromDate = moment(e[0]).format("YYYY/MM/DD");
    billSummaryData.toDate = moment(e[1]).format("YYYY/MM/DD");
    if (!reportByDevice) {
      dayWiseData.outletId = outletId;
      dayWiseData.deviceId = undefined;
      billSummaryData.outletId = outletId;
      billSummaryData.deviceId = undefined;
    } else {
      dayWiseData.outletId = undefined;
      dayWiseData.deviceId = deviceId;
      billSummaryData.outletId = undefined;
      billSummaryData.deviceId = deviceId;
    }

    if (!refreshSelected) {
      getDayWiseFunction(dayWiseData);
      getSummaryFunction(billSummaryData);
    }
  };

  const handleExportClick = () => {
    if (
      Daydata?.total?.[0]?.count > 0 &&
      Daydata?.total?.[0]?.count !== undefined
    ) {
      const downloadDayWiseData: DayWiseProps = {
        fromDate: selectedFromDate ?? moment().format("YYYY/MM/DD"),
        toDate: selectedtoDate ?? moment().format("YYYY/MM/DD"),
        BillStatus: "CONFIRM",
        page: "1",
        limit: downloadLimit.toString(),
        sort: {
          Date: true,
        },
        outletId: !reportByDevice ? outletId : undefined,
        deviceId: reportByDevice ? deviceId : undefined,
      };

      if (downloadDayWiseData.outletId === undefined) {
        delete downloadDayWiseData.outletId;
      } else if (downloadDayWiseData.deviceId === undefined) {
        delete downloadDayWiseData.deviceId;
      }

      downloadDayWiseReportFunction(downloadDayWiseData);
      setExportLoading(true);
    } else {
      notifications.show({
        title: "No Data Found",
        message: "No Data Found",
      });
    }
  };

  //Table data
  const TableData: any = () => {
    const data = Daydata?.docs;
    if (!data || data.length === 0) {
      return <tr>No Data Found</tr>;
    }

    return data.map((sitem: any, index: any) => (
      <React.Fragment key={index}>
        <Table.Tr key={sitem._id}>
          {/* <Table.Td>{new Date(sitem.Date).toLocaleDateString()}</Table.Td> */}
          <Table.Td>{moment.utc(sitem.Date).format("DD/MM/YYYY")}</Table.Td>
          <Table.Td>{sitem.NoOfBills}</Table.Td>
          <Table.Td>
            {convertToIndianCurrency(sitem.TotalCESS1 + sitem.TotalCESS2 || 0)}
          </Table.Td>
          <Table.Td>
            {convertToIndianCurrency(sitem.TotalDiscount || 0)}
          </Table.Td>
          <Table.Td>{convertToIndianCurrency(sitem.TotalGST || 0)}</Table.Td>
          <Table.Td>{convertToIndianCurrency(sitem.TotalSale || 0)}</Table.Td>
        </Table.Tr>
      </React.Fragment>
    ));
  };
  //summary table data

  const SummaryReport = () => {
    return (
      <Table.Tr
        style={{ backgroundColor: "#deedfd", color: "black", fontWeight: 600 }}
      >
        <Table.Td>
          <span>Total</span>
        </Table.Td>

        <Table.Td>
          {" "}
          <span>{Summary?.NoOfBills}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>
            {convertToIndianCurrency(
              Summary?.TotalCESS1 + Summary?.TotalCESS2 || 0
            )}
          </span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>
            {convertToIndianCurrency(Summary?.TotalDiscount || 0)}
          </span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(Summary?.TotalGST || 0)}</span>{" "}
        </Table.Td>
        <Table.Td>
          {" "}
          <span>{convertToIndianCurrency(Summary?.TotalSale || 0)} </span>{" "}
        </Table.Td>
      </Table.Tr>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Helmet title="Daywise Report" />
      <Title order={1}>Daywise Report</Title>
      <Divider my="md" />
      <Grid>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <Text size="sm" style={{ paddingBottom: "10px" }}>
            Filter By
          </Text>
          <Tabs
            color="grape"
            value={activeTab}
            onChange={(value) => {
              // switchStateChange(value === "Outlet" ? false : true);
              setActiveTab(value);
              setReportByDevice(value === "Outlet" ? false : true);
              setPageNumber("1");
              stopAPICalls();
            }}
            classNames={clases}
          >
            <Tabs.List>
              <Tooltip
                label="Select Outlet wise Reports"
                position="bottom-start"
                offset={2}
                arrowOffset={50}
                arrowSize={8}
                withArrow
                openDelay={500}
              >
                <Tabs.Tab value="Outlet" leftSection={<IconBuildingStore />}>
                  Outlet
                </Tabs.Tab>
              </Tooltip>
              <Tooltip
                label="Select Device wise Reports"
                position="bottom-start"
                offset={2}
                arrowOffset={50}
                arrowSize={8}
                withArrow
                openDelay={500}
              >
                <Tabs.Tab
                  value="Device"
                  leftSection={<IconDeviceLandlinePhone />}
                >
                  Device
                </Tabs.Tab>
              </Tooltip>
            </Tabs.List>
          </Tabs>
        </Grid.Col>
        {Ouletloading || DeviceLoading ? (
          <></>
        ) : (
          <>
            <Grid.Col
              mt={-1}
              span={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2,
                xl: 2,
              }}
            >
              <Select
                label={!reportByDevice ? "Select Outlet" : "Select Device"}
                disabled={exportLoading}
                value={!reportByDevice ? outletId : deviceId}
                onChange={(event) => {
                  let selectedName: any;
                  if (!reportByDevice) {
                    outlet?.docs?.find((item: any) => {
                      if (item._id === event) {
                        selectedName = item?.outletName;
                        return item?.outletName;
                      }
                    });
                  } else if (reportByDevice) {
                    DeviceData?.docs?.find((item: any) => {
                      if (item._id === event) {
                        selectedName = item?.userDeviceName;
                        return item?.userDeviceName;
                      }
                    });
                  }
                  handleOuletAndDeviceChange(event, selectedName);
                }}
                placeholder={
                  !reportByDevice ? "Select Outlet" : "Select Device"
                }
                data={
                  !reportByDevice ? selectOutletData() : seletcedDataDevice()
                }
                allowDeselect={false}
              />
            </Grid.Col>
            <Grid.Col
              mt={-1}
              span={{
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2,
                xl: 2,
              }}
            >
              <DatePickerInput
                label="Select Date"
                miw={230}
                maw={{ lg: 250, xl: 250 }}
                allowSingleDateInRange
                valueFormat="DD/MM/YYYY"
                required
                type="range"
                placeholder="Pick Dates Range"
                clearable
                defaultValue={[new Date(), new Date()]}
                value={date}
                onChange={(e: any) => {
                  setDate(e);
                  if (e[1]) {
                    stopAPICalls();
                    setBothDates({
                      fromDate: moment(e[0]).format("YYYY/MM/DD"),
                      toDate: moment(e[1]).format("YYYY/MM/DD"),
                    });
                  }
                  setPageNumber("1");
                  if (e && e[0] !== null && e[1] !== null) {
                    handledate(e);
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                xs: 6,
                sm: 6,
                md: 6,
                lg: 1.5,
                xl: 1.2,
              }}
            >
              <Checkbox
                style={{
                  position: "relative",
                  top: "30px",
                }}
                checked={refreshSelected}
                onChange={(event) =>
                  setRefreshSelected(event.currentTarget.checked)
                }
                label="Auto Refresh"
              />
            </Grid.Col>
            <Grid.Col
              pt={20}
              span={{
                xs: 6,
                sm: 6,
                md: 4,
                lg: 0.2,
                xl: 0.2,
              }}
              display="flex"
              style={{
                gap: "15px",
                alignItems: "center",
              }}
            >
              {Daydata.docs?.length > 0 ? (
                <>
                  <Tooltip
                    label={`Switch to ${
                      switchTableOrGraph ? "Graph" : "Table"
                    }`}
                    position="bottom-start"
                    offset={2}
                    arrowOffset={50}
                    arrowSize={8}
                    withArrow
                  >
                    <ActionIcon
                      variant="default"
                      size="lg"
                      onClick={() => {
                        if (switchTableOrGraph) {
                          setSwitchTableOrGraph(false);
                        } else {
                          setSwitchTableOrGraph(true);
                        }
                      }}
                    >
                      {switchTableOrGraph ? <IconTable /> : <IconChartBar />}
                    </ActionIcon>
                  </Tooltip>
                </>
              ) : (
                <></>
              )}

              {Summary?.NoOfBills > 0 && (
                <Tooltip label="Export Reports file">
                  <ActionIcon
                    loading={exportLoading}
                    variant="default"
                    size="lg"
                    onClick={() => handleExportClick()}
                  >
                    <IconDownload />
                  </ActionIcon>
                </Tooltip>
              )}
            </Grid.Col>
          </>
        )}
      </Grid>

      <SimpleGrid style={{ padding: "20px 16px " }} cols={1}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 30,
            alignItems: "end",
          }}
        ></div>

        <div style={{ paddingTop: "20px" }}>
          {loading ? (
            <div style={{ width: "100%" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    {[...Array(2)].map((_) => (
                      <th style={{ width: "50%" }}>
                        <Skeleton radius={0} height={60} />
                      </th>
                    ))}
                  </tr>
                </thead>
                <div style={{ width: "100%", height: "6px" }}></div>
                <tbody>
                  {[...Array(10)].map((_, index) => (
                    <tr key={index}>
                      {[...Array(6)].map(() => (
                        <td>
                          <Skeleton radius={0} height={35} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {Daydata?.docs?.length ? (
                switchTableOrGraph ? (
                  <>
                    <ChartUtils
                      label="Total Sales"
                      xAxisTitle="Days"
                      yAxisTitle="Total Sales"
                      pluginsTitle="Daywise"
                      data={Daydata?.docs}
                      labelsProp="BillNo"
                      dataSetProp="TotalSale"
                      multipleDataSets
                      secondLabel="Total Bills"
                      secondDataSetProp="NoOfBills"
                    />
                  </>
                ) : (
                  <>
                    <div>
                      <Table
                        verticalSpacing="xs"
                        horizontalSpacing="xs"
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                      >
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Date</Table.Th>
                            <Table.Th>Total Bills</Table.Th>
                            <Table.Th>Total CESS</Table.Th>
                            <Table.Th>Total Discount</Table.Th>
                            <Table.Th>TotalGST</Table.Th>
                            <Table.Th>Total Sale</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>{SummaryReport()}</Table.Tbody>
                        <Table.Tbody>{TableData()}</Table.Tbody>
                      </Table>
                    </div>
                    {TotalRecords > 1 && (
                      <div style={{ marginBottom: "50px" }}>
                        <Flex
                          p={15}
                          mih={50}
                          align="center"
                          gap="md"
                          justify="flex-end"
                          direction="row"
                          wrap="wrap"
                        >
                          {/* <div style={{ width: "5%" }}> */}
                          <Group>
                            <NumberInput
                              value={pageNumber}
                              w={60}
                              size="xs"
                              mt="sm"
                              placeholder="Page "
                              radius="sm"
                              min={1}
                              max={TotalRecords}
                              hideControls
                              onChange={(event) => {
                                setSearchpage(Number(event));
                              }}
                            />
                            {/* </div> */}
                            <Button
                              mt="sm"
                              size="xs"
                              variant="outline"
                              onClick={() => {
                                const pageNumber2 = searchpage;

                                if (
                                  pageNumber2 < 1 ||
                                  pageNumber2 > TotalRecords
                                ) {
                                  notifications.show({
                                    title: "Error",
                                    message: "Invalid page number",
                                    color: "red",
                                    icon: <IconX size="1.1rem" />,
                                  });
                                } else {
                                  handlePageChange(searchpage);
                                }
                              }}
                            >
                              Go
                            </Button>
                          </Group>

                          <Pagination
                            mt="sm"
                            total={TotalRecords}
                            onChange={handlePageChange}
                            value={Number(pageNumber)}
                            // radius="xl"
                          />
                        </Flex>
                      </div>
                    )}
                  </>
                )
              ) : (
                <>
                  <div style={{ paddingTop: "60px" }}>
                    <Player
                      autoplay
                      loop
                      src="./static/infographics/noRecordsFound.json"
                      style={{ height: "300px", width: "300px" }}
                      background="transparent"
                      controls={false}
                    ></Player>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </SimpleGrid>
    </div>
  );
};

export default Daywise;
