import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  UnstyledButton,
  rem,
  NavLink,
  useMantineColorScheme,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import classes from "../appShell/SideBarLinks.module.css";
import { useNavigate } from "react-router-dom";

export interface LinksGroupProps {
  icon: React.FC<any>;
  label: string;
  path?: string;
  iconColor?: string;
  initiallyOpened?: boolean;
  active: boolean;
  activeIndex: any;
  handleSelectedLink: (index: any, e: any) => void;
  index?: any;
  links?: { label: string; link: string }[];
  isOpen: boolean; // This prop will determine if the dropdown is open or not
  toggleMobile: any;
}

export function LinksGroup({
  icon: Icon,
  label,
  path,
  links,
  iconColor,
  index,
  active,
  activeIndex,
  handleSelectedLink,
  isOpen,
  toggleMobile,
}: LinksGroupProps) {
  const navigate = useNavigate();
  const { colorScheme } = useMantineColorScheme();
  const [linkSelected, setLinkSelected] = useState(-1);
  const hasLinks = Array.isArray(links);
  const isDarkMode = colorScheme === "dark";
  const isLightMode = colorScheme === "light";

  const darkModeLabelColor =
    isOpen || activeIndex === index
      ? isDarkMode
        ? "#000"
        : "#000"
      : isDarkMode
      ? "#fff"
      : "#000";

  const lightModeLabelColor =
    isOpen || activeIndex === index
      ? isLightMode
        ? "#000"
        : "#000"
      : isLightMode
      ? "#000"
      : "#000";

  const items = (hasLinks ? links : []).map((link, index) => (
    <NavLink
      w={230}
      data-active={linkSelected === index || undefined}
      key={link.label}
      opened={isOpen}
      component="button"
      className={colorScheme === "light" ? classes.lightLink : classes.link}
      label={link.label}
      onClick={(event) => {
        setLinkSelected(index);
        event.preventDefault();
        toggleMobile();
        navigate(link.link);
      }}
    ></NavLink>
  ));

  return (
    <>
      <UnstyledButton
        mb={5}
        data-active={active || undefined}
        onClick={(e) => {
          handleSelectedLink(index, e);
          if (path) {
            navigate(path);
          }
        }}
        className={
          colorScheme === "dark"
            ? classes.darkControl
            : colorScheme === "light"
            ? classes.lightControl
            : ""
        }
      >
        <Group justify="space-between">
          <Box style={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon
              // style={{
              //   color:
              //     isOpen || (activeIndex === index && isLightMode)
              //       ? "#fff"
              //       : "",
              //   // backgroundColor:
              //   //   colorScheme === "dark"
              //   //     ? "#fff"
              //   //     : colorScheme === "light"
              //   //     ? "#000"
              //   //     : "",
              //   // border:
              //   //   isOpen || (activeIndex === index && isLightMode)
              //   //     ? "1px solid #fff"
              //   //     : "",
              // }}
              variant={
                colorScheme === "light"
                  ? "light"
                  : colorScheme === "dark"
                  ? "dark"
                  : "dark"
              }
              size={30}
            >
              <Icon style={{ width: rem(18), height: rem(18) }} />
            </ThemeIcon>
            <Box
              ml="md"
              style={{
                color:
                  colorScheme === "dark"
                    ? darkModeLabelColor
                    : lightModeLabelColor,
              }}
            >
              {label}
            </Box>
          </Box>
          {hasLinks && (
            <IconChevronRight
              data-active={active || undefined}
              className={
                colorScheme === "dark"
                  ? classes.darkChevron
                  : classes.lightChevron
              }
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: isOpen ? "rotate(90deg)" : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={isOpen}>{items}</Collapse> : null}
    </>
  );
}
