import React from "react";
import { rem } from "@mantine/core";

export function Logo({ userClick }: { userClick: () => void }) {
  return (
    <img
      onClick={userClick}
      src="/static/images/logo.png"
      alt="logo"
      style={{
        width: rem(100),
        height: rem(30),
        marginLeft: rem(13),
      }}
    />
  );
}
