import { createSlice } from "@reduxjs/toolkit";
import {
  getDeviceSubscriptionInfo,
  getSubscriptionOffers,
  razorPay,
  subscriptionTransaction,
  subscriptionTransactionPatch,
  FetchSubscriptionTransactions,
} from "./subscriptionActions";

interface ReportState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

const initialGetDeviceSubscriptionInfoState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetSubscriptionOffersState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialRazorPayState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialSubscriptionTransactionState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialSubscriptionTransactionStatePatch: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetSubscriptionTrsansactionState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

interface GtData {
  CESSPercent?: number;
  Currency?: string;
  Description?: string;
  Discount?: number;
  Duration?: number;
  GSTPercent?: number;
  OtherPercent?: number;
  Price?: number;
  serialNumber: string;
  deviceid: string;
}

interface MySliceState {
  gtArray: any;
  gt: GtData | null; // Initialize with null or initial data if needed
}
const initialState: MySliceState = {
  gt: null,
  gtArray: [],
};

export const getDeviceSubscriptionInfoSlice = createSlice({
  name: "subscription/deviceSubscriptionInfo",
  initialState: initialGetDeviceSubscriptionInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceSubscriptionInfo.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getDeviceSubscriptionInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getDeviceSubscriptionInfo.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getSubscriptionOffersSlice = createSlice({
  name: "subscription/subscriptionOffers",
  initialState: initialGetSubscriptionOffersState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionOffers.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getSubscriptionOffers.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getSubscriptionOffers.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const razorPaySlice = createSlice({
  name: "subscription/razorPay",
  initialState: initialRazorPayState,
  reducers: {
    resetState: (state) => {
      // Reset the state to initialRazorPayState
      return initialRazorPayState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(razorPay.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(razorPay.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(razorPay.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const setSubscriptionTransactionSlice = createSlice({
  name: "subscription/subscriptionTransaction",
  initialState: initialSubscriptionTransactionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionTransaction.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(subscriptionTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(subscriptionTransaction.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const setSubscriptionTransactionPatchSlice = createSlice({
  name: "subscription/subscriptionTransactionPatch",
  initialState: initialSubscriptionTransactionStatePatch,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionTransactionPatch.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(subscriptionTransactionPatch.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(subscriptionTransactionPatch.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const mySlice = createSlice({
  name: "set/data",
  initialState,
  reducers: {
    addData: (state: any, action: any) => {
      const index = state.gtArray.findIndex(
        (item: any) => item.deviceId === action.payload.deviceId
      );

      if (index !== -1) {
        // If the item exists, replace it with the new data
        state.gtArray[index] = action.payload;
      } else {
        // If the item doesn't exist, push it to the array
        state.gtArray.push(action.payload);
      }
    },
    removeData: (state, action) => {
      state.gtArray = state.gtArray.filter(
        (item: any) => item.deviceId !== action.payload.id
      );
    },
    reset() {
      return {
        ...initialState,
      };
    },
  },
});

export const getFetchSubscriptionTransactionsSlice = createSlice({
  name: "subscription/getFetchSubscriptionTransactions",
  initialState: initialGetSubscriptionTrsansactionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchSubscriptionTransactions.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(FetchSubscriptionTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(FetchSubscriptionTransactions.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getDeviceSubscriptionInfoReducer =
  getDeviceSubscriptionInfoSlice.reducer;
export const selectDataArray = (state: any) => state.mySlice.dataArray;
export const { addData, removeData, reset } = mySlice.actions;
export const mysliceReducer = mySlice.reducer;
export const selectGtArray = (state: { mySlice: MySliceState }) =>
  state.mySlice.gtArray;
export const getSubscriptionOffersReducer = getSubscriptionOffersSlice.reducer;
export const razorPayReducer = razorPaySlice.reducer;
export const setSubscriptionTransactionReducer =
  setSubscriptionTransactionSlice.reducer;
export const setSubscriptionTransactionPatchReducer =
  setSubscriptionTransactionPatchSlice.reducer;
export const getFetchSubscriptionTransactionsReducer =
  getFetchSubscriptionTransactionsSlice.reducer;
