import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Text } from "@mantine/core";

const SelectDevice = () => {
  return (
    <Box
      mt={80}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Player
        autoplay
        loop
        src="/static/infographics/chooseDevice.json"
        style={{ height: "350px", width: "350px" }}
        background="transparent"
        controls={false}
      ></Player>
      <Text variant="h1" fw={700} fz={25}>
        Please Select a Device
      </Text>
    </Box>
  );
};

export default SelectDevice;
