import { createSlice } from "@reduxjs/toolkit";
import { getFirmWare } from "./firmwareActions";

interface FirmwareState{
    loading: boolean;
    errorData?: string | null;
    error?:boolean;
    data?: [];
}
const initialGetFirmwareState: FirmwareState = {
    loading: false,
    errorData: null,
    error:false,
    data: [],
 };
export const getFirmwareSlice = createSlice({
    name: 'report/getFirmware',
    initialState: initialGetFirmwareState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFirmWare.pending, (state) => {
                state.loading=true;
                state.errorData=null;
                state.data=[]
                state.error=false
            })
            .addCase(getFirmWare.fulfilled, (state, action) => {
                state.loading=false;
                state.errorData=null;
                state.data=action.payload
                state.error=false
              
                
            })
            .addCase(getFirmWare.rejected, (state, action) => {
                state.loading=false;
                state.errorData=action.error.message;
                state.data=[]
                state.error=true
              
            })
    },
}
);

export const getFirmwareReducer= getFirmwareSlice.reducer;