import { Button, Modal, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLock } from "@tabler/icons-react";
import classes from "../pages/styleModules/AppShellMainStyles.module.css";

interface Props {
  resetPasswordOpened: boolean;
  handleResetPassword: any;
  handleResetPasswordClose: any;
}

const ResetPassword = ({
  resetPasswordOpened,
  handleResetPassword,
  handleResetPasswordClose,
}: Props) => {
  const form = useForm({
    initialValues: {
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    },

    validate: {
      currentpassword: (value) =>
        value.length < 6
          ? "Must be 6 or more"
          : value.length > 15
          ? "Must be 15 or less"
          : null,
      newpassword: (value) =>
        value.length < 6
          ? "Must be 6 or more"
          : value.length > 15
          ? "Must be 15 or less"
          : null,
      confirmpassword: (value, values) =>
        value !== values.newpassword ? "Passwords did not match" : null,
    },
  });
  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      size="xs"
      closeOnClickOutside={false}
      opened={resetPasswordOpened}
      onClose={() => {
        handleResetPasswordClose();
        form.reset();
      }}
      title="Reset password"
    >
      <form
        onSubmit={form.onSubmit((values: any) => {
          handleResetPassword(values);
          form.reset();
        })}
      >
        <PasswordInput
          leftSection={<IconLock height={15} />}
          required
          label="Current Password"
          placeholder="Current Password"
          type="password"
          name="currentpassword"
          value={form.values.currentpassword}
          onChange={(e) =>
            form.setFieldValue("currentpassword", e.currentTarget.value)
          }
          error={form.errors.currentpassword}
        />
        <PasswordInput
          required
          leftSection={<IconLock height={15} />}
          label="New Password"
          placeholder="New Password"
          type="password"
          name="newpassword"
          value={form.values.newpassword}
          onChange={(e) =>
            form.setFieldValue("newpassword", e.currentTarget.value)
          }
          error={form.errors.newpassword}
        />
        <PasswordInput
          required
          leftSection={<IconLock height={15} />}
          label="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          name="confirmpassword"
          value={form.values.confirmpassword}
          onChange={(e) =>
            form.setFieldValue("confirmpassword", e.currentTarget.value)
          }
          error={form.errors.confirmpassword}
        />

        <Button type="submit" mt={12}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default ResetPassword;
