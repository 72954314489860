import React, { useEffect } from "react";
import { Button, Modal, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import classes from "../../styleModules/AppShellMainStyles.module.css";

interface Props {
  userDeviceName: string;
  nameEditable: any;
  setNameEditable: any;
  handleDeviceNameChange: any;
  updateLoading: any;
  setSelectedDevice: any;
}

const EditDeviceName = ({
  userDeviceName,
  nameEditable,
  setNameEditable,
  updateLoading,
  handleDeviceNameChange,
  setSelectedDevice,
}: Props) => {
  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      userDeviceName: userDeviceName ?? "",
    },
    validate: {
      userDeviceName: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 10
          ? null
          : "Must be 10 or less",
    },
  });

  useEffect(() => {
    form.setFieldValue("userDeviceName", userDeviceName);
  }, [userDeviceName]);

  return (
    <Modal
      classNames={{
        close: classes.modalCloseButton,
      }}
      title="Edit Device Name"
      closeOnClickOutside={false}
      withCloseButton
      opened={nameEditable}
      onClose={() => {
        setNameEditable(false);
        setSelectedDevice([]);
        form.reset();
      }}
      size="xs"
      radius="md"
    >
      <form
        onSubmit={form.onSubmit((values: any) =>
          handleDeviceNameChange(values.userDeviceName)
        )}
      >
        <TextInput
          error={form.errors.userDeviceName}
          maxLength={10}
          value={form.values.userDeviceName}
          onChange={(e) => {
            form.setFieldValue("userDeviceName", e.currentTarget.value);
          }}
          label="Device Name"
        />

        <Button
          loading={updateLoading}
          style={{ marginTop: "10px" }}
          type="submit"
        >
          Save
        </Button>
      </form>
    </Modal>
  );
};

export default EditDeviceName;
