import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";
import { FooterService } from "./footer/footerService";

export const fetch = createAsyncThunk(
    "footer/fetch",
    async ( thunkAPI) => {
      try {
        const footerService = new FooterService(APIPATH);
        const response = await footerService.fetchversion();
        return response;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );