import { createSlice } from "@reduxjs/toolkit";
import { signIn, businessSignIn, signUp, editPassword, forgotPassword, deviceLogin, isLoggedIn, logout, getProfile, editUserProfile } from "./authAction";
import { TokenData, BusinessTokenData, TerminalTokenData } from "./service/authService";

interface UserSignInState {
    signInLoading: boolean;
    signInError: string | null;
    token: string | null;
    isLoggedIn?:boolean;
    isBusinessAvailable?:boolean;
}

interface UserSignUpState {
    registerLoading: boolean;
    registerErrorMessage: string | null;
    isRegister: boolean;
    registerError: boolean;
}

interface GetUserProfileState {
    loading: boolean;
    errorData: boolean;
    error: boolean;
    data:any
}

interface EditUserProfileState{
    loading: boolean;
    errorData?: string | null;
    error: boolean;
    data:any
    isEdited:boolean
}

interface DeviceLoginState {
    deviceLoginLoading: boolean;
    deviceLoginErrorMessage: string | null;
    isLogin: boolean;
    deviceLoginError: boolean;
    terminalToken: string
    deviceId: string
}


interface EditPasswordState {
    loading: boolean;
    errorData: string | null;
    error: boolean;
    isEdited: boolean;
    editPasswordError: boolean;
}

interface ResetPasswordState {
    resetPasswordLoading: boolean;
    resetPasswordErrorMessage: string | null;
    isReset: boolean;
    resetPasswordError: boolean;
}


const initialSignInState: UserSignInState = {
    signInLoading: false,
    signInError: null,
    token: null,
    isLoggedIn:false
};

const initialGetProfileState: GetUserProfileState = {
    loading: false,
    errorData: false,
    error: false,
    data:null
};

const  initialEditUserProfileState:EditUserProfileState={
    loading: false,
    errorData: null,
    error: false,
    data:null,
    isEdited:false
}


const initialSignUpState: UserSignUpState = {
    registerLoading: false,
    isRegister: false,
    registerErrorMessage: null,
    registerError: false
};

const initialBusinessLoginState: UserSignInState = {
    signInLoading: false,
    signInError: null,
    token: null,
    isBusinessAvailable: false
};

const initialEditPasswordState: EditPasswordState = {
    loading: false,
    errorData: null,
    isEdited: false,
    error: false,
    editPasswordError: false,
}

const initialResetPasswordState: ResetPasswordState = {
    resetPasswordLoading: false,
    resetPasswordErrorMessage: null,
    isReset: false,
    resetPasswordError: false,
}

const initialDeviceLoginState: DeviceLoginState = {
    deviceLoginLoading: false,
    deviceLoginErrorMessage: null,
    isLogin: false,
    deviceLoginError: false,
    terminalToken: '',
    deviceId: ''
}



export const userSignInSlice = createSlice({
    name: 'user/login',
    initialState: initialSignInState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.signInLoading = true;
                state.signInError = null;
                state.token = null;
                state.isLoggedIn=false
            })
            .addCase(signIn.fulfilled, (state, action) => {
                const tokenData: TokenData = action.payload;
                state.signInLoading = false;
                state.signInError = null;
                state.isLoggedIn=true
                state.token = action.payload;
                localStorage.setItem('usertype', tokenData.usertype!)
                localStorage.setItem('access_token', tokenData.access_token!);
                localStorage.setItem('userId', tokenData.userId!);
            })
            .addCase(signIn.rejected, (state, action) => {
                state.signInLoading = false;
                state.signInError = action.error.message ?? 'Failed to Sign In';
                state.token = null;
                state.isLoggedIn=false
            })
            .addCase(isLoggedIn.fulfilled, (state, action) => {
                state.isLoggedIn = true;
              })
              .addCase(logout.fulfilled, (state) => {
                state.isLoggedIn = false;
              })
    },
}
);

export const userProfileSlice = createSlice({  
    name: "user/getProfile",
    initialState: initialGetProfileState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
                state.loading = true;
                state.errorData = false;
                state.error = false;
                state.data=null
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.errorData = false;
                state.error = false;
                state.data=action.payload
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loading = false;
                state.errorData = true;
                state.error = true;
                state.data=null
            })
    }
})

export const editUserProfileSlice = createSlice({
    name: 'user/editUserProfile',
    initialState: initialEditUserProfileState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(editUserProfile.pending, (state) => {
                state.loading = true;
                state.errorData = null;
                state.error = false;
                state.data=null
                state.isEdited=false
            })
            .addCase(editUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.errorData = action.payload;
                state.error = false;
                state.data=action.payload
                state.isEdited=true
            })
            .addCase(editUserProfile.rejected, (state, action) => {
                state.loading = false;
                state.errorData = null;
                state.error = true;
                state.data=null
                state.isEdited=false
            })
    },
}
);

export const businessLoginSlice = createSlice({
    name: 'user/businessLogin',
    initialState: initialBusinessLoginState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(businessSignIn.pending, (state) => {
                state.signInLoading = true;
                state.signInError = null;
                state.token = null;
                state.isBusinessAvailable = false;
            })
            .addCase(businessSignIn.fulfilled, (state, action) => {
                const tokenData: BusinessTokenData = action.payload;
                state.signInLoading = false;
                state.signInError = null;
                if (tokenData.isBusinessAvailable) {
                    localStorage.setItem('isBusinessAvailable', 'true');
                    localStorage.setItem('access_token', tokenData.access_token!);
                    localStorage.setItem('businessId', tokenData.businessId!);
                    state.token = tokenData.access_token;
                    state.isBusinessAvailable = true;
                }
                else {
                    localStorage.setItem('isBusinessAvailable', 'false');
                    state.isBusinessAvailable = false;
                }
            })
            .addCase(businessSignIn.rejected, (state, action) => {
                state.signInLoading = false;
                state.signInError = action.error.message ?? 'Failed to login';
                state.token = null;
                state.isBusinessAvailable = false;
            })
    },
});


export const deviceLoginSlice = createSlice({
    name: 'user/deviceLogin',
    initialState: initialDeviceLoginState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deviceLogin.pending, (state) => {
                state.deviceLoginLoading = true;
                state.deviceLoginError = false;
                state.isLogin = false;
                state.deviceLoginErrorMessage = null;
            })
            .addCase(deviceLogin.fulfilled, (state, action) => {
                const tokenData: TerminalTokenData = action.payload;

                if (tokenData.terminalToken) {
                    localStorage.setItem('terminalToken', tokenData.terminalToken!);
                    localStorage.setItem('deviceId', tokenData.deviceId)
                    state.terminalToken = tokenData.terminalToken;
                    state.deviceId = tokenData.deviceId
                }

                state.deviceLoginLoading = false;
                state.deviceLoginError = false;
                state.isLogin = true;
                state.deviceLoginErrorMessage = null;
            })
            .addCase(deviceLogin.rejected, (state, action) => {
                state.deviceLoginLoading = false;
                state.deviceLoginError = true;
                state.isLogin = false;
                state.deviceLoginErrorMessage = action.error.message ?? 'Failed to login';
            })
    },
});

export const userRegisterSlice = createSlice({
    name: 'user/register',
    initialState: initialSignUpState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(signUp.pending, (state) => {
                state.isRegister = false;
                state.registerLoading = true;
                state.registerErrorMessage = null;
                state.registerError = false

            })
            .addCase(signUp.fulfilled, (state) => {
                state.isRegister = true;
                state.registerLoading = false;
                state.registerErrorMessage = null;
                state.registerError = false
            })
            .addCase(signUp.rejected, (state, action) => {
                state.isRegister = false;
                state.registerLoading = false;
                state.registerErrorMessage = action.error.message ?? 'Failed to register';
                state.registerError = true
            })
    },
});

export const userEditPasswordSlice = createSlice({
    name: 'user/editPassword',
    initialState: initialEditPasswordState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(editPassword.pending, (state) => {
                state.isEdited = false;
                state.loading = true;
                state.error = false;
                state.errorData = null;
                state.editPasswordError = false
            })
            .addCase(editPassword.fulfilled, (state) => {
                state.isEdited = true;
                state.loading = false;
                state.error = false;
                state.errorData = null;
                state.editPasswordError = false
            })
            .addCase(editPassword.rejected, (state, action) => {
                state.isEdited = false;
                state.loading = false;
                state.error = true;
                state.errorData = action.error.message!=="" && action.error.message!==undefined?action.error.message : 'Failed to Reset , Check your password';
                state.editPasswordError = true
            })
    },
});

export const userForgotPasswordSlice = createSlice({
    name: 'user/forgotPassword',
    initialState: initialResetPasswordState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.isReset = false;
                state.resetPasswordLoading = true;
                state.resetPasswordErrorMessage = null;
                state.resetPasswordError = false
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.isReset = true;
                state.resetPasswordLoading = false;
                state.resetPasswordErrorMessage = null;
                state.resetPasswordError = false
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isReset = false;
                state.resetPasswordLoading = false;
                state.resetPasswordErrorMessage = action.error.message ?? 'Failed to register';
                state.resetPasswordError = true
            })
    },
});


export const userLoginReducer = userSignInSlice.reducer;
export const userProfileReducer = userProfileSlice.reducer;
export const editUserProfileReducer = editUserProfileSlice.reducer;
export const businessLoginReducer = businessLoginSlice.reducer;
export const userRegisterReducer = userRegisterSlice.reducer;
export const userEditPasswordReducer = userEditPasswordSlice.reducer;
export const userForgotPasswordReducer = userForgotPasswordSlice.reducer;
export const deviceLoginReducer = deviceLoginSlice.reducer;

