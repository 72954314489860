import { createSlice } from "@reduxjs/toolkit";
import {
  create,
  fetch,
  update,
  mapToOutlet,
  getDeviceSettings,
  getHeaderFooter,
  getWarrantyTC,
  mapToItemList,
  createDeviceSettings,
  updateDeviceSettings,
  createHeaderFooter,
  updateHeaderFooter,
} from "./deviceActions";

interface FetchDeviceState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

interface ActivateDeviceState {
  success: boolean;
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

interface UpdateDeviceState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: [];
  isUpdated: boolean;
}

interface GetDeviceSettingsProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data: any;
  hasData?: boolean | null;
}

interface CreateSettingsProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isCreated: boolean;
}

interface StateProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

interface UpdateHFProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isUpdated: boolean;
}

interface MapOutletProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isMapped?: boolean;
}

interface MapItemListProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isMapped?: boolean;
}

interface UpdateSettingsProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isUpdated: boolean;
}

interface GetHeaderFooterProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  hasData?: boolean | null;
}

interface CreateHFProps {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
  isCreated: boolean;
}

const initialFetchState: FetchDeviceState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialActivateState: ActivateDeviceState = {
  success: false,
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialUpdateState: UpdateDeviceState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isUpdated: false,
};

const initialGetDeviceSettingsState: GetDeviceSettingsProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  hasData: null,
};

const initialCreateDeviceSettingsState: CreateSettingsProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isCreated: false,
};

const initialUpdateDeviceSettingsState: UpdateSettingsProps = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
  isUpdated: false,
};
const initialGetHeaderFooterState: GetHeaderFooterProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  hasData: null,
};

const initialCreateHeaderFooterState: CreateHFProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isCreated: false,
};

const initialUpdateHeaderFooterState: UpdateHFProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
  isUpdated: false,
};

const initialGetWarrantyTCState: StateProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialMapToOutletState: MapOutletProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialMapToItemListState: MapItemListProps = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

export const fetchDeviceSlice = createSlice({
  name: "device/fetch",
  initialState: initialFetchState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const activateDeviceSlice = createSlice({
  name: "device/activate",
  initialState: initialActivateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.success = true;
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(create.rejected, (state, action) => {
        state.success = false;
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const updateDeviceSlice = createSlice({
  name: "device/updateName",
  initialState: initialUpdateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(update.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isUpdated = false;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isUpdated = true;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isUpdated = false;
      });
  },
});

export const mapToOutletSlice = createSlice({
  name: "device/mapToOutlet",
  initialState: initialMapToOutletState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(mapToOutlet.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isMapped = false;
      })
      .addCase(mapToOutlet.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isMapped = true;
      })
      .addCase(mapToOutlet.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isMapped = false;
      });
  },
});

export const mapToItemListSlice = createSlice({
  name: "device/mapToItemList",
  initialState: initialMapToItemListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(mapToItemList.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isMapped = false;
      })
      .addCase(mapToItemList.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isMapped = true;
      })
      .addCase(mapToItemList.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isMapped = false;
      });
  },
});

export const getDeviceSettingsSlice = createSlice({
  name: "device/getDeviceSettings",
  initialState: initialGetDeviceSettingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceSettings.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = {};
        state.error = false;
        state.hasData = null;
      })
      .addCase(getDeviceSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        if (typeof action.payload == "string" && action.payload !== undefined) {
          if (action.payload?.includes("1001")) {
            state.hasData = false;
            console.log("in 1001");
          }
        } else {
          state.hasData = true;
        }
      })
      .addCase(getDeviceSettings.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = {};
        state.error = true;
        state.hasData = null;
      });
  },
});

export const createDeviceSettingsSlice = createSlice({
  name: "device/createDeviceSettings",
  initialState: initialCreateDeviceSettingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDeviceSettings.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isCreated = false;
      })
      .addCase(createDeviceSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isCreated = true;
      })
      .addCase(createDeviceSettings.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isCreated = false;
      });
  },
});

export const updateDeviceSettingsSlice = createSlice({
  name: "device/updateDeviceSettings",
  initialState: initialUpdateDeviceSettingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDeviceSettings.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isUpdated = false;
      })
      .addCase(updateDeviceSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isUpdated = true;
      })
      .addCase(updateDeviceSettings.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isUpdated = false;
      });
  },
});

export const getDeviceHeaderFooterSlice = createSlice({
  name: "device/getHeaderFooter",
  initialState: initialGetHeaderFooterState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHeaderFooter.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.hasData = null;
      })
      .addCase(getHeaderFooter.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        if (typeof action.payload == "string" && action.payload !== undefined) {
          if (action.payload?.includes("1001")) {
            state.hasData = false;
          }
        } else {
          state.hasData = true;
        }
      })
      .addCase(getHeaderFooter.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.hasData = null;
      });
  },
});

export const createDeviceHeaderFooterSlice = createSlice({
  name: "device/createHeaderFooter",
  initialState: initialCreateHeaderFooterState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createHeaderFooter.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isCreated = false;
      })
      .addCase(createHeaderFooter.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isCreated = true;
      })
      .addCase(createHeaderFooter.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isCreated = false;
      });
  },
});

export const updateDeviceHeaderFooterSlice = createSlice({
  name: "device/updateHeaderFooter",
  initialState: initialUpdateHeaderFooterState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateHeaderFooter.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
        state.isUpdated = false;
      })
      .addCase(updateHeaderFooter.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        state.isUpdated = true;
      })
      .addCase(updateHeaderFooter.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
        state.isUpdated = false;
      });
  },
});

export const getDeviceWarrantyTCSlice = createSlice({
  name: "device/getWarrantyTC",
  initialState: initialGetWarrantyTCState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWarrantyTC.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getWarrantyTC.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getWarrantyTC.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const fetchDeviceReducer = fetchDeviceSlice.reducer;
export const activateDeviceReducer = activateDeviceSlice.reducer;
export const updateDeviceReducer = updateDeviceSlice.reducer;
export const mapToItemListReducer = mapToItemListSlice.reducer;
export const mapToOutletReducer = mapToOutletSlice.reducer;
export const getDeviceSettingsReducer = getDeviceSettingsSlice.reducer;
export const createDeviceSettingsReducer = createDeviceSettingsSlice.reducer;
export const updateDeviceSettingsReducer = updateDeviceSettingsSlice.reducer;
export const getDeviceHeaderFooterReducer = getDeviceHeaderFooterSlice.reducer;
export const createDeviceHeaderFooterReducer =
  createDeviceHeaderFooterSlice.reducer;
export const updateDeviceHeaderFooterReducer =
  updateDeviceHeaderFooterSlice.reducer;
export const getDeviceWarrantyTCReducer = getDeviceWarrantyTCSlice.reducer;
