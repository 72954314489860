import {
  Group,
  Text,
  Accordion,
  Button,
  TextInput,
  useMantineColorScheme,
  Grid,
  em,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import classes from "../../styleModules/AppShellMainStyles.module.css";
export interface OutletProps {
  _id: string;
  outletName: string;
  telephone: string;
  city: string;
  state: string;
  country: string;
  GSTN: string;
  businessId: string;
  fax: string;
  address: string;
  __v: number;
}

interface AccordionProps {
  data: OutletProps;
  handleUpdateOutlet: (values: any) => void;
  updateOutletLoading: boolean;
}

function AccordionLabel({ outletName }: OutletProps) {
  return (
    <Group>
      <Text>{outletName}</Text>
    </Group>
  );
}

export function OutletAccordion({
  data,
  handleUpdateOutlet,
  updateOutletLoading,
}: AccordionProps) {
  const { colorScheme } = useMantineColorScheme();
  const mobileOpened = useMediaQuery(`(max-width: ${em(999)})`);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      businessName: localStorage.getItem("businessName") ?? "",
      outletId: data._id ?? "",
      outletName: data.outletName ?? "",
      state: data.state ?? "",
      telephone: data.telephone ?? "",
      country: data.country ?? "",
      city: data.city ?? "",
      GSTN:
        data?.GSTN !== undefined && data?.GSTN !== null
          ? data?.GSTN.trim()
          : "",
      fax:
        data?.fax !== undefined && data?.fax !== null ? data?.fax.trim() : "",
      address:
        data?.address !== undefined && data?.address !== null
          ? data?.address.trim()
          : "",
    },
    validate: {
      outletName: (value) =>
        value.length < 1
          ? "Should not be empty"
          : value.length <= 50
          ? null
          : "Must be 50 or less",
      state: (value) =>
        value.length < 1
          ? "Should have state name"
          : value.length <= 20
          ? null
          : "Must be 20 or less",
      telephone: (value) =>
        value.length >= 15
          ? "Must be 15 or less"
          : value.length < 6
          ? "Must be 6 or more"
          : null,
      country: (value) =>
        value.length < 1
          ? "Should have country name"
          : value.length <= 20
          ? null
          : "Must be 20 or less",
      city: (value) =>
        value.length < 2
          ? "Must be 2 or more"
          : value.length <= 20
          ? null
          : "Must be 20 or less",
      GSTN: (value) =>
        value.length !== 15 && value.length > 0
          ? "Must be 15 characters"
          : null,

      fax: (value) =>
        value.length < 5 && value.length > 0
          ? "Must be more than 5 characters"
          : value.length > 20
          ? "Must be 20 or less"
          : null,
      address: (value) => (value.length <= 150 ? null : "Must be 150 or less"),
    },
  });

  const items = (
    <>
      <Accordion.Item
        style={{
          backgroundColor: colorScheme === "dark" ? "#191C24" : "#F0F2F5",
        }}
        value={data._id}
        key={data.outletName}
      >
        <Accordion.Control
          onClick={() => {
            form.setValues({
              businessName: localStorage.getItem("businessName") ?? "",
              outletId: data._id ?? "",
              outletName: data.outletName ?? "",
              state: data.state ?? "",
              telephone: data.telephone ?? "",
              country: data.country ?? "",
              city: data.city ?? "",
              GSTN:
                data?.GSTN !== undefined && data?.GSTN !== null
                  ? data?.GSTN.trim()
                  : "",
              fax:
                data?.fax !== undefined && data?.fax !== null
                  ? data?.fax.trim()
                  : "",
              address:
                data?.address !== undefined && data?.address !== null
                  ? data?.address.trim()
                  : "",
            });
          }}
        >
          <AccordionLabel key={data._id} {...data} />
        </Accordion.Control>
        <Accordion.Panel>
          <form
            onSubmit={form.onSubmit((values: any) =>
              handleUpdateOutlet(values)
            )}
          >
            <Divider style={{ width: "100%" }} />
            <Grid className={classes.OutletAccordionGrid}>
              <Grid.Col
                className={
                  mobileOpened
                    ? classes.mobileOuletView
                    : classes.desktopOutletView
                }
              >
                {/* <TextInput
                  disabled
                  className={classes.outletInput}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  contentEditable={false}
                  label="Business Name"
                  value={form.values.businessName}
                /> */}
                <TextInput
                  withAsterisk
                  className={classes.outletInput}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  label="Outlet Name"
                  maxLength={50}
                  value={form.values.outletName}
                  error={form.errors.outletName}
                  onChange={(e) => {
                    if (e.currentTarget.value !== form.values.outletName) {
                      form.setFieldValue(`outletName`, e.currentTarget.value);
                    }
                  }}
                />
                <TextInput
                  withAsterisk
                  className={classes.outletInput}
                  maxLength={15}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  type="number"
                  label="Contact Info"
                  value={form.values.telephone}
                  error={form.errors.telephone}
                  onChange={(e) => {
                    form.setFieldValue(`telephone`, e.currentTarget.value);
                  }}
                />
                <TextInput
                  withAsterisk
                  className={classes.outletInput}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  label="Country"
                  value={form.values.country}
                  error={form.errors.country}
                  onChange={(e) => {
                    form.setFieldValue(`country`, e.currentTarget.value);
                  }}
                />
              </Grid.Col>

              <Grid.Col
                className={
                  mobileOpened
                    ? classes.mobileOuletView
                    : classes.desktopOutletView
                }
              >
                <TextInput
                  withAsterisk
                  className={classes.outletInput}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  label="State"
                  value={form.values.state}
                  error={form.errors.state}
                  onChange={(e) => {
                    form.setFieldValue(`state`, e.currentTarget.value);
                  }}
                />
                <TextInput
                  withAsterisk
                  className={classes.outletInput}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  label="City"
                  maxLength={30}
                  value={form.values.city}
                  error={form.errors.city}
                  onChange={(e) => {
                    form.setFieldValue(`city`, e.currentTarget.value);
                  }}
                />
                <TextInput
                  className={classes.outletInput}
                  classNames={{
                    label: classes.mantineLabel,
                  }}
                  label="GSTN"
                  maxLength={15}
                  value={form.values.GSTN}
                  error={form.errors.GSTN}
                  onChange={(e) => {
                    form.setFieldValue(`GSTN`, e.currentTarget.value);
                  }}
                />
              </Grid.Col>
              <Grid.Col
                className={
                  mobileOpened
                    ? classes.mobileOuletView
                    : classes.desktopOutletView
                }
              >
                <Grid.Col span={{ xl: 3.9, lg: 3.9, md: 3.9 }} p={0}>
                  <TextInput
                    className={classes.outletInput}
                    type="number"
                    classNames={{
                      label: classes.mantineLabel,
                    }}
                    label="Fax"
                    maxLength={20}
                    value={form.values.fax}
                    error={form.errors.fax}
                    onChange={(e) => {
                      form.setFieldValue(`fax`, e.currentTarget.value);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xl: 3.9, lg: 3.9, md: 3.9 }} p={0}>
                  <TextInput
                    className={classes.outletInput}
                    classNames={{
                      label: classes.mantineLabel,
                    }}
                    label="Address"
                    maxLength={150}
                    value={form.values.address}
                    error={form.errors.address}
                    onChange={(e) => {
                      form.setFieldValue(`address`, e.currentTarget.value);
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                style={{
                  display: "flex",
                  justifyContent: mobileOpened ? "center" : "flex-start",
                }}
              >
                <Button loading={updateOutletLoading} type="submit">
                  Update
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );

  return items;
}
