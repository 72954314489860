import { Skeleton } from "@mantine/core";
import React from "react";

const SkeletonTableLoader = () => {
  return (
    <div style={{ width: "100%" }}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            {[...Array(2)].map((_, index) => (
              <th key={index} style={{ width: "50%" }}>
                <Skeleton radius={0} height={60} />
              </th>
            ))}
          </tr>
        </thead>
        <div style={{ width: "100%", height: "6px" }}></div>
        <tbody>
          {[...Array(10)].map((_, index) => (
            <tr key={index}>
              {[...Array(7)].map((_, innerIndex) => (
                <td key={innerIndex}>
                  <Skeleton radius={0} height={35} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkeletonTableLoader;
