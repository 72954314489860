import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../awatarStore/store";
import {
  create,
  fetch,
  update,
  mapToOutlet,
  getDeviceSettings,
  getWarrantyTC,
  getHeaderFooter,
  mapToItemList,
  createDeviceSettings,
  updateDeviceSettings,
  createHeaderFooter,
  updateHeaderFooter,
} from "./deviceActions";
import {
  GetDeviceProps,
  ActivateDeviceProps,
  UpdateDeviceNameProps,
  MapToOutletProps,
  MapToItemListProps,
  GetSettingsProps,
  GetWarrantyTCProps,
  GetHeaderFooterProps,
  HeaderFooterProps,
  SettingsProps,
} from "./service/deviceService";

export const useGetDevice = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/fetch"].loading
  );
  const error = useSelector((state: RootState) => state["device/fetch"].error);
  const data = useSelector((state: RootState) => state["device/fetch"].data);

  const fetchDevice = async (deviceData: GetDeviceProps) => {
    await dispatch(fetch(deviceData));
  };

  return { fetchDevice, data, loading, error };
};

export const useActivateDevice = () => {
  const dispatch: AppDispatch = useDispatch();
  const success = useSelector(
    (state: RootState) => state["device/activate"].success
  );
  const loading = useSelector(
    (state: RootState) => state["device/activate"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/activate"].error
  );
  const errorData = useSelector(
    (state: RootState) => state["device/activate"].errorData
  );
  const data = useSelector((state: RootState) => state["device/activate"].data);

  const activateDevice = async (deviceData: ActivateDeviceProps) => {
    await dispatch(create(deviceData));
  };

  return { activateDevice, loading, error, success, errorData, data };
};

export const useUpdateDevice = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/updateName"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/updateName"].error
  );
  const errorData = useSelector(
    (state: RootState) => state["device/updateName"].errorData
  );
  const data = useSelector(
    (state: RootState) => state["device/updateName"].data
  );
  const isUpdated = useSelector(
    (state: RootState) => state["device/updateName"].isUpdated
  );

  const updateDevice = async (deviceData: UpdateDeviceNameProps) => {
    await dispatch(update(deviceData));
  };

  return { updateDevice, errorData, data, loading, error, isUpdated };
};

export const useMapToOutlet = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/mapToOutlet"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/mapToOutlet"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/mapToOutlet"].data
  );
  const errorData = useSelector(
    (state: RootState) => state["device/mapToOutlet"].errorData
  );
  const isMapped = useSelector(
    (state: RootState) => state["device/mapToOutlet"].isMapped
  );

  const mapToOutletFunction = async (mapOutletData: MapToOutletProps) => {
    await dispatch(mapToOutlet(mapOutletData));
  };
  return { mapToOutletFunction, loading, error, errorData, isMapped, data };
};

export const useMapToItemList = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/mapToItemList"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/mapToItemList"].error
  );
  const errorData = useSelector(
    (state: RootState) => state["device/mapToItemList"].errorData
  );
  const isMapped = useSelector(
    (state: RootState) => state["device/mapToItemList"].isMapped
  );

  const mapToItemListFunction = async (mapItemListData: MapToItemListProps) => {
    await dispatch(mapToItemList(mapItemListData));
  };

  return { mapToItemListFunction, loading, error, errorData, isMapped };
};

export const useGetDeviceSettings = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/getDeviceSettings"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/getDeviceSettings"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/getDeviceSettings"].data
  );
  const hasData = useSelector(
    (state: RootState) => state["device/getDeviceSettings"].hasData
  );

  const getDeviceSettingsFunction = async (settingsData: GetSettingsProps) => {
    try {
      await dispatch(getDeviceSettings(settingsData));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getDeviceSettingsFunction, data, hasData, loading, error };
};

export const useCreateDeviceSettings = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/createDeviceSettings"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/createDeviceSettings"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/createDeviceSettings"].data
  );
  const isCreated = useSelector(
    (state: RootState) => state["device/createDeviceSettings"].isCreated
  );
  const errorData = useSelector(
    (state: RootState) => state["device/createDeviceSettings"].errorData
  );

  const createDeviceSettingsFunction = async (settingsData: SettingsProps) => {
    await dispatch(createDeviceSettings(settingsData));
  };

  return {
    createDeviceSettingsFunction,
    loading,
    error,
    isCreated,
    errorData,
    data,
  };
};

export const useUpdateDeviceSettings = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/updateDeviceSettings"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/updateDeviceSettings"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/updateDeviceSettings"].data
  );
  const isUpdated = useSelector(
    (state: RootState) => state["device/updateDeviceSettings"].isUpdated
  );
  const errorData = useSelector(
    (state: RootState) => state["device/updateDeviceSettings"].errorData
  );

  const updateDeviceSettingsFunction = async (settingsData: SettingsProps) => {
    await dispatch(updateDeviceSettings(settingsData));
  };

  return {
    updateDeviceSettingsFunction,
    loading,
    error,
    isUpdated,
    errorData,
    data,
  };
};

export const useGetWarrantyTC = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/getWarrantyTC"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/getWarrantyTC"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/getWarrantyTC"].data
  );

  const getWarrantyTCFunction = async (
    getWarrantyProps: GetWarrantyTCProps
  ) => {
    try {
      await dispatch(getWarrantyTC(getWarrantyProps));
    } catch (error: any) {
      console.log(error.message, "Error");
    }
  };

  return { getWarrantyTCFunction, data, loading, error };
};

export const useGetHeaderFooter = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/getHeaderFooter"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/getHeaderFooter"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/getHeaderFooter"].data
  );
  const hasData = useSelector(
    (state: RootState) => state["device/getHeaderFooter"].hasData
  );

  const getHeaderFooterFunction = async (
    headerFooterData: GetHeaderFooterProps
  ) => {
    await dispatch(getHeaderFooter(headerFooterData));
  };

  return { getHeaderFooterFunction, loading, error, data, hasData };
};

export const useCreateHeaderFooter = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/createHeaderFooter"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/createHeaderFooter"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/createHeaderFooter"].data
  );
  const isCreated = useSelector(
    (state: RootState) => state["device/createHeaderFooter"].isCreated
  );
  const errorData = useSelector(
    (state: RootState) => state["device/createHeaderFooter"].errorData
  );

  const createHeaderFooterFunction = async (
    headerFooterData: HeaderFooterProps
  ) => {
    await dispatch(createHeaderFooter(headerFooterData));
  };

  return {
    createHeaderFooterFunction,
    loading,
    error,
    isCreated,
    errorData,
    data,
  };
};

export const useUpdateHeaderFooter = () => {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector(
    (state: RootState) => state["device/updateHeaderFooter"].loading
  );
  const error = useSelector(
    (state: RootState) => state["device/updateHeaderFooter"].error
  );
  const data = useSelector(
    (state: RootState) => state["device/updateHeaderFooter"].data
  );
  const isUpdated = useSelector(
    (state: RootState) => state["device/updateHeaderFooter"].isUpdated
  );
  const errorData = useSelector(
    (state: RootState) => state["device/updateHeaderFooter"].errorData
  );

  const updateHeaderFooterFunction = async (
    headerFooterData: HeaderFooterProps
  ) => {
    await dispatch(updateHeaderFooter(headerFooterData));
  };

  return {
    updateHeaderFooterFunction,
    loading,
    error,
    isUpdated,
    errorData,
    data,
  };
};
