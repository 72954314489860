import {
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Text,
  Pagination,
  Skeleton,
  Box,
  Flex,
  NumberInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import {
  useCreateItemList,
  useGetItemList,
  useUpdateItemList,
} from "../../modules/itemList/itemListHooks";
import NoRecordsFound from "../../utils/uiUtils/NoRecordsFound";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { Helmet } from "react-helmet-async";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import classes from "../styleModules/AppShellMainStyles.module.css";
import CreateItemList from "./itemList/CreateItemList";
import UpdateItemList from "./itemList/UpdateItemList";
import ItemListComponent from "./itemList/ItemListComponent";
import { notifications } from "@mantine/notifications";

export interface onDeleteCallbackProps {
  itemId: String;
  itemListId: String;
}

export const ItemList = () => {
  const {
    loading: itemsListLoading,

    data: fetchItemsListData,
    fetchItemList,
  } = useGetItemList();

  const {
    updateItemList,

    error: updateError,
    errorData: updateErrorData,
    isUpdated,
  } = useUpdateItemList();
  const {
    createItemList,

    error: createItemListError,
    errorData: createErrorData,
    isCreated,
  } = useCreateItemList();

  const [itemListName, setItemListName] = useState("");
  const [selectedItemListId, setSelectedItemListId] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [createItemListDialog, setCreateItemListDialog] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(15);
  const [currentData, setCurrentData] = useState([]);
  const [createFlag, setCreateFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [searchpage, setSearchpage] = useState(1);

  useEffect(() => {
    const getItemListData = {
      businessId: localStorage.getItem("businessId") ?? "",
      page: 1,
      limit: limit,
    };
    fetchItemList(getItemListData); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSearchpage(activePage);
  }, [activePage]);
  useEffect(() => {
    if (fetchItemsListData?.docs !== undefined) {
      setCurrentData(fetchItemsListData?.docs);
      setTotalPages(fetchItemsListData?.pages);
    }
  }, [fetchItemsListData]);

  useEffect(() => {
    if (isCreated && createFlag) {
      notifications.show({
        title: "Success",
        message: "ItemList Created Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });

      const getItemsListData = {
        businessId: localStorage.getItem("businessId") ?? "",
        page: activePage,
        limit: limit,
      };
      fetchItemList(getItemsListData);
      setCreateItemListDialog(false);
    } else if (createItemListError && createFlag) {
      notifications.show({
        title: "Error",
        message: `ItemList Creation Error :${createErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated, createItemListError]);

  useEffect(() => {
    if (isUpdated && updateFlag) {
      notifications.show({
        title: "Success",
        message: "ItemList Updated Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      const getItemListData = {
        businessId: localStorage.getItem("businessId") ?? "",
        page: activePage,
        limit: limit,
      };
      fetchItemList(getItemListData);
      close();
    } else if (updateError && updateFlag) {
      notifications.show({
        title: "Error",
        message: `Itemlist Update Error :${updateErrorData}`,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, updateError]);

  const handleCreateItemList = (values: any) => {
    const createItemListData = {
      businessId: localStorage.getItem("businessId") ?? "",
      itemListName: values.itemListName,
    };

    createItemList(createItemListData);
    setCreateFlag(true);
  };

  function stringToColor(str: any) {
    let hash = 0;
    let alpha = ((str.length * 25) / 100) % 1;
    alpha = alpha < 0.5 ? 0.5 : alpha;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; // Convert to 32bit integer
    }
    let color = (hash & 0x00ffffff).toString(16).toUpperCase();
    color = `#${"00000".substring(0, 6 - color.length)}${color}`;
    const green = str.length / 100;
    const hexGreen = Math.floor(green * 255)
      .toString(16)
      .toUpperCase();
    const paddedGreen = hexGreen.padStart(2, "0");
    color = `${color.slice(0, 3)}${paddedGreen}${color.slice(5, 7)}`;

    const hexAlpha = Math.floor(alpha * 255)
      .toString(16)
      .toUpperCase();
    const paddedAlpha = hexAlpha.padStart(2, "0");
    color = `${color}${paddedAlpha}`;
    return color;
  }

  function stringAvatar(name: any) {
    let nameArray = name.trim().split(" ");
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children:
        nameArray.length > 1
          ? `${nameArray[0][0]}${nameArray[1][0]}`
          : `${nameArray[0][0]}`,
    };
  }

  const handleItemListNameEdit = (itemListId: any, itemListName: any) => {
    setSelectedItemListId(itemListId);
    setItemListName(itemListName);
    form.setFieldValue("newName", itemListName);
    form.setFieldValue("itemListId", itemListId);
    open();
  };

  function handleChangeItemListName(values: any) {
    const updateItemListData = {
      businessId: localStorage.getItem("businessId") ?? "",
      itemListId: values.itemListId,
      itemListName: values.newName,
    };
    updateItemList(updateItemListData);
    setUpdateFlag(true);
  }

  const onPageChange = (page: any) => {
    const getItemListData = {
      businessId: localStorage.getItem("businessId") ?? "",
      page: page,
      limit: limit,
    };
    fetchItemList(getItemListData);
    setActivePage(page);
  };

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      newName: itemListName ?? "",
      itemListId: selectedItemListId ?? "",
    },

    validate: {
      newName: (value) =>
        value.length < 1 ? "Item List Name is required" : null,
    },
  });

  const createForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      itemListName: "",
    },

    validate: {
      itemListName: (value) =>
        value.length < 1 ? "Item List Name is required" : null,
    },
  });

  return (
    <>
      <Helmet title="ItemList" />
      <CreateItemList
        createItemListDialog={createItemListDialog}
        setCreateItemListDialog={setCreateItemListDialog}
        createForm={createForm}
        handleCreateItemList={handleCreateItemList}
      />

      <Grid className={classes.mainGrid}>
        <Grid.Col className={classes.mainTitleCol}>
          <Text className={classes.mainTitle}>ItemList</Text>

          <Button
            className={classes.mainNavButton}
            rightSection={<IconPlus size={20} />}
            onClick={() => {
              setCreateItemListDialog(!createItemListDialog);
            }}
          >
            ADD
          </Button>
        </Grid.Col>
        <Divider style={{ width: "100%" }} />

        <Grid.Col
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "calc(100vh - 200px)",
          }}
        >
          <Card withBorder className={classes.itemListMainCard}>
            {itemsListLoading ? (
              <Grid className={classes.itemListGrid}>
                {[...Array(3)].map((_, index) => (
                  <Grid.Col
                    key={index}
                    span={{ md: 12, xs: 12, sm: 12, lg: 6, xl: 4 }}
                  >
                    <Card
                      key={index}
                      className={classes.itemListCard}
                      withBorder
                      radius="md"
                    >
                      <Group
                        ml={20}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Skeleton height={50} width={50} circle />
                        <Skeleton height={20} width={150} />
                      </Group>
                      <Group
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></Group>
                    </Card>
                  </Grid.Col>
                ))}
              </Grid>
            ) : (
              <Grid
                className={classes.itemListGrid}
                style={{
                  display: "flex",
                  justifyContent:
                    currentData?.length === 0 ||
                    currentData?.length === undefined
                      ? "center"
                      : "",
                  alignItems:
                    currentData?.length === 0 ||
                    currentData?.length === undefined
                      ? "center"
                      : "flex-start",
                  height: "100%",
                }}
              >
                {(currentData?.length === 0 ||
                  currentData?.length === undefined) && (
                  <Box
                    w="100%"
                    h="100%"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <NoRecordsFound />
                  </Box>
                )}
                {currentData?.map((item: any) => (
                  <Grid.Col
                    span={{ md: 12, xs: 12, sm: 12, lg: 6, xl: 4 }}
                    key={item?._id}
                  >
                    <ItemListComponent
                      item={item}
                      handleItemListNameEdit={handleItemListNameEdit}
                      stringAvatar={stringAvatar}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            )}

            <UpdateItemList
              opened={opened}
              close={close}
              form={form}
              handleChangeItemListName={handleChangeItemListName}
            />
          </Card>
        </Grid.Col>
        {totalPages > 1 && (
          <Grid.Col>
            <Flex
              p={15}
              mb={40}
              mih={50}
              align="center"
              gap="md"
              justify="flex-end"
              direction="row"
              wrap="wrap"
            >
              <Group>
                <NumberInput
                  value={searchpage}
                  w={60}
                  size="xs"
                  mt="sm"
                  placeholder="Page"
                  radius="sm"
                  min={1}
                  // max={totalPages}
                  hideControls
                  onChange={(event) => {
                    console.log("event enew", event);
                    setSearchpage(Number(event));
                  }}
                />

                <Button
                  mt="sm"
                  size="xs"
                  variant="outline"
                  onClick={() => {
                    console.log("searchpage", searchpage);
                    const pageNumber2 = searchpage;

                    if (pageNumber2 < 1 || pageNumber2 > totalPages) {
                      notifications.show({
                        title: "Error",
                        message: "Invalid page number",
                        color: "red",
                        icon: <IconX size="1.1rem" />,
                      });
                    } else {
                      console.log("searchpage", searchpage);
                      onPageChange(searchpage);
                    }
                  }}
                >
                  Go
                </Button>
              </Group>
              <Pagination
                // radius="xl"
                style={{
                  // width: "100%",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                value={activePage}
                onChange={onPageChange}
                total={totalPages}
              />
            </Flex>{" "}
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};
