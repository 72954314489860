import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIPATH } from "../../utils/utils";

import {
  ItemProps,
  GetItemProps,
  ItemService,
  DeleteProps,
} from "./service/itemService";

export const fetch = createAsyncThunk(
  "item/fetch",
  async (itemData: GetItemProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.getItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const download = createAsyncThunk(
  "item/download",
  async (itemData: GetItemProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.getItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const create = createAsyncThunk(
  "item/create",
  async (itemData: ItemProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.createItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const update = createAsyncThunk(
  "item/update",
  async (itemData: ItemProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.updateItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "item/delete",
  async (itemData: DeleteProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.deleteItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const deleteAllItem = createAsyncThunk(
  "item/deleteAll",
  async (itemData: DeleteProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.deleteAllItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const searchItem = createAsyncThunk(
  "item/search",
  async (itemData: GetItemProps, thunkAPI) => {
    try {
      const itemService = new ItemService(APIPATH);
      const response = await itemService.searchItem(itemData);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);