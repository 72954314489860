import React, { useEffect } from "react";
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRegister } from "../../modules/auth/authHooks";
import { useNavigate } from "react-router-dom";
import { upperFirst } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import classes from "../styleModules/AppShellMainStyles.module.css";

const Register = () => {
  const history = useNavigate();
  const form = useForm({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      mobile: "",
      password: "",
      confirm_password: "",
      address: "",
    },
    validate: {
      email: (val: string) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val)
          ? null
          : "Invalid email",
      firstname: (val: string) =>
        val.length <= 2
          ? "First name should include at least 2 characters"
          : val.length > 50
          ? "First name must be less than 50 characters"
          : null,
      lastname: (val: string) =>
        val.length > 50 ? "Last name must be less than 50 characters" : null,
      password: (val: string) =>
        val.length <= 5
          ? "Password should include at least 6 characters"
          : null,
      mobile: (val: string) =>
        val.length < 10
          ? "Mobile number should include at least 10 characters"
          : null,
      confirm_password: (val: string, values) =>
        val !== values.password ? "Password does not match" : null,
      address: (val: string) =>
        val.length <= 2
          ? "Address should include at least 2 characters"
          : val.length > 150
          ? "Address must be less than 150 characters"
          : null,
    },
  });

  const { registerUser, isRegistered, loading, error, errorMessage } =
    useRegister();

  useEffect(() => {
    if (isRegistered) {
      notifications.show({
        title: "Success",
        message: "User registered successfully",
        color: "green",
      });
      form.reset();
      history("/login");
    } else if (error) {
      notifications.show({
        title: "Register failed",
        message: errorMessage,
        color: "red",
      });
    }
  }, [isRegistered, errorMessage, error]);

  const handleUserRegistration = async () => {
    const registerData: any = {
      firstname: form.values.firstname,
      lastname: form.values.lastname,
      email: form.values.email,
      password: form.values.password,
      mobile: form.values.mobile,
      address: form.values.address,
      usertype: "default",
    };

    if (registerData.lastname.length === 0) {
      delete registerData.lastname;
    }

    await registerUser(registerData);
  };

  const handleModeToggle = (e: any) => {
    e.preventDefault();
    form.reset();
    history("/login");
  };

  return (
    <Container size={420} my={40}>
      <Title>Welcome!</Title>
      <Text color="dimmed" mt={5}>
        Register your account
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit(() => handleUserRegistration())}>
          <Stack>
            <>
              <TextInput
                withAsterisk
                classNames={{
                  label: classes.mantineLabel,
                }}
                label="First Name"
                placeholder="Enter First name"
                maxLength={50}
                value={form.values.firstname}
                error={form.errors.firstname}
                onChange={(event) => {
                  form.setFieldValue("firstname", event.currentTarget.value);
                }}
                radius="md"
              />
              <TextInput
                classNames={{
                  label: classes.mantineLabel,
                }}
                label="Last Name"
                maxLength={50}
                placeholder="Enter Last name"
                value={form.values.lastname}
                error={form.errors.lastname}
                onChange={(event) => {
                  form.setFieldValue("lastname", event.currentTarget.value);
                }}
                radius="md"
              />
              <TextInput
                classNames={{
                  label: classes.mantineLabel,
                }}
                withAsterisk
                type="number"
                label="Mobile Number"
                placeholder="Your contact number"
                value={form.values.mobile}
                onChange={(event) => {
                  form.setFieldValue(
                    "mobile",
                    String(event.currentTarget.value)
                  );
                }}
                error={form.errors.mobile}
                radius="md"
              />
              <TextInput
                classNames={{
                  label: classes.mantineLabel,
                }}
                withAsterisk
                autoComplete="new-email"
                label="Email"
                placeholder="Your email"
                value={form.values.email}
                onChange={(event) => {
                  form.setFieldValue("email", event.currentTarget.value);
                }}
                error={form.errors.email}
                radius="md"
              />
              <TextInput
                classNames={{
                  label: classes.mantineLabel,
                }}
                withAsterisk
                label="Address"
                maxLength={150}
                placeholder="Enter your address"
                value={form.values.address}
                error={form.errors.address}
                onChange={(event) => {
                  form.setFieldValue("address", event.currentTarget.value);
                }}
                radius="md"
              />
            </>

            <PasswordInput
              classNames={{
                label: classes.mantineLabel,
              }}
              withAsterisk
              autoComplete="new-password"
              label="Password"
              placeholder="Your password"
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={form.errors.password}
              radius="md"
            />
            <PasswordInput
              classNames={{
                label: classes.mantineLabel,
              }}
              withAsterisk
              label="Confirm Password"
              placeholder="Password"
              value={form.values.confirm_password}
              onChange={(event) => {
                form.setFieldValue(
                  "confirm_password",
                  event.currentTarget.value
                );
              }}
              error={form.errors.confirm_password}
              radius="md"
            />
          </Stack>

          <Group mt="xl">
            <Anchor
              component="button"
              type="button"
              color="dimmed"
              onClick={handleModeToggle}
              size="xs"
            >
              Already have an account? Login
            </Anchor>
            <Button loading={loading} type="submit" radius="xl" ml="auto">
              {upperFirst("Register")}
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
};

export default Register;
