import {
  Group,
  ActionIcon,
  useMantineColorScheme,
  Box,
  Burger,
  Tooltip,
  Title,
  em,
} from "@mantine/core";
import {
  IconSun,
  IconMoonStars,
  IconLock,
  IconCheck,
  IconX,
} from "@tabler/icons-react";

import { Profile } from "../components/ProfileCard";
import LogoutButton from "../components/LogoutButton";
import { useEffect, useState } from "react";
import ResetPassword from "../components/ResetPassword";
import { notifications } from "@mantine/notifications";
import { useEditPassword, useGetUserProfile } from "../modules/auth/authHooks";
import { useMediaQuery } from "@mantine/hooks";

// this function should implement on userClick
// define interface for BrandProps
interface BrandProps {
  userClick: () => void;
  handleLogout: () => void;
  mobileOpened: boolean;
  toggleMobile: () => void;
  businessName: string;
}

export function Brand({
  userClick,
  handleLogout,
  mobileOpened,
  toggleMobile,
  businessName,
}: BrandProps) {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [resetPasswordOpened, setResetPasswordOpened] = useState(false);
  const [resetPasswordFlag, setResetPasswordFlag] = useState(false);
  const mobileSize = useMediaQuery(`(max-width: ${em(699)})`);
  const {
    editUserPassword,

    error: editError,
    errorData: editErrorData,
    isEdited,
  } = useEditPassword();
  const { getUserProfile } = useGetUserProfile();

  useEffect(() => {
    if (isEdited && resetPasswordFlag) {
      notifications.show({
        title: "Success",
        message: "Password Reset Successfully",
        color: "green",
        icon: <IconCheck size="1.1rem" />,
      });
      handleResetPasswordClose();
      const userId = localStorage.getItem("userId");
      getUserProfile(userId ?? "");
    } else if (editError && resetPasswordFlag) {
      notifications.show({
        title: "Error",
        message: editErrorData,
        color: "red",
        icon: <IconX size="1.1rem" />,
      });
    }
  }, [isEdited, editError]);

  const handleResetPasswordClose = () => {
    setResetPasswordOpened(false);
  };

  const handleResetPassword = (values: any) => {
    const resetValues = {
      currentpassword: values.currentpassword,
      newpassword: values.newpassword,
    };
    editUserPassword(resetValues);
    setResetPasswordFlag(true);
  };

  return (
    <>
      <ResetPassword
        resetPasswordOpened={resetPasswordOpened}
        handleResetPassword={handleResetPassword}
        handleResetPasswordClose={handleResetPasswordClose}
      />
      <Box
        style={(theme) => ({
          paddingLeft: theme.spacing.xs,
          paddingRight: theme.spacing.xs,
          paddingBottom: theme.spacing.xs,
          paddingTop: theme.spacing.xs,
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <Group>
          <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm"
            size="sm"
          />
          <Title
            display={mobileSize ? "none" : "flex"}
            order={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              letterSpacing: "0.2px",
            }}
            ml={{ lg: 300, md: 300, xl: 300 }}
          >
            {businessName}
          </Title>
        </Group>
        <Group>
          <Tooltip label={colorScheme === "dark" ? "Light Mode" : "Dark Mode"}>
            <ActionIcon
              variant="default"
              onClick={() => toggleColorScheme()}
              size={30}
            >
              {colorScheme === "dark" ? (
                <IconSun size="1rem" />
              ) : (
                <IconMoonStars size="1rem" />
              )}
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Reset Password">
            <ActionIcon
              variant="default"
              onClick={() => setResetPasswordOpened(true)}
              size={30}
            >
              <IconLock size="1rem" />
            </ActionIcon>
          </Tooltip>
          <Profile />
          <LogoutButton handleLogout={handleLogout} />
        </Group>
      </Box>
    </>
  );
}
