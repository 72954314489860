import { createSlice } from "@reduxjs/toolkit";
import {
  downloadAggregateReports,
  downloadBillRefundReports,
  downloadBillWiseReports,
  downloadDayWiseReports,
  downloadGSTSummaryReports,
  downloadItemWiseReports,
  downloadMonthlyWiseReports,
  downloadSummaryReports,
  downloadUserWiseReports,
  downloadVoidBillWiseReports,
  getAggregate,
  getBillRefund,
  getBillRefundSummary,
  getBillWise,
  getDashboardData,
  getDayWise,
  getGSTSummary,
  getItemWise,
  getMonthlyWise,
  getSummary,
  getUserWise,
  getVoidBillWise,
} from "./reportActions";

interface GetDashboardDataState{
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

interface ReportState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}
interface GetBillWiseState {
  loading: boolean;
  errorData?: string | null;
  error?: boolean;
  data?: any;
}

const initialGetDashboardState: GetDashboardDataState = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
};

const initialGetBillWiseState: GetBillWiseState = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
};

const initialGetDayWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetItemWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetMonthlyWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetGSTSummaryState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetSummaryState: GetBillWiseState = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
};

const initialGetBillRefundSummaryState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetUserReportState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetVoidBillWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetBillRefundState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialGetAggregateState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadBillWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
};

const initialDownloadDayWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadItemWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadMonthlyWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadGSTSummaryState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadSummaryState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: {},
};

const initialDownloadUserReportState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadVoidBillWiseState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadBillRefundState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

const initialDownloadAggregateState: ReportState = {
  loading: false,
  errorData: null,
  error: false,
  data: [],
};

export const getDashboardDataSlice = createSlice({
  name: "report/getDashboardData",
  initialState: initialGetDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = {};
        state.error = false;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload[0];
        state.error = false;
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = {};
        state.error = true;
      });
  },
});

export const getBillWiseSlice = createSlice({
  name: "report/getBillWise",
  initialState: initialGetBillWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillWise.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = {};
        state.error = false;
      })
      .addCase(getBillWise.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getBillWise.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getDayWiseSlice = createSlice({
  name: "report/getDayWise",
  initialState: initialGetDayWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDayWise.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getDayWise.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getDayWise.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getItemWiseSlice = createSlice({
  name: "report/getItemWise",
  initialState: initialGetItemWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getItemWise.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getItemWise.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getItemWise.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getMonthlyWiseSlice = createSlice({
  name: "report/getMonthlyWise",
  initialState: initialGetMonthlyWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMonthlyWise.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getMonthlyWise.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getMonthlyWise.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getGSTSummarySlice = createSlice({
  name: "report/getGSTSummary",
  initialState: initialGetGSTSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGSTSummary.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getGSTSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getGSTSummary.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getSummarySlice = createSlice({
  name: "report/getSummary",
  initialState: initialGetSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummary.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getSummary.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getBillRefundSummarySlice = createSlice({
  name: "report/getBillRefundSummary",
  initialState: initialGetBillRefundSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillRefundSummary.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getBillRefundSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getBillRefundSummary.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getUserReportSlice = createSlice({
  name: "report/getUserWise",
  initialState: initialGetUserReportState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserWise.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getUserWise.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getUserWise.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getVoidBillWiseSlice = createSlice({
  name: "report/getVoidBillWise",
  initialState: initialGetVoidBillWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVoidBillWise.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getVoidBillWise.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getVoidBillWise.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getBillRefundSlice = createSlice({
  name: "report/getBillRefund",
  initialState: initialGetBillRefundState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillRefund.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getBillRefund.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getBillRefund.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const getAggregateSlice = createSlice({
  name: "report/getAggregate",
  initialState: initialGetAggregateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAggregate.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(getAggregate.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(getAggregate.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadBillWiseReportsSlice = createSlice({
  name: "report/downloadBillWiseReport",
  initialState: initialDownloadBillWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadBillWiseReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadBillWiseReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
        // state.data = action.payload;
      })
      .addCase(downloadBillWiseReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadDayWiseReportsSlice = createSlice({
  name: "report/downloadDayWiseReport",
  initialState: initialDownloadDayWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadDayWiseReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadDayWiseReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(downloadDayWiseReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadItemWiseReportsSlice = createSlice({
  name: "report/downloadItemWiseReport",
  initialState: initialDownloadItemWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadItemWiseReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data =[];
        state.error = false;
      })
      .addCase(downloadItemWiseReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        // state.data = action.payload;
      })
      .addCase(downloadItemWiseReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data =[];
        state.error = true;
      });
  },
});

export const downloadMonthlyWiseReportsSlice = createSlice({
  name: "report/downloadMonthlyWiseReport",
  initialState: initialDownloadMonthlyWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadMonthlyWiseReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadMonthlyWiseReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(downloadMonthlyWiseReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadGSTSummarySlice = createSlice({
  name: "report/downloadGSTReport",
  initialState: initialDownloadGSTSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadGSTSummaryReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadGSTSummaryReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
      })
      .addCase(downloadGSTSummaryReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadSummarySlice = createSlice({
  name: "report/downloadSummaryReport",
  initialState: initialDownloadSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadSummaryReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = {};
        state.error = false;
      })
      .addCase(downloadSummaryReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
      })
      .addCase(downloadSummaryReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = {};
        state.error = true;
      });
  },
});

export const downloadBillRefundSlice = createSlice({
  name: "report/downloadBillRefundReport",
  initialState: initialDownloadBillRefundState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadBillRefundReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadBillRefundReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
      })
      .addCase(downloadBillRefundReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadUserReportSlice = createSlice({
  name: "report/downloadUserWiseReport",
  initialState: initialDownloadUserReportState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadUserWiseReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadUserWiseReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
      })
      .addCase(downloadUserWiseReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadVoidBillWiseSlice = createSlice({
  name: "report/downloadVoidBillWiseReport",
  initialState: initialDownloadVoidBillWiseState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadVoidBillWiseReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data = [];
        state.error = false;
      })
      .addCase(downloadVoidBillWiseReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
      })
      .addCase(downloadVoidBillWiseReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data = [];
        state.error = true;
      });
  },
});

export const downloadAggregateSlice = createSlice({
  name: "report/downloadAggregateReport",
  initialState: initialDownloadAggregateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadAggregateReports.pending, (state) => {
        state.loading = true;
        state.errorData = null;
        state.data =[];
        state.error = false;

      })
      .addCase(downloadAggregateReports.fulfilled, (state, action) => {
        state.loading = false;
        state.errorData = null;
        state.data = action.payload;
        state.error = false;
      })
      .addCase(downloadAggregateReports.rejected, (state, action) => {
        state.loading = false;
        state.errorData = action.error.message;
        state.data =[];
        state.error = true;
      });
  },
});

export const getDashboardDataReducer = getDashboardDataSlice.reducer;
export const getBillWiseReducer = getBillWiseSlice.reducer;
export const getDayWiseReducer = getDayWiseSlice.reducer;
export const getItemWiseReducer = getItemWiseSlice.reducer;
export const getMonthlyWiseReducer = getMonthlyWiseSlice.reducer;
export const getGSTSummaryReducer = getGSTSummarySlice.reducer;
export const getSummaryReducer = getSummarySlice.reducer;
export const getBillRefundSummaryReducer = getBillRefundSummarySlice.reducer;
export const getUserReportReducer = getUserReportSlice.reducer;
export const getVoidBillWiseReducer = getVoidBillWiseSlice.reducer;
export const getBillRefundReducer = getBillRefundSlice.reducer;
export const getAggregateReducer = getAggregateSlice.reducer;
export const downloadBillWiseReducer = downloadBillWiseReportsSlice.reducer;
export const downloadDayWiseReducer = downloadDayWiseReportsSlice.reducer;
export const downloadItemWiseReducer = downloadItemWiseReportsSlice.reducer;
export const downloadMonthlyWiseReducer =
  downloadMonthlyWiseReportsSlice.reducer;
export const downloadGSTSummaryReducer = downloadGSTSummarySlice.reducer;
export const downloadSummaryReducer = downloadSummarySlice.reducer;
export const downloadBillRefundReducer =
  downloadBillRefundSlice.reducer;
export const downloadUserReportReducer = downloadUserReportSlice.reducer;
export const downloadVoidBillWiseReducer = downloadVoidBillWiseSlice.reducer;
export const downloadAggregateReducer = downloadAggregateSlice.reducer;
