import React from "react";

import {
  Paper,
  Text,
  Table,
  SimpleGrid,
  Space,
  useMantineColorScheme,
  ScrollArea,
  Grid,
} from "@mantine/core";
import { convertToIndianCurrency } from "../../../../utils/utils";
const Devicemodal: React.FC<{ item: any }> = ({ item }) => {
  const { colorScheme } = useMantineColorScheme();
  // const isLightMode = colorScheme === "light";
  const isDarkMode = colorScheme === "dark";

  const rows = item.Item.map((sitem: any) => (
    <Table.Tr key={sitem.Timestamp}>
      <Table.Td>{sitem.IName}</Table.Td>
      <Table.Td>{sitem.Qty}</Table.Td>
      <Table.Td>{convertToIndianCurrency(sitem?.Price || 0)}</Table.Td>
      <Table.Td>
        {convertToIndianCurrency(sitem?.Price * sitem?.Qty || 0)}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Grid>
        <Grid.Col p={20} span={{ md: 12, sm: 12, sx: 6 }}>
          <Paper shadow="xl" p="md">
            <SimpleGrid cols={3} spacing="xs">
              <Text>Bill number: {item.BillNo}</Text>
              <Text>No of Items: {item.NoOfItems}</Text>
              <Text> Total Quantity: {item.TQty}</Text>
              <Text> Date: {item.Date}</Text>
              <Text>User ID: {item.UserNo}</Text>
            </SimpleGrid>
          </Paper>
          <Space h="xs" />
          <Paper shadow="xl" p="md">
            <ScrollArea.Autosize mah={150}>
              <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Thead
                  style={{
                    position: "sticky",
                    top: -1,
                    backgroundColor: isDarkMode ? "#12486B" : "white",
                  }}
                >
                  <Table.Tr>
                    <Table.Th>Item Name</Table.Th>
                    <Table.Th>Quantity</Table.Th>
                    <Table.Th>Price</Table.Th>
                    <Table.Th>Total</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            </ScrollArea.Autosize>
          </Paper>
          <Space h="xs" />
          <Paper shadow="xl" p="md">
            <SimpleGrid cols={2} spacing="xs">
              <Text>SubTotal : {convertToIndianCurrency(item.SubTotal)}</Text>
              <Text>CGST : {convertToIndianCurrency(item.CGST)}</Text>
              <Text>SGST : {convertToIndianCurrency(item.SGST)}</Text>
              <Text>Total Cess : {convertToIndianCurrency(item.Tcess)}</Text>
              <Text>
                Total Cess1 : {convertToIndianCurrency(item.TotalCESS1)}
              </Text>
              <Text>
                Total Cess2 : {convertToIndianCurrency(item.TotalCESS2)}
              </Text>
              <Text>
                Discount : {convertToIndianCurrency(item.DiscVal || 0)}
              </Text>
              <Text
                style={{
                  color: isDarkMode ? "#50d8dd" : "#213555",
                }}
              >
                Grand Total : {convertToIndianCurrency(item.TotalAmt)}
              </Text>
              <Text>Payment Mode : {item.PaymentMode}</Text>
            </SimpleGrid>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Devicemodal;
