
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import '@mantine/dropzone/styles.css';
import "./index.css";

import {
  ColorSchemeScript,
  MantineProvider,
  createTheme,
  localStorageColorSchemeManager,
} from "@mantine/core";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import { ModalsProvider } from "@mantine/modals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const colorSchemeManager = localStorageColorSchemeManager({
//   key: "my-app-color-scheme",
// });
const theme = createTheme({
  fontFamily: "Montserrat, sans-serif",
  defaultRadius: "md",
  colors: {
    // override dark colors here to change them for all components
    dark: [
      "#d5d7e0",
      "#acaebf",
      "#8c8fa3",
      "#666980",
      "#4d4f66",
      "#34354a",
      "#272B2F", //components background
      "#212529", //app background
      "#0c0d21",
      "#01010a",
      "#272B2F",
    ],
  },
});

const colorSchemeManager = localStorageColorSchemeManager({
  key: "my-app-color-scheme",
});
root.render(
  // <React.StrictMode>
  <>
    <ColorSchemeScript defaultColorScheme="auto" />
    <MantineProvider
      theme={theme}
      colorSchemeManager={colorSchemeManager}
      defaultColorScheme="dark"
    >
      <HelmetProvider>
        <Helmet titleTemplate="%s | AWATAR" defaultTitle="AWATAR" />
        <Notifications position="top-right" />
        <ModalsProvider labels={{ confirm: "Submit", cancel: "Cancel" }}>
          <App />
        </ModalsProvider>
      </HelmetProvider>
    </MantineProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
